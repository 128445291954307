/*
4.FORMS
├── 4.1 LOGIN FORM
│   ├── 4.1.1 LOGIN FORM DESKTOP
│   └── 4.1.2 LOGIN FORM MOBILE
├── 4.2 FORGOT PASSWORD FORM
│   ├── 4.2.1 FORGOT PASSWORD MODAL FORM
│   └── 4.2.2 FORGOT PASSWORD PAGE FORM
├── 4.3 RESET PASSWORD FORM
│   ├── 4.3.1 RESET PASSWORD PAGE FORM
│   └── 4.3.2 RESET PASSWORD PAGE FORM
└── 4.4 CHANGE PASSWORD FORM
    ├── 4.4.1 CHANGE PASSWORD MODAL FORM
    └── 4.4.2 CHANGE PASSWORD PAGE FORM
*/

/*
| == 4.FORMS
|--------------------------------------------------------------------------
| == 4.1 LOGIN FORM
|--------------------------------------------------------------------------
| == 4.1.1 LOGIN FORM DESKTOP
|--------------------------------------------------------------------------
*/

%G-login-form {
    .ll-input.login__input--name {
        margin-bottom: 3rem;
    }

    .login__input--password {
        margin-bottom: 0;
    }

    .login-form__forgot-pwd-btn {
        font-size: 1.2rem;
        margin: 0 0 2rem auto;
        cursor: pointer;
        text-decoration: underline;
    }

    .login-form__close-btn,
    .login-form__login-btn {
        margin: 1rem 0;
        min-width: 7.5rem;
        border-radius: 2rem;
    }

    .login-form__footer {
        @include flex-config($flex-direction: column);
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .dx-overlay-wrapper {
        &.dx-invalid-message {
            position: relative !important;
            height: 0 !important;
        }
    }

    .dx-invalid-message {
        .dx-overlay-content {
            margin: 0 0 -8px 0 !important;
        }

        &.dx-overlay-wrapper {
            visibility: visible !important;
            transform: none !important;
            transition: all 2s ease-in-out !important;
        }
    }

    .login-form__signup-text {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 500;
    }

    .login-form__sms-auth-text,
    .login-form__sms-auth-btn {
        width: 100%;
        margin-top: 3.5rem;
    }
}

%G-login-form--desktop {
    padding: 10px 50px;
    width: 450px;

    .ll-input.login__input--name {
        margin-bottom: 1rem;
    }

    .login-form__signup-link {
        cursor: pointer;
        text-decoration: underline;
    }

    .login-form__login-btn {
        margin: .5rem auto 2rem;
        width: 14rem;
        height: 3.6rem;
        text-align: center;
    }

    .login-form__sms-auth-text,
    .login-form__sms-auth-btn {
        width: 100%;
        margin-top: 3.5rem;
    }

    .form__captcha-text {
        width: 100%;
        padding: 2rem 2rem 0 2rem;
        font-size: 1.1rem;
        text-align: center;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.1.2 LOGIN FORM MOBILE
|--------------------------------------------------------------------------
*/


%G-login-drawer__logo {
    @include size(140px, 50px);
    margin: 30px auto 30px;
}

/*
|--------------------------------------------------------------------------
| == 4.2 FORGOT PASSWORD FORM
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 4.2.1 FORGOT PASSWORD MODAL FORM
|--------------------------------------------------------------------------
*/

%G-forgot-password-form {
    padding: 0 50px 10px 50px;
    width: 500px;

    .forgot-password-form__footer {
        @include flex-config($justify-content: flex-end);
        width: 100%;
        margin-top: 30px;
    }

    .forgot-password-form__close-btn,
    .forgot-password-form__ok-btn {
        margin: 1px;
        min-width: 75px;
        text-align: center;
    }

    .form__captcha-text {
        width: 100%;
        padding: 2rem 2rem 0 2rem;
        font-size: 1.1rem;
        text-align: center;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.2.2 FORGOT PASSWORD PAGE FORM
|--------------------------------------------------------------------------
*/

%G-forgot-password--mobile {
    padding: 2.2rem 0;
    flex: 1;

    .forgot-password-form {
        padding: 0;
        width: calc(100% - 30px);
        margin: 2.2rem auto;
    }

    .forgot-password__header {
        width: 100%;
    }

    .forgot-password-form__ok-btn {
        width: 100%;
        border-radius: 2rem;
    }

    .form__captcha-text {
        width: 100%;
        padding: 2rem 2rem 0 2rem;
        font-size: 1.1rem;
        text-align: center;
    }

    .dx-overlay-wrapper {
        &.dx-invalid-message {
            position: relative !important;
            height: 0 !important;
        }
    }

    .dx-invalid-message {
        .dx-overlay-content {
            margin: 0 0 -8px 0 !important;
        }

        &.dx-overlay-wrapper {
            .dx-overlay-content {
                visibility: visible !important;
            }
        }
    }
}

%G-forgot-password--tablet {
    width: 50%;
    margin: auto;
    margin-top: 1rem;
}

/*
|--------------------------------------------------------------------------
| == 4.3 RESET PASSWORD FORM
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 4.3.1 RESET PASSWORD FORM
|--------------------------------------------------------------------------
*/

%G-reset-password--desktop {
    @include size(60%, 50vh);
    margin: auto;
    padding: 0 5%;

    .reset-password__form-container {
        padding: 0;
        width: calc(100% - 30px);
        margin: 22px auto;
    }

    .reset-password__fields-container {
        @include flex-config($justify-content: space-between);

        .ll-input {
            width: 40%;
        }
    }

    .reset-password__header {
        width: 100%;
        margin-top: 2rem;
    }

    .reset-password__wrong-code {
        @include size(60%, 50vh);
        margin: auto;
        @include flex-config(
            $flex-direction: column,
            $align-items: center,
            $justify-content: center
        );
    }

    .password-strength__label {
        width: 100%;
        display: inline-flex;
    }

    .password-strength {
        @include flex-config($flex-direction: column, $justify-content: center);
        position: relative;
        padding: 3.5rem 0;
        width: 100%;
        margin: auto;
    }

    .password-strength__str {
        position: relative;
    }

    .dx-overlay-wrapper {
        &.dx-invalid-message {
            position: relative !important;
            height: 0 !important;
        }
    }

    .dx-invalid-message {
        .dx-overlay-content {
            margin: 0 0 -8px 0 !important;
        }

        &.dx-overlay-wrapper {
            .dx-overlay-content {
                visibility: visible !important;
            }
        }
    }

    .reset-password__submit-btn {
        width: 15%;
        border-radius: 2rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.3.2 RESET PASSWORD PAGE FORM
|--------------------------------------------------------------------------
*/

%G-reset-password--tablet {
    width: 50%;
    margin: 22px auto;
}

%G-reset-password--mobile {
    padding: 1rem;

    .reset-password__form-container {
        padding: 0;
        width: calc(100% - 30px);
        margin: 22px auto;
    }

    .reset-password__header {
        width: 100%;
    }

    .reset-password__wrong-code {
        @include size(60%, 50vh);
        @include flex-config(
            $flex-direction: column,
            $align-items: center,
            $justify-content: center
        );
        margin: auto;
    }

    .password-strength__label {
        width: 100%;
        display: inline-flex;
    }

    .password-strength {
        @include flex-config($flex-direction: column, $justify-content: center);
        padding-bottom: 1.5rem;
        width: 100%;
        margin: auto;
        margin-top: 2rem;
    }

    .password-strength__str {
        position: relative;
        overflow: hidden;
    }

    .dx-overlay-wrapper.dx-invalid-message {
        &.dx-invalid-message {
            position: relative !important;
            height: 0 !important;
        }
    }

    .dx-invalid-message {
        .dx-overlay-content {
            margin: 0 0 -8px 0 !important;
        }

        .dx-overlay-wrapper {
            .dx-overlay-content {
                visibility: visible !important;
            }
        }
    }

    .reset-password__submit-btn {
        width: 100%;
        border-radius: 2rem;
    }

    .ll-input {
        margin-bottom: 4rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.4 CHANGE PASSWORD FORM
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 4.4.1 CHANGE PASSWORD MODAL FORM
|--------------------------------------------------------------------------
*/

%G-change-password {
    width: 50rem;
    @include flex-config($justify-content: center);
    padding: 0 5% 3rem;

    .change-password__form-container {
        width: 90%;
        @include flex-config($align-items: flex-end, $flex-direction: column);
    }

    .change-password__header {
        width: 100%;
    }

    .dx-invalid-message > .dx-overlay-content {
        padding: 0;
    }

    .password-strength__label {
        width: 100%;
        margin-bottom: .5rem;
    }

    .password-strength {
        @include flex-config($flex-direction: column, $justify-content: center);
        padding: 3rem 0 1.5rem 0;
        width: 100%;
        margin: auto;
        position: relative;
    }

    .password-strength__str {
        position: relative;
    }

    .dx-overlay-wrapper.dx-invalid-message {
        position: relative !important;
        height: 0 !important;
    }

    .dx-invalid-message {
        .dx-overlay-content {
            margin: 0 0 -8px 0 !important;
        }

        &.dx-overlay-wrapper {
            .dx-overlay-content {
                visibility: visible !important;
            }
        }
    }

    .change-password__submit-btn-box {
        margin-top: 1.5rem;
    }

    .change-password__submit-btn {
        margin-left: 2rem;
    }

    .dx-button-content {
        padding: 1rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.4.2 CHANGE PASSWORD PAGE FORM
|--------------------------------------------------------------------------
*/

%G-change-password--mobile {
    flex: 1;
    width: 100%;

    .change-password {
        width: auto;
        flex-direction: column;

        @include mq('tablet') {
            width: 65%;
            margin: auto;
        }

        @include mq('mobile') {
            width: 100%;
        }
    }

    .change-password__form-container {
        padding: 0;
        width: calc(100% - 3rem);
        margin: 2.2rem auto;
        display: inline-block;

        @include mq('mobile') {
            display: block;
        }
    }

    .change-password__header {
        width: 100%;
        margin: 2.2rem 0;
    }

    .password-strength__label {
        width: 100%;
        display: inline-flex;
        padding-bottom: 0.5rem;
    }

    .password-strength {
        @include flex-config($flex-direction: column, $justify-content: center);
        padding: 0 0 1.5rem 0;
        width: 100%;
        margin: auto;
    }

    .password-strength__str {
        position: relative;
        width: 100%;
    }

    .dx-overlay-wrapper {
        &.dx-invalid-message {
            position: relative !important;
            height: 0 !important;
        }
    }

    .dx-invalid-message {
        .dx-overlay-content {
            margin: -12px 0 -8px 0 !important;
        }

        &.dx-overlay-wrapper {
            .dx-overlay-content {
                visibility: visible !important;
            }
        }
    }

    .change-password__submit-btn--mobile {
        width: 100%;
        border-radius: 2rem;
    }

    .ll-input {
        padding-bottom: 3.5rem;
        width: 100%;
    }
}

.ll-clear-bets-modal {
    .ll-cear-bets-modal__text {
        font-size: 1.4rem;
        padding: 2rem
    }
    .ll-cear-bets-modal__footer {
        width: 100%;
        padding: 2rem 1rem;
        @include flex-config($justify-content: flex-end);
        .ll-clear-bets-modal__close-btn, .ll-clear-bets-modal__yes-btn {
            min-width: 7rem;
        }
        .ll-clear-bets-modal__yes-btn {
            margin-right: 1rem;
        }
    }
}