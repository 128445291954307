@font-face {
    font-family: 'Doruk-Casino-Icons';
    src:  url('../../static/fonts/Doruk-Casino-Icons.eot?62ndy3');
    src:  url('../../static/fonts/Doruk-Casino-Icons.eot?62ndy3#iefix') format('embedded-opentype'),
    url('../../static/fonts/Doruk-Casino-Icons.ttf?62ndy3') format('truetype'),
    url('../../static/fonts/Doruk-Casino-Icons.woff?62ndy3') format('woff'),
    url('../../static/fonts/Doruk-Casino-Icons.svg?62ndy3#Doruk-Casino-Icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="doruk-"], [class*=" doruk-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Doruk-Casino-Icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.doruk-mg-arrow-2:before {
    content: "\e900";
}
.doruk-bet-on-games-01:before {
    content: "\e901";
}
.doruk-bets-slip-01:before {
    content: "\e902";
}
.doruk-bonuses-01:before {
    content: "\e903";
}
.doruk-casino-01:before {
    content: "\e904";
}
.doruk-delete-01:before {
    content: "\e905";
}
.doruk-deposit-01:before {
    content: "\e906";
}
.doruk-filter-featured-01:before {
    content: "\e907";
}
.doruk-filter-for-fun-01:before {
    content: "\e908";
}
.doruk-filter-game-type-01:before {
    content: "\e909";
}
.doruk-filter-new-01:before {
    content: "\e90a";
}
.doruk-filter-play-mode-01:before {
    content: "\e90b";
}
.doruk-filter-popular-01:before {
    content: "\e90c";
}
.doruk-filter-provider-01:before {
    content: "\e90d";
}
.doruk-filter-real-01:before {
    content: "\e90e";
}
.doruk-filter-sort-by-01:before {
    content: "\e90f";
}
.doruk-filter-sort-by-1-9-01:before {
    content: "\e910";
}
.doruk-filter-sort-by-9-1-01:before {
    content: "\e911";
}
.doruk-filter-sort-by-a-z-01:before {
    content: "\e912";
}
.doruk-filter-sort-by-z-a-01:before {
    content: "\e913";
}
.doruk-ma-google-authenticator-01:before {
    content: "\e914";
}
.doruk-live-games-01:before {
    content: "\e915";
}
.doruk-live-match-01:before {
    content: "\e916";
}
.doruk-ma-my-information-01:before {
    content: "\e917";
}
.doruk-login-01:before {
    content: "\e918";
}
.doruk-lottery-01:before {
    content: "\e919";
}
.doruk-ma-account-history-01:before {
    content: "\e91a";
}
.doruk-ma-betting-history-01:before {
    content: "\e91b";
}
.doruk-ma-casino-report-01:before {
    content: "\e91c";
}
.doruk-ma-deposit-01:before {
    content: "\e91d";
}
.doruk-ma-my-limits-01:before {
    content: "\e91e";
}
.doruk-ma-mssages-01:before {
    content: "\e91f";
}
.doruk-sports-menu-01:before {
    content: "\e920";
}
.doruk-ma-my-limits-011:before {
    content: "\e921";
}
.doruk-ma-withdraw-01:before {
    content: "\e922";
}
.doruk-ma-withdraw-status-01:before {
    content: "\e923";
}
.doruk-money-01:before {
    content: "\e924";
}
.doruk-my-accont-01:before {
    content: "\e925";
}
.doruk-search-01:before {
    content: "\e926";
}
.doruk-slots-01:before {
    content: "\e927";
}
.doruk-about-us-01:before {
    content: "\e928";
}
.doruk-support-01:before {
    content: "\e929";
}
.doruk-virtual-games-01:before {
    content: "\e92a";
}
.doruk-aviator-01:before {
    content: "\e92b";
}
.doruk-close:before {
    content: "\e92c";
}
.doruk-live-ball:before {
    content: "\e92d";
}
.doruk-live-betlist:before {
    content: "\e92e";
}
.doruk-live-calendar:before {
    content: "\e92f";
}
.doruk-live-corner:before {
    content: "\e930";
}
.doruk-live-overview:before {
    content: "\e931";
}
.doruk-clock:before {
    content: "\e932";
}
.doruk-time:before {
    content: "\e933";
}
.doruk-tournament:before {
    content: "\e934";
}
.doruk-away:before {
    content: "\e935";
}
.doruk-home:before {
    content: "\e936";
}
.doruk-category:before {
    content: "\e937";
}
.doruk-live-support-01:before {
    content: "\e938";
}
.doruk-rules-01:before {
    content: "\e939";
}
.doruk-questions:before {
    content: "\e93a";
}
.doruk-streaming-icon:before {
    content: "\e93b";
}
.doruk-tvbet-icon:before {
    content: "\e93c";
}
.doruk-bonuse-discount-1:before {
    content: "\e93d";
}
.doruk-bonus-gift-1:before {
    content: "\e93e";
}
.doruk-bonus-main:before {
    content: "\e93f";
}
.doruk-error-danger:before {
    content: "\e940";
}
.doruk-error-stop-1:before {
    content: "\e941";
}
.doruk-error-warning:before {
    content: "\e942";
}
.doruk-error-x-1:before {
    content: "\e943";
}
.doruk-info-1:before {
    content: "\e944";
}
.doruk-info-2:before {
    content: "\e945";
}
.doruk-info-message-1:before {
    content: "\e946";
}
.doruk-info-question-1:before {
    content: "\e947";
}
.doruk-new-award-1:before {
    content: "\e948";
}
.doruk-new-hot-1:before {
    content: "\e949";
}
.doruk-new-hot-2:before {
    content: "\e94a";
}
.doruk-new-main-1:before {
    content: "\e94b";
}
.doruk-new-2:before {
    content: "\e94c";
}
.doruk-popup-message-info-1:before {
    content: "\e94d";
}
.doruk-success-bitcoin-1:before {
    content: "\e94e";
}
.doruk-success-crown-1:before {
    content: "\e94f";
}
.doruk-success-money-1:before {
    content: "\e950";
}
.doruk-success-money-2:before {
    content: "\e951";
}
.doruk-success-star-1:before {
    content: "\e952";
}
.doruk-asian-cup-01:before {
    content: "\e953";
}
.doruk-baseball-01:before {
    content: "\e954";
}
.doruk-basketball-01:before {
    content: "\e955";
}
.doruk-champions-cup-01:before {
    content: "\e956";
}
.doruk-dog-racing-01:before {
    content: "\e957";
}
.doruk-euro-cup-01:before {
    content: "\e958";
}
.doruk-football-01:before {
    content: "\e959";
}
.doruk-horse-racing-01:before {
    content: "\e95a";
}
.doruk-nations-cup-01:before {
    content: "\e95b";
}
.doruk-tennis-01:before {
    content: "\e95c";
}
.doruk-virtual-football-01:before {
    content: "\e95d";
}
.doruk-world-cup-01:before {
    content: "\e95e";
}
.doruk-widget-bet-assist:before {
    content: "\e95f";
}
.doruk-widget-h2h:before {
    content: "\e960";
}
.doruk-widget-lmt:before {
    content: "\e961";
}
.doruk-widget-preview:before {
    content: "\e962";
}
.doruk-filters:before {
    content: "\e963";
}
.doruk-others:before {
    content: "\e964";
}
.doruk-racing:before {
    content: "\e965";
}
.doruk-baccarat:before {
    content: "\e966";
}
.doruk-bet-on-numbers:before {
    content: "\e967";
}
.doruk-bingo:before {
    content: "\e968";
}
.doruk-blackjack:before {
    content: "\e969";
}
.doruk-keno:before {
    content: "\e96a";
}
.doruk-low-stakes-blackjack:before {
    content: "\e96b";
}
.doruk-money-wheel:before {
    content: "\e96c";
}
.doruk-other:before {
    content: "\e96d";
}
.doruk-poker:before {
    content: "\e96e";
}
.doruk-roulette:before {
    content: "\e96f";
}
.doruk-rulet:before {
    content: "\e970";
}
.doruk-sic-bo:before {
    content: "\e971";
}
.doruk-virtual:before {
    content: "\e972";
}
.doruk-bonus-talep-01:before {
    content: "\e973";
}
.doruk-bundesliga-01:before {
    content: "\e974";
}
.doruk-promoted:before {
    content: "\e975";
}
.doruk-close-circle:before {
    content: "\e976";
}
.doruk-dots:before {
    content: "\e977";
}
.doruk-active-bets:before {
    content: "\e978";
}
.doruk-edit:before {
    content: "\e979";
}
.doruk-list:before {
    content: "\e97a";
}
.doruk-cashout-01:before {
    content: "\e97b";
}
.doruk-share:before {
    content: "\e97c";
}
.doruk-calendar:before {
    content: "\e97d";
}
.doruk-triangle:before {
    content: "\e97e";
}
.doruk-prom2:before {
    content: "\e97f";
}
.doruk-promotions:before {
    content: "\e980";
}
.doruk-slot-battles:before {
    content: "\e981";
}
.doruk-copy-icon:before {
    content: "\e982";
}
.doruk-bahissenin-tv-icon:before {
    content: "\e983";
}
.doruk-maksat-tv-icon:before {
    content: "\e984";
}
.doruk-cricket-01:before {
    content: "\e985";
}
.doruk-auto-refresh:before {
    content: "\e986";
}
.doruk-queston:before {
    content: "\e987";
}
.doruk-coins:before {
    content: "\e988";
}
.doruk-minus:before {
    content: "\e989";
}
.doruk-plus:before {
    content: "\e98a";
}
.doruk-promoted1:before {
    content: "\e98b";
}
.doruk-soccer-english-league:before {
    content: "\e98c";
}
.doruk-refresh:before {
    content: "\e98d";
}
.doruk-fire:before {
    content: "\e98e";
}
.doruk-result:before {
    content: "\e98f";
}
.doruk-new:before {
    content: "\e990";
}
.doruk-sport-default-icon:before {
    content: "\e991";
}
.doruk-icon-twain:before {
    content: "\e992";
}
.doruk-bet-amount:before {
    content: "\e993";
}
.doruk-players:before {
    content: "\e994";
}
.doruk-random-arrows:before {
    content: "\e995";
}
.doruk-sort-by-popular:before {
    content: "\e996";
}
.doruk-rocket1:before {
    content: "\e99b";
}
.doruk-add-players:before {
    content: "\e997";
}
.doruk-banknote:before {
    content: "\e998";
}
.doruk-profit:before {
    content: "\e999";
}
.doruk-slot:before {
    content: "\e99a";
}
.doruk-stake-pop-up-icon:before {
    content: "\e99c";
}
.doruk-favorites:before {
    content: "\e99e";
}
.doruk-recently-played:before {
    content: "\e99f";
}
.doruk-star-fill:before {
    content: "\e9a0";
}
.doruk-star-line:before {
    content: "\e9a1";
}
.doruk-check:before {
    content: "\e9a2";
}
.doruk-bb-icon:before {
    content: "\e9a3";
}
.doruk-icon-jackpot:before {
    content: "\e9a4";
}
.doruk-icon-attention:before {
    content: "\e9a5";
}
.doruk-games-lobby:before {
    content: "\e9a6";
}