%G-vaix-widget {
    text-align: left;

    .vaix-widget__title {
        padding: 1rem;
        font-size: 1.4rem;
        font-weight: 500;
    }

    .vaix-widget-item {
        width: 100%;
        @include flex-config($flex-direction: column);
        padding-top: .5rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        row-gap: .3rem;
    }

    .vaix-widget-item__bet {
        height: 5.8rem;
        position: relative;
        display: grid;
        grid-template-columns: 1.9rem 1fr auto;
        padding: 0 1rem;

        &:last-of-type {
            margin-bottom: .5rem;
        }

        &:not(:last-of-type)::before {
            content: '';
            @include size(.1rem, 100%);
            @include position(absolute, top 2rem left 1.6rem);
        }
    }

    .vaix-widget-item__info-row,
    .vaix-widget-item__bet-name {
        min-width: 0;
    }

    .vaix-widget-item__info-row {
        grid-column: 2 / 4;
        @include flex-config($align-items: center, $justify-content: space-between);
    }

    .vaix-widget-item__match-icon {
        @include flex-config($align-items: center);
        font-size: 1.3rem;
        position: relative;
        z-index: 2;

        &::before {
           width: 1.7rem;
        }
    }

    .vaix-widget-item__bet-name {
        margin-right: .5rem;
        @include flex-config($align-items: center);
        font-size: 1.4rem;
        font-weight: 500;
    }

    .vaix-widget-item__bet-value {
        @include flex-config($align-items: center);
        font-size: 1.6rem;
        font-weight: 500;
    }

    .vaix-widget-item__match-name,
    .vaix-widget-item__market-name {
        font-size: 1.2rem;
    }

    .vaix-widget-item__date {
        flex-shrink: 0;
        margin-left: .2rem;
    }

    .vaix-widget-item__submit-box {
        height: 5rem;
        @include flex-config($align-items: center);
        padding: .5rem 1rem;
        border-radius: 3px;
    }

    .vaix-widget-item__combined-odd {
        flex: 1;
    }

    .vaix-widget-item__combined-odd-label {
        font-size: 1.4rem;
    }

    .vaix-widget-item__combined-odd-value {
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: 500;
    }

    .vaix-widget-item__submit-btn {
        height: 4rem;
        max-width: 50%;
        @include flex-config($align-items: center);
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.6rem;

        &:disabled,
        &[disabled] {
            opacity: .7;
            cursor: not-allowed;
        }
    }

    /* ---------------------- Fake slide ---------------------- */

    .vaix-widget-item__match-icon--fake {
        margin-left: -0.1rem;
    }

    .vaix-widget-item__bet-name--fake {
        width: 18rem;
        padding: .7rem 0;
    }

    .vaix-widget-item__info-row--fake {
        width: 10rem;
        padding: .3rem 0;
    }

    .vaix-widget-item__submit-btn--fake {
        background-color: transparent !important;
    }

    .vaix-widget-item__bet-name--fake,
    .vaix-widget-item__info-row--fake {
        .vaix-widget-item__fake-content {
            @include size(100%);
            border-radius: 3px;
            animation: dummyDataPulse 2s infinite ease-in-out;
        }
    }

    .vaix-widget-item__match-icon--fake {
        .vaix-widget-item__fake-content {
            @include size(1.5rem);
            border-radius: 50%;
            animation: dummyDataPulse 2s infinite ease-in-out;
        }
    }
}

%G-vaix-widget--desktop {
    .vaix-widget__title {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }
}
