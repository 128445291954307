/*
3.PAGES
└── 3.1.2. LIVE PAGE
    ├── 3.1.2.1. LIVE WIDGETS
    ├── 3.1.2.2. LIVE MENU (DESKTOP & TABLET)
    ├── 3.1.2.3. MATCH DETAILS (DESKTOP & TABLET)
    ├── 3.1.2.4. LIVE PAGE (MOBILE)
    ├───├── 3.1.2.4.1. LIVE PAGE MENU
    ├───└── 3.1.2.4.2. LIVE PAGE DETAILS
    └── 3.1.2.5. LIVE CALENDAR
*/

/*
|--------------------------------------------------------------------------
| == 3.1.2.1. LIVE WIDGETS
|--------------------------------------------------------------------------
*/

.live-sticky-widget {
    position: fixed;
    width: 100%;
    z-index: 300;

    @include mq('desktop') {
        position: absolute;
        top: 0 !important;
    }
}

/* --------------- Live widgets navigation tabs --------------- */
.live-widgets__nav--mobile {
    @include flex-config($justify-content: space-around);
    @include position(absolute, right -100%);
    transition: all .3s ease-in-out;
    height: 100%;
    width: calc(100% - 4rem);

    .live-widgets__nav-item {
        @include flex-config($flex-direction: column, $align-items: center, $justify-content: space-evenly);
        margin: auto;
        padding: .3rem;
        background: none;
        border: none;
        font-weight: 700;
        font-size: .9rem;
        height: 100%;
    }

    .live-widgets__nav-icon {
        font-size: 1.3rem;
    }

    .live-widgets__nav-text {
        text-align: center;
    }

    &.live-widgets__nav--shoved {
        @include position(absolute, right 4rem);
    }
}

.live-widgets__nav--tablet,
.live-widgets__nav--desktop {
    @include flex-config($justify-content: space-between);
    height: 3.2rem;
    margin-bottom: .3rem;
    border-radius: 3px;

    .live-widgets__nav-item {
        @include flex-config($justify-content: center, $align-items: center);
        position: relative;
        width: 100%;
        font-weight: 700;
        font-size: 1.2rem;

        .live-widgets__nav-icon {
            font-size: 1.5rem;
        }

        .live-widgets__nav-text {
            text-align: center;
        }
    }

    .live-widgets__nav-info {
        @include position(absolute, left 1rem);
    }

    .live-widgets-sticky,
    .live-widgets-sticky--top {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
    }

    .live-widgets-sticky--top {
        position: sticky;
        top: 4.5rem;
    }
}

.live-widgets__nav--desktop {
    width: 100%;

    .live-widgets__nav-info {
        font-size: 1.6rem;
    }

    .live-widgets__nav-item {
        cursor: pointer;

        .live-widgets__nav-icon {
            margin-right: .5rem;
        }
    }
}

.live-widgets__nav--tablet {
    margin: 0;

    .live-widgets__nav-info {
        font-size: 1.2rem;
    }

    .live-widgets__nav-item:first-of-type {
        padding-left: 1rem;
    }

    .live-widgets__nav-icon {
        margin-right: 1rem;
    }
}

.live-widgets {
    position: relative;

    .live-widgets__nav-info-text {
        margin: 1rem;
        padding: 1rem;
        @include position(absolute, top 3.1rem, left 0);
        line-height: 1.3rem;
        border-radius: 3px;
        z-index: 5;
    }
}

.prematch--mobile {
    .live-widgets__nav-container {
        @include flex-config($justify-content: space-between);
        width: 100%;
        overflow: hidden;
        max-width: 100vw;
        position: relative;

        .live-widgets__nav-info {
            @include flex-config($justify-content: center, $align-items: center);
            line-height: 1.2rem;
            padding-right: 1rem;
            margin-left: auto;
            width: min-content;
        }

        .live-widgets__nav-info-icon {
            margin-left: .5rem;
            font-size: 1rem;
        }

        .menu__board {
            border: none;
            box-shadow: none;
            padding: .3rem 1rem;
        }

        .live-widgets__nav-item {
            flex: 1;
            display: flex;
        }
    }
}

/* ------------ Live widgets navigation tabs END ------------ */

.live-detail__section .live-widgets__item {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;
}

/* -------------------- Stream widget -------------------- */
.stream {
    .stream__loader-box {
        @include flex-config($justify-content: center, $align-items: center);
        height: 10.8rem;
    }

    .stream__error-content {
        @include flex-config($flex-direction: column, $align-items: center);
        padding: 2rem 3rem 1rem;
    }

    .stream__error-content-image {
        background-image: url('../../../static/themes/theme/images/icons/warning_small.png');
        background-repeat: no-repeat;
        background-size: contain;
        @include size(6.4rem, 4.8rem);
    }

    .stream__error-text {
        margin-top: 1rem;
        font-size: 1.4rem;
        text-align: center;
    }
}

.prematch--mobile {
    .stream__error-content {
        margin-bottom: .1rem;
    }
}

/* ------------------- Stream widget END ------------------- */

/* ------------------- Live View Switcher START ------------------- */
.prematch--desktop {
    .livematches__view-buttons {
        height: 3.2rem;
        margin-bottom: .3rem;
        @include flex-config();
        border-radius: 3px;
        overflow: hidden;
    }

    .livematches__view-button,
    .livematches__view-button--active {
        @include flex-config($align-items: center);
        overflow: hidden;
        white-space: nowrap;
        text-transform: capitalize;
        cursor: pointer;
        transition: width .8s ease-out;

        &:last-child {
            padding: 0 1.5rem;
        }
    }

    .livematches__view-button {
        width: 5rem;
        justify-content: center;

        .livematches__view-button-text {
            max-width: 0;
            overflow: hidden;
        }
    }

    .livematches__view-button--active {
        width: 25rem;

        .livematches__view-button-icon {
            margin: 0 1rem 0 2rem;
        }

        .livematches__view-button-text {
            max-width: 5rem;
        }
    }

    .livematches__view-button-icon {
        font-size: 1.8rem;
    }

    .livematches__view-button-text {
        transition: max-width .4s ease-out;
    }
}

/* ------------------- Live View Switcher END ------------------- */

/*
|--------------------------------------------------------------------------
| == 3.1.2.2. LIVE MENU (DESKTOP & TABLET)
|--------------------------------------------------------------------------
*/

.live-menu__loading {
    @include flex-config($justify-content: center, $align-items: center);
    height: 100%;
}

.live-menu__section {
    position: relative;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;

    &.live-menu__section--disabled:before {
        content: '';
        @include size(100%);
        position: absolute;
        z-index: 2;
    }

    .live-left-menu__odds-count {
        border-radius: 3rem;
        padding: 0 1.5rem;
    }

    .live-left-menu__header-wrapper + .ll-accordion,
    .live-left-menu__header-wrapper + .ll-accordion--active {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        overflow: hidden;
    }

    .ll-accordion .ll-accordion__header,
    .ll-accordion--active .ll-accordion__header {
        cursor: pointer;
        justify-content: space-between;
        height: 46px;
    }

    .live-common-menu__item-name {
        padding: .4rem 1rem;
        font-size: 1.3rem;
        cursor: pointer;
    }

    .live-common-menu__category-expand-wrp {
        display: inline-block;
        margin-right: .5rem;
    }

    .live-common-menu__category-expand-icon {
        display: inline-block;
        font-size: 1rem;
        transform: rotate(90deg);
        transition: transform 0.3s ease-in-out;
    }

    .live-common-menu__category-expand-icon--expanded {
        transform: rotate(-90deg);
    }

    .live-common-menu__item-content {
        height: auto;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-out;

        &:last-of-type {
            .live-left-menu__item {
                border: none;
            }
        }
    }

    .live-common-menu__item-content--expanded {
        max-height: 3000px;
    }

    .ll-accordion__header-count {
        font-size: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 11px;
        border-radius: 10px;
        width: 51px;
        height: 18px;
    }

    .ll-accordion__sport-icon {
        margin: 0 .8rem;
    }

    .ll-accordion__header-text {
        font-weight: 100;
        padding: 7px 10px 8px;
        text-transform: capitalize;
        @include flex-config($align-items: center);
    }

    .live-left-menu__item {
        position: relative;
        font-size: 12px;
        cursor: pointer;
    }

    .live-left-menu__list-row,
    .live-left-menu__list-row--active {
        font-size: 1.15rem;
        display: flex;
        height: 44px;
        transition: all 0.3s;
        position: relative;

        .live-left-menu__bet {
            position: relative;
        }

        .live-left-menu__bet--disabled {
            cursor: not-allowed;
        }
    }

    .live-left-menu__time {
        min-width: 6rem;
        max-width: 6rem;
        @include flex-config($justify-content: center, $align-items: center);
    }

    .live-left-menu__can-cashout {
        font-size: 1.2rem;
        @include flex-config($justify-content: center, $align-items: center);
    }

    .live-left-menu__bets {
        min-width: 14rem;
        max-width: 14rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .live-left-menu__bet {
        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .live-left-menu__open-detail,
    .live-left-menu__open-detail--options,
    .live-left-menu__no-options {
        min-width: 4.5rem;
        max-width: 18.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .live-left-menu__open-detail--options,
    .live-left-menu__no-options {
        min-width: 18.5rem;
    }

    .live-left-menu__row-header {
        line-height: 12px;
        padding: 3px 0;
        font-size: 10px;
        display: flex;
        height: 18px;
    }

    .live-left-menu__row-header-time {
        width: 6rem;
        @include flex-config($justify-content: center, $align-items: center);
    }

    .live-left-menu__row-header-competitors {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }

    .live-left-menu__row-header-event {
        width: 60%;
        padding-left: 2.6rem;
    }

    .live-left-menu__oddsv,
    .live-left-menu__sides {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .live-left-menu__oddsv {
        width: 33.3%;
    }

    .live-left-menu__oddsv--half {
        width: 50%;
    }

    .live-left-menu__sides {
        width: 4.5rem;
    }

    .live-left-menu__header {
        @include flex-config($justify-content: space-between);
        margin-bottom: .3rem;
        padding: .7rem 1rem .8rem;
        border-radius: 3px;
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    .live-left-menu__bet--dn {
        .live-left-menu__bet-value:after {
            content: '\2794';
            display: inline-block;
            transform: rotate(90deg);
        }
    }

    .live-left-menu__bet--up {
        .live-left-menu__bet-value:after {
            content: '\2794';
            display: inline-block;
            transform: rotate(-90deg);
        }
    }
}

.live-match__competitors-holder {
    min-width: 0;
    @include flex-config();
    flex-grow: 1;

    .live-match__competitors {
        flex: 1;
        height: 100%;
        @include flex-config($flex-direction: column, $justify-content: center);
        line-height: 1.2;
    }

    .live-match__info {
        @include flex-config();
        height: 100%;
    }

    .live-match__info-item {
        @include flex-config($flex-direction: column, $justify-content: center);
        height: 100%;
        min-width: 1.4rem;
        position: relative;
        text-align: center;
    }

    .live-match__info-header-item {
        @include position(absolute, top 0);
        @include flex-config($align-items: center, $justify-content: center);
        transform: translateY(-100%);
        width: 100%;
        font-weight: 600;
        text-align: center;
    }

    .live-match__info-header-icon {
        font-weight: initial;
    }

    .live-match__competitor-card-red:after,
    .live-match__competitor-card-yellow:after {
        content: '';
        display: inline-block;
        border-radius: 1px;
    }

    .live-match__competitor-card-red:after {
        background-color: red;
    }

    .live-match__competitor-card-yellow:after {
        background-color: #ff0;
    }

    .live-match__score {
        font-weight: 700;
        min-width: 2.4rem;
    }

    &.mobile {
        .live-match__competitor-text {
            height: 1.7rem;
            font-size: 1.4rem;
            font-weight: 500;
        }

        .live-match__info-item {
            margin: 0 3px;
            font-size: 1.2rem;
        }

        .live-match__info-header-icon--sport {
            font-size: 1.2rem;
        }

        .doruk-live-corner {
            margin-bottom: .3rem;
        }

        .live-match__competitor-card-red:after,
        .live-match__competitor-card-yellow:after {
            @include size(.8rem, 1.2rem);
        }
    }

    &.desktop,
    &.tablet {
        .live-match__competitor-text {
            height: 1.8rem;
            padding-left: 1rem;
            font-size: 1.2rem;
        }

        .live-match__stream-icon {
            margin-left: auto;
            @include flex-config($justify-content: center, $align-items: center);
            @include size(3rem, 100%);
            flex-shrink: 0;

            & + .live-match__competitor-table-box {
                margin-left: unset;
            }
        }

        .live-match__info-header-item {
            top: -.2rem;
        }

        .live-match__info-header-item--quarter {
            top: -.1rem;
        }

        .live-match__info-header-icon--sport {
            font-size: 1rem;
        }

        .live-match__info-item {
            margin: 0 2px;
        }

        .live-match__competitor-card-red,
        .live-match__competitor-card-yellow {
            @include size(.6rem, .9rem);
            line-height: 0.9rem;
            margin-bottom: .2rem;

            &:after {
                @include size(100%);
            }
        }
    }
}

.prematch--tablet {
    .live-menu__section {
        min-width: 100%;
        max-width: 100%;
    }

    .live-left-menu__header-wrapper {
        @include flex-config();
        height: max-content;
        margin-bottom: .1rem;
    }

    .live-left-menu__header {
        flex: 1;
        margin-bottom: 0;
    }

    .live-left-menu__view-btn,
    .live-details__calendar-btn {
        @include flex-config($justify-content: center, $align-items: center);
        padding: 0 2rem;
        margin-left: .2rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.3. MATCH DETAILS (DESKTOP & TABLET)
|--------------------------------------------------------------------------
*/
.live-detail__section {
    position: relative;
    min-width: 0;
    width: 100%;
    margin-left: .5rem;
    flex: 1;
    overflow: hidden;

    .match-details__breadcrumbs-icon {
        &:before {
            margin: auto;
            width: auto;
        }
    }

    .match-details__header-info-competitor {
        @include flex-config(
                $align-items: center,
                $justify-content: space-between,
                $flex-direction: column
        );
        min-width: 7rem;
        max-width: 7rem;
        height: 7rem;
        text-align: center;
        flex: 1;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            z-index: 0;
            top: .5rem;
            left: .5rem;
            width: 6rem;
            height: 6rem;
            border-radius: 3rem;
            opacity: .3
        }
    }

    .match-details__header-info {
        @include flex-config($align-items: center, $justify-content: space-between);
        @include size(100%, 10.5rem);
        padding: 0.5rem 2rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        margin-bottom: .5rem;
    }

    .match-details__competitor-without-img {
        @include flex-config($align-items: center, $justify-content: center);
        @include size(6rem);
        margin: .5rem;
        z-index: 1;

        .match-details__breadcrumbs-icon {
            font-size: 4rem;
        }
    }

    .match-details__header-info-img {
        height: 6rem;
        margin: .5rem;
        object-fit: contain;
        z-index: 1;
    }

    .match-details__competitor-name {
        text-align: center;
        text-transform: uppercase;
        font-size: 1.6rem;
    }

    .match-details__competitor-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        flex: 1;
    }

    .match-details__description {
        max-width: 50%;
        margin: 0 1rem;
        text-align: center;
    }

    .match-details__score {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    @extend %G-match-details__market;
    @extend %G-match-details__fancy-slider--desktop;
}

.prematch--tablet .live-detail__section {
    margin: 0;
}

.live-market-groups__slider {
    .live-match-details__market-group,
    .live-match-details__market-group--active {
        padding: 0 1rem;
        text-align: center;
        min-height: 40px;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 1.2rem;
        cursor: pointer;
        position: relative;
        display: flex !important;
        width: auto;
        white-space: nowrap;
        @include flex-config($justify-content: center, $align-items: center);
        border: none;

        .live-match-details__progress-bar {
            @include position(absolute, bottom 0);
        }
    }

    .slick-prev,
    .slick-next {
        z-index: 1;
    }

    .slick-prev {
        left: .7rem;

        &:before {
            font-size: 1.5rem;
        }
    }

    .slick-next {
        right: .7rem;

        &:before {
            font-size: 1.5rem;
        }
    }

    .live-match-details__progress-bar {
        @include position(absolute, bottom 0);
    }
}

.live-match-details {
    position: relative;
    width: 100%;
    margin: .1rem 0;

    .match-details__market {
        position: relative;
    }

    .match-details__market-header {
        @include flex-config($align-items: center);
        height: 3rem;
        font-size: 1.3rem;
        font-weight: 500;
        text-align: center;
        text-transform: capitalize;
    }

    .market__icon--can-cashout,
    .market__icon--cant-cashout {
        font-size: 1.3rem;
    }

    .breadcrumbs__tournament {
        cursor: pointer;
        transition: color 0.3s;
    }

    .match-details__markets-error {
        padding-bottom: 1rem;
        text-align: center;
    }

    .match-details__markets {
        margin: 5px 0;
    }

    .match-details__market--empty {
        display: none;
    }

    .match-details__bet {
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 0 1rem;
        position: relative;
        height: 4rem;
        transition: all 0.3s;
        cursor: pointer;

        &.match-details__bet--disabled {
            cursor: not-allowed;
        }
    }

    .match-details__bet--dn {
        .match-details__bet-value:after {
            content: '\2794';
            display: inline-block;
            transform: rotate(90deg);
        }
    }

    .match-details__bet--up {
        .match-details__bet-value:after {
            content: '\2794';
            display: inline-block;
            transform: rotate(-90deg);
        }
    }

    .match-details__bet-value {
        text-align: right;
        min-width: 5rem;
    }

    .live-match-details__bet-name {
        padding: 0 8px;
        min-width: 0;
    }

    .match-details__bets--contains-1,
    .match-details__bets--contains-2,
    .match-details__bets--contains-3 {
        display: flex;
        flex-wrap: wrap;
        box-shadow: inset 0 1px 0 0 #383838;

        .match-details__bet {
            min-width: 0;
        }
    }

    .match-details__bets--contains-3 {
        .match-details__bet {
            flex: 1 1 33.3%;
        }
    }

    .match-details__bets--contains-2 {
        .match-details__bet {
            flex: 1 1 50%;
        }
    }

    .match-details__bets--contains-1 {
        .match-details__bet {
            box-shadow: none;
        }
    }

    .match-details__bets {
        @include flex-config($flex-wrap: wrap);
    }
}

.live-match-details__landscape {
    @include flex-config($justify-content: center);
    height: 100%;

    .live-match-details__landscape-widgets {
        position: relative;
    }

    .live-page__landscape-scrollbox {
        flex: 1;
        min-height: calc(100vh - 215px);
        min-width: 0;

        &:first-of-type {
            margin-right: .5rem;
        }
    }

    .live-match-details {
        margin: 0 .5rem 0 0;
    }

    .live-widgets__nav-item {
        font-size: 1rem;
        line-height: 1.1rem;
    }
}


/*
|--------------------------------------------------------------------------
| == 3.1.2.4. LIVE PAGE (MOBILE)
|--------------------------------------------------------------------------
*/

.prematch--mobile {
    flex: 1;
    padding: 0;
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.4.1. LIVE PAGE MENU
|--------------------------------------------------------------------------
*/

.livematches {
    height: 100%;
    @include flex-config($flex-direction: column);

    .livematches__list {
        flex: 1;
        @include flex-config($flex-direction: column);
    }

    .bet__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .livematches__view-selectors {
        width: 100%;
        height: 4rem;
        padding: 1rem;
        @include flex-config($justify-content: space-between, $align-items: center);
    }

    .livematches__title {
        font-size: 1.4rem;
    }

    .livematches__sport-item {
        @include flex-config($justify-content: space-between, $align-items: center);
        font-size: 1.4rem;
        position: relative;
        padding: 1rem;
        cursor: pointer;
        border-radius: 0;
        display: flex;
    }

    .livematches__sport-name {
        @include flex-config($align-items: center);

        .menu-item__icon {
            margin-right: 1rem;
        }
    }

    .livematches__sport-match-count {
        width: 3.5rem;
        padding: .1rem .2rem;
        text-align: center;
        font-size: 1.2rem;
        border-radius: 1rem;
    }

    .livematches__category-name {
        height: 3.6rem;
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 0 1rem;
        line-height: 1.1;
        text-align: center;
    }

    .livematches__category-expand-wrp {
        width: 1.5rem;
        @include flex-config($align-items: center);
        flex-shrink: 0;
    }

    .livematches__category-expand-icon {
        margin-top: .2rem;
        display: inline-block;
        transform: rotate(90deg);
        transition: transform 0.3s ease-in-out;
    }

    .livematches__category-expand-icon--expanded {
        transform: rotate(-90deg);
    }

    .livematches__category-content {
        height: auto;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-out;
    }

    .livematches__category-content--expanded {
        max-height: 5000px;
    }

    .livematches__match-items {
        @include flex-config($flex-direction: column, $justify-content: center, $align-items: center);
        padding: .8rem 1rem 1rem;
        position: relative;
        list-style: none;
        transition: .3s ease-in-out;
        font-size: 1.25rem;
        font-weight: 400;
    }

    .livematch__title-container {
        @include flex-config($flex-direction: column, $justify-content: center);
        width: 100%;
        font-size: 1.6rem;
    }

    .livematch__info {
        font-size: 1.3rem;
        @include flex-config($justify-content: space-between);

        .livematch__info-score {
            font-weight: bold;
        }

        .livematch__info-status {
            margin-right: .5rem;
        }

        .match__icon--can-cashout,
        .match__icon--cant-cashout {
            font-size: 1.2rem;
        }

        .live-match__stream-icon {
            margin-left: .2rem;
        }
    }

    .livematch__bets {
        @include flex-config($justify-content: center, $align-items: center);
        width: 100%;
        margin-top: .5rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.4.2. LIVE PAGE DETAILS
|--------------------------------------------------------------------------
*/

.livematches {
    .match-details__header-info {
        @include flex-config($align-items: center, $justify-content: space-between);
        @include size(100%, 10.5rem);
        padding: 0.5rem 2rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .match-details__competitor-without-img {
        @include flex-config($align-items: center, $justify-content: center);
        @include size(4rem);
        margin: 1rem;
        z-index: 1;

        .match-details__breadcrumbs-icon {
            font-size: 2.4rem;
        }
    }

    .match-details__header-info-competitor {
        @include flex-config(
                $align-items: center,
                $justify-content: space-between,
                $flex-direction: column
        );
        min-width: 7rem;
        max-width: 7rem;
        text-align: center;
        flex: 1;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            z-index: 0;
            top: 1rem;
            left: 1.5rem;
            width: 4rem;
            height: 4rem;
            border-radius: 3rem;
            opacity: .3
        }
    }

    .match-details__header-info-img {
        height: 5rem;
        margin: 1rem;
        object-fit: contain;
        z-index: 1;
    }

    .match-details__competitor-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        flex: 1;
    }

    .match-details__description {
        max-width: 50%;
        margin: 0 1rem;
        text-align: center;
    }

    .match-details__markets:first-of-type {
        .match-details__market-header {
            margin-top: 0;
        }
    }

    .match-details__bets {
        @include flex-config($flex-wrap: wrap);
    }

    .match-details__time-range,
    .match-details__date {
        display: block;
    }

    .match-details__time-range {
        padding-top: 1rem;
        font-size: 1.4rem;
    }

    .match-details__breadcrumbs-icon {
        &:before {
            margin: auto;
            width: auto;
        }
    }

    .match-details__empty-markets {
        text-align: center;
    }

    .bet--column,
    .bet--column2 {
        .match-details__bet-value {
            margin-left: 2rem;
        }
    }

    .matches__bets-btn--dn,
    .match-details__bet--dn,
    .matches__bets-btn--up,
    .match-details__bet--up {
        .matches__bets-btn-value-odd,
        .match-details__bet-value-odd {
            position: relative;
        }

        .matches__bets-btn-value-odd:after,
        .match-details__bet-value-odd:after {
            content: '\2794';
            display: inline-block;
            @include position(absolute, left -3rem);
        }
    }

    .match-details__bet--dn,
    .match-details__bet--up {
        &.bet--column3 {
            .matches__bets-btn-value-odd:after,
            .match-details__bet-value-odd:after {
                left: auto;
                right: -.5rem;
            }
        }
    }

    .matches__bets-btn--dn,
    .matches__bets-btn--up {
        .matches__bets-btn-value-odd:after,
        .match-details__bet-value-odd:after {
            left: auto;
            right: 0;
        }
    }

    .matches__bets-btn--dn,
    .match-details__bet--dn {
        .matches__bets-btn-value-odd:after,
        .match-details__bet-value-odd:after {
            transform: rotate(90deg) translateY(-70%);
        }
    }

    .matches__bets-btn--up,
    .match-details__bet--up {
        .matches__bets-btn-value-odd:after,
        .match-details__bet-value-odd:after {
            transform: rotate(-90deg) translateY(70%);
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.5. LIVE CALENDAR
|--------------------------------------------------------------------------
*/
.live-calendar-modal {
    width: 92vw;

    .ll-modal__custom-content {
        min-height: 80vh;
    }
}

.live-calendar {
    max-height: 100%;
    flex: 1;
    @include flex-config($flex-direction: column);

    .live-calendar__top-title-line {
        @include flex-config($justify-content: space-between);
    }

    .live-calendar__back-button,
    .live-calendar__title {
        margin-bottom: .3rem;
        @include flex-config($justify-content: center, $align-items: center);
        font-size: 1.4rem;
        line-height: 3.2rem;
    }

    .live-calendar__back-button {
        width: 15rem;
        margin-right: 0.5rem;
    }

    .live-calendar__title {
        flex: 1;
    }

    .live-calendar__back-button-icon,
    .live-calendar__title-icon {
        margin-right: 0.5rem;
    }

    .live-calendar__title-icon {
        font-weight: 600;
    }

    .live-calendar__data {
        position: relative;
        display: flex;
        flex: 1;

        .data-table--desktop {
            max-width: 100%;
        }
    }

    .live-calendar__preloader-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: calc(100vh - 5rem - 6rem - 5rem - 7rem);
        //min-height: calc(100vh - #{$size-height--footer_mobile} - #{$size-height--header_mobile} - #{$size-height--header-nav-menu_mobile} - 7rem);
    }
}

.prematch--desktop {
    .live-calendar__data {
        max-height: calc(100% - 30px - 1rem);
    }
}

.prematch--tablet {
    .live-details__calendar-btn-icon {
        font-size: 1.1rem;
    }

    .live-details__calendar-btn-text {
        margin-left: .5rem;
        text-transform: capitalize;
    }

    .live-details__header {
        @include flex-config();
        height: 3.3rem;
        margin-bottom: .2rem;
        font-size: 1.2rem;

        & > *:not(:last-child) {
            @include flex-config($justify-content: flex-start, $align-items: center);
            flex: 1;
            margin-right: .2rem;
            padding: 7px 10px 8px;
        }
    }

    .live-details__back-button-icon {
        margin-right: .5rem;
    }
}

.prematch--mobile {
    .live-calendar__data {
        display: block;
    }

    .live-calendar__no-data-text {
        margin: 2rem;
        padding: 2rem;
        border-radius: 5px;
        text-align: center;
    }

    .live-calendar__item-icon {
        &:before {
            width: 1.4rem;
        }
    }

    .live-calendar__item-row {
        @include flex-config($align-items: center);
        width: 100%;
    }

    .live-calendar__item-icon {
        @include size(2.4rem);
        min-width: 2.4rem;
        @include flex-config($justify-content: center, $align-items: center);
        margin-right: 1rem;
        border-radius: 50%;
        font-size: 1.4rem;
        text-transform: uppercase;
    }

    .live-calendar__item-text {
        font-size: 1.3rem;
    }
}