/*
3.PAGES
└── 3.2. SIGNUP PAGE
   ├── 3.2.1 SIGNUP DESKTOP
   └── 3.2.2 SIGNUP MOBILE
*/

%G-ll-input__pwdStrength {
    position: absolute;
    right: 8rem;
    top: 0;
    text-align: center;
    line-height: 4.2rem;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: capitalize;

    &.perfect {
        right: 4rem;
    }
}

%G-signup--desktop {
    padding: 2rem 0;

    .signup__phone-flag-container,
    .signup__country-picker-input,
    .dx-texteditor-input,
    .dx-item-content {
        font-family: "Maven Pro", sans-serif;
        font-size: 1.6rem;
    }

    .ll-input {
        margin-bottom: 1.2rem;
    }

    .ll-input--focused .ll-input__label--inside {
        transform: translate(14px, -12px) scale(1) !important;
    }

    .ll-input:not(.ll-input--labeled-up) label.ll-input__label--inside {
        transform: translate(12px, 10px) scale(1);
    }

    .dx-texteditor.dx-editor-outlined.dx-invalid .dx-texteditor-container,
    .signup__country-picker,
    .ll-date-picker--desktop input,
    .ll-date-picker--mobile input,
    .signup__currencies {
        border-radius: 3px;
    }

    .signup__form-header {
        @include flex-config($align-items: center, $justify-content: center);
        height: 2.8rem;
        text-align: center;
        font-size: 1.4rem;
    }

    .signup__container {
        border-radius: 0.5rem;
        margin: 0 auto;
        width: 75rem;
        max-width: 495px;

        .ll-input__textbox {
            border-radius: 4px;
        }
    }

    .dx-overlay-content {
        padding: .4rem 0 0 0;
        font-size: 1.2rem;
        line-height: 1.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .dx-texteditor {
        height: 44px !important;
    }

    .dx-texteditor.dx-editor-underlined{
            border-radius: .4rem;
    }

    .dx-radiogroup {
        width: 100%;
        align-content: space-around;

        .dx-radiobutton {
            margin: 0;
            display: flex;
            justify-content: start;
            border-radius: 4px;
            height: 44px;
            width: 50%;

           & > div {
                padding: 12px 0 12px 10px;
            }

            &:nth-child(-n+2) {
                width: 49%;
            }
        }
    }

    .signup__part-two {
        .signup__currencies {
            margin-bottom: 34px;
        }
    }

    .signup__part-one, .signup__part-two {
        margin: 1.5rem 1rem 0;

        .ll-input__textbox.ll-input__textbox--left,
        .ll-input__textbox.ll-input__textbox--inside {
            margin-bottom: 2.4rem;
        }

        .dx-overlay-content {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .signup__tab,
    .signup__tab--second {
        @include flex-config($justify-content: space-between);
        position: relative;
        overflow: hidden;

        .dx-tabs-wrapper {
            width: 100% !important;
            display: flex !important;
        }

        &:before {
            @include position(absolute, top -31rem left -41.4rem);
            @include size(70rem);
            content: '';
            transform: rotate(35deg);
            transition: all 1s ease;
        }
    }

    .signup__tab--second {
        &:before {
            left: 44.1rem;
        }
    }

    .signup__form {
        position: relative;
        font-size: 1.8rem;
    }

    .signup__errors {
        @include position(absolute, top 40% right -27rem);
        width: 25rem;

        .signup__errors-header {
            text-align: center;
            margin-bottom: 0.5rem;
        }

        .dx-item {
            margin-bottom: 0.5rem;
        }

        .dx-item:hover {
            font-size: 1.5rem;
        }

        .dx-item-content {
            border: none;
            line-height: 1.5rem;

            &:before {
                content: '\2022';
                margin-right: 0.5rem;
                font-size: 1.6rem;
                font-weight: bold;
            }
        }

        @include mq('tablet') {
            display: none;
        }
    }

    .signup__login {
        display: flex;
        justify-content: center;
        padding-bottom: 60px;

        span {
            font-size: 1.4rem;
            line-height: 32px;
        }

        .dx-button {
            box-shadow: none;
        }
    }

    .signup__login-btn {
        margin-left: .5rem;
        text-transform: capitalize;
        text-decoration: underline;
        cursor: pointer;
    }

    .password-strength__label,
    .signup__field-label {
        width: 35%;
    }

    .password-strength__label {
        height: auto;
        display: inline-flex;
    }

    .signup__name-container {
        @include flex-config($justify-content: space-between);

        .ll-input {
            width: 49%;
        }
    }

    .signup_double {
        display: flex;
        justify-content: space-between;
    }

    .signup__field {
        @include size(100%, 44px);
        margin: 0 0 1rem 0;
        border: 0;
        padding: 0;
        position: relative;
        min-width: 0;
        @include flex-config($display: flex);
        vertical-align: top;
    }

    .signup__field-label {
        display: inline-block;
        text-align: left;
        font-size: 1.8rem;
        line-height: 3.8rem;
    }

    .signup__date-of-birth, .signup__country, .sign-up__id, .sign-up__city  {
        width: 49%;
    }

    .signup__gender {
        .dx-collection {
            @include flex-config($justify-content: space-between);
            width: 100%;
            font-size: 1.8rem;
        }

        &.signup__field {
            margin-bottom: 36px;
        }

        .dx-item {
            padding-right: 1rem;
        }
    }

    .password-strength__container,
    .ll-date-picker {
        width: 65%;
    }

    .signup__country-picker-container,
    .signup__currencies-container {

        width: 100%;
        height: 44px;

        &:focus-within {

        }

        @include flex-config($display: flex);
        position: relative;
    }

    //.dx-texteditor-container {
    //    &:focus-within {
    //        border: 2px solid #59513b;
    //    }
    //}

    .signup__country-picker,
    .signup__currency-picker {
        @include flex-config($flex: 1);
    }

    .signup__currencies-symbol {
        @include position(absolute, top 0 bottom 0 left 1.2rem);
        margin: auto;
        z-index: 2;
    }

    .signup__country-flag {
        @include position(absolute, top 1.8rem left 1.2rem);
        margin: auto;
        z-index: 2;
    }

    .signup__country-picker-input {
        padding-left: 3.2rem !important;
    }

    .signup__currencies {
        .dx-texteditor-input {
            padding: 1.3rem 3.5rem;
        }
    }

    .signup__currencies-symbol {
        @include flex-config($align-items: center, $justify-content: center);
        width: 1.4rem;
        font-size: 1.4rem;
    }

    .signup__country-picker .dx-texteditor-input-container,
    .ll-date-picker__input,
    .signup__currencies .dx-texteditor-input-container {
        border-radius: .4rem;
    }

    .ll-date-picker__input {
        padding: 1rem 1.2rem;
        border: none;
        font-family: Maven Pro, sans-serif; // TODO move to variable
        line-height: 1.2857;
    }

    .ll-date-range-picker__picker-wrapper {
        width: 100%;
    }

    .dx-invalid-message {
        z-index: 2;
    }

    .signup__phone-flag-container {
        @include flex-config($align-items: center, $justify-content: center);
        @include position(absolute, top 0);
        z-index: 2;
        height: 4.4rem;
        margin: 0;
        font-size: 1.6rem;
        padding-right: 0.8rem;
    }

    .signup__phone {
        padding: 0;

        .dx-texteditor-input {
            padding-left: 8.6rem !important;
        }
    }

    .signup__footer {
        @include flex-config($align-items: center, $justify-content: space-evenly, $flex-wrap: wrap);
        height: fit-content;
        font-size: 1.4rem;
        margin: 0 10px 10px 10px;

        .dx-invalid-message-content {
            width: 15rem;
            top: 1.5rem;
        }
    }

    .signup__footer-link {
        cursor: pointer;
    }

    .signup__footer-eighteen--check {
        @include flex-config($align-items: center, $justify-content: center);
        @include size(2.5rem);
        margin-right: 0.9rem;

        .dx-checkbox,
        .dx-checkbox-icon {
            @include size(1.5rem);
            font-size: 1.3rem;
        }

        .dx-checkbox-container {
            margin-bottom: 2rem;
        }
        .dx-invalid-message-content {
            min-width: 30rem;
        }
    }

    .signup__footer-eighteen-box {
        @include flex-config($flex-direction: column, $align-items: center);
        margin-bottom: 3rem;
        position: relative;
    }

    .signup__footer-eighteen {
        @include flex-config($align-items: center, $justify-content: center);
        width: 100%;
        padding: 0 3rem;
        text-align: center;
    }

    .signup__footer-eighteen--error {
        @include position(absolute, bottom 0);
        transform: translateY(100%);
        font-family: Roboto,RobotoFallback,"Noto Kufi Arabic",Helvetica,Arial,sans-serif;
        font-size: 1.2rem;
    }

    .signup__sumbit-btn {
        @include flex-config($justify-content: center);
        @include size(15.5rem, 4.5rem);
        border-radius: 3rem;
        font-size: 1.6rem;
    }

    .signup__eighteen-indicator--ok,
    .signup__eighteen-indicator--error {
        @include position(absolute, top 0 right -2rem);
        @include size(1.5rem);
        background-size: contain;
    }

    .signup__eighteen-indicator--ok {
        background: url('../../../static/themes/theme/images/icons/inputOk.svg') no-repeat;
    }

    .signup__eighteen-indicator--error {
        background: url('../../../static/themes/theme/images/icons/inputError.svg') no-repeat;
    }

    .form__captcha-text {
        width: 100%;
        position: static;
        padding: 0 2rem 2rem;
        font-size: 1.1rem;
        text-align: center;
    }

    .signup__phone {
        .dx-texteditor-input-container .dx-texteditor-input {
            padding-left: 8.8rem !important;
        }
    }

    .signup--desktop .signup__gender .dx-collection, .dx-item-content, .signup--tablet .signup__gender .dx-collection {
        font-size: 1.6rem;
    }
}

%G-signup--tablet {
    padding: 0;

    .signup__phone-flag-container,
    .signup__country-picker-input,
    .dx-texteditor-input,
    .dx-item-content {
        font-family: "Maven Pro", sans-serif;
        font-weight: 500;
        font-size: 1.4rem;
    }

    .signup__form-header {
        height: 4.6rem;
    }

    .mobile-drawer__close-btn {
        position: absolute;
        right: .5rem;
    }

    .signup__part-one, .signup__part-two {
        margin: 2rem 1rem 0;
    }

    .signup__login {
        padding: 0;

        span {
            font-size: 1.4rem;
        }
    }

    .signup__container {
        max-width: 495px;
    }

    .signup__gender .signup__field {
        margin-bottom: 24px;
    }

    .signup__currencies {
        .dx-texteditor-input-container {
            height: 4.6rem;
            .dx-texteditor-input {
                padding: 1rem 1.2rem 1rem 3.8rem;
                font-weight: 500;
                font-size: 1.4rem;
            }
        }
    }

    .dx-texteditor-input-container {
        input {
            height: 4.4rem;
            font-size: 1.4rem!important;
            font-weight: 500;
        }
    }
    .dx-radiobutton {
        border-radius: .3rem;

        .dx-item-content {
            font-size: 1.4rem!important;
            font-weight: 500;
        }
    }

    .signup__currencies-symbol {
        @include position(absolute, top 0 left 1.2rem);
        z-index: 3;
        font-size: 1.6rem;
    }

    .ll-input__textbox, .signup__country-picker, .dx-texteditor-container, .signup__footer-eighteen {
        border-radius: .3rem;
    }

    .signup__country-picker, .signup__currency-picker {
        display: block;
    }

    .form__captcha-text {
        border-radius: .3rem;
        width: 100%;
        position: static;
        text-align: center;
        margin-bottom: 2rem;
    }

    .signup__footer-eighteen,
    .form__captcha-text {
        font-size: 1.2rem;
        padding: 1rem;
    }

    .signup__sumbit-btn {
        margin: 2rem auto;
    }

    .signup__date-of-birth .ll-date-picker--mobile,
    .signup__date-of-birth .react-datepicker-wrapper {
        width: 100%;
    }

    .ll-drawer.tablet.ll-drawer_left_open {
        max-width: 495px;
    }
}

%G-signup--mobile {
    padding: 0;
    margin: 0;
    width: 100%;

    .signup__header--mobile {
        @include flex-config($justify-content: space-between, $align-items: center);
        margin-bottom: 1rem;
        padding: 0 .1rem 0 1rem;
        justify-content: center;
        height: 4rem;
        font-size: 1.4rem;
    }

    .mobile-drawer__close-btn {
        position: absolute;
        right: 0.5rem;
    }

    .signup_double {
        display: flex;
        justify-content: space-between;
    }

    .ll-input__textbox, .signup__country-picker{
        height: 100%;
    }

    .ll-input__text-box-container--inside {
        height: 4.6rem;
    }

    .dx-radiobutton {
        border-radius: 3px;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 4.6rem;
        width: 50%;
    }

    .signup__currencies-container{
        box-shadow: none;
    }

    .dx-invalid-message-content {
        transform: translate(0, 0)!important;
    }

    .ll-input {
        margin-bottom: 3rem;
        .dx-texteditor {
            transition: all .3s ease-in-out;

            &.dx-invalid {
                margin-bottom: 1.7rem;
            }
        }
    }

    .dx-editor-underlined:after {
        border: none;
    }

    .signup--mobile .signup_double > .signup__date-of-birt > .signup__field {
        width: 48%;
    }

    .signup__part-one, .signup__part-two {
        margin: 2rem 1rem;
    }

    .ll-input--blured.signup__phone label {
        transform: translate(14px, -12px);
        padding: 0 3px;
        zoom: 0.75;
    }

    .password-strength {
        display: block;
    }

    .password-strength__label {
        width: 100%;
        display: inline-flex;
        font-size: 1.2em;
    }

    .password-strength__str {
        position: relative;
        margin-bottom: 3rem;
    }

    .signup__field-label {
        position: absolute;
        transform: translate(10px, -13px);
        padding: .3rem;
        z-index: 11;
    }

    .signup__currencies{
        margin-bottom: 3rem;
        border-radius: 3px;
    }

    .signup__field {
        position: relative;
        padding-bottom: 0;

        .dx-datebox,
        .signup__currencies-container {
            height: 4.6rem;

            .dx-texteditor.dx-editor-underlined:after {
                border-bottom: none;
            }
        }

        .dx-datebox {
            border-radius: .4rem;

            &:after {
                border-bottom: none;
            }
        }

        .dx-show-invalid-badge {
            transition: all .2s ease-in-out;
            margin-bottom: 0;
        }
    }

    .signup__gender {
        width: 100%;
        margin: 0 auto 3rem;

        .signup__field-label {
            position: relative;
            font-size: 1.6rem;
        }

       .dx-collection {
            @include flex-config($justify-content: space-between);
            width: 100%;
            font-size: 1.6rem;
        }

        .dx-item {
            width: 48%;
            justify-content: center;
            padding-right: 1rem;

            .dx-radio-value-container {
                width: 26px;
                padding-left: 15px;
            }
        }
    }

    .signup__phone-flag-container,
    .signup__country-picker-input,
    .dx-texteditor-input,
    .dx-item-content {
        font-family: "Maven Pro", sans-serif;
        font-weight: 500;
        font-size: 1.4rem;
    }

    .ll-input__textbox,
    .signup__country-picker,
    .dx-texteditor-container,
    .signup__footer-eighteen {
        border-radius: .3rem;
    }

    .signup__phone-flag-container {
        @include flex-config($align-items: center);
    }

    .form__captcha-text {
        border-radius: .3rem;
        position: static;
        padding: .5rem;
    }
    .dx-invalid-message {
        position: relative !important;
        height: 0 !important;
        z-index: 5!important;

        & > .dx-overlay-content {
            padding: 0;
        }

        .dx-overlay-content {
            margin: 0 0 -.8rem 0 !important;
        }

        &.dx-overlay-wrapper {
            visibility: visible !important;
            transform: none !important;
            transition: all 2s ease-in-out !important;
        }
    }

    .signup__name-container {
        @include flex-config($justify-content: space-between);
    }

    .signup__name,
    .signup__surname, .sign-up__city, .signup__country  {
        width: 48%;
    }

    .ll-date-range-picker__picker-wrapper {
        width: 100%;
    }

    .ll-date-picker__input {
        border: none;
        padding: 1rem 1.2rem;
        line-height: 1.2857;
    }

    .signup__footer {
        @include flex-config($align-items: center, $justify-content: center, $flex-direction: column);
        margin: 0 1rem;
    }

    .signup__footer-eighteen-box {
        @include flex-config($flex-direction: column, $align-items: center);
        margin-bottom: 3.4rem;
        position: relative;
    }

    .signup__footer-eighteen {
        @include flex-config($align-items: center, $justify-content: center);
        width: 100%;
        padding: 1rem;
    }

    .signup__footer-eighteen--error {
        @include position(absolute, bottom 0);
        transform: translateY(100%);
        font-family: Roboto,RobotoFallback,"Noto Kufi Arabic",Helvetica,Arial,sans-serif;
        font-size: 1.2rem;
    }

    .signup__footer-eighteen--check {
        @include flex-config($align-items: center);
        @include size(2.5rem, 100%);
        margin: .6rem .9rem 0 0;

        .dx-checkbox-icon {
            @include size(2.2rem);
            font-weight: bold;
        }
    }

    .signup__sumbit-btn {
        border-radius: 2rem;
        margin: 3rem auto 0;
        width: 80%;
        height: 4rem;
    }

    .signup__country-picker-container {
        height: 4.6rem;

        .dx-texteditor-input-container {
            .dx-texteditor-input {
                padding: 1.2rem 1.2rem 1.4rem 3.8rem;
            }
        }

        .signup__country-flag {
            @include position(absolute, top 1.7rem left 0.3rem);
            z-index: 3;
        }
    }

    .signup__phone {
        .signup__phone-flag-container {
            font-weight: 500;
            padding-right: .8rem;
            top: 1.4rem;
            left: .2rem;

            span {
                font-size: 1.4rem !important;
            }
        }

        .dx-texteditor-input-container .dx-texteditor-input {
            padding-left: 8rem !important;
        }

        .ll-input__label--inside {
            transform: translate(1.4rem, -1.2rem) !important;
            padding: 0 3px !important;
            zoom: 0.75 !important;
        }
    }

    .signup__currencies {
        .dx-texteditor-input-container {
            .dx-texteditor-input {
                padding: 1.4rem 1.2rem 1.4rem 3.8rem;
                font-weight: 500;
                font-size: 1.4rem;
            }
        }
    }

    .signup__phone-flag-container,
    .signup__currencies-symbol {
        @include position(absolute, top 1rem left 1.2rem);
        z-index: 3;
        font-size: 1.6rem;
    }

    .react-datepicker-wrapper {
        width: 100%;

        .dx-texteditor-container {
            height: 4.6rem;
            border-radius: 3px;
        }
    }

    .sign-up__id.ll-input .dx-texteditor-input {
        padding: 1rem 1.5rem !important;
    }

    .form__captcha-text {
        width: 100%;
        padding: 1rem;
        font-size: 1.2rem;
        text-align: center;
    }

    .signup__date-of-birth {
        .ll-input__textbox.ll-input__textbox--inside {
            margin-bottom: 1rem;
        }
    }

    .signup__date-of-birth .ll-date-picker--mobile,
    .signup__date-of-birth .react-datepicker-wrapper {
        width: 100%;
    }

    //.ll-input__textbox, .signup__country-picker, .dx-texteditor-container, .signup__footer-eighteen_box, .signup__currencies-container {
    //    //border: none !important;
    //    //border: 2px solid #59513b;
    //}
    //
    //.signup__currencies-container {
    //    //box-shadow: none!important;
    //    //border: 2px solid #59513b;
    //}

    .signup__login {
        @include flex-config($align-items: center, $justify-content: center);
        margin-top: 3rem;

        span {
            font-size: 1.4rem;
        }

        .dx-button {
            box-shadow: none;
        }
    }

    .signup__login-btn {
        margin-left: .5rem;
        text-transform: capitalize;
        text-decoration: underline;
    }
}

.signup__country-flag,
.signup__phone-country-flag {
    width: 1.7rem !important;
    margin: 0 0.5rem 0 1rem;
}
.dx-texteditor.dx-editor-underlined:after,
.ll-input__textbox:after {
    border: none!important;
}