/*
3.PAGES
└── 3.4. HOME PAGE
    ├── 3.4.1 HOME PAGE CAROUSEL
*/

/*
|--------------------------------------------------------------------------
| == 3.4. HOME PAGE
|--------------------------------------------------------------------------
*/

$desktop-height: calc(100vh - #{$size-height--footer_desktop} - #{$size-height--header_desktop} - #{$size-height--header-menu_desktop});
$min-desktop-height: calc(740px - #{$size-height--footer_desktop} - #{$size-height--header_desktop} - #{$size-height--header-menu_desktop});
$min-desktop-long-height: calc(900px - #{$size-height--footer_desktop} - #{$size-height--header_desktop} - #{$size-height--header-menu_desktop});

@mixin get-desktop() {
    height: $desktop-height;
    grid-template-columns: calc($desktop-height - (20px + 237px)) minmax(320px, 1fr) calc($desktop-height - (20px + 237px));
    grid-template-rows: calc($desktop-height - (20px + 237px)) 237px;
    @media only screen and (max-height: 740px) {
        height: $min-desktop-height;
        grid-template-columns: calc($min-desktop-height - (20px + 237px)) minmax(320px, 1fr) calc($min-desktop-height - (20px + 237px));
        grid-template-rows: calc($min-desktop-height - (20px + 237px)) 237px;
    }
}

@mixin get-desktop-resize-home-4() {
    height: $desktop-height;
    grid-template-columns: 503px minmax(320px, 1fr) 503px;
    grid-template-rows: 503px 1fr;
}

@mixin get-desktop-long() {
    height: $desktop-height;
    grid-template-columns: calc($desktop-height - 10px) 1fr calc($desktop-height - 10px);
    grid-template-rows: repeat(2, calc($desktop-height / 2 - 10px));
    @media only screen and (max-height: 900px) {
        height: $min-desktop-long-height;
        grid-template-columns: calc($min-desktop-long-height - 10px) 1fr calc($min-desktop-long-height - 10px);
        grid-template-rows: repeat(2, calc($min-desktop-long-height / 2 - 10px));
    }
}

$tablet-height__album: calc(100vh - #{$size-height--header_tablet} - #{$size-height--header-nav-menu_tablet} - #{$size-height--footer_tablet});
$min-tablet-height__album: calc(1080px - #{$size-height--header_tablet} - #{$size-height--header-nav-menu_tablet} - #{$size-height--footer_tablet});

@mixin get-tablet-album() {
    height: $tablet-height__album;
    grid-template-columns: calc($tablet-height__album / 2 - 10px) 1fr;
    grid-template-rows: repeat(2, calc($tablet-height__album / 2 - 10px));
    @media only screen and (max-height: 1080px) {
        height: $min-tablet-height__album;
        grid-template-columns: calc($min-tablet-height__album / 2 - 10px) 1fr;
        grid-template-rows: repeat(2, calc($min-tablet-height__album / 2 - 10px));
    }
}

.home-page__loader--desktop {
    height: $size-max-height--page-container_desktop;
}
.home-page__loader--tablet {
    height: $size-max-height--page-container_tablet;
}
.home-page__loader--mobile {
    height: $size-max-height--page-container_mobile;
}

.home-page {
    @include get-desktop();

    --bg-grad: linear-gradient(90deg, #060b12 0%, #060b1247 10%, transparent, #060b1247 90%, #060b12 100%);

    .ll-widget-wrp--event {
        background: var(--bg-grad), url("../../../../static/themes/betlima/images/widgets/sport.jpg");

        &:has(.ll-widget-item--combined) {
            background: var(--bg-grad), url("../../../../static/themes/betlima/images/widgets/so.jpg");
        }
    }

    .ll-widget-wrp--slot {
        background: var(--bg-grad), url("../../../../static/themes/betlima/images/widgets/slots.jpg");
    }

    .ll-widget-wrp--casino {
        background: var(--bg-grad), url("../../../../static/themes/betlima/images/widgets/casino.jpg");
    }

    .ll-widget-wrp {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.home-1--empty {
    background-image: url("../../../../static/themes/betlima/images/widgets/home-1.jpg");
}

.home-2--empty {
    background-image: url("../../../../static/themes/betlima/images/widgets/home-2.jpg");
}

.home-3--empty {
    background-image: url("../../../../static/themes/betlima/images/widgets/home-3.jpg");
}

.home-4--empty {
    background-image: url("../../../../static/themes/betlima/images/widgets/home-4.jpg");
}

@media only screen and (min-width: 1366px) and (max-aspect-ratio: 1.59/1) {
    .home-page {
        @include get-desktop-resize-home-4();
    }
}

@media only screen and (max-width: 1366px) and (min-aspect-ratio: 1/1.5) {
    .home-page {
        @include get-tablet-album();
    }
}

@media only screen and (min-width: 1367px) and (min-aspect-ratio: 1.78/1) {
    .home-page {
        height: $desktop-height;
        grid-template-columns: $desktop-height minmax(320px, 1fr) calc($desktop-height - (20px + 217px));
        grid-template-rows: calc($desktop-height - (217px + 20px)) 217px;
        @media only screen and (max-height: 740px) {
            height: $min-desktop-height;
            grid-template-columns: $min-desktop-height minmax(320px, 1fr) calc($min-desktop-height - (20px + 217px));
            grid-template-rows: calc($min-desktop-height - (217px + 20px)) 217px;
        }
    }
}

@media only screen and (min-width: 2020px) and (min-aspect-ratio: 2.22/1) {
    .home-page {
        @include get-desktop-long();
    }
}

@media only screen and (max-width: 1366px) and (max-aspect-ratio: 1/1.5),
only screen and (max-width: 700px) {
    .home-page {
        height: auto;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 100vw);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }
    .home-1 {
        grid-area: 1 / 1 / 2 / 2;
    }
    .home-2 {
        grid-area: 2 / 1 / 3 / 2;
    }
    .home-3 {
        grid-area: 3 / 1 / 4 / 2;
    }
    .home-4 {
        grid-area: 4 / 1 / 5 / 2;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.4.1 HOME PAGE CAROUSEL
|--------------------------------------------------------------------------
*/

.ll-page {
    .slick-dots li button:before {
        box-shadow: 0 2px 2px #0000004D;
        background-color: #e7e7e7;
    }

    .slick-dots li.slick-active button:before {
        background-color: #F6A83C;
    }
}
