/*
3.PAGES
└── 3.7. HOSTED SOLUTION PAGE
*/

/*
|--------------------------------------------------------------------------
| == 3.7. HOSTED SOLUTION PAGES
|--------------------------------------------------------------------------
*/
.live-games--desktop,
.virtual-games--desktop,
.tvbet--desktop,
.lottery--desktop,
.bet-games--desktop {
    min-height: calc(100vh - #{$size-height--footer_desktop} - #{$size-height--header_desktop} - #{$size-height--header-menu_desktop});
}

.live-games-iframe--desktop,
.virtual-games-iframe--desktop,
.tvbet-iframe--desktop,
.lottery-iframe--desktop,
.bet-games-iframe--desktop {
    height: calc(100vh - #{$size-height--footer_desktop} - #{$size-height--header_desktop} - #{$size-height--header-menu_desktop});
}