.ll-custom-bets__loading {
    .ll-accordion__progress-bar {
        position: relative;
        height: 3px;
    }
}

.ll-custom-bets {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .ll-custom-bets__markets .match-details__market {
        height: calc(100% - 4rem);
        border: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .ll-custom-bets__loading {
        @include size(100%);
        position: absolute;
        background-color: #00000020;
        top: 0;
        left: 0;
        z-index: 401;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ll-custom-bets-title-container {
        flex: 0;
    }

    .ll-custom-bets__about-info-container--desktop,
    .ll-custom-bets__about-info-container--tablet {


        .ll-custom-bets__about-info-button {
            top: -7.1rem;
            right: 0;

            @include flex-config($align-items: center);

            width: 6rem;
            height: 3rem;
        }
        .ll-custom-bets__about-info-content-container {
            top: -4.2rem;
            right: 1.6rem;

            max-width: 340px;
            max-height: 130px;

            border-radius: 0.3rem;
        }
    }

    .ll-custom-bets__about-info-container--mobile {


        .ll-custom-bets__about-info-button {
            top: -13.5rem;
            right: 0;

            @include flex-config($flex-direction: column, $align-items: center);

            width: 3.5rem;
            height: 2.5rem;
        }

        .ll-custom-bets__about-info-content-container {
            top: -14.5rem;
            right: 0;

            width: 100%;
            height: 105px;
        }
    }

    .ll-custom-bets__about-info-button {
        position: absolute;
        z-index: 12;

        cursor: pointer;
        transition: color 0.1s linear;

        .ll-custom-bets__about-info-button-icon {
            font-size: 1.6rem;
            margin: 0 0.4rem;
        }
    }

    .ll-custom-bets__about-info-content-container {
        @include flex-config($flex-direction: column, $justify-content: space-between);
        gap: 8px;

        position: absolute;
        z-index: 12;

        font-weight: 500;

        padding: 1.2rem;

        transition: visibility 0.3s, opacity 0.3s linear;

        &.ll-custom-bets__about-info-content-container--show {
            visibility: visible;
            opacity: 1;
        }

        &.ll-custom-bets__about-info-content-container--hidden {
            visibility: hidden;
            opacity: 0;
        }


        .ll-custom-bets__about-info-text {
            width: calc(100% - 1.6rem);
        }

        .ll-custom-bets__about-info-close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
        }

    }

    .ll-custom-bets__markets {
        height: 100%;
        width: 100%;
    }

    .ll-custom-bets__info {
        position: relative;
    }

    .ll-custom-bets__title {
        @include flex-config($align-items: center, $justify-content: center);
        padding: 0 1rem;
        line-height: 3.2rem;
        border-radius: 3px;
    }

    .ll-custom-bets__about {
        @include flex-config($justify-content: center);
        padding: 1rem;
        font-size: 1.4rem;
    }

    .ll-custom-bets__about-text {
        flex: 1;
        @include flex-config($flex-direction: column, $justify-content: space-between);
    }

    .ll-custom-bets__about-image {
        width: 12rem;
        height: 12rem;
        text-align: center;
        margin: 0 2rem 0 0;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .ll-custom-bets__about-terms-link {
        cursor: pointer;
        font-weight: bold;
    }

    .ll-custom-bets__close-info {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        padding: 3px 1rem;
        border-radius: 3px;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            bottom: -6px;
            right: .7rem;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }
    }

    .custom-market-group > .match-details__market-header .match-details__market-header-name {
        margin-left: 0;
    }

    .ll-custom-bets__open-close-info {
        width: 2.6rem;
        height: 2.6rem;
        margin: .7rem 1rem;
        @include position(absolute, top -.2rem right 0);
        @include flex-config($align-items: center, $justify-content: center);
        font-size: 2rem;
        font-weight: bold;
        cursor: pointer;
    }

    .ll-custom-bets__no-bets {
        @include flex-config($align-items: center, $justify-content: center);
        min-height: 200px;
    }


    .ll-custom-bets__selection {
        padding: 1rem 1rem;
        position: relative;
    }

    .ll-custom-bets__selection-icon--open,
    .ll-custom-bets__selection-icon--closed {
        position: absolute;
        top: 1rem;
        right: 1rem;
        transition: 0.3s ease-in-out;
    }

    .ll-custom-bets__selection-icon--closed {
        transform: rotate(90deg);
    }

    .ll-custom-bets__selection-title {
        width: 100%;
        text-align: center;
        font-size: 1.4rem;
        text-transform: uppercase;
        cursor: pointer;
    }

    .ll-custom-bets__selection-count {
        margin-left: .5rem;
    }

    .ll-custom-bets__selected-bets {
        @include flex-config($flex-direction: column, $justify-content: center);
        padding: 1rem 0;
    }

    .coupons__custom-warning {
        max-width: 50%;
        font-size: 1.2rem;
        padding-right: 1rem;
    }

    .ll-custom-bets__selected-button-part {
        margin-top: 1rem;
        @include flex-config($justify-content: center, $align-items: center);
        flex: 1;
    }

    .ll-custom-bets__selected-bets-part {
        flex: 1;
        @include flex-config($flex-direction: row, $flex-wrap: wrap);
    }

    .ll-custom-bets__selected-bet {
        width: calc(50% - 1rem);
        display: flex;
        padding: 0 0 0 1rem;
        margin-bottom: .2rem;
        border-radius: .3rem;
        line-height: 28px;
        margin-top: .5rem;
    }


    .ll-custom-bets__selected-bet:nth-child(odd) {
        margin-right: .5rem;
    }

    .ll-custom-bets__selected-bet:nth-child(even) {
        margin-left: .5rem;
    }

    .ll-custom-bets__selected-bet-name {
        flex: 1;
    }

    .ll-custom-bets__summary {
        padding: 0 1rem 0 1rem;
    }

    .ll-custom-bets__selected-bet-remove {
        cursor: pointer;
        min-width: 30px;
        text-align: center;
    }

    .ll-custom-bets__bet-button {
        transition: all .2s;
        text-transform: uppercase;
        border-radius: 30px;
        padding: 0 2rem;

        &--disabled {
            opacity: .5;
        }
    }

    .ll-custom-bets__no-bets {
        flex: 1;
    }

    .match-details__bets {
        margin-right: 0;
    }
}

.bet-builder__info-modal {
    text-align: center;
    font-size: 1.4rem;

    .ll-modal__custom-content {
        display: block;
    }

    .bet-builder-modal__info-header {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    .bet-builder-modal__ok-btn {
        margin: 3rem auto 0;
    }

    .bet-builder__info-modal-buttons {
        width: 100%;
        margin: 3rem 0 0;
        @include flex-config($justify-content: space-around, $align-items: center);
    }
}
