@mixin square-widget() {
    min-width: 30rem;
    max-width: 45rem;
    flex: 1;
}

@mixin long-widget() {
    max-height: calc(100% - 4rem);
    padding: 0;
    margin-bottom: 2rem;
}

@mixin event-widget() {
    .ll-widget-item__widget-bg,
    .ll-widget-item__header-icon.doruk-time {
        display: none;
    }

    .ll-widget-item__header {
        position: static;
        max-width: none;
        height: auto;
        @include flex-config($flex-direction: column, $align-items: center);
        transform: none;
    }

    .ll-widget-item__header-match-time {
        font-size: 2.4rem;
    }

    .ll-widget-item__date {
        position: relative;
        margin-right: 0;
        padding-right: 2rem;

        &::after {
            content: "";
            display: inline-block;
            @include size(.1rem, 1.5rem);
            @include position(absolute, right 0 top 52%);
            transform: translateY(-50%);
            background-color: #fff;
        }
    }

    .ll-widget-item__time {
        padding-left: 1.5rem;
    }

    .ll-widget-item__header-sport {
        margin-top: .6rem;
        @include flex-config($align-items: center);
        font-size: 1.4rem;
        font-weight: 500;

        .ll-widget-item__header-icon {
            font-size: 1.2rem;
        }
    }

    .ll-widget-item__competitor-logo,
    .ll-widget-item__competitor-logo--no-logo {
        width: 4.8rem;
        max-width: 4.8rem;
    }

    .ll-widget-item__competitor-logo--no-logo {
        @include size(4.8rem);
        position: relative;

        &::before {
            font-size: 3.6rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .ll-widget-item__competitor-name {
        font-size: 2rem;
        line-height: 1.2;
        word-break: normal;
    }

    .ll-widget-item__market-name {
        font-size: 1.4rem;
    }

    &.ll-widget-item--combined {
        .ll-widget-item__event-name {
            font-size: 2rem;
        }

        .ll-popover-wrapper {
            -webkit-line-clamp: 4;
        }

        .ll-widget-item__bet-value-original-odd {
            font-size: 2rem;
        }
    }

    .ll-widget-item__bet-wrp {
        flex: 1;
        max-width: 13.6rem;
    }

    .ll-widget-item__bet {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 4rem;
    }

    .ll-widget-item__dropdown-wrp {
        margin: 1rem 0 0;
        font-size: 1.4rem;
    }

    .ll-widget-item__bets-item--bet-builder {
        font-size: 1.2rem;
        justify-content: center;
    }

}

@mixin square-event-widget() {
    .ll-widget-item__event-name {
        margin-bottom: 1rem;
        column-gap: 2rem;
    }

    .ll-widget-item__event-name-box {
        column-gap: 1rem;
        width: 45%;

        &:first-child,
        &:last-child {
            flex: 1 1 auto;
            justify-content: center;
            flex-direction: column;
        }

        &:last-child {
            flex-direction: column-reverse;
        }
    }

    .ll-widget-item__event-name--winner {
        margin: 1rem 0 2rem;

        .ll-widget-item__event-name-box {
            &:first-child,
            &:last-child {
                justify-content: center;
                flex-direction: column;
            }
        }
    }

    &.ll-widget-item--combined {
        .ll-widget-item__event-name {
            margin: 2rem;
        }
    }

    .ll-widget-item__event-name .ll-widget-item__event-name-box {
        .ll-widget-item__competitor-logo,
        .ll-widget-item__competitor-logo--no-logo {
            width: 6rem;
            max-width: 6rem;
        }

        .ll-widget-item__competitor-logo--no-logo {
            @include size(6rem);
        }
    }

    .ll-widget-item__dropdown-wrp {
        width: 80%;
        align-self: center;
    }

    .ll-widget-item__bets {
        min-width: 27.8rem;
        max-width: 42.8rem;
        column-gap: 1rem;
    }
}

@mixin long-event-widget() {
    width: 70%;
    min-width: 78rem;
    max-width: none;
    position: relative;

    &:not(.ll-widget-item--combined) {
        .ll-widget-item__header {
            @include flex-config($flex-direction: column, $align-items: center);
        }

        .ll-widget-item__event-name {
            margin-top: 1.5rem;

            .ll-widget-item__event-name-box:first-of-type,
            .ll-widget-item__event-name-box:last-of-type {
                width: 15rem;
                height: 100%;
                justify-content: center;
            }

            .ll-widget-item__event-name-box:first-of-type {
                @include position(absolute, left 0 top 0);
                flex-direction: column;
            }

            .ll-widget-item__event-name-box:last-of-type {
                @include position(absolute, right 0 top 0);
                flex-direction: column-reverse;
            }

            &.ll-widget-item__event-name--winner .ll-widget-item__event-name-box:last-of-type {
                flex-direction: column;
            }
        }

        .ll-widget-item__competitor-logo,
        .ll-widget-item__competitor-logo--no-logo {
            width: 8rem;
            max-width: 8rem;
        }

        .ll-widget-item__competitor-logo--no-logo {
            @include size(8rem);
        }
    }

    &.ll-widget-item--combined {
        .ll-widget-item__event-name {
            margin: 1rem 0;
        }
    }

    .ll-widget-item__event-name {
        margin-top: 2rem;
        column-gap: 20rem;
    }

    .ll-widget-item__dropdown-wrp {
        margin: .5rem 0 -.5rem;
    }

    .ll-widget-item__bets-with-dropdown {
        min-width: 30rem;
        width: auto;
        align-self: center;
    }

    .ll-widget-item__bets {
        align-self: center;
        min-width: 40rem;
        column-gap: 3rem;
        justify-content: center;
        margin-bottom: 1rem;
    }
}

@mixin long-to-square-event-widget() {
    min-width: 30rem;
    max-width: 45rem;
    flex: 1;
    margin-bottom: 0;
    padding: 1rem 1rem 0;

    &:not(.ll-widget-item--combined) {
        .ll-widget-item__header {
            @include flex-config($flex-direction: column, $align-items: center);
        }

        .ll-widget-item__event-name {
            margin-top: 1rem;
            margin-bottom: 1rem;

            .ll-widget-item__event-name-box:first-of-type,
            .ll-widget-item__event-name-box:last-of-type {
                height: auto;
                justify-content: center;
                width: 45%;
            }

            .ll-widget-item__event-name-box:first-of-type {
                position: inherit;
                flex-direction: column;
            }

            .ll-widget-item__event-name-box:last-of-type {
                position: inherit;
                flex-direction: column-reverse;
            }

            &.ll-widget-item__event-name--winner .ll-widget-item__event-name-box:last-of-type {
                flex-direction: column;
            }
        }

        .ll-widget-item__competitor-logo,
        .ll-widget-item__competitor-logo--no-logo {
            width: 6rem;
            max-width: 6rem;
        }

        .ll-widget-item__competitor-logo--no-logo {
            @include size(6rem);
        }
    }

    &:not(.ll-widget-item--combined) .ll-widget-item__header {
        position: static;
        max-width: none;
        height: auto;
        @include flex-config($flex-direction: column, $align-items: center);
        transform: none;
    }

    .ll-widget-item__event-name-box {
        column-gap: 1rem;

        &:first-child,
        &:last-child {
            flex: 1 1 auto;
            justify-content: center;
            flex-direction: column;
        }

        &:last-child {
            flex-direction: column-reverse;
        }
    }

    .ll-widget-item__event-name {
        margin-top: 0;
        margin-bottom: 2rem;
        column-gap: 2rem;
    }

    .ll-widget-item__event-name--winner {
        margin: 1rem 0 2rem;

        :last-child {
            flex-direction: column;
        }
    }

    &.ll-widget-item--combined {
        .ll-widget-item__event-name {
            margin: 2rem;
        }
    }

    .ll-widget-item__dropdown-wrp {
        margin: 0;
        width: 80%;
        align-self: center;
    }

    .ll-widget-item__bets-with-dropdown {
        min-width: auto;
        width: auto;
        align-self: auto;
    }

    .ll-widget-item__bets {
        align-self: inherit;
        min-width: 27.8rem;
        max-width: 42.8rem;
        column-gap: 1rem;
        justify-content: center;
        margin-bottom: 0;
    }
}

@mixin game-widget() {
    padding: 0;

    .ll-widget-item__info {
        min-width: 17.2rem;
    }

    .ll-widget-item__header-icon {
        margin-right: .5rem;
    }

    .ll-widget-item__game-type {
        @include flex-config($justify-content: center, $align-items: center);
        font-size: 1.4rem;
    }

    .ll-widget-item__info-title {
        font-size: 1.8rem;
    }

    .ll-widget-item__info-text {
        font-size: 1.6rem;
    }

    .play-button--under-games {
        display: none;
    }
}

@mixin square-game-widget() {
    height: calc(100% - 4rem);
    width: 100%;
    @include flex-config($justify-content: center, $align-items: center);
    margin: 0 0 2rem 0;

    .ll-widget-item__content {
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .play-button--under-info {
        display: none;
    }

    .play-button--under-games {
        display: flex;
        margin-top: 2rem;
    }

    .ll-widget-item__games {
        max-width: 100%;
        @include flex-config($justify-content: center, $align-items: center);
        height: 45%;
        max-height: 22.7rem;
        justify-content: center;
        align-items: center;
        flex: auto;
    }

    .ll-widget-item__game {
        max-width: 17.2rem;
        width: 100%;
        height: 100%;
    }

    .ll-widget-item__game-image {
        max-height: 100%;
        max-width: 100%;
    }

    .ll-widget-item__info {
        row-gap: 1rem;
        margin-bottom: 2rem;
        flex: none;
    }
}

@mixin long-game-widget() {
    width: fit-content;
    height: 100%;
    padding: 0;

    .ll-widget-item__content {
        align-items: center;
        height: 100%;
    }

    .ll-widget-item__info {
        margin-right: 2rem;
    }

    .ll-widget-item__games {
        justify-content: center;
        align-items: center;
        height: 100%;
        flex: auto;
    }

    .ll-widget-item__game {
        max-width: 17.2rem;
        height: 100%;
        @include flex-config($align-items: center);
    }

    .ll-widget-item__game-image {
        max-height: 100%;
        max-width: 100%;
    }
}

@mixin jackpot-widget() {
    @include size(100%);
    @include flex-config($justify-content: center, $align-items: center);
    padding: 0 2rem;

    .ll-widget-item__inner {
        width: 100%;
        height: 18rem;
        @include flex-config($justify-content: center, $align-items: center, $flex-direction: column, $gap: .5rem);
        cursor: pointer;
    }

    .ll-widget-item__image {
        @include size(4.8rem);
    }

    .jackpot-widget__info--big,
    .jackpot-widget__info--small {
        @include flex-config($justify-content: center, $align-items: center, $gap: 1rem);
        width: 100%;
    }

    .jackpot-widget__info-part {
        @include flex-config($justify-content: center, $align-items: center, $flex-direction: column);
        flex: 1;
    }

    .jackpot-widget-details__label {
        font-size: 1.2rem;
        text-transform: uppercase;
    }

    .jackpot-widget-details__label-value {
        @include flex-config($justify-content: center, $align-items: center);
        width: 100%;
        height: 3.2rem;
        border-radius: .3rem;
        font-size: 1.4rem;
    }
}

@mixin square-jackpot-widget() {
    min-width: 100%;
    max-width: 100%;

    .ll-widget-item__inner {
        max-width: 30rem;
        height: calc(100% - 5rem);
        gap: 1.1rem;
    }

    .ll-widget-item__image {
        @include size(auto);
        height: calc(100% - 19rem);
        max-height: 10rem;
    }

    .jackpot-widget__info--big {
        flex-direction: column;
        .jackpot-widget__info-part {
            width: 100%;
        }
    }

    .jackpot-widget__info--small {
        gap: 2rem;
    }

    .jackpot-widget-details__label,
    .jackpot-widget-details__label-value {
        font-size: 1.6rem;
    }
}

@mixin long-jackpot-widget() {
    height: 100%;
    max-height: 100%;
    margin-bottom: 0;

    .ll-widget-item__inner {
        flex-direction: row;
        min-width: 65rem;
        max-width: 60%;
    }

    .jackpot-widget__info--big {
        order: 1;
    }

    .ll-widget-item__image {
        order: 2;
        @include size(10rem);
        margin: 2rem 2rem 0;
    }

    .jackpot-widget__info--small {
        order: 3;
    }

    .jackpot-widget__info--big,
    .jackpot-widget__info--small {
        flex-direction: column;
        .jackpot-widget__info-part {
            width: 100%;
        }
    }

    .jackpot-widget-details__label,
    .jackpot-widget-details__label-value {
        font-size: 1.6rem;
    }
}

.ll-widget-item {
    @include flex-config($flex-direction: column);
    padding: 1rem 1rem 0;
    position: relative;

    @media (max-width: 375px) {
        .ll-popover-wrapper {
            word-break: break-word;
        }
    }

    &.ll-widget-item--sports {
        height: 18rem;
    }

    &.ll-widget-item--banner {
        @include size(100%);
        padding: 0;
    }

    // ~~~~~~ HEADER ~~~~~~~
    .ll-widget-item__header {
        height: 1.2rem;
        @include flex-config($justify-content: space-between);
        font-size: 1.1rem;
        line-height: 1.2;

        & > span {

            &:last-child {
                text-align: right;
                justify-content: flex-end;
            }
        }

        .ll-widget-item__header-icon {
            margin-right: .5rem;
            font-size: 1rem;
        }

        .ll-widget-item__date {
            margin-right: .5rem;
        }
    }

    // ~~~~~~ JACKPOT WIDGET ~~~~~~~

    &.ll-widget-item--jackpot {
        @include jackpot-widget();
    }

    // ~~~~~~ EVENT WIDGET ~~~~~~~
    .ll-widget-item__widget-bg {
        display: inline-block;
        @include size(100%);
        @include position(absolute, top -1rem left 0, $z-index: 0);
        font-size: 17rem;
        opacity: 0.03;
        text-align: center;

        &.doruk-rocket1 {
            top: 2rem;
        }

        &::before {
            display: contents;
        }
    }

    &.ll-widget-item--event {
        justify-content: space-between;
        font-size: 1.2rem;
    }

    .ll-widget-item__event-name {
        @include flex-config($justify-content: space-around, $align-items: center);
        margin-top: 1rem;
        column-gap: 1rem;
    }

    .ll-widget-item__event-name-box,
    .ll-widget-item__event-name-box--right {
        @include flex-config($justify-content: flex-start, $align-items: center, $flex-direction: row);
        flex: 1 1;
        gap: 0.5rem;
        text-align: center;

        .ll-popover-wrapper {
            -webkit-line-clamp: 2;
        }
    }

    .ll-widget-item__event-name-box--right {
        justify-content: flex-end;
    }

    .ll-widget-item__competitor-logo,
    .ll-widget-item__competitor-logo--no-logo {
        max-width: 2.4rem;
    }

    .ll-widget-item__competitor-logo--no-logo {
        @include size(2.4rem);
        position: relative;

        &::before {
            @include position(absolute, top 50% left 50%);
            transform: translate(-50%, -50%);
            font-size: 2rem;
        }
    }

    .ll-widget-item__event-name--winner {
        @include flex-config($justify-content: space-around, $align-items: center);
        column-gap: 2rem;

        .ll-widget-item__event-name-box,
        .ll-widget-item__event-name-box--right {
            @include flex-config($justify-content: center, $align-items: center, $flex-direction: column);
            flex: 1;
            gap: 1rem;
        }

        .ll-widget-item__competitor-logo,
        .ll-widget-item__competitor-logo--no-logo {
            width: 4rem;
            max-width: 4rem;
        }

        .ll-widget-item__competitor-logo--no-logo {
            @include size(4rem);
            position: relative;

            &::before {
                font-size: 3.6rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .ll-widget-item__market-name {
        @include flex-config($justify-content: center, $align-items: center);
        padding: 0 3rem;
        max-width: 100%;

        &.ll-widget-item__market-name--top {
            margin-bottom: 1rem;
        }

        .ll-popover-wrapper {
            text-align: center;
        }
    }

    &.ll-widget-item--combined {
        & > div:first-child {
            flex: 1;
            @include flex-config($flex-direction: column);
        }

        .ll-widget-item__event-name {
            flex: 1;
            margin-top: 4.5rem;
            @include flex-config($justify-content: center, $align-items: center);
            text-align: center;
        }
    }

    .ll-widget-item__bets {
        column-gap: 1rem;
        @include flex-config($justify-content: space-evenly);
        margin-bottom: 2rem;
    }

    .ll-widget-item__bets--combined {
        .ll-widget-item__bet-value-original-odd {
            font-size: 1.2rem;
            margin-right: .5rem;
            opacity: .5;
            text-decoration: line-through;
        }

        .ll-widget-item__bet-value-odd:before {
            content: "»";
            font-size: 1.4rem;
            margin-right: .5rem;
        }
    }

    .ll-widget-item__bets-with-dropdown {
        margin: 0 .5rem 2rem;
        @include flex-config($flex-direction: column, $align-items: center);
    }

    .ll-widget-item__dropdown-wrp {
        margin: 0 2.5rem;
        align-self: stretch;
    }

    .ll-widget-item__dropdown {
        height: 3.2rem;
        padding: 0 1rem;
        @include flex-config($justify-content: space-between, $align-items: center);
        border-radius: 3px;
        cursor: pointer;
    }

    .ll-widget-item__bets--bet-builder {
        flex-direction: column;

        .ll-widget-item__bet--with-dropdown {
            align-self: center;
        }
    }

    .ll-widget-item__bets-content--bet-builder {
        display: block;
        @include size(100%, 5.7rem);
        padding: 0 2rem;
        text-align: left;

        .ll-popover-wrapper {
            display: block;
            @include size(100%, calc(100% - 0.8rem));
        }

        .ll-popover-wrapper--desktop {
            display: block;
            @include size(100%, calc(100% - 1rem));
        }

        .ll-widget-item__bets-item-cross--opened {
            @include position(absolute, top 0.5rem right 1rem);
            display: block;
        }

        .ll-widget-item__bets-item-cross--closed {
            display: none;
        }

        &.ll-widget-item__bets-content--bet-builder--overflowed {
            position: relative;
            cursor: pointer;

            .ll-widget-item__bets-item--bet-builder-dots {
                padding-top: 0;
                padding-left: 2rem;

                &::after {
                    transform: translateY(-50%);
                }
            }

            .doruk-dots {
                display: inline-block;
                position: relative;
                @include size(2.5rem, 1.2rem);
                border-radius: 3px;
                bottom: 0;
                left: 0;

                &::before {
                    @include position(absolute, top 50% left 50%);
                    transform: translate(-50%, -50%);
                    padding: 0 0.5rem;
                    border-radius: 3px;
                }
            }
        }
    }

    .ll-widget-item__bet-wrp {
        width: 100%;
        max-width: 10rem;
        min-width: 7.2rem;
        @include flex-config($flex-direction: column);
        line-height: 1.5rem;
        text-align: center;

        .ll-popover-wrapper {
            flex: 1;
            @include flex-config($justify-content: center, $align-items: center);
            -webkit-line-clamp: 2;
        }
    }

    .ll-widget-item__bet {
        flex-grow: 0;
        margin-top: .5rem;
        border-radius: 3px;
        font-size: 1.4rem;
        line-height: 3.2rem;
        text-align: center;
    }

    .ll-widget-item__bet--with-dropdown {
        max-width: 10rem;
        min-width: 7.2rem;
        margin-top: 1rem;
    }

    // ~~~~~~ GAME WIDGET ~~~~~~~
    &.ll-widget-item--game {
        padding-bottom: 1.5rem;
        justify-content: space-between;

        .ll-widget-item__content {
            z-index: 1;
            @include flex-config($justify-content: center);
        }

        .ll-widget-item__bg--image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            z-index: 0;
            filter: blur(5px);
        }

        .ll-widget-item__bg {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.2;
            height: 100%;
            width: 100%;
            z-index: 0;
            overflow: hidden;
            object-fit: fill;
        }
    }

    .ll-widget-item__info,
    .ll-widget-item__games {
        flex: 1;
    }

    .ll-widget-item__info {
        @include flex-config($flex-direction: column, $justify-content: center, $align-items: center);
        row-gap: 1.5rem;
        padding: 0 1rem;
    }

    .ll-widget-item__info-title,
    .ll-widget-item__info-text {
        text-align: center;
    }

    .ll-widget-item__info-title {
        font-size: 1.2rem;
    }

    .ll-widget-item__info-text {
        font-size: 1.1rem;
    }

    .ll-widget-item__play-button {
        min-width: 10rem;
        height: 3.2rem;
        min-height: 3.2rem;
        justify-self: flex-end;
        @include flex-config($justify-content: center, $align-items: center);
        border-radius: .3rem;
    }

    .ll-widget-item__games {
        @include flex-config($justify-content: center, $align-items: center);
        column-gap: 1rem;
    }

    .ll-widget-item__games--multi {
        width: calc(100% - 3rem);
        justify-content: center;
    }

    .ll-widget-item__game {
        display: inline-block;
        height: auto;
    }

    .ll-widget-item__game-image {
        max-height: 12rem;
        max-width: 9rem;
        border-radius: .3rem;
        transform: scale(1);
        transform-origin: center;
        transition: all .1s ease-in-out;
    }

    // ~~~~~~ HOME PAGE WIDGETS ~~~~~~~

    &:not(.ll-widget-item--sports) {
        &.ll-widget-item--event {
            @include event-widget();
        }

        &.ll-widget-item--game {
            @include game-widget();
        }
    }

    &.ll-widget-item--home-1,
    &.ll-widget-item--home-2,
    &.ll-widget-item--home-3,
    &.ll-widget-item--sports-banner {
        @include square-widget();

        &.ll-widget-item--event {
            @include square-event-widget();
        }

        &.ll-widget-item--game {
            @include square-game-widget();
        }

        &.ll-widget-item--jackpot {
            @include square-jackpot-widget();
        }
    }

    &.ll-widget-item--home-4 {
        @include long-widget();

        &.ll-widget-item--event {
            @include long-event-widget();
        }

        &.ll-widget-item--game {
            @include long-game-widget();
        }

        &.ll-widget-item--jackpot:not(.ll-widget-item--mobile):not(.ll-widget-item--tablet) {
            @include long-jackpot-widget();
        }
        &.ll-widget-item--jackpot.ll-widget-item--mobile,
        &.ll-widget-item--jackpot.ll-widget-item--tablet {
            @include square-jackpot-widget();
        }
    }

    @media only screen and (max-width: 1366px) and (min-aspect-ratio: 1/1.5) {
        &.ll-widget-item--home-4 {
            @include square-widget();

            &.ll-widget-item--game {
                @include square-game-widget();
                padding: 1rem 1rem 1.5rem;
            }

            &.ll-widget-item--event {
                @include long-to-square-event-widget();
            }

            &.ll-widget-item--jackpot {
                @include square-jackpot-widget();
            }
        }
    }

    @media only screen and (min-width: 2020px) and (min-aspect-ratio: 2.22/1) {
        &.ll-widget-item--home-4 {
            @include square-widget();

            &.ll-widget-item--game {
                @include square-game-widget();
                padding: 1rem 1rem 1.5rem;
            }

            &.ll-widget-item--event {
                @include long-to-square-event-widget();
            }

            &.ll-widget-item--jackpot {
                @include square-jackpot-widget();
            }
        }
    }

    @media only screen and (max-width: 1366px) and (max-aspect-ratio: 1/1.5),
    only screen and (max-width: 900px) {
        &.ll-widget-item--home-4 {
            @include square-widget();

            &.ll-widget-item--game {
                @include square-game-widget();
                padding: 1rem 1rem 1.5rem;
            }

            &.ll-widget-item--event {
                @include long-to-square-event-widget();
            }

            &.ll-widget-item--jackpot {
                @include square-jackpot-widget();
            }
        }
    }

    // ~~~~~~ SPORTS PAGE WIDGETS ~~~~~~~
    &.ll-widget-item--sports {
        .ll-widget-item__event-name--winner {
            .ll-widget-item__event-name-box,
            .ll-widget-item__event-name-box--right {
                margin: 1rem 1.5rem 0;

                .ll-popover-wrapper {
                    line-height: 1.5rem;
                    -webkit-line-clamp: 1;
                }
            }
        }
    }
}

%G-ll-widget-item-overflowed-content {
    padding: 0.9rem 1rem 1.2rem;
    margin: 1rem !important;
    cursor: pointer;
    min-height: 10rem;
    line-height: 1.1rem;
    border-radius: 0.3rem;

    &.ll-popover--mobile {
        min-height: 10.5rem;
        z-index: 0;
    }

    .ll-widget-item__bets-item--bet-builder:not(:first-child) {
        padding-top: .5rem;
    }

    .ll-widget-item__bets-item-cross--opened {
        @include position(absolute, top 1rem right 1rem);
        font-size: 1rem;
        display: block;
    }

    .ll-widget-item__bets-item-cross--closed {
        display: none;
    }
}

%G-ll-widget-item__bets-item--bet-builder {
    position: relative;
    padding-left: 2rem;
    @include flex-config($justify-content: space-between, $align-items: center);
    font-size: 1.1rem;

    &::before {
        content: "";
        display: block;
        @include size(.5rem);
        @include position(absolute, left .2rem, $z-index: 2);
        border-radius: 5px;
    }

    &::after {
        content: "";
        display: block;
        @include size(.1rem, 100%);
        @include position(absolute, left .4rem, $z-index: 1);
    }

    &:first-child::after {
        transform: translateY(50%);
    }

    &:last-child::after {
        transform: translateY(-50%);
    }
}

.ll-widget-item-dropdown-content {
    max-height: 40vh;
    @include flex-config($flex-direction: column);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .ll-dropdown-menu-item,
    .ll-dropdown-menu-item--active {
        cursor: pointer;
        font-size: 1.1rem;
    }

    .ll-widget-item-dropdown-content__bet {
        @include flex-config($justify-content: space-between);
        padding: 1rem;
    }
}

.body--with-cursor {
    .ll-widget-item__game-image:hover {
        transform: scale(1.05);
    }
}