/*
3.PAGES
└── 3.4. HOME PAGE
    ├── 3.4.1 HOME PAGE CAROUSEL
*/

/*
|--------------------------------------------------------------------------
| == 3.4. HOME PAGE
|--------------------------------------------------------------------------
*/

.home-page__loader--desktop,
.home-page__loader--tablet,
.home-page__loader--mobile, {
    width: 100%;
    @include flex-config($justify-content: center, $align-items: center);
}

.home-page {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    overflow: hidden;

    div[class^="home-"] {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .ll-widget-wrp {
        @include size(100%);
        @include flex-config($align-items: center, $justify-content: center);
    }

    .ll-widget-wrp--banner {
        .ll-widget-item__inner,
        .ll-widget-item__image {
            @include size(100%)
        }
    }
}

.home-1 {
    grid-area: 1 / 1 / 2 / 2;
}

.home-2 {
    grid-area: 1 / 2 / 2 / 3;
}

.home-3 {
    grid-area: 1 / 3 / 2 / 4;
}

.home-4 {
    grid-area: 2 / 1 / 3 / 4;
}

@media only screen and (max-width: 1366px) and (min-aspect-ratio: 1/1.5) {
    .home-page {
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
    }
    .home-1 {
        grid-area: 1 / 1 / 2 / 2;
    }
    .home-2 {
        grid-area: 1 / 2 / 2 / 3;
    }
    .home-3 {
        grid-area: 2 / 1 / 3 / 2;
    }
    .home-4 {
        grid-area: 2 / 2 / 3 / 3;
    }
}

@media only screen and (min-width: 1367px) and (min-aspect-ratio: 1.78/1) {
    .home-page {
        overflow: hidden;
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
    }
    .home-1 {
        grid-area: 1 / 1 / 3 / 2;
    }
    .home-2 {
        grid-area: 1 / 2 / 2 / 3;
    }
    .home-3 {
        grid-area: 1 / 3 / 2 / 4;
    }
    .home-4 {
        grid-area: 2 / 2 / 3 / 4;
    }
}

@media only screen and (min-width: 2020px) and (min-aspect-ratio: 2.22/1) {
    .home-page {
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
    }
    .home-1 {
        grid-area: 1 / 1 / 3 / 2;
    }
    .home-2 {
        grid-area: 1 / 2 / 2 / 3;
    }
    .home-3 {
        grid-area: 1 / 3 / 3 / 3;
    }
    .home-4 {
        grid-area: 2 / 2 / 3 / 3;
    }
}


/*
|--------------------------------------------------------------------------
| == 3.4.1 HOME PAGE CAROUSEL
|--------------------------------------------------------------------------
*/

.home-page__banner-slider,
.slick-slider,
.slick-list,
.slick-track,
.slick-slide,
.slick-slide > div,
.home__slide-container {
    @include size(100%);
}

.ll-page {
    .slick-dots li button:before {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .slick-dots li button:before {
        content: '';
        display: block;
        @include size(8px);
        opacity: 1;
        border-radius: 50%;
    }
}