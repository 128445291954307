%G-games__search--tablet--desktop {
    @include flex-config($align-items: center, $justify-content: center);
    flex-shrink: 0;
    width: 20rem;
    height: 3.5rem;
    margin: 0 0.8rem;
    padding: 0 0.8rem;
    border-radius: 50px;

    .games__search-input,
    .slot-battle__search-input {
        padding: 0;
    }

    .games__search-icon,
    .slots-battles__search-icon {
        margin: 0 0.8rem;
        font-size: 1.6rem;
        cursor: pointer;
    }

    .dx-placeholder {
        display: flex;
        align-items: center;
    }

    .ll-input__textbox {
        border: none;
        box-shadow: none !important;
        padding: 0 0.8rem;

        input,
        .dx-placeholder::before {
            padding: 0 !important;
            font-size: 1.2rem;
        }
    }
}

%G-games {
    .slots-casino--loader-container {
        @include flex-config($align-items: center, $justify-content: center);
    }

    .games__header {
        @include flex-config($flex-direction: column, $align-items: center, $justify-content: flex-start);
        width: 100%;
    }

    .games__header-banner {
        width: 100%;
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &:before {
            content: '';
            @include position(absolute, top 0 left 0);
            @include size(100%);
            background: linear-gradient(
                            180deg,
                            rgba(2, 15, 31, 0.04) 0,
                            rgba(2, 15, 31, 0.04) 50%,
                            rgba(2, 15, 31, 0.06) 78%,
                            rgba(2, 15, 31, 0.13) 84%,
                            rgba(2, 15, 31, 0.73)
            );
        }
    }

    .games__header-menu-container {
        width: 100%;
        user-select: none;
    }

    .slots-casino-menu-wrapper {
        @include flex-config($align-items: center, $justify-content: center);
        margin: auto;
        position: relative;
    }

    .games-menu__btn {
        white-space: nowrap;
        border-radius: 35px;

        .menu-item__icon {
            font-size: 1.5rem;
            margin-right: 1rem;
        }
    }

    .games__header-menu {
        @include flex-config($align-items: center, $justify-content: center);
    }

    .slots-casino-menu__item--disabled,
    .sub-item-filters--disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    .games__list {
        @include flex-config($flex-wrap: wrap, $align-items: flex-start, $justify-content: flex-start);
        position: relative;
    }

    .games__list--empty {
        font-size: 2rem;
        width: 100%;
        text-align: center;
    }

    .game__item-container {
        cursor: pointer;
        position: relative;
        height: 100%;
    }

    .game__item-container--loading {
        @include size(100%);
        @include flex-config($justify-content: center, $align-items: center);
    }

    .games__list-reloader {
        @include position(absolute, bottom 50% left 50%);
        transform: translate(-50%);
    }

    .games__list-loader {
        @include position(absolute, bottom 0 left 50%);
        transform: translateX(-50%);
    }

    .game__wrapper {
        @include flex-config($flex-direction: column, $align-items: center, $justify-content: flex-start);
        @include size(100%);
        margin: auto;
        position: relative;
        border-radius: 3px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .games__item-statuses {
        @include flex-config($justify-content: space-between);
        @include position(absolute, top 0, $z-index: 4);
        width: 100%;
        padding: .5rem;
    }

    .games__item-statuses-part {
        display: flex;
        gap: .3rem;
    }

    .game__item-jackpot-logo,
    .games__item-status-icon {
        @include size(2.4rem);
        @include flex-config($align-items: center, $justify-content: center);
        color: #fff;
        border-radius: 3px;
        transition: .3s all ease-in-out;
    }

    .game__item-jackpot-logo {
        @include size(3.2rem);
    }

    .game__item-popup {
        @include size(100%);
        @include flex-config($flex-direction: column, $align-items: center, $justify-content: center);
        @include position(absolute, top 0 left 0, $z-index: 3);
    }

    .games__item--without-image {
        @include size(100%);
        @include flex-config($flex-direction: column, $align-items: center, $justify-content: center);
        margin: auto;
        border-radius: 3px;
        background-size: 200%;
        background-repeat: no-repeat;
        background-position: center;
    }

    .game__provider-without-img {
        padding: 0.2rem 0.5rem;
        border-radius: 5px;
        line-height: 40px;
    }

    .game__game-name {
        @include position(absolute, bottom 0);
        @include flex-config($align-items: center, $justify-content: center);
        width: 100%;
        margin: auto;
        padding: 0.5rem 0.8rem;
        line-height: 1.2rem;
        height: 3.4rem;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.51);
        text-transform: uppercase;

        .game__game-name-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

%G-games__desktop {
    .slots-casino--loader-container {
        height: calc(100vh - 140px - 242px - 45px);
    }

    .games__header-banner {
        height: 30rem;
    }

    .slots-casino-menu-wrapper {
        @include size(100%, 4.5rem);

        & > .slots-casino-menu__item,
        & > .slots-casino-menu__item--active {
            justify-content: flex-start;
            width: 20rem;
        }

        .games-menu__btn {
            @include flex-config($align-items: center, $justify-content: center);
            font-size: 1.4rem;
            height: 3.2rem;
            margin: 0 1rem;
            padding: 0 2rem;
        }
    }

    .games__header-menu {
        @include flex-config($align-items: center, $justify-content: center);
        height: 4.5rem;
        min-width: 80%;
        max-width: 80%;
        margin-right: 3.2rem;
    }

    .slots-casino-menu-slider {
        width: auto;
        height: 100%;

        .slider__item {
            height: 100%;
        }
    }

    .games__search {
        @extend %G-games__search--tablet--desktop;
    }

    .games__list {
        width: 90%;
        margin: auto;
        padding: 0;
    }

    .game__item-popup {
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .game__item-container:hover {
        .game__item-popup {
            opacity: 1;
            border-radius: 3px;
            background-color: rgb(0, 0, 0, 0.6);
        }
    }

    .game__fun-button,
    .game__real-button {
        @include size(80%, 30px);
        border-radius: 50px;
        margin: 5px;
        font-size: 1.4rem;
        transition: .3s all ease-in-out;
        white-space: nowrap;

        &:hover {
            @include mq('desktop') {
                width: 90% !important;
            }
        }
    }

    .game__item-logo {
        width: 140px;
    }
}

%G-games__tablet {
    .slots-casino--loader-container {
        min-height: 100%;
        flex: 1;
    }

    .games__header-banner {
        height: 12rem;
    }

    .slots-casino-menu-wrapper {
        width: 100vw;
    }

    .games__header-menu {
        height: 5rem;
        min-width: calc(100vw - 155px - 155px - 230px);
        max-width: calc(100vw - 155px - 155px - 230px);

        @media screen and (max-width: 1200px) {
            width: 125px;
            min-width: calc(100vw - 125px - 125px - 230px);
            max-width: calc(100vw - 125px - 125px - 230px);
        }
        @media screen and (max-width: 1100px) {
            width: 110px;
            min-width: calc(100vw - 114px - 114px - 230px);
            max-width: calc(100vw - 114px - 114px - 230px);
        }
        @media screen and (max-width: 1023px) {
            width: 135px;
            min-width: calc(100vw - 135px - 135px - 230px);
            max-width: calc(100vw - 135px - 135px - 230px);
        }
        @media screen and (max-width: 900px) {
            width: 115px;
            min-width: calc(100vw - 115px - 115px - 200px);
            max-width: calc(100vw - 115px - 115px - 200px);
        }
    }

    .slider__item .menu-item__label {
        white-space: normal;
    }

    .games-menu__btn {
        @include flex-config($align-items: center, $justify-content: center);
        font-size: 1.2rem;
        height: 3.5rem;
        margin: 0 .5rem;
        padding: 0 1rem;
    }

    .games__search {
        @extend %G-games__search--tablet--desktop;

        max-width: 11rem;
    }

    .games__list {
        width: 90%;
        margin: auto;
    }

    .game__wrapper {
        background-position: inherit;
    }

    .game__item-logo {
        width: 140px;
    }
}

%G-games__mobile {
    @include flex-config($flex-direction: column, $align-items: center, $justify-content: center);
    flex: 1;

    .slots-casino--loader-container {
        height: calc(100vh - 160px);
    }

    .games__header-banner {
        height: 14rem;
    }

    .slots-casino__header-menu-wrapper {
        flex-direction: column;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .games-menu--mobile {
        width: 100%;

        .games-menu__btn {
            min-width: 7rem;
            height: 4rem;
            margin: 0 .5rem;
            padding: .5rem;
            position: relative;
            @include flex-config($flex-direction: column, $justify-content: space-between, $align-items: center);
            border-radius: 3px;
            font-size: 1rem;
            line-height: 1.1rem;
            text-align: center;
        }

        .games-menu__btn-text {
            max-width: 100%;
            text-wrap: wrap;
        }

        .games-menu__submenu-item--disabled {
            opacity: .5;
        }

        @media only screen and (max-width: 374px) {
            .games-menu__btn {
                min-width: 5rem;
                margin: 0 .25rem;
            }
        }

        .games-menu__inner-box {
            @include flex-config($align-items: flex-end);
            @include size(100%, 6.6rem);
            padding: 1rem 0;
        }

        .games-menu__filter-header {
            padding: 1rem;
            font-size: 1.4rem;
        }

        .games-menu__filter-header-item {
            display: inline-block;

            &:not(:last-of-type) {
                margin-right: 1rem;
                padding-right: 1rem;
            }
        }

        .games-menu__filter-header-icon {
            display: inline-block;
            margin-right: .5rem;
        }

        .dx-texteditor.dx-editor-outlined .dx-placeholder:before {
            padding: .3rem 0 0 1rem;
            font-size: 1.2rem;
        }

        .games__search {
            flex: 1;
            margin: 0 .5rem;
            position: relative;
            align-self: flex-end;

            input {
                height: 4rem;
                -webkit-appearance: none;
                border: 0;
                outline: 0;
                border-radius: 3px;
                font-size: 1.2rem;
            }

            .games__search-icon {
                @include position(absolute, right 1rem top 50%);
                transform: translateY(-50%);
            }
        }

        @media only screen and (max-width: 374px) {
            .games-menu__inner-box {
                padding: 1rem .75rem;
            }

            .games__search {
                margin: 0 .25rem;
            }
        }

        .games-menu__btn--mode {
            width: 7rem;
            flex-shrink: 0;
        }

        .games-menu__btn--checked {
            &::before {
                content: '';
                display: block;
                @include size(1.4rem);
                @include position(absolute, top -.1rem right -.1rem);
                border-radius: 50%;
            }

            &::after {
                content: '';
                @include size(.3rem, .6rem);
                @include position(absolute, top .1rem right .3rem);
                border: 2px solid #393939;
                border-top-width: 0;
                border-left-width: 0;
                transform: rotate(45deg);
            }
        }

        .games-menu__submenu-box {
            width: calc(100% - 1rem);
            @include position(absolute, top 6.6rem left .5rem, $z-index: 6);
            border-radius: 3px;
        }

        .games-menu__submenu-inner-wrp {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            overflow-y: auto;
            max-height: 25rem;
        }

        .games-menu__submenu-header {
            position: relative;
            @include flex-config($flex-direction: column, $align-items: center);
            padding: .5rem;

            .games-menu__submenu-item-icon {
                font-size: 1.4rem;
            }
        }

        .games-menu__submenu-header-close-btn {
            @include position(absolute, right 1rem top 50%);
            transform: translateY(-50%);
        }

        .games-menu__submenu-item {
            min-width: 10rem;
            height: 6rem;
            @include flex-config($justify-content: center, $align-items: center, $flex-direction: column);
            font-size: 1.2rem;
            text-align: center;
        }

        .games-menu__submenu-item-icon {
            font-size: 1.6rem;
        }

        .games-menu__submenu-item-img {
            border-radius: 50%;
            margin: 0.3rem;
            max-height: 2.5rem;
            padding: 0.1rem;
            width: 2.5rem;
        }
    }

    .game-list__back-drop {
        width: 100%;
        height: 100%;
        background-color: #2D2D2Daa;
        backdrop-filter: blur(5px);
        @include position(absolute, top 0 left 0, $z-index: 5);
    }

    .games__list {
        width: 100%;
        @include flex-config($flex-wrap: wrap, $justify-content: center);
        flex: 1;
        margin: auto;
        padding: 0 .4rem 0 .5rem;
    }

    .game__wrapper {
        background-position: top;
    }

    .games__item--without-image {
        background-size: cover;
    }

    .game__provider-without-img {
        font-size: 1rem;
    }

    .game__item-logo {
        max-width: 80%;
    }

    .game-list__group {
        padding-bottom: 1rem;
    }
}

%G-games-icon--favorite {
    font-size: 1.4rem;
    color: #fff; // for all themes

    &.filled {
        background: #2D2D2D85; // for all themes
    }
}

%G-games-icon--promoted {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        width: 500%;
        height: 80%;
        filter: blur(.2rem);
        opacity: .8;
        background: linear-gradient(-90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
        @include position(absolute, top 0 left 0, $z-index: 4);
        animation: games-icon--promoted-animation 5s infinite;
        transform-origin: 0 50%;
    }

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
        filter: blur(.2rem);
        transition: .5s all ease-in-out;
        @include position(absolute, top -95% left 0, $z-index: 4);
        animation: games-icon--promoted-border-animation 5s infinite;
    }
}

@keyframes games-icon--promoted-animation {
    0% {
        transform: rotateZ(-90deg);
    }
    5% {
        transform: rotateZ(-45deg);
    }
    10% {
        transform: rotateZ(0);
    }
    15% {
        transform: rotateZ(45deg);
    }
    20% {
        transform: rotateZ(90deg);
    }
    25% {
        transform: rotateZ(135deg);
    }
    30% {
        transform: rotateZ(180deg);
    }
    100% {
        transform: rotateZ(180deg);
    }
}

@keyframes games-icon--promoted-border-animation {
    0% {
        left: 0
    }
    5% {
        left: 95%;
    }
    7% {
        top: auto;
        bottom: 95%;
        left: 95%;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    }
    14% {
        top: auto;
        bottom: -95%;
        left: 95%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.2) 100%);
    }
    18% {
        top: auto;
        left: 0;
        bottom: -95%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 0) 100%);
    }
    22% {
        top: auto;
        left: -100%;
        bottom: -100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    }
    100% {
        top: -100%;
        left: -100%;
        bottom: auto;
        background: transparent;
    }
}

%G-games-icon--exclusive-wrp {
    @include position(absolute, top -.5rem left -.5rem);
    overflow: hidden;

    &::before {
        content: "";
        @include size(.5rem, .5rem);
        filter: grayscale(80%);
    }

    &::after {
        content: "";
        @include size(.5rem, .5rem);
        @include position(absolute, bottom 0 left 0);
        filter: grayscale(80%);
    }

    .games-icon--exclusive-label {
        @include position(relative, left 0, $z-index: 3);
        @include flex-config($justify-content: center, $align-items: center);

        &::before {
            content: attr(data-content);
            font-weight: 500;
            text-transform: capitalize;
            transform: rotate(-45deg) translateY(-60%);
        }
    }
}

%G-games-icon--exclusive-wrp__mobile {
    @extend %G-games-icon--exclusive-wrp;

    &::before {
        @include position(absolute, top 0 left 5rem);
    }

    .games-icon--exclusive-label {
        @include size(5.5rem);

        &::before {
            font-size: 1rem;
        }
    }
}

%G-games-icon--exclusive-wrp__desktop {
    @extend %G-games-icon--exclusive-wrp;

    &::before {
        @include position(absolute, top 0 left 6rem);
    }

    .games-icon--exclusive-label {
        @include size(6.5rem);

        &::before {
            font-size: 1.1rem;
        }
    }
}

.body--with-cursor {
    .games__item-status-icon--favorite:hover {
        transform-origin: center;
        transform: scale(1.2);
    }
}

.game-list__group {
    @include flex-config($flex-direction: column, $align-items: center, $justify-content: center);
    width: 100%;
}

/* ~~~~~~~~~ JACKPOTS ~~~~~~~~~~ */

%G-jackpots {
    .jackpot-group__header {
        width: 100vw;

        .jackpot-group__header-wrapper {
            @include flex-config($align-items: center, $justify-content: space-between);
        }

        .jackpot-group__header-label {
            font-size: 1.4rem;
            text-transform: uppercase;
        }

        .jackpot-group__header-link {
            font-size: 1.4rem;
            text-decoration: underline;
        }
    }

    .jackpot-group__dropdown {
        width: 20rem;

        .jackpot-group__dropdown-wrapper {
            @include flex-config($justify-content: space-between, $align-items: center);
            padding: 0.9rem 1rem;
            border: none;
            border-radius: 0.3rem;
            font-size: 1.4rem;
            user-select: none;
        }

        .ll-dropdown-wrapper--opened {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .ll-dropdown-menu__icon,
        .ll-dropdown-menu__icon--opened {
            font-size: 2rem;
            margin-right: -0.5rem;
            transition: transform .2s ease-in-out;
        }

        .ll-dropdown-menu__icon--opened {
            transform: rotate(-180deg);
        }

        .jackpot-group__dropdown-content {
            width: 100%;
        }

        .jackpot-group__dropdown-scrollbox {
            max-height: 20rem;
            width: 100%;
            border-bottom-left-radius: .3rem;
            border-bottom-right-radius: .3rem;
        }

        .jackpot-group__dropdown-item {
            display: block;
            padding: 1rem;
            font-size: 1.4rem;
            text-transform: capitalize;
        }

        .jackpot-group__dropdown-item--brand {
            font-weight: 500;
        }
    }

    .game-list__group-wrapper {
        margin: 1.5rem 0 2rem;
        display: grid;

        .jackpot-group__card {
            @include flex-config($flex-direction: column, $justify-content: center);
            border-radius: 3px;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url('../../../static/themes/theme/images/slots-and-casino/jackpot-without-img.jpg');
            background-position: center;
            position: relative;
        }

        .jackpot-group__card-logo {
            align-self: center;
            @include flex-config($justify-content: center, $align-items: center);
            max-width: 20rem;
        }

        .games__item {
            margin: 0;
        }

        .jackpot-group__info-box {
            @include flex-config($flex-direction: column, $justify-content: space-between);
        }

        .jackpot-group__label {
            align-self: center;
            border-radius: 3px;
            line-height: 1.2;
            text-transform: uppercase;
        }

        .jackpot-group__label--grand,
        .jackpot-group__label--major {
            font-size: 2.4rem;
            font-weight: 600;
        }

        .jackpot-group__label-value {
            position: relative;
            @include flex-config($justify-content: center, $align-items: center);
            border-radius: 3px;
            font-weight: 500;
            cursor: default;

            &:hover,
            &:active {
                & > .jackpot-group__details {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .jackpot-group__label-value-number {
            animation: money-grows .5s cubic-bezier(0.165, 0.84, 0.44, 1);
        }

        @keyframes money-grows {
            0% {
                opacity: 1;
                scale: .9;
            }
            60% {
                opacity: .8;
                scale: 1.1;
            }
            100% {
                opacity: 1;
                scale: .9;
            }
        }

        .jackpot-group__small-jackpots {
            align-self: stretch;
            @include flex-config($justify-content: center);
            column-gap: 2rem;
        }

        .jackpot-group__small-jackpots-item {
            flex: 1;
            @include flex-config($flex-direction: column, $justify-content: center);
        }

        .jackpot-group__label--minor,
        .jackpot-group__label--mini {
            align-self: center;
            font-size: 2rem;
            font-weight: 500;
        }

        .jackpot-group__details {
            @include position(absolute, left 0);
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.3s ease-in;
            bottom: calc(100% + .2rem);
            padding: 1rem 2rem 1.5rem;
            border-radius: 3px;
            font-size: 1.6rem;

            background: linear-gradient(to top, #090000, #0b0000);

            .jackpot-group__details-row {
                border-bottom: 1px solid #C5C5C560;
            }
        }

        .jackpot-group__details--mini {
            left: auto;
            right: 0;
        }

        .jackpot-group__details-row {
            @include flex-config($justify-content: space-between, $align-items: flex-end);
            padding: 1rem 0;

        }

        .jackpot-group__details-label {
            text-transform: uppercase;
        }

        .jackpot-group__details-date {
            font-size: 1.2rem;
            line-height: 2rem;
        }

        .jackpot-group__details-totals {
            margin-top: 1rem;
            font-size: 1.2rem;
            text-align: center;
        }
    }
}

%G-jackpots--mobile {
    .jackpot-group__header-wrapper {
        margin: 0 .5rem;
        flex-wrap: wrap;
    }

    .jackpot-group__dropdown {
        width: 100%;
        margin-top: .5rem;

        .jackpot-group__dropdown-wrapper {
            height: 4rem;
        }
    }

    .jackpot-group__header-label,
    .jackpot-group__header-link {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .jackpot-group__header-label {
        margin-left: .2rem;
    }

    .jackpot-group__header-link {
        margin-right: .2rem;
    }

    .game-list__group-wrapper {
        width: 100%;
        margin: 0;
        grid-gap: 1rem;
        justify-content: center;

        grid-template-columns: repeat(auto-fill, 115px);
        grid-template-rows: repeat(auto-fill, 147px);

        .jackpot-group__card {
            grid-column: 1 / 4;
            grid-row: 1 / 4;
            padding: 2rem;
            column-gap: 1rem;
        }

        .jackpot-group__card-logo {
            margin-bottom: 1rem;
        }

        .jackpot-group__card-logo-image {
            max-height: 6.4rem;
        }

        .jackpot-group__label {
            margin-top: .5rem;
            font-size: 1.8rem;
        }

        .jackpot-group__label-value {
            height: 4rem;
            font-size: 1.6rem;
        }

        .jackpot-group__label-value--grand {
            font-size: 2rem;
        }

        .jackpot-group__label-value--major {
            font-size: 1.8rem;
        }

        .jackpot-group__details {
            width: 32.5rem;
        }
    }
}

%G-jackpots--tablet-desktop {
    .jackpot-group__header-wrapper {
        @include size(90vw, 4rem);
        margin: auto;
    }

    .jackpot-group__dropdown {
        .jackpot-group__dropdown-wrapper {
            height: 3.2rem;
        }
    }

    .jackpot-group__header-link {
        width: 20rem;
        text-transform: uppercase;
    }

    .game-list__group-wrapper {
        width: 100%;
        min-height: 471.5px;
        grid-gap: 1.5rem;
        grid-template-columns: repeat(auto-fill, 176px);
        grid-template-rows: repeat(auto-fill, 232px);

        .jackpot-group__card {
            grid-column: 1 / 3;
            grid-row: 1 / 3;
            padding: 3rem;
            column-gap: 2rem;
        }

        .jackpot-group__card-logo-image {
            max-height: 12rem;
        }

        .jackpot-group__label {
            margin-top: 2rem;
        }

        .jackpot-group__label-value {
            height: 5.4rem;
            font-size: 2rem;
        }

        .jackpot-group__label-value--grand {
            font-size: 2.8rem;
        }

        .jackpot-group__label-value--major {
            font-size: 2.4rem;
        }

        .jackpot-group__details {
            width: 30.7rem;
        }
    }

    .game-list__group-wrapper--featured {
        @include flex-config($justify-content: center, $align-items: center, $flex-wrap: wrap);
    }
}

