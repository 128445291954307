/*
3.PAGES
├── 3.1. SPORT COMMON ELEMENTS
│   ├── 3.1.1. SPORT SEARCH INPUT
│   ├── 3.1.2. SPORT BRANCH PICKER
│   └── 3.1.3. SPORT FILTERS POPUP PANEL
├── 3.2. PREMATCH PAGE
│   └── 3.2.1. PREMATCH PAGE FILTER TOP PANEL
└── 3.3. LIVEMATCH PAGE
    ├── 3.3.1. LIVE PAGE FILTER TOP PANEL
    └── 3.3.2. LIVE SEARCH RESULT DROPDOWN
*/

/*
|--------------------------------------------------------------------------
| == 3.1.1. SPORT SEARCH INPUT
|--------------------------------------------------------------------------
*/

%G-sport-search {
    &,
    .sport-search__container,
    .ll-dropdown-wrapper,
    .ll-dropdown-menu__title-wrapper,
    .ll-input__text-box-container {
        height: 100%;
    }

    &.sport-search--menu {
        height: 3.2rem;
        margin-bottom: .3rem;
        border-radius: 3px;
    }

    .sport-search__container {
        border: 1px solid;
        border-radius: 3px;

        .dx-placeholder,
        .dx-texteditor-input {
            font-size: 1.2rem;
            font-weight: 500;
        }

        .dx-placeholder {
            line-height: 1.5rem;
        }

        .dx-placeholder:before {
            padding: 0 !important;
        }

        .dx-texteditor-input {
            padding: 1rem 0 !important;
        }
    }

    .ll-input__text-box-container {
        @include flex-config($justify-content: center, $align-items: center);
        padding: 1rem;

        .ll-input__textbox {
            flex: 1;
        }
    }

    /* ---------------------- Dev-extreme input normalize ---------------------- */

    .ll-input__textbox.dx-state-focused .dx-placeholder::before {
        display: none;
    }

    .dx-texteditor.dx-editor-underlined::after {
        border: none;
    }

    .dx-texteditor.dx-editor-underlined.dx-state-hover::after {
        border: none;
    }

    /* ---------------------- Icons for search ---------------------- */

    .sport-search__icons,
    .sport-search__icons--search,
    .sport-search__icons--clear {
        @include size(1.5rem);
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    }

    .sport-search__icon {
        @include size(1.4rem);
        position: absolute;
        right: 0;
        font-size: 1.6rem;
        opacity: 1;
        transition: all 0.2s; //todo: при переключении с лайвов на мобилке на календарь, прыгает иконка
        cursor: pointer;
    }

    .sport-search__icons--search {
        .dx-icon-search {
            top: 0;
        }

        .dx-icon-remove {
            top: -1.5rem;
        }
    }

    .sport-search__icons--clear {
        .dx-icon-search {
            top: 1.5rem;
        }

        .dx-icon-remove {
            top: 0;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2. SPORT BRANCH PICKER
|--------------------------------------------------------------------------
*/

/* ----------------- Mobile Branch Picker ----------------- */

%G-branch-picker--mobile {
    height: 100%;
    margin: 0 1rem 0 .5rem;

    .ll-branch-picker__dropdown-wrapper--mobile {
        height: 100%;
        padding: 1rem;
        @include flex-config($justify-content: space-between, $align-items: center);
        border-radius: 3px;
        font-size: 1.4rem;

        &.ll-dropdown-wrapper--opened {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .ll-branch-picker__dropdown {
        width: 100%;
        padding: 0 1rem;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .ll-dropdown-menu-item,
    .ll-dropdown-menu-item--active {
        padding: 1rem 0;
    }
}

/* ------------ Tablet Desktop Branch Picker ----------------- */

%G-branch-picker--tablet--desktop {
    width: 100%;
    margin-bottom: .3rem;

    .branch-picker__branch-item-label {
        height: 3.2rem;
        @include flex-config($justify-content: center, $align-items: center);
        padding: 0 1rem;
        border-radius: 3px;
        font-size: 1.4rem;
        font-weight: 500;
    }

    .slider__item:not(:last-of-type) .branch-picker__branch-item-label {
        margin-right: .1rem;
    }
}

%G-branch-picker--tablet {
    .branch-picker__branch-item-label {
        font-size: 1.3rem;
    }
}

%G-branch-picker--desktop {
    .slider__content {
        width: 100%;
    }

    .slider__item {
        flex: 1;
    }

    .branch-picker .slider__arrow {
        border-radius: 3px;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.3. SPORT FILTERS POPUP PANEL
|--------------------------------------------------------------------------
*/

%G-pre-filters-panel {
    @include position(absolute, top 0 left 0, $z-index: 3);
    width: 100%;

    .pre-filters-panel__container {
        position: relative;
        overflow: auto;
    }

    .pre-filters-panel__container-inner {
        position: relative;
        height: 100%;
    }

    .pre-filters-panel__loader {
        @include size(100%);
        @include position(absolute, top 0 left 0, $z-index: 4);
        @include flex-config($justify-content: center, $align-items: center);
    }

    .filters-panel__header {
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 0 1rem;
        font-size: 1.4rem;
        font-weight: 500;
    }

    .filters-panel__close-icon-wrp {
        @include size(4rem, 100%);
        @include flex-config($justify-content: flex-end, $align-items: center);
    }

    .filters-panel__close-icon {
        font-size: 1.2rem;
        cursor: pointer;
    }

    .filters-panel__item {
        padding: 1.5rem 1rem;
    }

    .filters-panel__item-label {
        margin-bottom: 1rem;
        font-size: 1.4rem;
        font-weight: 500;
    }

    /* ---------------------- Search ---------------------- */

    .sport-search__sports-list {
        @include flex-config();
        margin: 1.5rem 0 1rem;
    }

    .sport-search__sport-item {
        @include flex-config($align-items: center);
        padding: 0 1rem;
        border-radius: 3px;
        font-size: 1.4rem;
        font-weight: 500;
        white-space: nowrap;
        cursor: pointer;
    }

    .sport-search__sport-icon {
        display: inline-block;
        margin-right: .5rem;
    }

    .sport-search__sport-count {
        display: inline-block;
        margin-left: .5rem;
        padding: 0 .5rem;
        border-radius: 3px;
    }

    .event__search-highlite {
        font-weight: inherit;
        text-decoration: underline;
    }

    /* ---------------------- Filters Date Range Picker ---------------------- */

    .ll-date-range-picker--sport-filters {
        .ll-date-range-picker__pickers {
            @include flex-config($align-items: center, $justify-content: space-between);
            height: 100%;
            padding-bottom: 2rem;
        }

        .ll-date-range-picker__picker-box {
            border-radius: 3px;

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }

        .ll-date-picker--mobile,
        .ll-date-picker--tablet,
        .ll-date-picker--desktop {
            @include flex-config($align-items: baseline);

            & > div {
                order: 2;
            }
        }

        .ll-date-range-picker__picker-input {
            z-index: 0;
            margin-left: .5rem;
            border: none;
            background: transparent;
            font-family: 'Maven Pro', sans-serif;
            font-size: 1.4rem;
            font-weight: 500;
        }

        .ll-date-picker__icon {
            position: static;
            order: 1;
            font-size: 1.4rem;
        }
    }

    /* ---------------------- Filters ---------------------- */

    .filters-panel__add-icon {
        display: block;
        padding: 0.6rem;
        border-radius: 50%;
        border: 1px solid;
        font-size: 0.6rem;
    }

    .filters-panel__add-filter-button {
        @include flex-config($justify-content: space-between, $align-items: center);
        margin-bottom: 2rem;
        padding: 0 1rem;
        border-radius: 3px;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.2;
    }

    .filters-panel__add-filter-button--disabled {
        cursor: not-allowed;
        opacity: .8;
    }

    .filters-panel__add-filter-button-name {
        @include flex-config($justify-content: space-between, $align-items: center);
        line-height: 1.6rem;
    }

    .filters-panel__add-filter-button-icon {
        margin-right: .5rem;
    }

    .filters-panel__selected-filter {
        @include flex-config($align-items: center, $justify-content: space-between);
        margin-bottom: 2rem;
        padding: 0 1rem;
        border-radius: 3px;
        font-size: 1.4rem;
        font-weight: 500;
    }

    .filters-panel__selected-filter-name {
        margin-right: 1rem;
        line-height: 1.1;
    }

    .filters-panel__selected-filter-remove-btn {
        display: block;
        padding: 0.6rem;
        border-radius: 50%;
        font-size: 0.7rem;
        cursor: pointer;
    }

    .filters-panel__selected-filter-arrow {
        transform: rotate(90deg);
        transition: transform .2s ease-in-out;
    }

    .filters-panel__selected-filter-arrow--opened {
        transform: rotate(-90deg);
    }

    .filters-panel__filters-submit-btn {
        @include flex-config($justify-content: center, $align-items: center);
        width: 60%;
        margin: 0 auto;
        border-radius: 3px;
        font-size: 1.4rem;
        font-weight: 500;
        cursor: pointer;
    }

    .filters-panel-menu {
        @include position(absolute, top 0 left 0);
        @include size(calc(100% - 2rem), calc(100% - 3rem));
        @include flex-config($flex-direction: column);
        margin: 1.5rem 1rem;
        border-radius: 3px;
    }

    .filters-panel-menu__header {
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 1rem;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        font-size: 1.4rem;
        font-weight: 500;
    }

    .filters-panel-menu__header-close-icon {
        font-size: 1.2rem;
        cursor: pointer;
    }

    .filters-panel-menu__tabs {
        @include flex-config($align-items: center, $justify-content: space-between);
    }

    .filters-panel-menu__tabs-item {
        height: 100%;
        flex: 1;
        @include flex-config($align-items: center, $justify-content: center);
        position: relative;
        font-size: 1.4rem;
        text-transform: capitalize;
        cursor: pointer;

        &:not(:last-child):after {
            content: '';
            height: 50%;
            width: 1px;
        }

        &.disabled {
            opacity: .5;
            cursor: not-allowed;
        }
    }

    .filters-panel-menu__tabs-item-text {
        width: 100%;
        text-align: center;
    }

    .filters-panel-menu__tabs-item-loader {
        @include position(absolute, bottom 0 left 0);
    }

    .filters-panel-menu__tabs-content {
        flex: 1;
        padding: 1rem 0 6rem 0;
        overflow: auto;
    }

    .filters-panel-menu__item {
        width: 100%;
        @include flex-config($align-items: center, $justify-content: space-between);
        padding: 0 1rem;
        font-size: 1.4rem;
        line-height: 1.1;
        cursor: default;
    }

    .filters-panel-menu__item:not(.filters-panel-menu__item-tournament) {
        cursor: pointer;
    }

    .filters-panel-menu__item-tournament .filters-panel__add-icon-wrp {
        cursor: pointer;
    }

    .filters-panel-menu__item-content {
        @include flex-config($align-items: center);
        flex: 1;
    }

    .filters-panel-menu__item-icon {
        font-size: 1.4rem;
        margin-right: 0.6rem;
    }

    .filters-panel-menu__item-name{
        margin-right: 1rem;
    }

    .filters-panel-menu__item-count {
        @include flex-config($align-items: center, $justify-content: center);
        height: 1.4rem;
        flex: 0 0 auto;
        padding: 0 .5rem;
        border-radius: 3px;
    }

    .filters-panel-menu__submit-btn-wrp {
        @include position(absolute, bottom 0 left 0, $z-index: 2);
        @include size(100%, 6rem);
        @include flex-config($justify-content: center, $align-items: center);
    }

    .filters-panel__filters-submit-btn--disabled {
        opacity: .7;
        cursor: not-allowed;
    }

    .filters-panel__add-icon-wrp {
        height: 100%;
        padding-left: 1rem;
        @include flex-config($align-items: center, $justify-content: center);
    }

    .filters-panel-menu__item-dropdown-arrow {
        display: inline-block;
        margin: 0 .4rem;
        font-size: 1.2rem;
        text-align: center;
        transform: rotate(90deg);
        transition: transform 0.3s ease-in-out;

        &.opened {
            transform: rotate(-90deg);
        }
    }

    .filters-panel__filters-result {
        height: 100%;

        .filters-panel__selected-filter {
            width: calc(100% - 2rem);
            margin: 0 1rem 1.5rem 1rem;
        }
    }

    .filters-panel__filters-result-header {
        @include flex-config($align-items: center, $justify-content: space-between);
        padding: 1rem;
        @include size(100%, 5rem);
        font-size: 1.4rem;
    }

    .filters-panel__filters-result-clear-button {
        font-size: 1.2rem;
        text-decoration: underline;
        cursor: pointer;
    }

    .filters-panel__filters-result-tabs {
        width: calc(100% - 2rem);
        @include flex-config($align-items: center, $justify-content: center);
        margin: 0 1rem 1.5rem 1rem;
        font-size: 1.4rem;
    }

    .filters-panel__filters-result-tab {
        flex: 1;
        @include flex-config($align-items: center, $justify-content: center);
        padding: 0 .5rem;
        border-radius: 3px 3px 0 0;
        cursor: pointer;
    }

    .filters-panel__filters-result-tab-label {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        word-break: break-all;
    }

    .filters-panel__filters-results {
        overflow: auto;
    }

    .filters-panel__filters-result-content {
        max-height: 0;
        margin-bottom: 1.5rem;
        overflow: hidden;
        transition: max-height cubic-bezier(0.86, 0, 0.2, 1) 400ms;
    }

    .filters-panel__filters-result-content--opened {
        max-height: 20000px;
        overflow: auto;
    }

    .filters-panel__filters-result-content--empty {
        @include flex-config($align-items: center, $justify-content: center);
        width: 100%;
        height: 4rem;
        padding: 0 1rem 2rem 1rem;
    }
}

%G-pre-filters-panel--mobile--tablet {
    .filters-panel__header {
        @include size(100%, 4.9rem);
    }

    .pre-filters-panel__container {
        @include size(100%, calc(100% - 5rem));
    }

    .sport-search {
        height: 4rem;
    }

    .sport-search__sport-item {
        @include size(auto, 4rem);
    }

    .ll-date-range-picker--sport-filters .ll-date-range-picker__picker-box {
        padding: 1.2rem 1rem;
    }

    .filters-panel__add-filter-button {
        height: 4rem;
    }

    .filters-panel__selected-filter {
        width: 100%;
        min-height: 4rem;
    }

    .filters-panel__filters-submit-btn {
        padding: 1.1rem 1rem;
    }

    .filters-panel-menu__header {
        height: 4rem;
    }

    .filters-panel-menu__tabs {
        height: 4rem;
    }

    .filters-panel-menu__item {
        min-height: 4rem;
    }

    .filters-panel__filters-result-tab {
        height: 4rem;
    }

    .filters-panel__filters-results {
        max-height: calc(100% - 10.5rem);
    }

    .filters-panel__filters-results--single {
        max-height: calc(100% - 5rem);
    }
}

%G-pre-filters-panel--tablet--desktop {
    height: 100%;
    border-radius: 3px;
    overflow: hidden;

    .filters-panel__selected-filter-name,
    .filters-panel__add-filter-button-name {
        font-size: 1.2rem;
    }
}

%G-pre-filters-panel--tablet {
    .filters-panel__header {
        font-size: 1.3rem;
    }

    .ll-date-range-picker--sport-filters {
        .ll-date-range-picker__pickers {
            flex-wrap: wrap;
            justify-content: center;
        }

        .ll-date-range-picker__picker-box {
            width: 100%;
        }

        div.ll-date-range-picker__picker-box:not(:last-child) {
            margin: 0 0 1rem 0;
        }

        .ll-date-range-picker__picker-input {
            font-size: 1.3rem;
        }
    }

    .filters-panel__add-filter-button {
        font-size: 1.3rem;
    }

    .filters-panel-menu__header {
        font-size: 1.3rem;
    }

    .filters-panel-menu__tabs-item {
        flex: auto;
    }

    .filters-panel-menu__tabs-item-text {
        padding: 0 .5rem;
    }
}

%G-pre-filters-panel--desktop {
    .pre-filters-panel__container {
        @include size(100%, calc(100% - 3.2rem));
    }

    .filters-panel__header {
        @include size(100%, 3.2rem);
    }

    .sport-search {
        height: 3.2rem;
    }

    .sport-search__sport-item {
        @include size(auto, 3.2rem);
    }

    .sport-search__sport-icon {
        font-size: 1.4rem;
    }

    .sport-search__sport-count {
        font-size: 1.2rem;
        line-height: 1.2;
    }

    .ll-date-range-picker--sport-filters {
        .ll-date-range-picker__picker-box {
            padding: 0;
        }

        .ll-date-range-picker__picker-input {
            height: 3.2rem;
            margin: 0;
            padding-left: 3rem;
            cursor: pointer;
        }

        .ll-date-picker__icon {
            @include position(absolute, top 0 left 1rem);
        }
    }

    .filters-panel__add-filter-button {
        height: 3.2rem;
        cursor: pointer;
    }

    .filters-panel__selected-filter {
        width: 100%;
        min-height: 3.2rem;
    }

    .filters-panel__filters-submit-btn {
        padding: .6rem 1rem;
    }

    .filters-panel-menu__header {
        height: 3.2rem;
    }

    .filters-panel-menu__tabs {
        height: 3.2rem;
    }

    .filters-panel-menu__item {
        min-height: 3.4rem;
    }

    .filters-panel__filters-result-tab {
        height: 3.2rem;
    }

    .filters-panel__filters-results {
        max-height: calc(100% - 9.7rem);
    }

    .filters-panel__filters-results--single {
        max-height: calc(100% - 5rem);
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.1. PREMATCH PAGE FILTER TOP PANEL
|--------------------------------------------------------------------------
*/

%G-prematch-filters-mobile {
    @include flex-config();
    padding: 1rem 0;

    .prematch__filters-search-btn {
        @include size(calc(50% - 1rem), 4rem);
        @include flex-config($align-items: center, $justify-content: space-between);
        margin-left: 1rem;
        padding: 1rem;
        border-radius: 0.3rem;
        border: 0.1rem solid;
        font-size: 1.4rem;
    }

    .prematch__filters-branches-wrp {
        width: 50%;
    }
}

 /*
 |--------------------------------------------------------------------------
 | == 3.3.1. LIVE PAGE FILTER TOP PANEL
 |--------------------------------------------------------------------------
 */

%G-live-filters--mobile {
    .live-filters__inner {
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 1rem;
        gap: 1rem;
    }

    .live-filters__search,
    .live-filters__switcher {
        height: 4rem;
        flex: 1 1;
        @include flex-config($justify-content: center, $align-items: center);
        border-radius: 0.3rem;
    }

    .sport-filters__branch {
        gap: 1rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.3.2. LIVE SEARCH RESULT DROPDOWN
|--------------------------------------------------------------------------
*/

.sport-search__dropdown--live {
    width: 100%;
    overflow: auto;
    font-size: 1.4rem;

    &.sport-search__dropdown--mobile,
    &.sport-search__dropdown--tablet {
        max-height: 50vh;
    }

    &.ll-dropdown-menu {
        justify-content: flex-start;
        z-index: 100;
    }

    .ll-dropdown-menu-item {
        width: 100%;
        @include flex-config($align-items: center);
        cursor: pointer;
    }

    .sport-search__dropdown-item {
        @include flex-config($align-items: center);
        @include size(100%);
        padding: .5rem 1rem;
    }

    .sport-search__item-icon {
        margin-right: 1rem;
    }
}