%page-bg {
    background-image: url("../../../../static/themes/betlima/images/backgrounds/bg_lobby.jpg");
    background-color: #0e0e10;
}

.lobby-page {
    color: #fff;

    .lobby-item__label-text {
        color: var(--lobby-color);
    }

    .lobby-item__btn {
        transition: color 0.3s, border-color 0.3s;
        border: 0.1rem solid var(--lobby-color);
        color: var(--lobby-color);
    }
}

.lobby-page--desktop {
    @extend %page-bg;
}

.lobby-page--tablet {
    @extend %page-bg;
}