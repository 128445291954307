/*
2. LAYOUT
├── 2.1. COMMON LAYOUT COMPONENTS
│   ├── 2.1.1 SOCIAL ICONS
│   └── 2.1.2 LICENSE ICONS
├── 2.2. FOOTER
│   ├── 2.2.1 FOOTER DESKTOP
│   └── 2.2.2 FOOTER MOBILE
├── 2.3. CONTENT
│   ├── 2.3.1 CONTENT FOOTER
├── 2.4. HEADER
│   ├── 2.4.1 HEADER DESKTOP
│   ├── 2.4.2 HEADER MOBILE
│   ├── 2.4.3 HEADER MENU
│   └── 2.4.4 HEADER LANGUAGE PICKER
├── 2.5. MODAL
│   ├── 2.5.1 MODAL DESKTOP
│   └── 2.5.2 MODAL MOBILE
├── 2.6. DRAWER
│   ├── 2.6.1 GAME MENU DRAWER
│   ├── 2.6.2 SUPPORT MENU DRAWER
│   ├── 2.6.3 MY ACCOUNT MENU DRAWER
│   └── 2.6.4 LOGIN DRAWER
├── 2.7. POPOVER
└── 2.8. BACKDROP
*/

/*
|--------------------------------------------------------------------------
| == 2.LAYOUT
|--------------------------------------------------------------------------
*/

.ll-page {
    @extend %G-ll-page;
}

.page-wrapper--desktop {
    @extend %G-page-wrapper--desktop;
}

.page-wrapper__children {
    @extend %G-page-wrapper__children;
}

.ll-content-container-scroll {
    @extend %G-ll-content-container-scroll;
}

.ll-full-width-content-wrapper {
    @extend %G-ll-full-width-content-wrapper;
}

/*
|--------------------------------------------------------------------------
| == 2.1. COMMON LAYOUT COMPONENTS
| == 2.1.1 SOCIAL ICONS
|--------------------------------------------------------------------------
*/

.ll-social-icons--desktop,
.ll-social-icons--tablet,
.ll-social-icons--mobile {
    @extend %G-ll-social-icons;
}

/*
|--------------------------------------------------------------------------
| == 2.1.2 LICENSE ICONS
|--------------------------------------------------------------------------
*/
.ll-license-icons {
    @extend %G-ll-license-icons;

    .ll-license-icons--circle-icon {
        height: 4rem;
        background: url('../../../../static/themes/theme/images/footer/footer_tiny_circle.png')
            no-repeat center top;
    }

    .ll-license-icons__icon,
    .ll-license-icons__icon--desktop {
        background-color: initial;
        background-size: contain;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.2. FOOTER
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| == 2.2.1 FOOTER DESKTOP
|--------------------------------------------------------------------------
*/

.ll-footer--desktop {
    @extend %G-ll-footer--desktop;

    min-height: $size-height--footer_desktop;
    max-height: $size-height--footer_desktop;
    background: #06486C;

    .ll-footer__icon {
        color: #000;
    }

    .ll-footer__link {
        color: #000;
    }

    .ll-footer__btn {
        color: #000;
        background-color: #F6A83C;

        &.ll-footer__link--active::before {
            border-right: 7px solid transparent;
            border-top: 7px solid #06486C;
            border-left: 7px solid transparent;
        }
    }

    .ll-footer__logo {
        background-image: url('../../../../static/themes/theme/images/logos/logo.png');
        background-repeat: no-repeat;
        background-size: contain;
        @include size(15rem, 3rem)
    }
}

.body--with-cursor {
    .ll-footer--desktop {
        .ll-footer__btn:hover {
            background: #042E4B;

            .ll-footer__link,
            .ll-footer__icon {
                color: #fff;
            }
            .footer-featured__background-img-blur {
                background: #042E4B;
            }
        }
    }
    .footer-featured__background-img-blur {
        background: radial-gradient(#f6a83c60 0%, #f6a83c 70%);
    }
}

/*
|--------------------------------------------------------------------------
| == 2.2.2 FOOTER MOBILE
|--------------------------------------------------------------------------
*/

.ll-footer--mobile {
    @extend %G-ll-footer--mobile;

    min-height: $size-height--footer_mobile;
    max-height: $size-height--footer_mobile;
    border-top: 1px solid #2C628D;
    background-color: #042E4B;

    .slider__arrow {
        color: #fff;
    }

    .ll-footer__item {
        color: #fff;

        &.ll-footer__item--featured.ll-footer__item--active {
            color: #F6A83C;
        }
    }

    .ll-footer__money-item-part:first-of-type:after {
        background: #06486C;
    }

    .footer-featured__background-img-blur {
        background: radial-gradient(#042e4b85 0%, #042e4b 70%);
    }
}

/*
|--------------------------------------------------------------------------
| == 2.3 CONTENT
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| == 2.3.1 CONTENT FOOTER
|--------------------------------------------------------------------------
*/
.ll-content__footer {
    @extend %G-ll-content__footer;
    height: $size-height--content-footer;
    background: #031E33;
    color: #fff;

    .footer-menu__item {
        color: #606060;
    }

    .footer-license__icon--desktop {
        color: #F6A83C;
    }
}

.page-wrapper--desktop:not(.page-wrapper--sport):not(.page-wrapper--live):not(.page-wrapper--casino):not(.page-wrapper--slots):not(.page-wrapper--home-page) {
    .ll-content__footer {
        box-shadow: inset 0 1px 0 0 #267188;
    }
}

.body--with-cursor {
    .ll-content__footer {
        .footer-menu__item:hover,
        .footer-menu__support-mail-link:hover,
        .footer-license__link:hover {
            color: #F0871A;

            .footer-license__icon--desktop {
                color: #fff;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4. HEADER
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 2.4.1 HEADER DESKTOP
|--------------------------------------------------------------------------
*/
.ll-header--desktop {
    @extend %G-ll-header--desktop;

    background-color: #06486C;

    .ll-header__top {
        height: $size-height--header_desktop;
    }

    .ll-header-btn {
        background: #F6A83C;
        color: #000;

        &.ll-header-btn--secondary {
            background: #497A9A;
            color: #fff;
        }

        &.ll-header-btn--accent {
            background: #F0871A;
        }
    }

    .ll-header__logo {
        background: url('../../../../static/themes/theme/images/logos/logo.png')
            no-repeat;
        background-size: contain;
    }

    .ll-header__messages-counter {
        border: 1px solid #F6A83C;
        background: #F6A83C;
        color: #000;
    }

    .ll-header__sign-out-btn {
        border-color: #F0871A;
        color: #F0871A;
        background: transparent;
    }
}

.body--with-cursor {
    .ll-header--desktop {
        .ll-header-btn {
            &:hover {
                background: #F0871A;
            }

            &.ll-header-btn--secondary {
                &:hover {
                    background: transparent;
                    box-shadow: inset 0 0 1px 1px #fff;
                    color: #fff;
                }
            }

            &.ll-header-btn--accent {
                &:hover {
                    box-shadow: inset 0 0 1px 1px #F6A83C;
                    background: transparent;
                    color: #F6A83C;
                }
            }
        }

        .ll-header__sign-out-btn {
            &:hover {
                background: transparent;
                border-color: #fff;
                color: #fff;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4.2 HEADER MOBILE
|--------------------------------------------------------------------------
*/

.ll-header--mobile {
    @extend %G-ll-header--mobile;
    background: #042E4B;

    .ll-header__menu-button {
        color: #fff;
    }

    .ll-header__button-icon {
        color: #F6A83C;
    }

    .ll-header__logo {
        @include size(125px, 40px);
        background: url('../../../../static/themes/theme/images/logos/logo.png')
            no-repeat;
        background-size: contain;
    }

    .active-bets__icon-count {
        background-color: #f6a83c;
        color: #2d2d2d;
        border-color: #042e4b;

        &.animated {
            background-color: #f0871a;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4.3 HEADER MENU
|--------------------------------------------------------------------------
*/

.ll-header__menu--sticky,
.slick-list__top-container--desktop,
.slick-list__top-container--tablet,
.slick-list__top-container--mobile {
    @extend %G-ll-header__menu;

    height: $size-height--header-menu_desktop;
}

.slick-list__top-container--desktop,
.slick-list__top-container--tablet,
.slick-list__top-container--mobile {
    // .ll-dropdown-menu {
    //     color: #fff;
    // }

    .menu-item--mobile,
    .menu-item--tablet {
        .menu-item__is-new-indicator {
            background: #F6A83C;

            &:after {
                border-left: .6rem solid #042e4b;
            }
        }

        .menu-item__is-new-indicator-label {
            color: #042e4b;
        }

        &.active {
            box-shadow: none;
            background-color: #F6A83C;

            .ll-header__item-text,
            .menu-item__icon,
            .menu-item__label,
            .ll-dropdown-menu__icon,
            .ll-dropdown-menu__text {
                color: #000;
            }

            .menu-item__is-new-indicator {
                background: #000;

                &:after {
                    border-left: .6rem solid #F6A83C;
                }
            }

            .menu-item__is-new-indicator-label {
                color: #F6A83C;
            }
        }
    }

    .menu-item--desktop {
        color: #fff;

        &:not(:first-child):before {
            background: #042E4B;
            color: #fff;
        }

        .menu-item__is-new-indicator {
            background: #F6A83C;

            &:after {
                border-left: .6rem solid #042E4B;
            }
        }

        .menu-item__is-new-indicator-label {
            color: #042E4B;
        }

        &:hover,
        &.active {
            box-shadow: none;
            color: #F6A83C;

            .ll-header__item-text,
            .menu-item__icon,
            .menu-item__label,
            .ll-dropdown-menu__icon,
            .ll-dropdown-menu__text {
                color: #F6A83C;
            }
        }
    }
}

.slick-list__top-container--desktop {
    @extend %G-slick-list__top-container--desktop;

    background: #042E4B;
}

.slick-list__top-container--tablet,
.slick-list__top-container--mobile {
    @extend %G-slick-list__top-container--mobile;
}

.slick-list__top-container--tablet,
.slick-list__top-container--mobile {
    background: #042E4B;

    .slick-arrow {
        background: #042E4B;
        color: #fff;
    }
}

.ll-header__menu--sticky {
    @extend %G-ll-header__menu--sticky;
}

.header-menu-subs {
    @extend %G-header-menu-subs;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1254901961);

    &.menu-sub-item--mobile,
    &.menu-sub-item--tablet {
        background-color: #06486C;
    }

    &.menu-sub-item--desktop {
        @extend %G-header-menu-subs--desktop;

        background-color: #06486C;
    }
}

.header-menu-sub-item {
    @extend %G-header-menu-sub-item;

    color: #fff;

    &:not(:last-child) {
        border-bottom: 1px solid #042E4B;
    }
}

.body--with-cursor {
    .slick-list__top-container--desktop,
    .slick-list__top-container--tablet,
    .slick-list__top-container--mobile {
        .slick-arrow {
            &:hover {
                color: #F6A83C;
            }
        }
    }

    .menu-item--desktop {
        &:hover {
            color: #F6A83C;
        }
    }

    .header-menu-sub-item:hover {
        color: #F6A83C;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4.4 HEADER LANGUAGE PICKER
|--------------------------------------------------------------------------
*/

.ll-language-picker--desktop,
.ll-language-picker--tablet,
.ll-language-picker--mobile {
    @extend %G-ll-language-picker;

    .ll-language-picker__wrapper {
        background: #06486C;
        color: #fff;
    }

    .ll-dropdown-menu-item--active {
        .ll-language-picker__text {
            font-weight: bold;
        }
    }
}

.ll-language-picker--desktop {
    @extend %G-ll-language-picker--desktop;
    text-transform: uppercase;

    .ll-language-picker__wrapper {
        border: 1px solid #031E33;
        border-radius: 14px;
    }

    .ll-dropdown-menu-item,
    .ll-dropdown-menu-item--active {
        border: 1px solid #031E33;
        background: #06486C;
        color: #fff;
    }

    .ll-dropdown-menu-item--active {
        color: #F6A83C;
    }

    .ll-language-picker__items {
        :first-child {
            background: #06486C;
            :after {
                background: #06486C;
                border-right: 1px solid #031E33;
                border-top: 1px solid #031E33;
            }
            &.ll-dropdown-menu-item--active {
                :after {
                    border-right: 1px solid #031E33;
                    border-top: 1px solid #031E33;
                }
            }
        }
    }
}

.body--with-cursor {
    .ll-language-picker--desktop {
        .ll-dropdown-menu-item:hover,
        .ll-dropdown-menu-item--active:hover {
            color: #F6A83C;
        }
    }
}

.ll-language-picker--tablet,
.ll-language-picker--mobile {
    @extend %G-ll-language-picker--desktop;
    @extend %G-ll-language-picker--mobile;

    .ll-language-picker__wrapper {
        background: transparent;
        color: #fff;
    }

    .ll-dropdown-menu-item {
        background: #0C5982;
        border: 1px solid #162136;
        color: #fff;
    }

    .ll-dropdown-menu-item--active {
        background: #06486C;
        border: 1px solid #162136;
        color: #fff;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.5. MODAL
|--------------------------------------------------------------------------
*/

.fullscreen-enabled {
    .ll-modal__scrollbox--desktop {
        max-width: 110vw;
    }
}

.ll-modal {
    @extend %G-ll-modal;
    background: #042E4B;
    color: #fff;

    .ll-modal__header {
        background: #031E33;
        color: #fff;
    }

    .ll-modal__header--error {
        background: #c81c1c;
        color: #fff;
    }

    .ll-modal__btns--external {
        .ll-modal__button {
            background: #F6A83C;
            color: #000;
        }
    }

    .ll-modal__button-inner {
        color: #000;
    }

    .ll-modal__close-button {
        border-radius: 50%;
    }

    .ll-modal__fullscreen-button .ll-modal__button-inner {
        border-radius: 50%;
    }

    .ll-modal__custom-content {
        background: #042E4B;
    }
}

.ll-modal__yes-no {
    @extend %G-modal__yes-no;
}

.ll-modal__external-mobile-wrp,
.ll-game-modal {
    @extend %G-ll-game-modal;
}

.ll-game-error-modal {
    @extend %G-ll-game-error-modal;
}

.body--with-cursor {
    .ll-modal__yes-no .ll-btn--outlined:hover {
        border-color: #fff;
        background: none;
        color: #fff;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.5.1 MODAL DESKTOP
|--------------------------------------------------------------------------
*/

.ll-modal__fullscreen-button {
    top: -3.2rem;
    right: 4.3rem;
}

.ll-modal.ll-modal--desktop {
    @extend %G-ll-modal--desktop;

    &.luckyland-link {
        max-width: 70vw;
        .ll-modal__scrollbox--external {
            width: 70vw;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.5.2 MODAL MOBILE
|--------------------------------------------------------------------------
*/

.ll-modal--mobile {
    @extend %G-ll-modal--mobile;

    .promo-modal {
        min-width: 80vw;
    }
}

.body--small-desktop {
    .ll-game-modal {
        @extend %G-ll-game-modal--small-desktop;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6. DRAWER
|--------------------------------------------------------------------------
*/

.ll-drawer {
    @extend %G-ll-drawer;
    background-color: #042E4B85;
    color: #fff;

    @supports (-webkit-backdrop-filter: blur()) {
        background: #042E4Bee;
    }

    .ll-drawer__header--mobile,
    .signup-drawer__header {
        background-color: #042E4B;
        color: #F6A83C;
    }

    .drawer-menu {
        @extend %G-drawer-menu;
        color: #fff;

        .drawer-menu__item {
            border-bottom: 1px solid #C7C7C7;
        }
    }

    .ll-drawer__btn {
        background: #F0871A;
        color: #000;
    }

    .drawer-menu__item {
        color: #fff;
    }

    .ll-drawer__btn.ll-btn--outlined {
        background: transparent;
        color: #F0871A;
        border-color: #F0871A;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.1 GAME MENU DRAWER
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 2.6.2 SUPPORT MENU DRAWER
|--------------------------------------------------------------------------
*/

.support-menu {
    @extend %G-support-menu;

    .llg-envelope-o,
    .ll-footer__social-icon {
        color: #F0871A;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.3 MY ACCOUNT MENU DRAWER
|--------------------------------------------------------------------------
*/

.my-account-menu-drawer {
    @extend %G-my-account-menu-drawer;

    .my-account-menu-drawer__icon {
        color: #F0871A;
    }

    .my-account-menu-drawer__blocked-user {
        color: #F0871A;
    }

    .my-account-menu-drawer__text--accent {
        color: #F0871A;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.4 LOGIN DRAWER
|--------------------------------------------------------------------------
*/

.login-drawer {
    @extend %G-login-drawer;

    .login-form__signup-link {
        color: #F0871A;
    }

    .login-drawer-menu__item {
        border: 1px solid #267188;
        color: #F0871A;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.7. POPOVER
|--------------------------------------------------------------------------
*/

.ll-popover {
    @extend %G-ll-popover;
}

.ll-popover-wrapper {
    @extend %G-ll-popover-wrapper;
}

.change-password__popover {
    @extend %G-change-password__popover;
}

.bet-history__status-filter-dropdown {
    @extend %G-bet-history__status-filter-dropdown;
}

.ll-footer__popover {
    @extend %G-ll-footer__popover;
}

.ll-footer__popover.ll-popover--tablet,
.ll-footer__popover.ll-popover--mobile {
    @extend %G-ll-footer__popover--tablet--mobile;

    background-color: #184161;

    .ll-footer__popover-item {
        color: #fff;

        &:not(:last-of-type) {
            border-bottom: 1px solid #042E4B;
        }
    }
}

.ll-footer__popover.ll-popover--desktop {
    @extend %G-ll-footer__popover--desktop;

    .ll-footer__popover-content {
        background-color: #06486C;
    }

    .ll-footer__popover-item {
        color: #fff;

        &:not(:last-of-type) {
            border-bottom: 1px solid #042E4B;
        }
    }
}

.body--with-cursor {
    .ll-footer__popover-item:hover {
        color: #F6A83C;
    }
}

.ll-popover__text {
    border: 1px solid #06486C;
    border-radius: 3px;
    background: #042E4B;
    color: #fff;
    padding: 0.3rem;
}

/*
|--------------------------------------------------------------------------
| == 2.8. BACKDROP
|--------------------------------------------------------------------------
*/

.ll-backdrop {
    @extend %G-ll-backdrop;

    background: transparent;
    transition: background 0.2s ease-out;

    &.ll-backdrop--open {
        background: rgba(0, 0, 0, 0.5);

        &.ll-backdrop--transparent {
            background: transparent;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == DISABLE OF USER SELECTS
|--------------------------------------------------------------------------
*/
.ll-dropdown-menu-item {
    user-select: none;
}
/*
|--------------------------------------------------------------------------
| == ACTIVE BETS & MY BETS DESKTOP POPOVER
|--------------------------------------------------------------------------
*/

.ll-popover-cashout_yes-no {
    @extend %G-popover-cashout_yes-no;
    .popup__text {
        font-size: 16px;
    }
    .popup__btns {
        .popup-btn {
            background: #f0871a;
            color: #000;
            &:hover {
                box-shadow: inset 0 0 1px 1px #f0871a;
                background: transparent;
                color: #000;
            }
        }

        .popup-btn--loading {
            box-shadow: inset 0 0 1px 1px #f0871a;
            background: transparent;
        }

        .popup-btn-outlined {
            box-shadow: inset 0 0 1px 1px #f0871a;
            background: transparent;
            color: #000;
            &:hover {
                background: #f0871a;
                color: #000;
            }
        }
    }
    .popup__btns--delete {
        .popup-btn {
            background: #ff0000;
            color: #fff;
            &:hover {
                box-shadow: inset 0 0 1px 1px #ff0000;
                background: transparent;
                color: #ff0000;
            }
        }

        .popup-btn-outlined {
            box-shadow: inset 0 0 1px 1px #ff0000;
            background: transparent;
            color: #ff0000;
            &:hover {
                background: #ff0000;
                color: #fff;
            }
        }
    }
}
