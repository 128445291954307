// Animation by CSS Transition
.ll-accordion__content-enter {
    max-height: 0 !important;
    display: block;
}

.ll-accordion__content-enter-active {
    max-height: 1000px !important;
    overflow: hidden;
    transition: max-height cubic-bezier(0.86, 0, 0.2, 1) 400ms;
}

.ll-accordion__content-enter-done {
    display: block;
}

.ll-accordion__content-exit {
    max-height: 2000px;
    display: block;
}

.ll-accordion__content-exit-active {
    max-height: 0 !important;
    display: block;
    overflow: hidden;
    transition: max-height cubic-bezier(0.86, 0, 0.2, 1) 250ms;
}

.ll-accordion__content-exit-done {
    display: none;
}
.ll-accorion__collapsed{
    display: none;
}