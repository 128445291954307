.mini-banners {
    border-radius: 3px;
    overflow: hidden;
}

.mini-banner__wrapper {
    position: relative;

    .mini-banner__description {
        @include position(absolute, bottom 0);
        padding-bottom: .8rem;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.54) 50%, rgba(0, 0, 0, 0) 100%);
        @include flex-config($align-items: center, $justify-content: flex-end, $flex-direction: column);
        max-height: 40%;
        min-height: 20%;
        height: fit-content !important;
        width: 100%;
        overflow: hidden;
    }
}