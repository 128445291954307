.mail-verify--desktop {   
    @include flex-config($align-items: center, $justify-content: center);
    min-height: calc(
        100vh - #{$size-height--header_desktop} - #{$size-height--header-menu_desktop}
    );
}

.mail-verify--tablet {
    @include flex-config($align-items: center, $justify-content: center);
    min-height: calc(
        100vh - #{$size-height--header_tablet}
    );
}

.mail-verify--mobile {
    @include flex-config($align-items: center, $justify-content: center);
    min-height: calc(
        100vh - #{$size-height--header_mobile}
    );
}

.mail-verify__button-container {
    padding-top: 2rem;
    @include flex-config($align-items: center, $justify-content: center);
}