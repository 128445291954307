%G-about-us {
    max-width: 1300px;
    margin: auto;
    padding: 1.8rem;
    flex: 1;

    @include mq('desktop') {
        margin: 1.8rem auto;
        border-radius: .8rem;
    }

    .about-us__header {
        margin: 1.6rem 0 0;
        font-size: 3rem;
        font-weight: 400;
    }

    .about-us__content {
        font-size: 1.6rem;
        margin: 3.6rem 0;
    }
}