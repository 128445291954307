/*
2. LAYOUT
├── 2.1. COMMON LAYOUT COMPONENTS
│   ├── 2.1.1 SOCIAL ICONS
│   └── 2.1.2 LICENSE ICONS
├── 2.2. FOOTER
│   ├── 2.2.1 FOOTER DESKTOP
│   └── 2.2.2 FOOTER MOBILE
├── 2.3. CONTENT
│   ├── 2.3.1 CONTENT FOOTER
├── 2.4. HEADER
│   ├── 2.4.1 HEADER DESKTOP
│   ├── 2.4.2 HEADER MOBILE
│   ├── 2.4.3 HEADER MENU
│   └── 2.4.4 HEADER LANGUAGE PICKER
├── 2.5. MODAL
│   ├── 2.5.1 MODAL DESKTOP
│   └── 2.5.2 MODAL MOBILE
├── 2.6. DRAWER
│   ├── 2.6.1 GAME MENU DRAWER
│   ├── 2.6.2 SUPPORT MENU DRAWER
│   ├── 2.6.3 MY ACCOUNT MENU DRAWER
│   ├── 2.6.4 LOGIN DRAWER
│   └── 2.6.5 MY BETS DRAWER
├── 2.7. POPOVER
└── 2.8. BACKDROP
*/

/*
|--------------------------------------------------------------------------
| == 2. LAYOUT
|--------------------------------------------------------------------------
*/

.grecaptcha-badge {
    visibility: hidden;
}

%G-ll-page {
    padding: 0;

    &.prematch--desktop {
        padding: 1rem 0;
    }
}

%G-page-wrapper--desktop {
    @include flex-config($flex-direction: column);
}

.ll-content-container--tablet {
    .ll-footer--mobile {
        padding: 0 7rem;
    }
}

%G-page-wrapper__children {
    min-height: 100%;
    flex: 1;
}

%G-ll-content-container-scroll {
    flex-grow: 2;
    overflow: auto;
}

%G-ll-full-width-content-wrapper {
    width: 90%;
    max-width: 170rem;
    margin: 0 auto;

    @include mq('(max-width: 1500px)') {
        width: calc(100% - 1rem);
    }

    @include mq('(max-width: 1367px)') {
        width: calc(100% - 2rem);
    }
}

/*
|--------------------------------------------------------------------------
| == 2.1. COMMON LAYOUT COMPONENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 2.1.1. SOCIAL ICONS
|--------------------------------------------------------------------------
*/

%G-ll-social-icons {
    margin: 0 -1rem;

    .ll-social-icons__link {
        margin: .5rem;
        padding: 4px;
        display: inline-block;
    }

    .ll-social-icons__img {
        width: 3rem;
        cursor: pointer;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.1.2. LICENSE ICONS
|--------------------------------------------------------------------------
*/

%G-ll-license-icons {
    align-self: center;
    @include flex-config();

    .ll-license-icons__icon {
        @include size(4rem);
    }

    .ll-license-icons__icon--desktop {
        @include size(3.5rem);
    }

    .ll-license-icons__link {
        margin: 0 1rem;
    }

    .ll-license-icons--license-icon {
        background: url('../../../static/themes/theme/images/footer/footer_tiny_license.png') no-repeat center center;
    }

    .ll-license-icons--logo-icon {
        background: url('../../../static/themes/theme/images/footer/footer_tiny_ll_logo.png') no-repeat;
        margin-top: 5px;
    }

    .ll-license-icons--18-icon {
        background: url('../../../static/themes/theme/images/footer/footer_tiny_icon18.png') no-repeat center center;
    }

    .ll-license-icons__icon,
    .ll-license-icons__icon--desktop {
        display: flex;
        cursor: pointer;
        background-color: initial;
        background-size: contain;
    }

    .license-seal img {
        width: 35px !important;
        margin: 0 1rem;
    }

    .license-img img {
        width: 35px !important;
        margin: 0 1rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.2. FOOTER
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 2.2.1. FOOTER DESKTOP
|--------------------------------------------------------------------------
*/

%G-ll-footer--desktop {
    position: relative;
    width: 100%;
    bottom: 0;
    z-index: 10;

    .ll-footer__wrapper {
        @include size(100%, 100%);
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        max-width: 137rem;
        min-width: 137rem;
        margin: auto;
    }

    .ll-footer__left-part {
        @include flex-config($justify-content: flex-start);
    }

    .ll-footer__right-part {
        @include flex-config($justify-content: flex-end);
    }

    .ll-footer__center-part {
        justify-self: center;
        @include flex-config($justify-content: center);
    }

    .ll-footer__logo-link {
        @include flex-config($align-items: center);
        align-self: center;
        color: #fff;
        margin: auto;
    }

    .ll-footer__logo {
        @include size(150px, 31px);
        background-size: contain;
        background-position: center;
    }

    .ll-footer__icon {
        @include flex-config($align-items: center, $justify-content: center);
        @include size(2.8rem);
        min-width: 2.8rem;
        border-radius: 50%;
        margin: 0 1rem 0 0;
        font-size: 2.2rem;
        transition: all 0.15s ease;
    }

    .llg-angle-right {
        font-size: 26px;
        transition: all 0.15s ease;
        padding: 0 15px;
    }

    .ll-footer__link {
        @include flex-config($align-items: center, $justify-content: center);
    }

    .llg-phone {
        font-size: 15px;
        padding: 0px 5px;
    }

    .dx-button .dx-button-content {
        padding: 3px;
    }

    .ll-footer__btn {
        height: 3.6rem;
        margin: 0 1rem;
        padding: 0;
        border-radius: 35px;
        font-size: 1.4rem;
        text-transform: initial;
        transition: transform .2s ease-in-out;

        &.ll-footer__link--featured {
            max-width: 30rem;
            overflow: hidden;
            :not(img) {
                z-index: 2;
            }
        }

        &.ll-footer__link--multiple {
            .llg-angle-right {
                transform: rotate(90deg);
            }

            &.ll-footer__link--active {
                .llg-angle-right {
                    transform: rotate(-90deg);
                }
            }
        }

        &.ll-footer__link--active::before {
            content: "";
            top: -1.6rem;
            right: calc(50% - .7rem);
            position: absolute;
            display: inline-block;
        }

        .ll-btn__content {
            padding: .3rem;
        }

        &:hover {
            opacity: 1;

            .llg-help2,
            .llg-question1,
            .llg-empire {
                transition: all 0.15s ease;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.2.2. FOOTER MOBILE
|--------------------------------------------------------------------------
*/

%G-ll-footer--mobile {
    @include size(100%, 50px);
    @include flex-config($align-items: center, $justify-content: space-around);
    position: fixed;
    bottom: 0;
    z-index: 200;

    //crutch for ios. do not remove it
    transform: translateZ(0);
    -moz-transform: translatez(0);
    -ms-transform: translatez(0);
    -o-transform: translatez(0);
    -webkit-transform: translateZ(0);
    -webkit-font-smoothing: antialiased;

    .ll-footer__slider--mobile {
        height: 100%;

        .slider__content,
        .slider__item {
            height: 100%;
        }

        .slider__item {
            @include flex-config($align-items: center, $justify-content: center);
        }

        .menu-item--tablet,
        .menu-item--mobile {
            height: 80%;
            margin: 0 .5rem;
            padding: 0;
        }
    }

    .ll-footer__item {
        @include size(100%);
        @include flex-config($flex-direction: column, $justify-content: center);
        align-content: center;
        cursor: pointer;
    }

    .ll-footer__money-item {
        @include flex-config($align-items: center, $justify-content: center, $flex-direction: row);
        .ll-footer__money-item-part {
            @include flex-config($flex-direction: column, $justify-content: center);
            align-items: center;
            &:first-of-type {
                flex: 1;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    height: 50%;
                    width: 1px;
                    transform: translateY(-50%);
                }
            }
            &:last-of-type {
                padding: 0 .8rem;
                .menu-item__icon {
                    font-size: 1.4rem;
                }
            }
        }
    }

    .ll-footer__item--featured {
        width: 16rem;
        flex-direction: row;
        overflow: hidden;

        :not(img) {
            z-index: 2;
        }

        @media only screen and (max-width: 374px) {
            width: 11rem;

            .menu-item__icon {
                display: none;
            }
        }

        .menu-item__icon {
            margin: .5rem;
        }

        .menu-item__label {
            width: auto;
            overflow: hidden;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            text-transform: none;
        }
    }

    .ll-footer__icon {
        text-align: center;
        font-size: 16px;
    }

    .menu-item--tablet {
        max-width: 100%;
    }

    .ll-footer__label {
        margin: 0;
        margin-top: 5px;
        font-size: 13px;
        text-align: center;

        @include mq('(max-width: 767px)') {
            margin: 5px;
        }
        @include mq('(max-width: 640px)') {
            font-size: 11px;
        }
        @include mq('(max-width: 440px)') {
            font-size: 10px;
            margin: 3px;
        }
        @include mq('(max-width: 360px)') {
            font-size: 9px;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.3. CONTENT
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 2.3.1. CONTENT FOOTER
|--------------------------------------------------------------------------
*/
%G-ll-content__footer {
    @include flex-config($flex-direction: column, $justify-content: center, $align-items: center);

    .footer-menu {
        min-width: 135rem;
        max-width: 135rem;
        margin: 2rem auto 0;
        padding: 0 1rem;
        @include flex-config($justify-content: space-between);
    }

    .footer-menu__section {
        @include flex-config($flex-direction: column);
        order: 2;
    }

    .footer-menu__section--menu {
        font-size: 1.6rem;

        &.left {
            order: 1;
        }

        &.right {
            order: 3;
        }

        &.right.odd,
        &.right.even:not(.with-additional) {
            align-items: flex-end;
        }
    }

    .footer-menu__section--info {
        &.even {
            order: 4;
            align-items: flex-end;
        }

        &.odd {
            order: 2;
            align-items: center;
        }
    }

    .footer-menu__section-title {
        margin-bottom: .2rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    .footer-menu__item {
        margin-bottom: .3rem;
        transition: all 0.15s ease;
        cursor: pointer;
    }

    .footer-menu__section--info {
        @include flex-config($flex-direction: column, $justify-content: space-between, $align-items: center);
    }

    .footer-license {
        @include flex-config($justify-content: space-between, $align-items: center);
        margin-bottom: 1rem;
    }

    .footer-license-message {
        font-size: .9rem;
        width: 60%;
        text-align: center;
    }

    .footer-license__link {
        @include flex-config($flex-direction: column, $align-items: center);
        cursor: pointer;
    }

    .footer-license__icon--desktop {
        font-size: 2rem;
    }

    .ll-social-icons__link {
        margin: 0 .8rem 1rem;
    }

    .ll-social-icons__img:hover {
        transform: scale(1.1);
        filter: saturate(2);
    }

    .footer-menu__support-mail {
        margin-top: .5rem;
        font-size: 1.4rem;
    }

    .footer-info__partners-logo {
        @include size(88%, 98px);
        background: url('../../../static/themes/theme/images/footer/footer_logo.png') center center no-repeat;
        background-size: contain;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4. HEADER
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 2.4.1. HEADER DESKTOP
|--------------------------------------------------------------------------
*/

%G-ll-header--desktop {
    position: relative;

    .ll-header__top,
    .ll-header__top--hide {
        @include flex-config($justify-content: space-between, $align-items: center);
        margin: auto;
        width: 100%;
        max-width: 1370px;
        transition: all 0.3s ease-out;
    }

    .ll-header__top--hide {
        height: 0;
        overflow: hidden;
    }

    .ll-header__top-section {
        @include flex-config($align-items: center);
        margin: 3.4rem;
    }

    .ll-header__top-section-user {
        @include flex-config();
    }

    .ll-header-btn {
        height: 2.8rem;
        margin-left: 1.5rem;
        border-radius: 30px;
        text-transform: uppercase;
        padding: 0;

        &.ll-header-btn--user-name {
            max-width: 20rem;
            text-transform: none;
        }
    }

    .ll-header__logo {
        @include size(147px, 52px);
        margin-right: 1.5rem;
    }

    .ll-header__balance-hidden,
    .ll-header__balance-visible {
        max-width: 8rem;
        min-width: 8rem;
        transition: all .1s ease-out;
        @include flex-config($align-items: center, $justify-content: center);
    }

    .ll-header__balance-visible {
        max-width: 300px;

        span {
            white-space: nowrap;
        }
    }

    .ll-header__btn-messages {
        min-width: 2.8rem;
        max-width: 2.8rem;
        overflow: visible !important;
        border-radius: 50%;
    }

    .ll-header__user-messages {
        position: relative;
    }

    .ll-header__messages-icon {
        font-size: 1.2rem;
        display: inline-block;
        margin-top: .1rem;
        margin-right: .1rem;
    }

    .ll-header__messages-counter {
        @include size(20px);
        @include flex-config($align-items: center, $justify-content: center);
        @include position(absolute, top -16px right -18px);
        border-radius: 50%;
        font-size: 1.1rem;
    }

    .ll-header__sign-out-btn {
        border: 1px solid transparent;
    }

    .ll-header__balance-icon {
        padding-left: 5px;
    }

    .ll-header__bonus-icon {
        padding: 0 5px;
        font-size: 1.1rem;
    }

    .ll-header__balance--divider {
        min-height: 28px;
        min-width: 1px;
        display: block;
        margin: 0 1rem;
        background-color: rgb(0, 0, 0);
    }

    .ll-header__balance-stars {
        font-size: 1.4rem;
        line-height: 1.4rem;
        max-height: .8rem;
        flex: 1;
    }

    .dx-tabs,
    .scrolledtabs {
        margin: auto;
    }

    .ll-header__top-section-user {
        .dx-button-content {
            padding: 0;
        }
    }

    .ll-header__top-section-guest {
        @include flex-config();

        .ll-btn__content {
            padding: 6px 22px;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4.2. HEADER MOBILE
|--------------------------------------------------------------------------
*/

%G-ll-header--mobile {
    width: 100%;
    position: fixed;
    z-index: 200;

    //crutch for ios. do not remove it
    transform: translateZ(0);
    -moz-transform: translatez(0);
    -ms-transform: translatez(0);
    -o-transform: translatez(0);
    -webkit-transform: translateZ(0);
    -webkit-font-smoothing: antialiased;

    .ll-header__buttons-container {
        @include size(100%, 60px);
        @include flex-config($align-items: center, $justify-content: space-between);
    }

    .ll-header__menu-button {
        @include size(auto, 60px);
        @include flex-config($align-items: center, $justify-content: center);
        cursor: pointer;
        margin: 0 1rem;
    }

    .ll-header__logo {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }

    .ll-header__button-icon {
        line-height: 60px;
        text-align: center;
        font-size: 2.6rem;
    }

    .dx-texteditor-buttons-container {
        display: none;
    }

    .active-bets__icon {
        position: relative;
    }

    .active-bets__icon-count {
        @include flex-config($align-items: center, $justify-content: center);
        @include position(absolute, top -1rem right -.8rem);
        @include size(1.9rem);
        border: 0.1rem solid;
        border-radius: 50px;
        font-size: 1.1rem;
        font-weight: 500;
        transition: all .5s ease-out;

        &.animated {
            transform: scale(1.5);
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4.3. HEADER MENU
|--------------------------------------------------------------------------
*/

%G-ll-header__menu {
    @include flex-config($justify-content: center);
    width: 100%;

    transition: all 0.2s ease;

    .slick-list {
        height: 100%;
        overflow: visible;
        margin: 0 20px;
    }

    .slick-track {
        height: 100%;
        @media only screen and (min-width: 1366px) {
            width: 100% !important;
            justify-content: center;
        }
    }

    .slick-prev {
        left: 0;

        @media only screen and (min-width: 1366px) {
            left: -2.5rem;
        }
    }

    .slick-next {
        right: 0;

        @media only screen and (min-width: 1366px) {
            right: -2.5rem;
        }
    }

    .slick-slide {
        @include flex-config($align-items: center, $justify-content: center);
        height: 100%;
        position: relative;
        cursor: pointer;

        div {
            @include size(100%);
        }

        @media only screen and (min-width: 1366px) {
            .menu-item__icon {
                margin-right: 0.5rem;
            }
        }
    }

    .menu-item__is-new-indicator-wrapper {
        @include position(absolute, top .4rem right 1rem);
    }

    .menu-item__is-new-indicator--animated {
        animation: battle-animated 4s infinite;
    }

    @keyframes battle-animated {
        0% {
            transform: scale(1);
        }
        80% {
            transform: scale(1);
        }
        90% {
            transform: scale(1.15);
        }
        100% {
            transform: scale(1);
        }
    }

    .menu-item__is-new-indicator {
        width: 3.5rem;
        height: 1.2rem;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-top: .6rem solid transparent;
            border-bottom: .6rem solid transparent;
        }
    }

    .menu-item__is-new-indicator-label {
        @include position(absolute, top 0 right .3rem);
        height: 1.2rem;
        line-height: 1.2rem;
        font-size: 1.1rem;
        font-weight: 500;
        width: 75%;
        text-align: center;
    }

    .slick-list__top-menu {
        width: auto;
    }

    .slick-arrow {
        z-index: 3;
        height: 5rem;
    }

    .slick-list__slider {
        @include size(100%);
    }

    .menu-item--mobile,
    .menu-item--tablet {
        .menu-item__is-new-indicator-wrapper {
            top: 1.2rem;
        }

        .menu-item__is-new-indicator {
            width: 2.6rem;
        }

        .menu-item__is-new-indicator-label {
            right: 0.1rem;
            font-size: .9rem;
        }

        .menu-item--new {
            margin-right: 3rem;
        }
    }
}

%G-slick-list__top-container--desktop {
    .slick-slide {
        @media only screen and (min-width: 1366px) {
            width: auto !important;
        }
    }

    .slick-list__slider {
        overflow: hidden;
    }

    .slick-track {
        transform: none !important;
        margin: auto;
        overflow: hidden;
    }

    .menu-item--desktop {
        line-height: 5rem;
    }
}

%G-slick-list__top-container--mobile {
    padding: 0;
    width: auto;
    height: auto;

    .slick-list {
        margin: 0;
    }

    .slick-prev {
        left: -25px;
    }

    .slick-next {
        right: -25px;
    }

    .slick-arrow {
        @include size(25px, 100%);
    }

    .slider__item {
        height: 5rem;

        .menu-item__label {
            white-space: normal;
        }
    }
}

%G-ll-header__menu--sticky {
    @include position(absolute, top 0 left 0);
    z-index: 500;
}

%G-header-menu-subs {
    min-width: 14.6rem;
    z-index: 50;
}

%G-header-menu-sub-item {
    @include size(100%, 4rem);
    @include flex-config($align-items: center);
    padding: 0 2rem;
    font-size: 1.4rem;
    line-height: 0.96;
    cursor: pointer;
}

%G-header-menu-subs--desktop {
    text-transform: uppercase;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

/*
|--------------------------------------------------------------------------
| == 2.4.4. HEADER LANGUAGE PICKER
|--------------------------------------------------------------------------
*/

%G-ll-language-picker {
    @include flex-config($align-items: center);
    box-shadow: none !important;
    cursor: pointer;
    user-select: none;
    position: relative;
    z-index: 4;
    transition: all 0.3s;
    font-weight: 500;
    font-size: 11px;

    .ll-language-picker__wrapper {
        @include flex-config($align-items: center);
        @include size(100%, 100%);
    }

    .ll-language-picker__items {
        border: none;
        background: transparent;

        .ll-dropdown-scrollbox {
            padding-top: 12px;
        }
    }

    .ll-dropdown-menu__icon,
    .ll-dropdown-menu__icon--opened {
        display: none;
    }

    .ll-language-picker__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

%G-ll-language-picker--desktop {
    &:hover,
    &.ll-dropdown-wrapper--opened {
        @include size(86px, 27px);
    }

    &.ll-dropdown-wrapper--closed:not(:hover) {
        @include size(27px, 27px);
    }

    .ll-language-picker__item {
        @include flex-config($align-items: center);
        width: 100%;
    }

    .ll-language-picker__flag {
        @include size(25px, 25px);
        border-radius: 14px;
        object-fit: cover;
        margin-right: 5px;
    }

    .ll-language-picker__items {

        :first-child {
            :after {
                @include size(10px, 10px);
                content: '';
                display: block;
                position: absolute;
                top: 5px;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                transform: rotate(-45deg);
            }
        }
    }

    .ll-dropdown-menu-item,
    .ll-dropdown-menu-item--active {
        @include flex-config($align-items: center);
        @include size(86px, 30px);
        box-sizing: border-box;
        margin: -1px 0 0 0;

        .ll-language-picker__flag {
            @include size(23px, 23px);
            border: none;
            margin-left: 2px;
        }
    }
}

%G-ll-language-picker--mobile {
    margin: 0;

    &.ll-dropdown-wrapper--opened {
        @include size(90px, 26px);

        .ll-language-picker__flag {
            margin-left: 1rem;
            @include size(24px, 24px);
            border-radius: 12px;
        }
    }

    &.ll-dropdown-wrapper--closed {
        @include size(26px, 26px);
    }

    .ll-language-picker__wrapper {
        font-size: 1.2rem;
    }

    .ll-language-picker__items {
        padding-top: .9rem;
        font-size: 1.2rem;
    }

    .ll-dropdown-menu-item,
    .ll-dropdown-menu-item--active {
        @include size(90px, 46px);
    }

    .ll-language-picker__flag {
        @include size(26px, 26px);
        border-radius: 0;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.5. MODAL
|--------------------------------------------------------------------------
*/
%G-ll-modal {
    @include position(fixed, top 50% left 50%, $z-index: 1700);
    transform: translate(-50%, -50%);
    max-height: 80vh;
    max-width: 90vw;
    border-radius: 7px;

    &.empty-modal__backdrop {
        height: 0 !important;
        width: 0 !important;
    }

    .ll-modal__button {
        @include flex-config($align-items: center, $justify-content: center);
        @include size(3rem, 3rem);
        cursor: pointer;

        &:hover i {
            transform: scale(1.4);
            transition: all 0.2s ease-out;
        }

        .ll-modal__fullscreen-button {
            font-size: 1.4rem;
        }

        .ll-modal__close-button-icon {
            font-size: 1rem;
        }
    }

    .ll-modal__fullscreen-button,
    .ll-modal__close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    .ll-modal__fullscreen-button {
        right: 3.5rem;
    }

    .ll-modal__btns--external {
        @include flex-config($align-items: center);
        @include position(absolute, top -3.3rem right 0);

        .ll-modal__button {
            margin-right: 0.3rem;
        }
    }

    &.ll-modal--with-header {
        .ll-modal__button {
            @include size(2.4rem, 2.4rem);
            margin-left: .5rem;

            .ll-modal__fullscreen-button {
                font-size: 1.4rem;
            }

            .ll-modal__close-button-icon {
                font-size: 1.2rem;
            }
        }
    }

    .ll-modal__custom-content {
        min-width: 300px;
        min-height: 100px;
        padding: 20px;
        @include flex-config($justify-content: center, $align-items: center);
    }

    iframe,
    .fullscreen,
    .ll-scrollbox--desktop {
        margin: 0;
        border: none;
    }

    .ll-modal__scrollbox--mobile,
    .ll-modal__scrollbox--tablet,
    .ll-modal__scrollbox--desktop,
    .ll-modal__scrollbox--external {
        overflow: auto;
    }

    .ll-modal__scrollbox--external {
        @include size(60vw, 80vh);
        display: flex;
    }

    &.ll-modal--with-header {
        .ll-modal__scrollbox--external,
        .ll-modal__scrollbox--desktop {
            max-height: calc(80vh - 4.2rem);
        }

        .fullscreen-enabled {
            .ll-modal__header {
                height: 0;

                .ll-modal__header-title {
                    display: none;
                }

                .ll-modal__fullscreen-button,
                .ll-modal__close-button {
                    position: absolute;
                    @include size(3rem, 3rem);

                    .ll-modal__fullscreen-button-icon,
                    .ll-modal__close-button-icon {
                        font-size: 2.5rem;

                        &:before {
                            text-shadow: 1px 1px 7px #393939;
                        }
                    }
                }
            }

            .open-game__container-iframe {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    &.ll-modal--mobile {
        .ll-modal__scrollbox--external {
            width: 90vw;
        }
    }

    &.ll-modal-enter,
    &.ll-modal-enter-active,
    &.ll-modal-enter-done,
    &.ll-modal-exit,
    &.ll-modal-exit-active {
        display: block;
    }

    &.ll-modal-enter {
        opacity: 0;
        transform: translate(-50%, -50vh);
    }

    &.ll-modal-enter-active {
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: all 150ms;
    }

    &.ll-modal-exit-active {
        opacity: 0;
        transform: translate(-50%, -50vh);
        transition: all 250ms;
    }

    .ll-modal__external-link-wrp {
        @include flex-config();
        flex: 1;
        position: relative;
    }

    .ll-modal__external-link-loading {
        display: none;
        @include size(100%);
        @include position(absolute, top 0 left 0, $z-index: 10);

        &.is-loading {
            display: flex;
        }
    }

    .ll-modal__external-link {
        flex: 1;
    }

    &.ll-modal__external-mobile-wrp {
        max-width: 100vw;
        width: 100vw;
        max-height: 100vh;
        height: 100vh;
        max-height: calc(var(--vh, 1vh) * 100);
        height: calc(var(--vh, 1vh) * 100);

        .ll-modal__external-mobile,
        .ll-modal__external-tablet,
        .ll-modal__custom-content,
        .ll-modal__scrollbox--tablet,
        .ll-modal__scrollbox--mobile {
            padding: 0;
            width: 100vw;
            max-height: calc(100vh - 4.2rem) !important;
            height: calc(100vh - 4.2rem) !important;
            max-height: calc((var(--vh, 1vh) * 100) - 4.2rem) !important;
            height: calc((var(--vh, 1vh) * 100) - 4.2rem) !important;
        }
    }

    .ll-modal__header,
    .ll-modal__header--error {
        @include flex-config($align-items: center, $justify-content: space-between);
        height: 4.2rem;
        padding: 0 2rem;
        font-size: 1.6rem;

        .ll-popover-wrapper {
            flex: 1;
        }
    }

    .ll-modal__header-title {
        flex: 1;
        margin: auto;
        text-align: center;
        text-transform: uppercase;
        padding: 0;
    }

    .cashout-modal {
        .modal__text p {
            display: flex;
            justify-content: center;
        }

        .cashout-modal__btn {
            overflow: hidden;

            &.cashout-modal__btn--disabled {
                opacity: .7;
                cursor: not-allowed;
            }
        }
    }
}

%G-modal__yes-no {
    @include flex-config($flex-direction: column, $align-items: center);

    .modal__text {
        margin-bottom: 2rem;
        font-size: 1.8rem;
        text-align: center;
    }

    .modal__btns {
        @include flex-config();

        & > * {
            flex: 1;

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
}

%G-popover-cashout_yes-no {
    @include flex-config($flex-direction: column, $align-items: center);

    .popup__text {
        margin-bottom: 2rem;
        font-size: 1.8rem;
        text-align: center;
    }

    .popup__btns {
        @include flex-config();

        & > * {
            flex: 1;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }

    .popup__btns--delete {
        @include flex-config();

        & > * {
            flex: 1;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}

%G-ll-game-modal {
    .fullscreen-enabled {
        iframe,
        .ll-scrollbox--desktop {
            height: 100vh;
        }
    }


    iframe,
    .fullscreen,
    .ll-scrollbox--desktop {
        max-width: 100vw;
        margin: 0;
    }
}

%G-ll-game-modal--small-desktop {
    max-height: 100vh !important;
    max-width: 100vw !important;

    iframe,
    .ll-scrollbox--tablet {
        height: calc(100vh - 4.2rem);
        max-height: calc(100vh - 4.2rem);
        max-width: 100vw;
        margin: 0;
    }

    .fullscreen {
        height: 100vh;
    }

    .open-game__container,
    .ll-modal__scrollbox--desktop,
    .ll-modal__scrollbox--mobile {
        max-height: calc(100vh - 4.2rem) !important;
        height: calc(100vh - 4.2rem) !important;
        width: 100vw !important;
    }


    .fullscreen-enabled {
        iframe,
        .open-game__container,
        .ll-scrollbox--tablet {
            height: 100vh !important;
            max-height: 100vh !important;
        }
    }
}

%G-ll-game-error-modal {
    width: 30rem;

    .ll-modal__custom-content {
        @include flex-config($flex-direction: column);
        text-align: center;

        .game-error-message {
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 1rem;
        }
    }

    .ll-modal__header--error {
        padding: 0;
    }

    .ll-modal__close-button {
        position: absolute;
        right: 1rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.5.1 MODAL DESKTOP
|--------------------------------------------------------------------------
*/

%G-ll-modal--desktop {
    max-width: 60vw;

    &.ll-game-modal {
        max-width: 95vw;
        max-height: 90vw;
    }

    .fullscreen-enabled {
        .ll-modal__close-button {
            top: 1rem;
            right: 2.7rem;
        }

        .ll-modal__fullscreen-button {
            top: 1rem;
            right: 6.4rem;
        }

        .ll-scrollbox--desktop,
        .open-game__container {
            width: 100vw !important;
            height: 100vh !important;
            max-height: 100vh !important;
            max-width: 100vw !important;
        }
    }

    .open-game__container {
        margin: auto;
        overflow: hidden;
    }

    .open-game__load-indicator {
        @include position(absolute, top 0 left 0 bottom 0 right 0);
        margin: auto;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.5.2 MODAL MOBILE
|--------------------------------------------------------------------------
*/

%G-ll-modal--mobile {
    max-height: 100vh;
    max-width: 90vw;

    .ll-modal__custom-content {
        min-width: 280px;
        min-height: 100px;
        @include flex-config($justify-content: center, $align-items: center);
    }

    .ll-modal__close-button {
        top: 1rem;
        right: 0.6rem;
    }
}


/*
|--------------------------------------------------------------------------
| == 2.6. DRAWER
|--------------------------------------------------------------------------
*/

%G-ll-drawer {
    height: 100%;
    @include position(fixed, top 0 left -115rem right -115rem, $z-index: 1600);
    backdrop-filter: blur(5px);
    transition: all 0.3s;
    overflow: auto;

    .signup-drawer__header,
    .ll-drawer__header--mobile {
        @include flex-config($justify-content: space-between, $align-items: center);
        height: 4rem;
        padding: 0 .1rem 0 1rem;
        font-size: 1.4rem;
    }

    .signup-drawer__header {
        justify-content: center;
        border-radius: 3px;
        margin: 0 2rem 2rem;
        font-size: 2rem;
        font-weight: 500;
    }

    .desktop-drawer__close-btn {
        font-size: 1.5rem;
        padding: 1.2rem;
        position: absolute;
        right: 20px;
        cursor: pointer;
    }

    .mobile-drawer__close-btn {
        font-size: 1.5rem;
        padding: 1.2rem;
    }

    &.ll-drawer_left.mobile {
        left: -30rem;
    }

    &.ll-drawer_left.tablet {
        left: -50rem;
    }

    &.ll-drawer_right.mobile {
        right: -30rem;
    }

    &.ll-drawer_right.tablet {
        right: -50rem;
    }

    &.tablet,
    &.mobile {
        max-width: 80%;

        .ll-input input {
            padding: 14px 12px !important;
        }

        .ll-input .dx-texteditor-input,
        .ll-input__label {
            font-size: 1.4rem !important;
            font-weight: 500;
        }

        .ll-input--focused,
        .ll-input--labeled-up {
            .ll-input__label {
                font-size: 1.6rem !important;
            }
        }
    }

    &.mobile {
        .ll-input--blured {
            .ll-input__label {
                top: .4rem;
            }
        }

        .ll-input--focused,
        .ll-input--labeled-up {
            .ll-input__label {
                top: 0;
            }
        }
    }

    &.ll-drawer_left_open,
    &.ll-drawer_right_open {
        @include size(auto, 100vh);
        min-width: 115rem;
        width: 80vw;
    }

    &.ll-drawer_left_open.mobile,
    &.ll-drawer_right_open.mobile {
        min-width: 80%;
        max-width: 80%;
    }

    &.tablet {
        max-width: 90%;
    }

    &.ll-drawer_left_open.tablet,
    &.ll-drawer_right_open.tablet {
        min-width: 30rem;
    }

    &.desktop {
        //max-width: 115rem;
    }

    .ll-drawer__inner {
        position: relative;
    }

    .ll-drawer__btn {
        @include size(80%, 4rem);
        margin: 2rem auto;
        border-radius: 2rem;
    }

    &.ll-drawer_left {
        right: auto;
    }

    &.ll-drawer_right {
        left: auto;
    }

    &.ll-drawer_left_open {
        left: 0;
        right: auto;
    }

    &.ll-drawer_right_open {
        right: 0;
        left: auto;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.1 GAME MENU DRAWER
|--------------------------------------------------------------------------
*/

%G-drawer-menu {
    padding: 0 1rem;

    .drawer-menu__items {
        @include flex-config($flex-direction: column);
    }

    .drawer-menu__item {
        @include flex-config($flex-direction: row, $align-items: center, $justify-content: space-between);
        padding: 0;
        height: 48px;
        font-weight: 500;
    }

    .drawer-menu__item-icon {
        margin-left: .5rem;
        font-size: 1.8rem;
    }

    .menu-item__label {
        font-size: 1.4rem !important;
        text-align: left;
        text-transform: capitalize;
        margin-left: 10px;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.2 SUPPORT MENU DRAWER
|--------------------------------------------------------------------------
*/

%G-support-menu {
    @include flex-config($justify-content: space-between, $flex-direction: column);
    min-height: 100%;
    padding: 2.5rem 2rem;

    .support-menu__top {
        width: 100%;
        @include flex-config($align-items: center, $justify-content: flex-start);
    }

    .support-menu__heading {
        font-size: 1.5rem;
        font-weight: 300;
    }

    .support-menu__top-icon {
        font-size: 21px;
        margin: 0 10px 4px 2px;
    }

    .support-menu__list {
        @include flex-config($flex-direction: column);
        margin: 3.2rem 0 2rem;
    }

    .support-menu__item {
        @include flex-config($flex-direction: row, $align-items: center);
        height: 57px;
    }

    .support-menu__icon {
        font-size: 19px;
        margin: 0 20px 0 10px;
    }

    .menu-item__label {
        text-align: left;
        font-size: 16px;
        text-transform: capitalize;
        margin-right: 10px;
    }

    .support-menu__mail {
        @include flex-config($align-items: center, $justify-content: center);
        margin: 2rem 0;
        font-size: 12px;
        color: inherit;
    }

    .support-menu__mail-icon {
        margin-right: 0.5rem;
    }

    .support-menu__additional-btns-container {
        @include flex-config($flex-direction: column, $align-items: center);
        margin-bottom: 1rem;
    }

    .support-menu__additional-btn {
        height: 3.6rem;
        margin: .5rem 1rem;
        padding: 0;
        border-radius: 35px;
        font-size: 1.4rem;
        text-transform: initial;

        .ll-btn__content {
            padding: .3rem;
        }

        .llg-angle-right {
            font-size: 2.6rem;
            padding: 0 1.5rem;
        }
    }

    .support-menu__additional-btn-link {
        @include flex-config($align-items: center, $justify-content: center);
    }

    .support-menu__additional-btn-icon {
        @include flex-config($align-items: center, $justify-content: center);
        @include size(2.8rem);
        border-radius: 50%;
        margin: 0 1rem 0 0;
        font-size: 2.2rem;
    }

    .support-menu__license-icons-container {
        @include flex-config($justify-content: center);
        width: 100%;
    }

    .support-menu__tiny-icon {
        @include size(40px);
        display: flex;
    }

    .support-menu__social-icons-container {
        @include flex-config($justify-content: center);
        width: 100%;
        margin: 20px 0;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.3 MY ACCOUNT MENU DRAWER
|--------------------------------------------------------------------------
*/

%G-my-account-menu-drawer {
    .my-account-menu-drawer__info {
        font-size: 1.4rem;
        display: flex;
        align-items: center;
    }

    .my-account-menu-drawer__icon {
        font-size: 2.1rem;
        margin: 0 1rem 0.4rem 0;
    }

    .my-account-menu-drawer__blocked-user {
        text-align: center;
        max-width: 20rem;
        margin: auto;
    }

    .ll-btn {
        text-transform: uppercase;
        margin: 1.5rem 0 0 1rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.4 LOGIN DRAWER
|--------------------------------------------------------------------------
*/

%G-login-drawer {
    padding: 0 1rem;

    .mobile-drawer__close-btn {
        position: absolute;
        top: -3rem;
        right: 0;
    }

    .login-drawer-menu__item {
        @include size(100%, 4.6rem);
        @include flex-config($align-items: center, $justify-content: space-between);
        margin: 2rem 0;
        border-radius: .3rem;
    }

    .login-drawer-menu__item:first-of-type {
        margin-top: 7rem;
    }

    .login-drawer-menu__item-icon {
        margin: 0 1rem;
        font-size: 2.4rem;
    }

    .login-drawer-menu__item-name {
        @include flex-config($align-items: center);
        flex: 1;
    }

    .login-drawer-menu__item-text {
        text-transform: capitalize;
        margin-right: 1rem;
        font-size: 1.4rem;
        font-weight: 500;
    }

    .login-drawer-menu__item-arrow-icon {
        font-size: 1.4rem;
    }

    .form__captcha-text {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 2rem 2rem 0 2rem;
        font-size: 1.1rem;
        text-align: center;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.5 MY BETS DRAWER
|--------------------------------------------------------------------------
*/
%G-my-bets-drawer {
    height: calc(100vh - 10rem);
    padding: 0;
}

/*
|--------------------------------------------------------------------------
| == 2.7. POPOVER
|--------------------------------------------------------------------------
*/

%G-ll-popover {
    z-index: 2000;
}

%G-change-password__popover {
    max-width: 18vw;
}

%G-bet-history__status-filter-dropdown {
    width: 100%;
    z-index: 200;
}

%G-ll-footer__popover {
    width: 100vw;

    .ll-footer__popover-item {
        @include size(100%, 4rem);
        @include flex-config($align-items: center);
        line-height: 0.96;
        cursor: pointer;
    }
}

%G-ll-footer__popover--tablet--mobile {
    .ll-scrollbox--mobile {
        max-height: 60vh;
    }

    .ll-footer__popover-item {
        padding: 0 1rem;
        font-size: 1.2rem;
    }
}

%G-ll-footer__popover--desktop {
    max-width: 33vw;
    width: fit-content;
    min-width: 15rem;
    padding-left: .5rem;

    .ll-footer__popover-content {
        border-radius: 3px;
    }

    .ll-footer__popover-item {
        padding: 0 2rem;
        justify-content: center;
        font-size: 1.4rem;
        text-align: center;
    }
}

%G-ll-popover-wrapper {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

/*
|--------------------------------------------------------------------------
| == 2.8. BACKDROP
|--------------------------------------------------------------------------
*/


%G-ll-backdrop {
    @include position(absolute, top 0 left 0, $z-index: 100);
    width: 100vw;
    height: 0;

    &.ll-backdrop--open {
        height: 100%;
        backdrop-filter: blur(.7px) grayscale(40%);

        &.ll-backdrop--transparent {
            backdrop-filter: none;
        }
    }

    &.ll-backdrop--full-screen {
        z-index: 400;
    }
}

.ll-modal-exit-done {
    display: none;
}

.ll-modal--mobile.custom-bets__clear-modal {
    width: 90vw !important;
}

.footer-featured__background-img {
    position: absolute;
    background-position: center;
    background-size: cover;
    width: 70%;
    height: 100%;
}

.footer-featured__background-img-blur {
    width: 100%;
    height: 100%;
    position: absolute;
}
