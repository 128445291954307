@import './helpers.scss';

@import './global-variables-parts/global.scss';
@import './global-variables-parts/layout.scss';
@import './global-variables-parts/prematch.scss';
@import './global-variables-parts/coupons.scss';
@import './global-variables-parts/vaix.scss';
@import './global-variables-parts/live.scss';
@import './global-variables-parts/signup.scss';
@import './global-variables-parts/slots-battles.scss';
@import './global-variables-parts/slots-casino.scss';
@import './global-variables-parts/home.scss';
@import './global-variables-parts/promo.scss';
@import './global-variables-parts/my-account.scss';
@import './global-variables-parts/hosted-solution.scss';
@import './global-variables-parts/about.scss';
@import './global-variables-parts/help.scss';
@import './global-variables-parts/error.scss';
@import './global-variables-parts/forms.scss';
@import './global-variables-parts/messages.scss';
@import './global-variables-parts/popup.scss';
@import './global-variables-parts/sport-filters.scss';
@import './global-variables-parts/mini-banners.scss';
@import './global-variables-parts/custombets.scss';
@import './global-variables-parts/lobby.scss';
@import './global-variables-parts/widgets.scss';


body {
    @extend %G-body;
    font-size: 12px;
    font-family: 'Maven Pro', sans-serif;
}

//[class*="sport_icon_font-"]::before {
//    width: 1.6rem;
//    display: inline-block;
//    content: "\ea57";
//}