@font-face {
    font-family: 'llg';
    src:  url('../../static/fonts/llg.eot');
    src:  url('../../static/fonts/llg.eot#iefix') format('embedded-opentype'),
    url('../../static/fonts/llg.ttf') format('truetype'),
    url('../../static/fonts/llg.woff') format('woff'),
    url('../../static/fonts/llg.svg#llg') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="llg-"], [class*=" llg-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'llg' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.llg-13:before {
  content: "\e900";
}
.llg-betongame:before {
  content: "\e901";
}
.llg-calendar:before {
  content: "\e902";
}
.llg-casino:before {
  content: "\e903";
}
.llg-dollar:before {
  content: "\e904";
}
.llg-ezugi:before {
  content: "\e905";
}
.llg-football1:before {
  content: "\e906";
}
.llg-help:before {
  content: "\e907";
}
.llg-horse1:before {
  content: "\e908";
}
.llg-light:before {
  content: "\e909";
}
.llg-mail:before {
  content: "\e90a";
}
.llg-popular:before {
  content: "\e90b";
}
.llg-question:before {
  content: "\e90c";
}
.llg-support:before {
  content: "\e90d";
}
.llg-tv:before {
  content: "\e90e";
}
.llg-user:before {
  content: "\e90f";
}
.llg-spikes:before {
  content: "\e910";
}
.llg-lottery-01:before {
  content: "\e911";
}
.llg-lottery-02:before {
  content: "\e912";
}
.llg-aviator:before {
  content: "\e913";
}
.llg-user2:before {
  content: "\e914";
}
.llg-deposit:before {
  content: "\e915";
}
.llg-withdraw:before {
  content: "\e916";
}
.llg-purse2:before {
  content: "\e917";
}
.llg-tickets:before {
  content: "\e918";
}
.llg-user-history:before {
  content: "\e919";
}
.llg-casino-report:before {
  content: "\e91a";
}
.llg-limits:before {
  content: "\e91b";
}
.llg-authenticator:before {
  content: "\e91c";
}
.llg-corners:before {
  content: "\e91d";
}
.llg-poker-chip:before {
  content: "\e91e";
}
.llg-help2:before {
  content: "\e91f";
}
.llg-virtual-worldcup:before {
  content: "\e920";
}
.llg-virtual-tennis:before {
  content: "\e921";
}
.llg-virtual-football:before {
  content: "\e922";
}
.llg-virtual-basketball:before {
  content: "\e923";
}
.llg-basketball1:before {
  content: "\e924";
}
.llg-tennis1:before {
  content: "\e945";
}
.llg-x2-gaming:before {
  content: "\e94d";
}
.llg-game-ball:before {
  content: "\e955";
}
.llg-livegames-bold:before {
  content: "\e957";
}
.llg-casino-bold:before {
  content: "\e958";
}
.llg-football-field1:before {
  content: "\e95a";
}
.llg-trash:before {
  content: "\e962";
}
.llg-calendar1:before {
  content: "\e963";
}
.llg-betslip:before {
  content: "\e967";
}
.llg-star-filled:before {
  content: "\e96c";
}
.llg-star-stroke:before {
  content: "\e96d";
}
.llg-livegames:before {
  content: "\e978";
}
.llg-casino1:before {
  content: "\e97a";
}
.llg-roulette:before {
  content: "\e986";
}
.llg-beach-tennis:before {
  content: "\e995";
}
.llg-greyhound:before {
  content: "\e9b7";
}
.llg-indoor-soccer:before {
  content: "\e9bc";
}
.llg-world-championship:before {
  content: "\e9e7";
}
.llg-search:before {
  content: "\f002";
}
.llg-envelope-o:before {
  content: "\f003";
}
.llg-star:before {
  content: "\f005";
}
.llg-star-o:before {
  content: "\f006";
}
.llg-user1:before {
  content: "\f007";
}
.llg-check:before {
  content: "\f00c";
}
.llg-close:before {
  content: "\f00d";
}
.llg-cog:before {
  content: "\f013";
}
.llg-trash-o:before {
  content: "\f014";
}
.llg-home:before {
  content: "\f015";
}
.llg-repeat:before {
  content: "\f01e";
}
.llg-refresh:before {
  content: "\f021";
}
.llg-list-alt:before {
  content: "\f022";
}
.llg-lock:before {
  content: "\f023";
}
.llg-headphones:before {
  content: "\f025";
}
.llg-qrcode:before {
  content: "\f029";
}
.llg-tag:before {
  content: "\f02b";
}
.llg-tags:before {
  content: "\f02c";
}
.llg-book:before {
  content: "\f02d";
}
.llg-bookmark:before {
  content: "\f02e";
}
.llg-share-square-o:before {
  content: "\f045";
}
.llg-check-square-o:before {
  content: "\f046";
}
.llg-arrows:before {
  content: "\f047";
}
.llg-chevron-left:before {
  content: "\f053";
}
.llg-chevron-right:before {
  content: "\f054";
}
.llg-plus-circle:before {
  content: "\f055";
}
.llg-minus-circle:before {
  content: "\f056";
}
.llg-times-circle:before {
  content: "\f057";
}
.llg-check-circle:before {
  content: "\f058";
}
.llg-question-circle:before {
  content: "\f059";
}
.llg-info-circle:before {
  content: "\f05a";
}
.llg-times-circle-o:before {
  content: "\f05c";
}
.llg-check-circle-o:before {
  content: "\f05d";
}
.llg-ban:before {
  content: "\f05e";
}
.llg-arrow-left:before {
  content: "\f060";
}
.llg-arrow-right:before {
  content: "\f061";
}
.llg-arrow-up:before {
  content: "\f062";
}
.llg-arrow-down:before {
  content: "\f063";
}
.llg-mail-forward:before {
  content: "\f064";
}
.llg-expand:before {
  content: "\f065";
}
.llg-compress:before {
  content: "\f066";
}
.llg-plus:before {
  content: "\f067";
}
.llg-minus:before {
  content: "\f068";
}
.llg-exclamation-circle:before {
  content: "\f06a";
}
.llg-gift:before {
  content: "\f06b";
}
.llg-eye:before {
  content: "\f06e";
}
.llg-eye-slash:before {
  content: "\f070";
}
.llg-calendar2:before {
  content: "\f073";
}
.llg-comment:before {
  content: "\f075";
}
.llg-chevron-up:before {
  content: "\f077";
}
.llg-chevron-down:before {
  content: "\f078";
}
.llg-bar-chart:before {
  content: "\f080";
}
.llg-twitter-square:before {
  content: "\f081";
}
.llg-facebook-square:before {
  content: "\f082";
}
.llg-comments:before {
  content: "\f086";
}
.llg-trophy:before {
  content: "\f091";
}
.llg-phone:before {
  content: "\f095";
}
.llg-square-o:before {
  content: "\f096";
}
.llg-bookmark-o:before {
  content: "\f097";
}
.llg-phone-square:before {
  content: "\f098";
}
.llg-twitter:before {
  content: "\f099";
}
.llg-facebook:before {
  content: "\f09a";
}
.llg-unlock:before {
  content: "\f09c";
}
.llg-credit-card:before {
  content: "\f09d";
}
.llg-bell-o:before {
  content: "\f0a2";
}
.llg-arrow-circle-left:before {
  content: "\f0a8";
}
.llg-arrow-circle-right:before {
  content: "\f0a9";
}
.llg-arrow-circle-up:before {
  content: "\f0aa";
}
.llg-arrow-circle-down:before {
  content: "\f0ab";
}
.llg-filter:before {
  content: "\f0b0";
}
.llg-briefcase:before {
  content: "\f0b1";
}
.llg-arrows-alt:before {
  content: "\f0b2";
}
.llg-group:before {
  content: "\f0c0";
}
.llg-bars:before {
  content: "\f0c9";
}
.llg-google-plus-square:before {
  content: "\f0d4";
}
.llg-google-plus:before {
  content: "\f0d5";
}
.llg-money:before {
  content: "\f0d6";
}
.llg-caret-down:before {
  content: "\f0d7";
}
.llg-caret-up:before {
  content: "\f0d8";
}
.llg-caret-left:before {
  content: "\f0d9";
}
.llg-caret-right:before {
  content: "\f0da";
}
.llg-envelope:before {
  content: "\f0e0";
}
.llg-rotate-left:before {
  content: "\f0e2";
}
.llg-comment-o:before {
  content: "\f0e5";
}
.llg-comments-o:before {
  content: "\f0e6";
}
.llg-bell:before {
  content: "\f0f3";
}
.llg-plus-square:before {
  content: "\f0fe";
}
.llg-angle-double-left:before {
  content: "\f100";
}
.llg-angle-double-right:before {
  content: "\f101";
}
.llg-angle-double-up:before {
  content: "\f102";
}
.llg-angle-double-down:before {
  content: "\f103";
}
.llg-angle-left:before {
  content: "\f104";
}
.llg-angle-right:before {
  content: "\f105";
}
.llg-angle-up:before {
  content: "\f106";
}
.llg-angle-down:before {
  content: "\f107";
}
.llg-desktop:before {
  content: "\f108";
}
.llg-laptop:before {
  content: "\f109";
}
.llg-tablet:before {
  content: "\f10a";
}
.llg-mobile:before {
  content: "\f10b";
}
.llg-circle-o:before {
  content: "\f10c";
}
.llg-circle:before {
  content: "\f111";
}
.llg-mail-reply:before {
  content: "\f112";
}
.llg-gamepad:before {
  content: "\f11b";
}
.llg-question1:before {
  content: "\f128";
}
.llg-calendar-o:before {
  content: "\f133";
}
.llg-chevron-circle-left:before {
  content: "\f137";
}
.llg-chevron-circle-right:before {
  content: "\f138";
}
.llg-chevron-circle-up:before {
  content: "\f139";
}
.llg-chevron-circle-down:before {
  content: "\f13a";
}
.llg-unlock-alt:before {
  content: "\f13e";
}
.llg-ellipsis-h:before {
  content: "\f141";
}
.llg-ellipsis-v:before {
  content: "\f142";
}
.llg-play-circle:before {
  content: "\f144";
}
.llg-ticket:before {
  content: "\f145";
}
.llg-minus-square:before {
  content: "\f146";
}
.llg-minus-square-o:before {
  content: "\f147";
}
.llg-check-square:before {
  content: "\f14a";
}
.llg-external-link-square:before {
  content: "\f14c";
}
.llg-share-square:before {
  content: "\f14d";
}
.llg-caret-square-o-down:before {
  content: "\f150";
}
.llg-caret-square-o-up:before {
  content: "\f151";
}
.llg-caret-square-o-right:before {
  content: "\f152";
}
.llg-eur:before {
  content: "\f153";
}
.llg-gbp:before {
  content: "\f154";
}
.llg-dollar1:before {
  content: "\f155";
}
.llg-inr:before {
  content: "\f156";
}
.llg-cny:before {
  content: "\f157";
}
.llg-rouble:before {
  content: "\f158";
}
.llg-bitcoin:before {
  content: "\f15a";
}
.llg-thumbs-up:before {
  content: "\f164";
}
.llg-thumbs-down:before {
  content: "\f165";
}
.llg-youtube-square:before {
  content: "\f166";
}
.llg-youtube:before {
  content: "\f167";
}
.llg-youtube-play:before {
  content: "\f16a";
}
.llg-instagram:before {
  content: "\f16d";
}
.llg-long-arrow-down:before {
  content: "\f175";
}
.llg-long-arrow-up:before {
  content: "\f176";
}
.llg-long-arrow-left:before {
  content: "\f177";
}
.llg-long-arrow-right:before {
  content: "\f178";
}
.llg-dribbble:before {
  content: "\f17d";
}
.llg-skype:before {
  content: "\f17e";
}
.llg-archive:before {
  content: "\f187";
}
.llg-try:before {
  content: "\f195";
}
.llg-plus-square-o:before {
  content: "\f196";
}
.llg-envelope-square:before {
  content: "\f199";
}
.llg-bank:before {
  content: "\f19c";
}
.llg-language:before {
  content: "\f1ab";
}
.llg-fax:before {
  content: "\f1ac";
}
.llg-life-bouy:before {
  content: "\f1cd";
}
.llg-empire:before {
  content: "\f1d1";
}
.llg-paper-plane:before {
  content: "\f1d8";
}
.llg-paper-plane-o:before {
  content: "\f1d9";
}
.llg-circle-thin:before {
  content: "\f1db";
}
.llg-sliders:before {
  content: "\f1de";
}
.llg-futbol-o:before {
  content: "\f1e3";
}
.llg-trash1:before {
  content: "\f1f8";
}
.llg-area-chart:before {
  content: "\f1fe";
}
.llg-pie-chart:before {
  content: "\f200";
}
.llg-line-chart:before {
  content: "\f201";
}
.llg-toggle-off:before {
  content: "\f204";
}
.llg-toggle-on:before {
  content: "\f205";
}
.llg-cart-plus:before {
  content: "\f217";
}
.llg-diamond:before {
  content: "\f219";
}
.llg-venus:before {
  content: "\f221";
}
.llg-mars:before {
  content: "\f222";
}
.llg-facebook-official:before {
  content: "\f230";
}
.llg-whatsapp:before {
  content: "\f232";
}
.llg-user-plus:before {
  content: "\f234";
}
.llg-user-times:before {
  content: "\f235";
}
.llg-clone:before {
  content: "\f24d";
}
.llg-television:before {
  content: "\f26c";
}
.llg-calendar-plus-o:before {
  content: "\f271";
}
.llg-calendar-minus-o:before {
  content: "\f272";
}
.llg-calendar-times-o:before {
  content: "\f273";
}
.llg-calendar-check-o:before {
  content: "\f274";
}
.llg-commenting:before {
  content: "\f27a";
}
.llg-commenting-o:before {
  content: "\f27b";
}
.llg-credit-card-alt:before {
  content: "\f283";
}
.llg-pause-circle:before {
  content: "\f28b";
}
.llg-pause-circle-o:before {
  content: "\f28c";
}
.llg-shopping-basket:before {
  content: "\f291";
}
.llg-percent:before {
  content: "\f295";
}
.llg-question-circle-o:before {
  content: "\f29c";
}
.llg-volume-control-phone:before {
  content: "\f2a0";
}
.llg-low-vision:before {
  content: "\f2a8";
}
.llg-envelope-open:before {
  content: "\f2b6";
}
.llg-envelope-open-o:before {
  content: "\f2b7";
}
.llg-address-book:before {
  content: "\f2b9";
}
.llg-address-book-o:before {
  content: "\f2ba";
}
.llg-address-card:before {
  content: "\f2bb";
}
.llg-address-card-o:before {
  content: "\f2bc";
}
.llg-user-circle:before {
  content: "\f2bd";
}
.llg-user-circle-o:before {
  content: "\f2be";
}
.llg-user-o:before {
  content: "\f2c0";
}
.llg-id-badge:before {
  content: "\f2c1";
}
.llg-drivers-license:before {
  content: "\f2c2";
}
.llg-drivers-license-o:before {
  content: "\f2c3";
}
.llg-telegram:before {
  content: "\f2c6";
}
.llg-window-maximize:before {
  content: "\f2d0";
}
.llg-window-minimize:before {
  content: "\f2d1";
}
.llg-window-restore:before {
  content: "\f2d2";
}
.llg-times-rectangle:before {
  content: "\f2d3";
}
.llg-times-rectangle-o:before {
  content: "\f2d4";
}
.llg-horse:before {
  content: "\e925";
}
.llg-football-field:before {
  content: "\e926";
}
.llg-dog:before {
  content: "\e927";
}
.llg-football:before {
  content: "\e928";
}
.llg-world-cup:before {
  content: "\e929";
}
