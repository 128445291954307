@import "./styles/themes/theme/theme.scss";
/*
|--------------------------------------------------------------------------
| == ^^^^^^^^^^^^^^^  COMMENT THIS  ^^^^^^^^^^^^^^^^^
| == SELECT AND UNCOMMENT THEME PATH
| == COPY THEME IMAGE TO /src/static/themes/theme/,
| == ENJOY SELECTED THEME
| == DONT FORGET COMMENT THEME PATH AND UNCOMMENT @import "./styles/themes/theme/theme.scss";
|--------------------------------------------------------------------------
*/

//@import "./styles/themes/bahissenin/theme.scss";
//@import "./styles/themes/betaestas/theme.scss";
//@import "./styles/themes/betlima/theme.scss";
//@import "./styles/themes/ciprobet/theme.scss";
//@import "./styles/themes/colonybet/theme.scss";
//@import "./styles/themes/danobet/theme.scss";
//@import "./styles/themes/efsanebahis/theme.scss";
//@import "./styles/themes/jumpodds/theme.scss";
//@import "./styles/themes/maksatbahis/theme.scss";
//@import "./styles/themes/retrobet/theme.scss";
//@import "./styles/themes/showbahis/theme.scss";


html {
    font-size: 10px;
    overscroll-behavior: none;
}

body {
    padding: 0;
    margin: 0;
    position: relative;
    overflow: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    background: $color--body-background;
    overscroll-behavior: none;
}

a,
a:active,
a:focus,
a:hover {
    text-decoration: none;
}

*:focus {
    outline: none;
}

#root {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
