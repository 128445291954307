/*
3.PAGES
└── 3.2. SIGNUP PAGE
   ├── 3.2.1 SIGNUP DESKTOP
   └── 3.2.2 SIGNUP MOBILE
*/

/*
|--------------------------------------------------------------------------
| == 3.2. SIGNUP PAGE
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| == 3.2.1. SIGNUP DESKTOP
|--------------------------------------------------------------------------
*/

.signup--desktop,
.signup--tablet {
    @extend %G-signup--desktop;
}

.signup--desktop,
.signup--tablet,
.signup--mobile  {
    .ll-input label.ll-input__label--inside,
    .signup__currencies .signup__field-label {
        color: #fff;
    }

    .dx-texteditor.dx-editor-outlined.dx-invalid .dx-texteditor-container {
        box-shadow: inset 0 0 0 1px #C73738;
    }

    .dx-texteditor.dx-editor-outlined.dx-state-focused {
        -webkit-box-shadow: inset 0 0 0 2px #F6A83C55 !important;
        box-shadow: 0 0 2px 1px #F6A83C55 !important;
    }

    .signup__form-header {
        background-color: #042E4B;
        color: #F6A83C;
    }

    .signup__container {
        background-color: #06486C;
        border-radius: 0.5rem;
    }

    .signup__gender {
        .dx-collection {
            color: #fff;
        }

        .dx-radiobutton {
            background: #042e4b !important;
            box-shadow: inset 0 0 0 1px #267188;
        }

        .dx-radiobutton-icon:before {
            border: 2px solid #F6A83C;
            background: transparent;
        }

        .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
            background: #F6A83C;
        }
    }

    .signup__country-picker,
    .ll-date-picker--desktop input,
    .ll-date-picker--mobile input,
    .signup__currencies {
        background: #042e4b !important;
        box-shadow: inset 0 0 0 1px #267188;

        .dx-texteditor-input {
            color: #fff;
        }
    }

    .signup__phone-flag-container {
        color: #fff;
        border-right: 1px solid #162136;
    }

    .signup__footer-link {
        color: #F6A83C;
        cursor: pointer;
    }

    .signup__footer-text {
        color: #fff;
    }

    .signup__footer-eighteen--check {
        .dx-checkbox-icon {
            background: transparent !important;
            border: 1px solid #fff;
            color: #fff;
            font-size: 1.2rem;
            font-weight: bold;
        }
    }

    .form__captcha-text {
        color: #fff;
    }

    .form__captcha-link {
        color: #F6A83C;
    }

    .signup__sumbit-btn {
        background: #F6A83C;
    }

    .signup__login-btn {
        color: #F6A83C;
    }

    .signup__errors {
        .dx-item-content {
            border: none;
            color: #C73738;

            &:before {
                color: #C73738;
            }
        }
    }

    .signup__footer-eighteen--error {
        color: #f44336;
    }
}

.body--with-cursor {
    .signup--desktop,
    .signup--tablet {
        .form__captcha-link:hover,
        .signup__footer-link:hover {
            text-decoration: underline;
        }

        .signup__sumbit-btn:hover {
            opacity: 1;
            background: #f0871a;
        }

        .dx-item:hover {
            font-size: 1.4rem;

            .dx-item-content,
            .dx-item-content:before {
                color: #f0871a;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.2 SIGNUP MOBILE
|--------------------------------------------------------------------------
*/

.signup--tablet {
    @extend %G-signup--tablet;
}

.signup--tablet,
.signup--mobile {
    .signup__container {
        background: transparent;
    }

    .signup__footer-eighteen,
    .form__captcha-text {
        background: #042e4b;
        box-shadow: inset 0 0 0 1px #162136;
    }

    .dx-invalid-message > .dx-overlay-content,
    .signup__footer-eighteen--error {
        color: #f44336;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.3 SIGNUP MOBILE
|--------------------------------------------------------------------------
*/

.signup--mobile {
    @extend %G-signup--mobile;

    .signup__header--mobile {
        background-color: #042E4B;
        color: #F6A83C;
    }
}
