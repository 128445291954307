/*
3.PAGES
└── 3.1.2. LIVE PAGE
    ├── 3.1.2.1. LIVE WIDGETS
    ├── 3.1.2.2. LIVE MENU (DESKTOP & TABLET)
    ├── 3.1.2.3. MATCH DETAILS (DESKTOP & TABLET)
    ├── 3.1.2.4. LIVE PAGE (MOBILE)
    ├───├── 3.1.2.4.1. LIVE PAGE MENU
    ├───└── 3.1.2.4.2. LIVE PAGE DETAILS
    └── 3.1.2.5. LIVE CALENDAR
*/

/*
|--------------------------------------------------------------------------
| == 3.1.2.1. LIVE WIDGETS
|--------------------------------------------------------------------------
*/

.live-sticky-widget {
    top: calc(#{$size-height--header_mobile} + #{$size-height--header-nav-menu_mobile});

    @include mq('tablet') {
        top: calc(#{$size-height--header_tablet} + #{$size-height--header-nav-menu_tablet});
    }
}

/* --------------- Live widgets navigation tabs --------------- */
.live-widgets__nav--mobile {
    background-color: #E8F1F5;

    .live-widgets__nav-item {
        color: #C7C7C7;
    }
}

.live-widgets__nav--tablet,
.live-widgets__nav--desktop {
    background-color: #027992;

    .live-widgets__nav-item {
        color: #C7C7C7;

        .live-widgets__nav-icon {
            color: #C7C7C7;
        }
    }

    .live-widgets__nav-item--active {
        color: #F6A83C;

        .live-widgets__nav-icon {
            color: #F6A83C;
        }
    }

    .live-widgets__nav-info {
        color: #C7C7C7;

        &:hover {
            color: #F6A83C;
        }
    }
}

.live-widgets__nav--desktop {
    .live-widgets__nav-item:hover {
        color: #F6A83C;

        .live-widgets__nav-icon {
            color: #F6A83C;
        }
    }
}

.live-widgets__nav--tablet {
    border-bottom: 1px solid #C7C7C7;
}

.live-widgets {
    .live-widgets__nav-info-text {
        background-color: #042E4B;
        color: #FFFFFF;
    }
}

.prematch--mobile {
    .live-widgets__nav-container {
        background: #35678E;
        box-shadow: 3px 2px 5px #35678E;

        .menu__board {
            background-color: #35678E;
        }

        .live-widgets__nav-item {
            border-right: 1px solid #042E4B;
        }

        .live-widgets__nav-item {
            background-color: #35678E;
            color: #E0E9EF;

            .live-widgets__nav-icon {
                color: #E0E9EF;
            }
        }

        .live-widgets__nav-item--active {
            color: #F6A83C;

            .live-widgets__nav-icon {
                color: #F6A83C;
            }
        }

        .live-widgets__nav-burger,
        .live-widgets__nav-burger--opened {
            background-color: #35678E;

            div {
                background-color: #E0E9EF;
            }
        }

        .live-widgets__nav-info {
            background: #35678E;
            color: #E0E9EF;
        }
    }
}

/* ------------ Live widgets navigation tabs END ------------ */

/* -------------------- Stream widget -------------------- */
.stream {
    .stream__loader-box {
        background: #031E33;
    }

    .stream__error-content {
        background: #031E33;
    }

    .stream__error-text {
        color: #fff;
    }
}

.livematches__view-buttons {
    color: #C7C7C7;
    background-color: #0F6B7E;
    padding: 0;
}

.livematches__view-button,
.livematches__view-button--active {
    width: 6rem!important;
    padding: 0 2rem;
    height: 100%;
}

.livematches__view-button--active {
    color: #F6A83C;
    background-color: #027992;
    width: 24rem!important;
}

.prematch--desktop {
    .livematches__view-button:hover,
    .livematches__view-button--active:hover {
        color: #fff;
    }
}

/* ------------------- Stream widget END ------------------- */


/*
|--------------------------------------------------------------------------
| == 3.1.2.2. LIVE MENU (DESKTOP & TABLET)
|--------------------------------------------------------------------------
*/

.live-menu__loading {
    background-color: #00000020;
}

.live-menu__section {
    min-width: $size-width__livematch-menu--desktop-1360;
    max-width: $size-width__livematch-menu--desktop-1360;

    @include mq('(min-width: 1500px)') {
        min-width: $size-width__livematch-menu--desktop-1500;
        max-width: $size-width__livematch-menu--desktop-1500;
    }

    @include mq('(min-width: 1700px)') {
        min-width: $size-width__livematch-menu--desktop-1700;
        max-width: $size-width__livematch-menu--desktop-1700;
    }

    & + .prematch__loading--initial {
        background-color: #202020dd;
    }

    &.live-menu__section--disabled:before {
        background: #00000020;
    }

    .live-left-menu__header {
        background-color: #027992;
        color: #F6A83C;
    }

    .live-left-menu__odds-count {
        background-color: #031E33;
        color: #F6A83C;
    }

    .ll-accordion .ll-accordion__header,
    .ll-accordion--active .ll-accordion__header {
        border-bottom: 1px solid #042E4B;
        background-color: #2c628d;
    }

    .ll-accordion__header-text {
        color: #fff;
    }

    .live-common-menu__item-name {
        border-bottom: 1px solid #042E4B;
        background-color: #027992;
        color: #fff;
    }

    .ll-accordion__header-count {
        color: #F6A83C;
        background-color: #042e4b;
    }

    .match__icon--can-cashout,
    .match__icon--cant-cashout {
        color: #000;
    }

    .match__icon--cant-cashout {
        opacity: .2;
    }

    .live-left-menu__list-row,
    .live-left-menu__list-row--active {
        background: #fff;
        color: #000;

        &:not(:first-of-type) {
            box-shadow: inset 0 1px 0 0 #031E33;
        }

        &:first-of-type:last-of-type {
            box-shadow: inset 0 -1px 0 0 #031E33;
        }

        &:not(:first-of-type):last-of-type {
            box-shadow:
                inset 0 1px 0 0 #031E33,
                inset 0 -1px 0 0 #031E33;
        }

        .live-left-menu__bet {
            box-shadow: inset -1px 0 0 0 #031E33;
        }

        .live-left-menu__bet--active {
            box-shadow: inset -1px 1px 0 0px #031E33;
            background: #F6A83C !important;

            .live-left-menu__bet-value:after {
                color: #000;
            }
        }
    }

    .live-match__competitors-holder {
        box-shadow: inset -1px 0 0 0 #031E33;
    }

    .live-left-menu__list-row,
    .live-left-menu__list-row--active {
        .live-match__info-header-item {
            color: #fff;
        }
    }

    .live-left-menu__bet {
        box-shadow: inset 1px 0 0 0 #031E33, inset -1px 0 0 0 #031E33;
    }

    .live-left-menu__row-header {
        color: #fff;
        background-color: #042e4b;
    }

    .live-left-menu__row-header-competitors {
        box-shadow: inset -1px 0 0 0 #031E33;
    }

    .live-left-menu__oddsv {
        box-shadow: inset -1px 0 0 0 #000000aa;
    }

    .live-left-menu__bet--dn {
        .live-left-menu__bet-value:after {
            color: #d10307;
        }
    }

    .live-left-menu__bet--up {
        .live-left-menu__bet-value:after {
            color: #84DB60;
        }
    }
}

.prematch--desktop {
    .live-left-menu__list-row--active {
        background: #F6A83C;
        color: #000;

        .live-left-menu__bet {
            .live-left-menu__bet-value:after {
                color: #000;
            }
        }
    }
}

.body--with-cursor {
    .ll-accordion__header:hover {
        background-color: #F6A83C;

        .ll-accordion__header-text {
            color: #000;
        }
    }

    .live-common-menu__item-name:hover {
        background-color: #005b6d;
    }

    .live-left-menu__list-row,
    .live-left-menu__list-row--active {
        .live-left-menu__bet:hover {
            box-shadow: inset -1px 1px 0 0px #202020;
            background: #f0871a !important;
            color: #000;

            .live-left-menu__bet-value:after {
                color: #000;
            }
        }

        &:hover {
            background: #F6A83C;
            color: #000;

            .live-match__info-header-item {
                color: #fff;
            }

            .live-left-menu__bet-value:after {
                color: #000;
            }
        }
    }
}

.prematch--tablet {
    .live-left-menu__view-btn,
    .live-details__calendar-btn {
        background-color: #0F6B7E;
        color: #fff;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.3. MATCH DETAILS (DESKTOP & TABLET)
|--------------------------------------------------------------------------
*/
.live-detail__section {
    .match-details__header-info-competitor:after {
        background-color: #000000;
    }

    .match-details__header-info {
        background-color: #999999;
        color: #fff;
    }

    .match-details__competitor-without-img {
        .match-details__breadcrumbs-icon {
            color: white
        }

        .match-details__competitor-without-img-icon::before {
            color: #fff;
        }
    }

    .match-details__competitor-name {
        color: #fff;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.51);
    }

    .match-details__description {
        color: #fff;
    }
}

.live-market-groups__slider {
    background-color: #042E4B;

    .live-match-details__market-group,
    .live-match-details__market-group--active {
        color: #fff;
    }

    .live-match-details__market-group--active {
        background-color: #F0871A;
        color: #1C1C1C;
    }

    .slider__arrow {
        color: #fff;
    }
}

.body--with-cursor {
    .live-match-details__market-group:hover {
        background-color: #F6A83C;
        color: #1C1C1C;
    }

    .live-market-groups__slider .slider__arrow:hover {
        color: #F0871A;
    }
}

.live-match-details {
    color: #000;

    .match-details__market-header {
        border-bottom: .1rem solid #031E33;
        background-color: #07476b;
        color: #fff;
    }

    .match-details__markets-error {
        color: #fff;
    }

    .status-filter-switcher .status-filter-switcher-track {
        background-color: #031E33 !important;
    }

    .match-details__bet {
        box-shadow: inset -1px 0 0 0 #031E33, inset 0 -2px 1px -1px #031E33;
        background: #fff;
        color: #000;

        &:not(.match-details__bet--disabled) {
            &:active,
            &:focus,
            &:hover {
                box-shadow: inset -1px 0 0 0 #031E33, inset 0 -2px 1px -1px #031E33;
                background: #F6A83C;
                color: #000;

                .match-details__bet-value,
                .match-details__bet-value:after {
                    color: #000;
                }
            }
        }

        &.match-details__bet--active {
            background: #F6A83C;
            color: #000;

            .match-details__bet-value,
            .match-details__bet-value:after {
                color: #000;
            }
        }
    }

    .match-details__bet--dn {
        .match-details__bet-value:after {
            color: #d10307;
        }
    }

    .match-details__bet--up {
        .match-details__bet-value:after {
            color: #84DB60;
        }
    }

    .match-details__bet-value {
        color: #CD5B1D;
    }

    .match-details__bets--contains-3 {
        .match-details__bet:nth-child(3n),
        .match-details__bet:last-child {
            box-shadow: inset 0 -2px 1px -1px #202020;
        }
    }

    .match-details__bets--contains-2 {
        .match-details__bet:nth-child(2n),
        .match-details__bet:last-child {
            box-shadow: inset 0 -2px 1px -1px #202020;
        }
    }

    .match-details__bets {
        background: #5D5D5D;
        color: #fff;
    }
    .market__icon--can-cashout,
    .market__icon--cant-cashout {
        color: #fff;
    }

    .market__icon--cant-cashout {
        opacity: .2;
    }
}

.live-match-details__landscape {
    .live-page__landscape-scrollbox {
        background: #85B0BA;
    }
}


/*
|--------------------------------------------------------------------------
| == 3.1.2.4. LIVE PAGE (MOBILE)
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| == 3.1.2.4.1. LIVE PAGE MENU
|--------------------------------------------------------------------------
*/

.livematches {
    .livematches__view-selectors {
        background-color: #027992;
        color: #fff;
    }
    
    .livematches__sport-item {
        color: #fff;
        //border-bottom: 1px solid #031E33;
        background: #35678E;
    }

    .livematches__sport-match-count {
        background: #F6A83C;
        color: #000;
    }

    .livematches__category-name {
        border-bottom: 1px solid #042E4B;
        background-color: #06486C;
        color: #C7C7C7;
    }

    .livematches__match-items {
        background: #042E4B;
        border-bottom: 1px solid #202020;
        color: #fff;
    }

    .livematch__info {
        .livematch__info-score {
            color: #F6A83C;
        }

        .livematch__info-time,
        .livematch__info-status {
            color: #A5C2DF;
        }
    }

    .match__icon--can-cashout,
    .match__icon--cant-cashout {
        color: #c7c7c7;
    }

    .match__icon--cant-cashout {
        opacity: .4;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.4.2. LIVE PAGE DETAILS
|--------------------------------------------------------------------------
*/

.livematches {
    .match-details__competitor-without-img {
        .match-details__breadcrumbs-icon {
            color: #fff;
        }
    }

    .match-details__header-info-competitor:after {
        background-color: #000000;
    }

    .match-details__competitor-without-img-icon::before {
        color: #fff;
    }

    .match-details__competitor-name {
        color: #fff;
    }

    .match-details__description {
        color: #fff;
    }

    .live-market-groups__slider,
    .live-match-details__market-group {
        background-color: #031E33;
        border: none;
    }

    .market__icon--can-cashout,
    .market__icon--cant-cashout {
        color: #fff;
    }

    .market__icon--cant-cashout {
        opacity: .2;
    }

    .match-details__empty-markets {
        background-color: #031E33;
        color: #fff;
    }

    .matches__bets-btn--dn,
    .match-details__bet--dn {
        .matches__bets-btn-value-odd:after,
        .match-details__bet-value-odd:after {
            color: #d10307;
        }
    }

    .matches__bets-btn--up,
    .match-details__bet--up {
        .matches__bets-btn-value-odd:after,
        .match-details__bet-value-odd:after {
            color: #84DB60;
        }
    }

    .match-details__bet--active .match-details__bet-value-odd:after,
    .matches__bets-btn--active .matches__bets-btn-value-odd:after {
        color: #000 !important;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.5. LIVE CALENDAR
|--------------------------------------------------------------------------
*/

.live-calendar {
    .live-calendar__back-button,
    .live-calendar__title {
        background: #027992;
        color: #F6A83C;
    }

    .live-calendar__preloader-wrapper {
        background-color: rgba(0, 0, 0, 0.4);
    }
}

.prematch--tablet {
    .live-details__header > *:not(:last-child) {
        background-color: #027992;
        color: #fff;
    }
}

.prematch--mobile {
    .live-calendar__no-data-text {
        border: 1px solid #027992;
        background: #1F3851;
        color: #fff;
    }
    
    .livematches__list {
        background: #031E33;
    }
}