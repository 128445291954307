/*
3.PAGES
└── 3.11. MESSAGES PAGE
*/

/*
|--------------------------------------------------------------------------
| == 3.11. MESSAGES PAGE
|--------------------------------------------------------------------------
*/

.messages {
    .message-iframe {
        height: calc(100vh - 164px);
        max-height: calc(100vh - 164px); 
        max-width : 100%;
        width : 100%;
        padding-top: 2px;
    }
}

.my-account--desktop {
    .messages {
        width: 100%;

        .message-iframe {
            height: calc(100vh - 192px);
            max-height: calc(100vh - 192px);     
        }
    }    
}