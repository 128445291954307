/*
3.PAGES
├── 3.1. SPORT COMMON ELEMENTS
│   ├── 3.1.1. SPORT SEARCH INPUT
│   ├── 3.1.2. SPORT BRANCH PICKER
│   └── 3.1.3. SPORT FILTERS POPUP PANEL
├── 3.2. PREMATCH PAGE
│   └── 3.2.1. PREMATCH PAGE FILTER TOP PANEL
└── 3.3. LIVEMATCH PAGE
    ├── 3.3.1. LIVE PAGE FILTER TOP PANEL
    └── 3.3.2. LIVE SEARCH RESULT DROPDOWN
*/

/*
|--------------------------------------------------------------------------
| == 3.1.1. SPORT SEARCH INPUT
|--------------------------------------------------------------------------
*/

.sport-search {
    @extend %G-sport-search;

    .sport-search__container {
        border-color: #0C5982;

        .dx-placeholder {
            color: #E0E9EF;
        }

        .sport-search__icon,
        .dx-texteditor-input {
            color: #FFFFFF;
        }
    }

    &.sport-search--menu {
        background-color: #027992;
    }

    .dx-texteditor-input,
    input.dx-texteditor-input:-webkit-autofill {
        color: #fff !important;
        -webkit-text-fill-color: #fff !important;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2. SPORT BRANCH PICKER
|--------------------------------------------------------------------------
*/

/* ----------------- Mobile Branch Picker ----------------- */

.branch-picker--mobile {
    @extend %G-branch-picker--mobile;

    .ll-branch-picker__dropdown-wrapper--mobile {
        background-color: #35678E;
        color: #fff;
    }

    .ll-branch-picker__dropdown-wrapper--accent {
        color: #F6A83C;
    }

    .ll-branch-picker__dropdown {
        background-color: #042e4b;
        color: #fff;
    }

    .ll-dropdown-menu-item--active {
        color: #F6A83C;
    }
}

/* -------------- Tablet Desktop Branch Picker ---------------- */

.branch-picker--tablet,
.branch-picker--desktop {
    @extend %G-branch-picker--tablet--desktop;

    color: #fff;

    .branch-picker__branch-item {
        &.active .branch-picker__branch-item-label {
            background-color: #F6A83C;
            color: #000000;
        }
    }

    .branch-picker__branch-item-label {
        background-color: #07476B;
    }

    .slider__arrow {
        color: #E0E9EF;
    }
}

.branch-picker--tablet {
    @extend %G-branch-picker--tablet;
}

.branch-picker--desktop {
    @extend %G-branch-picker--desktop;
}

.body--with-cursor {
    .branch-picker {
        .slider__arrow:hover {
            color: #fff;
        }

        .branch-picker__branch-item:hover {
            .branch-picker__branch-item-label {
                background-color: #F6A83C;
                color: #000000;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.3. SPORT FILTERS POPUP PANEL
|--------------------------------------------------------------------------
*/

.pre-filters-panel {
    @extend %G-pre-filters-panel;

    color: #fff;

    .event__search-highlite {
        text-decoration-color: #F6A83C;
    }

    /* ---------------------- Filters ---------------------- */
    .filters-panel__filters-submit-btn {
        background: #F6A83C;
        color: #000000;
    }
}

.pre-filters-panel--mobile,
.pre-filters-panel--tablet {
    @extend %G-pre-filters-panel--mobile--tablet;
}

.pre-filters-panel--mobile {
    height: $size-height__page-content-100vh_mobile;

    background-color: #042E4B;

    .filters-panel__header {
        background: #027992;
    }

    .pre-filters-panel__loader {
        background: #042E4B85;
    }

    .filters-panel__item {
        &:not(:last-of-type) {
            border-bottom: 1px solid #0C5982;
        }
    }

    /* ---------------------- Search ---------------------- */
    .sport-search__sport-item {
        background-color: #06486C;
        border-right: 1px solid #042E4B;
    }

    .sport-search__sport-count {
        background-color: #FFFFFF;
        color: #2C628D;
    }

    .sport-search__sport-item--active {
        background-color: #2C628D;

        .sport-search__sport-count {
            background-color: #F6A83C;
            color: #042E4B;
        }
    }

    /* ---------------------- Filters Date Range Picker ---------------------- */
    .ll-date-range-picker--sport-filters {
        .ll-date-range-picker__picker-box {
            background-color: #06486C;
            color: #FFFFFF;
        }

        .ll-date-range-picker__picker-input {
            color: #FFFFFF;
        }
    }

    /* ---------------------- Filters ---------------------- */
    .filters-panel__add-filter-button {
        background-color: #027992;
        color: #FFFFFF;
    }

    .filters-panel__selected-filter {
        background-color: #2C628D;
        color: #F6A83C;
    }

    .filters-panel__selected-filter-remove-btn {
        border: 1px solid #DDDDDD;
        color: #DDDDDD;
    }

    .filters-panel-menu {
        background-color: #06486C;
    }

    .filters-panel-menu__header {
        background-color: #F6A83C;
        color: #000000;
    }

    .filters-panel-menu__tabs {
        background-color: #0C5982;
        color: #E0E9EF;
    }

    .filters-panel-menu__tabs-item {
        &:not(:last-child):after {
            background-color: #E0E9EF;
        }

        &.active {
            color: #F6A83C;
        }
    }

    .filters-panel-menu__item {
        .filters-panel__add-icon {
            color: #FFFFFF;
            border-color: #FFFFFF;
        }
    }

    .filters-panel-menu__item.selected {
        color: #F6A83C;

        .filters-panel__add-icon {
            border-color: #F6A83C;
            color: #F6A83C;
        }

        .filters-panel-menu__item-count {
            background-color: #F6A83C;
            color: #042E4B;
        }
    }

    .filters-panel-menu__item-count {
        background-color: #027992;
        color: #FFFFFF;
    }

    .filters-panel-menu__item--sub {
        background: #0C5982;
        color: #E0E9EF;

        .filters-panel-menu__item-count {
            background-color: #E0E9EF;
            color: #042E4B;
        }
    }

    .filters-panel-menu__item--opened {
        background-color: #027992;

        .filters-panel-menu__item-count {
            background-color: #FFFFFF;
            color: #027992;
        }
    }

    .filters-panel-menu__submit-btn-wrp {
        background-color: #06486CE6;
    }

    .filters-panel__filters-result-clear-button {
        color: #E0E9EF;
    }

    .filters-panel__filters-result-tab {
        border: 1px solid #2C628D;
        background-color: #042E4B;
        color: #DDDDDD;

        &:not(:last-of-type) {
            border-right: none;
        }

        &.active {
            background: #2C628D;
            color: #F6A83C;
        }
    }

    .filters-panel__selected-filter-arrow {
        color: #E0E9EF;
    }
}

.pre-filters-panel--tablet,
.pre-filters-panel--desktop {
    @extend %G-pre-filters-panel--tablet--desktop;

    background-color: #042E4B;

    .filters-panel__header {
        background: #027992;
    }

    .filters-panel__close-icon {
        color: #E0E9EF;
    }

    .pre-filters-panel__loader {
        background: #042E4B85;
    }

    .filters-panel__item {
        &:not(:last-of-type) {
            border-bottom: 1px solid #0C5982;
        }
    }

    .filters-panel__item-label {
        color: #fff;
    }

    /* ---------------------- Search ---------------------- */
    .sport-search {
        .sport-search__icon {
            color: #E0E9EF;
        }

        .dx-texteditor-input,
        input.dx-texteditor-input:-webkit-autofill {
            color: #fff !important;
            -webkit-text-fill-color: #fff !important;
        }
    }

    .sport-search__sport-item {
        background-color: #06486C;
        border-right: 1px solid #042E4B;
    }

    .sport-search__sport-count {
        background-color: #FFFFFF;
        color: #2C628D;
    }

    .sport-search__sport-item--active {
        background-color: #2C628D;

        .sport-search__sport-count {
            background-color: #F6A83C;
            color: #042E4B;
        }
    }

    /* ---------------------- Filters Date Range Picker ---------------------- */
    .ll-date-range-picker--sport-filters {
        .ll-date-range-picker__picker-box {
            background-color: #06486C;
            color: #fff;
        }

        .ll-date-range-picker__picker-input {
            color: #fff;
        }
    }

    /* ---------------------- Filters ---------------------- */
    .filters-panel__add-filter-button {
        background-color: #027992;
        color: #fff;
    }

    .filters-panel__selected-filter {
        background-color: #35678E;
        color: #F6A83C;
    }

    .filters-panel__selected-filter-arrow {
        color: #E0E9EF;
    }

    .filters-panel__selected-filter-remove-btn {
        border: 1px solid #E0E9EF;
        color: #E0E9EF;
    }

    .filters-panel-menu {
        background-color: #06486C;
        color: #fff;
    }

    .filters-panel-menu__header {
        background-color: #F6A83C;
        color: #3E3E3E;
    }

    .filters-panel-menu__header-close-icon {
        color: #3E3E3E;
    }

    .filters-panel-menu__tabs {
        background-color: #0C5982;
    }

    .filters-panel-menu__tabs-item {
        &:not(:last-child):after {
            background-color: #fff;
        }

        &.active {
            color: #F6A83C;
        }
    }

    .filters-panel-menu__item.selected {
        color: #F6A83C;

        .filters-panel__add-icon {
            border-color: #F6A83C;
            color: #F6A83C;
        }

        .filters-panel-menu__item-count {
            background-color: #F6A83C;
            color: #042E4B;
        }
    }

    .filters-panel-menu__item-count {
        background-color: #027992;
        color: #FFFFFF;
    }

    .filters-panel-menu__item--sub {
        background: #0C5982;

        .filters-panel-menu__item-count {
            background-color: #027992;
            color: #FFFFFF;
        }
    }

    .filters-panel-menu__item--opened {
        background-color: #027992;
        color: #fff;

        .filters-panel-menu__item-count {
            background-color: #FFFFFF;
            color: #027992;
        }
    }

    .filters-panel-menu__submit-btn-wrp {
        background-color: #06486CE6;
    }

    .filters-panel__filters-result-clear-button {
        color: #E0E9EF;
    }

    .filters-panel__filters-result-tab {
        border: 1px solid #2C628D;
        color: #DDDDDD;

        &:not(:last-of-type) {
            border-right: none;
        }

        &.active {
            background: #2C628D;
            color: #F6A83C;
        }
    }
}

.pre-filters-panel--tablet {
    @extend %G-pre-filters-panel--tablet;
}

.pre-filters-panel--desktop {
    @extend %G-pre-filters-panel--desktop;

    .sport-search__sports-list-slider .slider__arrow {
        color: #E0E9EF;
    }
}

.body--with-cursor {
    .pre-filters-panel--tablet,
    .pre-filters-panel--desktop {
        .filters-panel__close-icon:hover {
            color: #fff;
        }

        .sport-search__icon:hover {
            color: #fff;
        }

        .sport-search__sport-item:hover {
            background-color: #2C628D;

            .sport-search__sport-count {
                background-color: #F6A83C;
                color: #042E4B;
            }
        }

        .sport-search__sports-list-slider .slider__arrow:hover {
            color: #fff;
        }

        .ll-date-range-picker--sport-filters {
            .ll-date-range-picker__picker-box:hover {
                background-color: #2C628D;
            }
        }

        .filters-panel__add-filter-button:hover {
            background-color: #F6A83C;
            color: #042E4B;
        }

        .filters-panel__selected-filter-remove-btn:hover {
            border-color: #fff;
            color: #fff;
        }

        .filters-panel__filters-submit-btn:not(.filters-panel__filters-submit-btn--disabled):hover {
            box-shadow: inset 0 0 1px 1px #F6A83C;
            background: #F6D1A380;
            color: #fff;
        }

        .filters-panel-menu__header-close-icon:hover {
            color: #fff;
        }

        .filters-panel-menu__tabs-item:not(.disabled):hover {
            color: #F6A83C;
        }

        .filters-panel-menu__item:not(.filters-panel-menu__item--opened):not(.filters-panel-menu__item-tournament) {
            .filters-panel-menu__item-content:hover {
                color: #F6A83C;

                .filters-panel-menu__item-count {
                    background-color: #F6A83C;
                    color: #042E4B;
                }
            }
        }

        .filters-panel-menu__item-dropdown-arrow:hover,
        .filters-panel-menu__item .filters-panel__add-icon:hover {
            border-color: #F6A83C;
            color: #F6A83C;
        }

        .filters-panel-menu__item:has(.filters-panel__add-icon:hover) {
            color: #F6A83C;

            .filters-panel-menu__item-count {
                background-color: #F6A83C;
                color: #042E4B;
            }
        }

        .filters-panel-menu__item--opened:hover {
            background-color: #005b6d;

            .filters-panel-menu__item-content {
                color: #fff;
            }

            .filters-panel-menu__item-count {
                background-color: #FFFFFF;
                color: #027992;
            }
        }

        .filters-panel__selected-filter--collapsable:hover {
            cursor: pointer;

            .filters-panel__selected-filter-arrow {
                color: #fff;
            }
        }

        .filters-panel__filters-result-clear-button:hover {
            color: #fff;
        }

        .filters-panel__filters-result-tab:hover {
            background: #2C628D;
            color: #F6A83C;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.1. PREMATCH PAGE FILTER TOP PANEL
|--------------------------------------------------------------------------
*/

.prematch__filters--mobile {
    @extend %G-prematch-filters-mobile;
}

/*
|--------------------------------------------------------------------------
| == 3.3.1. LIVE PAGE FILTER TOP PANEL
|--------------------------------------------------------------------------
*/

.live-filters--mobile {
    @extend %G-live-filters--mobile;

    .live-filters__inner {
        background-color: #031E33;
        color: #FFFFFF;
    }

    .live-filters__search {
        border-color: #35678E;
    }

    .live-filters__switcher {
        background-color: #35678E;
    }

    .live-filters__switcher-item {
        color: #fff;
        opacity: .4;

        &.live-filters__switcher-item--active {
            opacity: 1;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.3.2. LIVE SEARCH RESULT DROPDOWN
|--------------------------------------------------------------------------
*/

.sport-search__dropdown--live {
    background: #06486c;
    color: #fff;

    .ll-dropdown-menu-item {
        border-bottom: 1px solid #24272e;
    }
}

.body--with-cursor {
    .sport-search__dropdown--live .ll-dropdown-menu-item:hover {
        color: #f6a83c;
    }
}