/*
3.PAGES
└── 3.7. HOSTED SOLUTION PAGE
    ├── 3.7.1 BET ON GAMES
    ├── 3.7.2 LIVE GAMES
    ├── 3.7.3 LOTTERY
    └── 3.7.4 TVBET
*/

/*
|--------------------------------------------------------------------------
| == 3.7. HOSTED SOLUTION PAGE
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 3.7.1 BET ON GAMES
|--------------------------------------------------------------------------
*/

.bet-games--desktop,
.live-games--desktop,
.tvbet--desktop,
.lottery--desktop,
.bet-games--desktop-empty,
.live-games--desktop-empty,
.tvbet--desktop-empty,
.lottery--desktop-empty {
    position: relative;
}

.bet-games--tablet,
.bet-games--mobile,
.live-games--tablet,
.live-games--mobile,
.lottery--tablet,
.lottery--mobile,
.tvbet--tablet,
.tvbet--mobile {
    flex: 1
}

.live-games-iframe--desktop,
.live-games-iframe--tablet,
.live-games-iframe--mobile,
.bet-games-iframe--desktop,
.bet-games-iframe--tablet,
.bet-games-iframe--mobile,
.lottery-iframe--desktop,
.lottery-iframe--tablet,
.lottery-iframe--mobile,
.tvbet-iframe--desktop,
.tvbet-iframe--tablet,
.tvbet-iframe--mobile {
    width: 100%;
    border: 0;
    display: block;
    overflow: hidden;
}

.live-games-iframe--tablet,
.live-games-iframe--mobile {
    min-height: calc(100vh - 162px);
}

.lottery-iframe--tablet,
.lottery-iframe--mobile {
    min-height: calc(100vh - 162px);
}
.tvbet-iframe--tablet,
.tvbet-iframe--mobile {
    min-height: calc(100vh - 162px);
}


.bet-games-iframe--tablet,
.bet-games-iframe--mobile {
    height: 75vh;
}