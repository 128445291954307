.ll-popup {
    width: 20%;
    min-width: 28rem;
    min-height: 10rem;
    position: fixed;
    right: 2rem;
    z-index: 1709;
    @include flex-config($flex-direction: row);
    border-radius: 3px;
    overflow: hidden;

    &.ll-popup--battle-hidden {
        right: calc(-20% + 3rem);
    }

    &.ll-popup--battle {
        min-width: 34rem;
        z-index: 1710;

        @media (max-width: 1700px) {
            &.ll-popup--battle-hidden {
                right: calc(-34rem + 3rem);
            }
        }
    }

    &.ll-popup--battle-invitations {
        min-height: 6rem;
    }

    &.ll-popup--tablet {
        width: 20rem;
        right: 2rem;

        &.ll-popup--battle-hidden {
            right: -31rem !important;
        }
    }

    &.ll-popup--mobile {
        width: 94%;
        right: 3%;

        &.ll-popup--battle-hidden {
            right: calc(-95% + 3rem) !important;
        }

        @media (max-width: 350px) {
            &.ll-popup--battle {
                min-width: 30rem;
            }
        }
    }

    &.ll-popup--warning {
        .ll-popup__type {
            font-size: 2.2rem;
        }
    }

    &.ll-popup--new .ll-popup__type,
    &.ll-popup--success .ll-popup__type {
        font-size: 1.7rem;
    }

    &.ll-popup--bonus .ll-popup__type {
        font-size: 1.5rem;
    }

    &.ll-popup--info .ll-popup__type {
        font-size: 1.8rem;
    }

    &.ll-popup--battle .ll-popup__type {
        font-size: 2rem;
        transition: all .5s ease-out;

        &.animated {
            transform: scale(1.4);
        }
    }

    .ll-popup__type {
        width: 3rem;
        @include flex-config($justify-content: center, $align-items: center);
    }

    .ll-popup__inner {
        flex: 1;
        backdrop-filter: blur(4px);
        @include flex-config($flex-direction: column);
    }

    &.ll-popup--battle .ll-popup__header {

        .ll-popup__header-text {
            font-size: 1.6rem;
            font-weight: bold;
        }

        .ll-popup__header-icon--battle {
            font-size: 1.4rem;
        }
    }

    .ll-popup__header {
        @include flex-config($flex-direction: row, $align-items: center);
        min-height: 3rem;
    }

    .ll-popup__message {
        margin: 0 1rem 1rem 1rem;
        flex: 1;
        font-size: 1.6rem;
        max-height: 50vh;
        overflow: auto;
        max-width: 100%;
        word-break: break-word;

        a {
            word-break: break-all;
            font-style: italic;

            &:hover {
                opacity: .5;
            }
        }
    }

    .ll-battle-popup__wrapper {
        @include flex-config($align-items: center);
        gap: 1rem;
        height: 100%;

        &.invitation {
            margin: 1rem;

            .ll-popup__content {
                gap: 1rem;
            }

            .game-img {
                width: 8rem;
                height: 11rem;
                border-radius: 0.3rem;
                overflow: hidden;

                .game-img__wrp {
                    min-width: 8rem;
                    min-height: 11rem;
                }
            }

            .ll-popup__content .ll-popup__title .ll-popup__header-text {
                margin-left: 0;
                margin-right: 2rem;
            }

            .slots-battles__btn--accent {
                flex: 0;
                min-width: 8rem;
                font-size: 1.4rem;

                @media screen and (max-width: 380px) {
                    min-width: 6rem;
                }

                &.open-battle__button {
                    min-width: 100%;
                }
            }

            .slots-battles__btn--outlined {
                flex: 1 1;
                font-size: 1.2rem;

                .ll-popover-wrapper {
                    -webkit-line-clamp: 2;
                    line-height: 1.2rem;
                    word-break: break-word;
                }
            }
        }

        .ll-popup__content {
            @include flex-config($flex-direction: column, $justify-content: space-between);
            height: 100%;
            flex: 1 1;

            .ll-popup__title {
                position: relative;

                .ll-popup__header-text {
                    margin: .5rem 1rem;
                    @media screen and (max-width: 380px) {
                        font-size: 1.3rem;
                    }
                }

                .ll-popup__header-close {
                    @include position(absolute, top 0rem right 0rem);
                }
            }

            .ll-popup__battle-info {
                @include flex-config($align-items: flex-start, $justify-content: space-between);
                gap: 1rem;
                font-size: 1.4rem;
                font-weight: 600;

                @media screen and (max-width: 380px) {
                    font-size: 1.2rem;
                    gap: 0.5rem;
                }

                .ll-popup__battle-info-item {
                    position: relative;
                    @include flex-config($align-items: center, $justify-content: center);
                    flex: 1 1;
                    min-width: 6.5rem;

                    &::after {
                        @include position(absolute, top 0.6rem right 0);
                        content: '';
                        display: inline-block;
                        width: 0.1rem;
                        height: 1rem;
                    }

                    &:last-child::after {
                        display: none;
                    }

                    @media screen and (max-width: 380px) {
                        min-width: 4.5rem;
                        font-size: 1rem;
                        &::after {
                            top: 0.3rem;
                            height: 0.8rem;
                        }
                    }

                }

                .ll-popup__icon {
                    font-size: inherit;
                    font-weight: inherit;
                    margin-right: 0.5rem;
                }
            }

            .ll-popup__timer-count {
                display: inline-block;
                min-width: 5rem;
            }
        }
    }

    .ll-popup__message-text {
        margin-bottom: 1rem;

        span {
            font-weight: 600;
        }
    }

    .ll-popup__bottom-message-text {
        font-weight: 500;
        font-size: 1.4rem;
        margin-top: .5rem;
    }

    .ll-popup__invitation {
        @include flex-config($align-items: center, $justify-content: space-between);
        margin-bottom: 1rem;

        .game-img {
            margin-right: 1rem;
            flex: 0;
        }

        @media (max-width: 350px) {
            .game-img {
                margin-right: .5rem;
            }
        }

        .game-img__wrp {
            height: 48px;
            width: 52.5px;
        }
    }

    .ll-popup__invitation_info {
        @include flex-config($align-items: center, $justify-content: space-between, $flex-direction: column);
        flex: 1;

        .ll-popup__invitation_info-part {
            @include flex-config($align-items: center, $justify-content: space-between);
            width: 100%;
            height: 24px;
            font-size: 1.4rem;
        }

        .ll-popup__icon {
            height: 1.4rem;
            width: 1.4rem;
            margin-right: .5rem;
        }

        .ll-popup__invitation_info-game-name {
            width: 100%;
            font-size: 1.4rem;
            font-weight: 600;
            text-align: left;
        }

        .ll-popup__message-enable-invitation {
            font-size: 1.4rem;
        }

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            span {
                display: inline-block;
                width: 43px;
            }
        }

        .ll-popup__invitation-info-part-text {
            display: flex;
            align-items: center;
        }

        .ll-popup__invitation-info-part-border {
            flex: 1;
            height: 1rem;
            display: flex;
            justify-content: center;

            &::after {
                content: '';
                display: inline-block;
                width: .1rem;
            }
        }

        @media (max-width: 350px) {
            .ll-popup__invitation-info-part-text,
            .ll-popup__invitation_info-game-name,
            .ll-popup__message-enable-invitation {
                font-size: 1.2rem;
            }

            span span {
                width: 38px;
            }
        }
    }

    .ll-popup__bottom-buttons {
        @include flex-config($justify-content: space-around);

        & > button:not(:last-child) {
            margin-right: 1rem;
        }

        @media (max-width: 350px) {
            & > button:not(:last-child) {
                margin-right: .8rem;
            }
        }

        & > button {
            width: 50%;
        }

        .ll-btn2 {
            &.slots-battles__btn--outlined,
            &.slots-battles__btn--accent {
                height: 2.8rem;
                font-size: 1.2rem;
                font-weight: 600;
                width: 50%;

                &:hover:before {
                    transform: none;
                }

                .ll-btn__content {
                    padding: .5rem 1rem;
                }
            }

            @media (max-width: 350px) {
                &.slots-battles__btn--outlined,
                &.slots-battles__btn--accent {
                    .ll-btn__content {
                        padding: .5rem;
                    }
                }
            }
        }


        @media screen and (max-width: 375px) {
            .ll-btn2 {
                &.slots-battles__btn--outlined {
                    font-size: 1rem;
                    line-height: 1.1rem;
                }
            }
        }
    }

    .ll-popup__time {
        margin-left: 1rem;
        font-size: 1.2rem;
        margin-bottom: .5rem;
    }

    .ll-popup__timer {
        font-weight: 600;
    }

    .ll-popup__header-text {
        flex: 1;
        margin-left: 1rem;
        font-size: 1.6rem;
        font-weight: bold;
    }

    .ll-popup__header-close {
        height: 100%;
        min-width: 3rem;
        @include flex-config($justify-content: center, $align-items: center);
        cursor: pointer;

        &:hover .ll-popup__close-button-icon {
            zoom: 1.1;
        }
    }

    .ll-popup__header-icon {
        margin-left: 1rem;
    }
}
