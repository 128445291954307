/*
3.PAGES
└── 3.6. MY ACCOUNT PAGE
    ├── 3.6.1 MY INFORMATION
    ├── 3.6.2 PAYMENTS
    ├── 3.6.4 ACCOUNT HISTORY
    ├── 3.6.5 BET HISTORY
    ├── 3.6.6 CASINO REPORT
    ├── 3.6.7 MY LIMITS
    └── 3.6.8 GOOGLE AUTHORIZATION
*/

/*
|--------------------------------------------------------------------------
|  == 3.6. MY ACCOUNT PAGE
|--------------------------------------------------------------------------
*/

.my-account--desktop,
.my-account--tablet,
.my-account--mobile {
    background: #031E33;

    @extend %G-my-account;

    .bet-delete-icon {
        color: #757575;
    }
}

.my-account--tablet,
.my-account--mobile {
    @extend %G-my-account--mobile;

    .my-account-section {
        border: 1px solid #162136;
    }

    .my-account-section__title {
        background: #06486C;
        color: #fff;
    }

    .my-account-section--secondary {
        .my-account-section__title {
            background: #0C5982;
        }
    }
}

.my-account__title--mobile {
    @extend %G-my-account__title--mobile;

    background: #267188;
    color: #fff;

    .my-account__title-back-icon {
        color: #fff;
    }
}

.my-account--tablet,
.my-account--desktop {
    @extend %G-my-account--tablet-desktop;
}

.my-account--tablet {
    @extend %G-my-account--tablet;

    .my-account__title {
        background: #042E4B;
        color: #F6A83C;
    }
}

.my-account--desktop {
    @extend %G-my-account--desktop;
    background: transparent;

    .account-menu_link-icon {
        fill: rgba(0, 0, 0, 0.65);
    }

    .my-account__title {
        background: #042E4B;
        color: #F6A83C;
    }
}

.my-account-drawer {
    @extend %G-my-account-drawer;

    .my-account-drawer__blocked-user {
        background: #042E4B;
        color: #F6A83C;
    }

    .my-account-drawer__menu-list {
        background: #042E4B;
    }

    .my-account-drawer__menu-link {
        color: #fff;

        &.active {
            color: #F6A83C;
        }
    }
}

.my-account-info-list {
    @extend %G-my-account-info-list;

    .my-account-info-list__item {
        color: #fff;
        background: #0C5982;

        &:nth-child(2n) {
            background: #06486C;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid #202D46;
        }
    }

    .my-account-info-list__item-value--negative {
        color: #f16969;
    }
}

.body--with-cursor {
    .bet-delete-icon:hover {
        color: #000;
    }

    .my-account-drawer__close-btn:hover {
        color: #fff;
    }

    .my-account-drawer__menu-link:hover {
        color: #F6A83C;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.1 MY ACCOUNT PAGE -> MY INFORMATION
|--------------------------------------------------------------------------
*/

.my-account--tablet,
.my-account--mobile {
    .my-info {
        @extend %G-my-info--mobile;

        .my-info__change-pass {
            color: #F6A83C;
        }
    }
}

.my-info__wrapper--tablet,
.my-info__wrapper--mobile {
    @extend %G-my-info__wrapper--mobile;
}

.my-info__wrapper--tablet {
    @extend %G-my-info__wrapper--tablet;
}

.my-info__wrapper--desktop {
    @extend %G-my-info__wrapper--desktop;

    .my-info__coll {
        background: #E6F0F6;
        color: #000;
    }

    .my-info__sub-title {
        background: #497A9A;
        color: #fff;
    }

    .my-info__row {
        border-bottom: 1px solid #042E4B;

        &:last-of-type {
            border-bottom: none;
        }
    }
}

.body--with-cursor {
    .my-info__cell-link:hover {
        text-shadow: 0 0 7px #F6A83C;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.2 PAYMENTS
|--------------------------------------------------------------------------
*/
.payments {
    @extend %G-payments;

    color: #fff;

    .payments__icon {
        color: #F6A83C;
    }

    .payment__btn-link-text {
        color: #06486c;
    }
    .payment__btn-link-name {
        color: #000;
    }
}

.payments__content--mobile,
.payments__content--desktop {
    @extend %G-payments-btn-link;

    .payment {
        box-shadow: 3px 2px 5px #00000038;
        background-color: #fff;
    }
}

.payments__content--mobile {
    .payment {
        border: 1px solid #00000038;
    }
}

.payments__content--desktop {
    .payments__no-data {
        background: #267188;
        color: #fff;
    }

    .payment {
        border: 1px solid #00000038;
    }
}

.payment-modal {
    @extend %G-payment-modal;
}

/*
|--------------------------------------------------------------------------
| == 3.6.4 ACCOUNT HISTORY
|--------------------------------------------------------------------------
*/

.my-account--mobile .account-history {
    .my-account-info-list {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        overflow: hidden;
    }
}

.account-report--mobile {
    @extend %G-account-report--mobile;

    border: 1px solid #162136;
    background: #06486C;
    color: #fff;

    .account-report-item--active:not(:last-child),
    .account-report-item:not(:last-child) {
        border-right: 1px solid #162136;
    }

    .account-report-item--active {
        background: #042E4B;
        color: #F6A83C;
    }

    .ll-burger-icon--opened {
        div {
            background-color: #F6A83C;
        }
    }

    & + .my-account-info-list {
        border: 1px solid #162136;
        border-top: none;
    }
}

.account-report--tablet,
.account-report--desktop {
    @extend %G-account-report--desktop;

    .account-report-item {
        background: #E6F0F6;
        color: #000;
    }

    .my-account-info-item__content--overflow {
        background: #E6F0F6;

        &.expanded {
            box-shadow: 0 2px 8px 0px #0000002b;
        }
    }

    .my-account-info-item__expand-panel--icon {
        color: #000;
    }

    .my-account-info-item__title {
        background: #267188;
        color: #fff;
    }

    .my-account-info-list__item {
        border-bottom: 1px solid #042E4B;
    }

    .my-account-info-list__item-value--negative {
        color: #f58c89;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.5 BET HISTORY
|--------------------------------------------------------------------------
*/
.bet-history--mobile,
.bet-history--tablet {
    @extend %G-bet-history--mobile-tablet;

    .status-filter-switcher-switch-base {
        color: #fff !important;
    }

    .bet-history__status-active-bets--disabled,
    .bet-history__status-filter--disabled {
        color: #C5C5C5;

        .ll-dropdown-menu__icon {
            color: #C5C5C5;
        }
    }

    .status-filter-switcher-track {
        background-color: #000 !important;
        opacity: 0.38!important;
    }

    .bet-history__status-filter {
        color: #F6A83C;

        .bet-history__status-filter-dropdown {
            background: #497A9A;
            border: 1px solid #042E4B;

            .ll-dropdown-menu-item,
            .ll-dropdown-menu-item--disabled,
            .ll-dropdown-menu-item--active {
                background: #0C5982;
                color: #fff;

                &:not(:last-of-type) {
                    border-bottom: 1px solid #162136;
                }
            }

            .ll-dropdown-menu-item--disabled {
                color: #979797;
            }

            .ll-dropdown-menu-item--active {
                background: #06486C;
            }
        }
    }
}

.bet-history--tablet {
    @extend %G-bet-history--tablet;
}

.bet-history--desktop {
    @extend %G-bet-history--desktop;

    .bet-history__cashout-cell-btn {
        background: #F6A83C;
        color: #000;
    }

    /* ---------------------- Bet details in table ---------------------- */

    .bet-history-detail__info {
        background-color: #FFFFFF;
        color: #000;
    }

    .bet-history-detail__info-row {
        border: 1px solid #e0e0e0;
        border-top: none;
    }

    .bet-history-detail__info-td {
        &:not(:last-child)::after {
            background-color: #e0e0e0;
        }
    }
}

.body--with-cursor {
    .bet-history__cashout-cell-btn:hover {
        background: #F0871A;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.6 CASINO REPORT
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 3.6.7 MY LIMITS
|--------------------------------------------------------------------------
*/

.my-limits {
    @extend %G-my-limits;

    .my-limits__provider-limit-info-icon {
        color: #f6a83c;
    }
}

.my-limits--mobile,
.my-limits--tablet {
    @extend %G-my-limits--mobile-table;

    .my-limits__content-box--rows .my-limits__content-box-title {
        background-color: #06486c;
        color: #fff;
    }

    .my-limits__content-box--info .my-limits__content-box-title {
        color: #f6a83c;
    }

    .my-limits__content-box-wrp {
        color: #fff;
    }

    .my-limits__content-row {
        &:nth-child(2n + 1) {
            background-color: #0c5982;
        }

        &:nth-child(2n) {
            background-color: #06486c;
        }
    }
}

.my-limits--desktop {
    @extend %G-my-limits--desktop;

    .my-limits__content-box {
        box-shadow: 0 0 5px 0 #00000020;
    }

    .my-limits__content-box-title {
        background: #497a9a;
        color: #fff;
    }

    .my-limits__content-box-wrp {
        background-color: #e6f0f6;
        color: #000;
    }

    .my-limits__content-row {
        &:not(:last-child) {
            border-bottom: 1px solid #042e4b;
        }

        & > * {
            &:first-child {
                border-right: 1px solid #042e4b;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.8 MY ACCOUNT PAGE -> GOOGLE AUTHORIZATION
|--------------------------------------------------------------------------
*/
.google-auth {
    @extend %G-google-auth;

    .google-auth__content {
        border: 1px solid #267188;
        background: #06486C;
        color: #fff;
    }

    .google-auth__img-qr {
        @include size(
            $size-width--cabinet-google-auth-img + px,
            $size-height--cabinet-google-auth-img + px
        );
    }

    .google-auth__qr-img-container {
        border-bottom: 1px solid #267188;
    }

    .google-auth__install-app {
        border-bottom: 1px solid #267188;
    }

    .google-auth__form-password,
    .google-auth__form-password--mobile {
        @extend %G-google-auth__form-password;
    }

    .google-auth__form-password--mobile {
        @extend %G-google-auth__form-password--mobile;
    }

    .google-auth__form-qr-code,
    .google-auth__form-qr-code--mobile {
        @extend %G-google-auth__form-qr-code;
    }

    .google-auth__form-qr-code--mobile {
        @extend %G-google-auth__form-qr-code--mobile;
    }
}

.my-account--mobile .google-auth {
    @extend %G-google-auth--mobile;
}

/*
|--------------------------------------------------------------------------
| == 3.6.9 MY ACCOUNT PAGE -> MESSAGES PAGE
|--------------------------------------------------------------------------
*/

.messages {
    @extend %G-messages;

    .message-item {
        background-color: #e6f0f6;
        color: #000;
    }

    .message-date {
        color: #757575;
    }

    .message-unread {
        background-color: #497a9a;

        .message-text {
            color: #fff;
        }

        .message-date {
            color: rgb(151, 151, 151);
        }
    }
}

.messages--mobile,
.messages--tablet {
    @extend %G-messages--mobile;
}

.messages--desktop {
    @extend %G-messages--desktop;
}