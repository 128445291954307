/*
1. COUPONS
├── 1.1. COUPONS COMMON ELEMENTS
│   ├── 1.1.1 COUPONS MODALS
│   └── 1.1.2 COUPONS LIVE INDICATOR
├── 2. COUPONS
│   ├── 2.1. COUPONS MOBILE/TABLET
│   └── 2.2. COUPONS DESKTOP
└── 3. SPORT PAGE RIGHT PANEL DESKTOP
    └── 3.1 DESKTOP RIGHT PANEL TABS
*/

/*
|--------------------------------------------------------------------------
| == 1.1. COUPONS COMMON ELEMENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 3.1.1 COUPONS MODALS
|--------------------------------------------------------------------------
*/

/* ---------------------- Clear coupons modal ---------------------- */
%G-coupons-clear-modal {
    @include flex-config($flex-direction: column, $align-items: center, $justify-content: space-evenly);
    min-width: 30rem;
    min-height: 15rem;
    padding: 0 5rem 1rem 5rem;

    .coupons-clear-modal__btns-container {
        @include flex-config($justify-content: space-between, $align-items: center);
        width: 100%;
    }

    .coupons-clear-modal__btn {
        min-width: 8rem;
    }
}

/* ---------------------- Success coupons modal ---------------------- */

%G-success-bet-modal {
    .success-bet-modal {
        @include flex-config($flex-direction: column, $justify-content: center, $align-items: center);
        padding: .5rem;
        width: 100%;
    }

    .coupons__live-indicator {
        margin-left: .5rem;
    }

    .success-bet-modal__canvas {
        position: absolute;
        top: 0;
        right: -150vw;
    }

    .success-bet-modal__selected-systems {
        width: 90%;
        overflow-wrap: anywhere;
        overflow: hidden;
        text-align: center;
        padding: 1rem;
    }

    .success-bet-modal__heading {
        font-size: 1.6rem;
        text-align: center;
        text-transform: uppercase;
    }

    .coupons__box-scroll-container {
        width: 100%;
    }

    .coupons__box {
        width: 100%;
        padding: .5rem;
        font-size: 1.4rem;
    }

    .ll-coupon__name-with-odd--full {
        display: flex;
        justify-content: space-between;
    }

    .success-bet-modal__block-double {
        @include flex-config($justify-content: center, $align-items: center);
        width: 100%;
    }

    .success-bet-modal__block {
        @include flex-config($justify-content: center, $align-items: center);
        flex: 1;
        width: 100%;
        padding: 1rem;
        font-size: 1.2rem;
        font-weight: 500;
    }

    .success-bet-modal__value {
        margin-left: .5rem;
    }

    .success-bet-modal__currency {
        margin-left: 0.5rem;
    }

    .success-bet-modal__buttons {
        margin: 2rem 0 1rem 0;
        width: 100%;
        @include flex-config($justify-content: space-around, $align-items: center);
    }

    .success-bet-modal__submit-btn {
        min-width: 10rem;
    }

    .success-bet-modal__print-btn {
        min-width: 5rem;
    }

    .coupons__box--custom {
        .coupons__match-name {
            font-weight: bold;
        }

        .coupons__match-odd-info__with-dots {
            position: relative;
            padding-left: 2rem;
            overflow: hidden;
            @include flex-config($justify-content: space-between, $align-items: center);

            &:before {
                content: '';
                width: 5px;
                height: 5px;
                border-radius: 5px;
                border: 1px solid;
                display: block;
                position: absolute;
                left: .2rem;
                top: .7rem;
                z-index: 2;
            }

            &:after {
                z-index: 1;
                content: '';
                width: 1px;
                left: .5rem;
                display: block;
                position: absolute;
                height: 100%;
            }
        }

        .coupons__match-odd-info__with-dots:first-child {
            &:after {
                transform: translateY(50%);
            }
        }

        .coupons__match-odd-info__with-dots:last-child {
            &:after {
                transform: translateY(-50%);
            }
        }
    }
}

%G-success-bet-modal--mobile {
    width: 100%;
    max-width: 100vw;
    @include position(fixed, left 0 bottom 0);
    top: auto;
    transform: translate(0, 0);

    .ll-modal__header {
        border-radius: 0;
    }

    .coupons__live-indicator {
        margin-left: .5rem;
    }

    .success-bet-modal__block {
        width: 100%;
    }
}

%G-success-bet-modal--tablet {
    min-width: 60%;

    .ll-modal__scrollbox--tablet {
        width: 100%;
        @include flex-config($justify-content: center);
    }

    .success-bet-modal {
        max-width: 30rem;
        min-width: 30rem;
        padding: 1rem;
    }

    .success-bet-modal__block {
        width: 100%;
    }
}

%G-success-bet-modal--desktop {
    .ll-modal__scrollbox--desktop {
        border-radius: 0 0 3px 3px;
    }

    .success-bet-modal {
        max-width: 50rem;
        min-width: 50rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2 COUPONS LIVE INDICATOR
|--------------------------------------------------------------------------
*/
%G-coupons__live-indicator {
    margin: 0 .5rem 0 0;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

/*
|--------------------------------------------------------------------------
| == 2. COUPONS
|--------------------------------------------------------------------------
*/

%G-coupons {
    .coupons__view-tabs {
        @include flex-config($justify-content: space-around, $align-items: center);
        @include size(100%, 3.2rem);
    }

    .coupons__view-tab {
        position: relative;
        flex: 1;
        padding: 0 2rem;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 500;
        cursor: pointer;

        &:not(:last-of-type) {
            &:after {
                content: '';
                @include position(absolute, right 0 top 50%);
                @include size(.1rem, 1rem);
                transform: translateY(-50%);
            }
        }

        &.disabled {
            cursor: not-allowed;
        }
    }

    .coupons__settings {
        @include flex-config($justify-content: center, $align-items: center, $flex-direction: column, $gap: 1rem);
        padding: 2rem 1rem;

        .coupons__amount-limit {
            @include flex-config($justify-content: flex-start, $align-items: center);
            width: 100%;
            font-size: 1.2rem;

            .coupons__amount-limit-icon {
                margin-right: .3rem;
            }
        }

        .coupons__amount-preset {
            @include flex-config($align-items: center, $justify-content: space-between, $gap: 1rem);
            @include size(100%, 4rem);

            .coupons__amount-preset-btn {
                flex: 1;
                font-size: 1.2rem;

                .ll-btn__content {
                    padding: 0;
                }
            }
        }

        .coupons__detete-btn,
        .coupons__amount-preset-edit {
            @include flex-config($align-items: center, $justify-content: center);
            @include size(4rem);
            max-width: 4rem;

            .ll-btn__content {
                padding: 0 !important;
            }
        }

        .coupons__amount-preset-editor {
            @include flex-config($justify-content: center, $align-items: center, $flex-direction: column, $gap: 1rem);
            width: 100%;
            margin: 1rem 0;

            .amount-preset-editor__item {
                @include flex-config($align-items: center, $justify-content: center, $gap: 1rem);
                @include size(100%, 4rem);
                padding: .5rem;
                border-radius: 3px;

                .dx-texteditor-input {
                    flex: 1;
                    text-align: right;
                    font-size: 1.4rem;
                }
            }

            .amount-preset-editor__item-label {
                flex: 1;
                white-space: nowrap;
            }

            .amount-preset-editor__item-input {
                height: 4rem;
            }

            .amount-preset-editor__btn {
                width: 100%;
            }
        }

        .coupons__bet-system-picker {
            @include flex-config($align-items: center, $justify-content: space-between);
            width: 100%;
            position: relative;
            padding: 1px;
            cursor: pointer;
            border-radius: 3px;

            .ll-dropdown-menu__icon,
            .ll-dropdown-menu__icon--opened {
                font-size: 2rem;
                transition: transform .2s ease-in-out;
            }

            .ll-dropdown-menu__icon--opened {
                transform: rotate(180deg);
            }
        }

        .coupons__bet-system-picker-button {
            @include flex-config($align-items: center, $justify-content: space-between);
            @include size(100%, 4rem);
            padding: 0 .8rem;
            user-select: none;
            border: none;
            background: none;
            font-family: inherit;
            cursor: pointer;

            span {
                font-weight: 500;
            }
        }

        .coupons__bet-system-picker-button-text {
            margin-left: auto;
        }

        .coupons__bet-system-dropdown-menu {
            width: 100%;
            border-radius: 3px;
        }

        .coupons__bet-system-picker-item {
            padding: .8rem 1.5rem;
            font-size: 1.4rem;
            cursor: pointer;
            font-weight: 500;
        }

        .coupons__submit-container {
            @include flex-config($justify-content: space-between, $align-items: center, $gap: 1rem);
            min-height: 4rem;
            width: 100%;

            .coupons__submit-wrp {
                flex: 1;
            }

            .coupons__accept-btn {
                width: 100%;
                box-shadow: none;
            }
        }

        .coupons__total-row--total,
        .coupons__amount-row--total {
            @include flex-config($justify-content: space-between, $align-items: center);
            width: 100%;
            font-weight: 500;
            font-size: 1.4rem;
        }

        .coupons__checkbox {
            @include flex-config($justify-content: flex-start, $align-items: center);
            @include size(100%, 4.6rem);

            .dx-checkbox-text {
                font-weight: 500;
            }

            .coupons__checkbox-input {
                font-size: .9rem !important;

                .dx-checkbox-icon {
                    @include size(1rem);
                    margin-right: 1rem;
                }
            }
        }
    }

    .coupons__amount {
        @include flex-config($justify-content: space-between, $align-items: center, $flex-direction: column);
        width: 100%;
        border-radius: 3px;

        &:not(.coupons__amount--multi) {
            min-height: 7.4rem;
            font-size: 1.4rem;
            font-weight: 500;

            .coupons__amount-wrapper {
                margin-bottom: 1rem;
            }
        }

        .coupons__amount-wrapper {
            @include flex-config($align-items: center, $justify-content: space-evenly);
            @include size(100%, 4rem);
            position: relative;
            padding: 0 .5rem;
            border-radius: 3px;

            .ll-input__textbox .dx-texteditor-input {
                flex: 1;
                font-size: 1.4rem;
                text-align: right;
            }
        }

        .coupons__amount-label {
            white-space: nowrap;
        }

        .coupons__amount-input--dummy {
            width: calc(100% - 14rem);
            height: 100%;
            @include position(absolute, top 0 right 0, $z-index: 1);
            cursor: text;
        }

        .dx-editor-outlined,
        .dx-editor-outlined:hover,
        .dx-editor-outlined:active {
            box-shadow: none !important;
        }

        input {
            padding: 9px 6px 10px !important;
        }
    }

    .coupons-event-settings {
        @include flex-config($justify-content: space-between);
        gap: 1rem;
        margin-top: .7rem;

        .coupons__amount {
            position: relative;
            font-size: 1.2rem;

            .ll-input__textbox .dx-texteditor-input {
                padding: 10px 6px !important;
                text-align: left;
            }
        }

        .coupons__amount-label--multi {
            @include position(absolute, top 0 left 1rem);
            transform: translateY(-50%);
            padding: .2rem;
        }
    }
}

%G-coupons-item {
    .coupons-list__event {
        @include flex-config($justify-content: space-between, $gap: 1rem);
        position: relative;
        min-height: 8rem;
        padding: 0 1rem 0 0;
        font-size: 1.4rem;
        overflow: hidden;

        &.coupons-list__event--custom {
            height: auto;
        }
    }

    .coupons-list__events-list {
        flex: 1;
        @include flex-config($justify-content: center, $flex-direction: column);
    }

    .coupons-list__event-part {
        @include flex-config($justify-content: center, $align-items: flex-start, $flex-direction: column);
        z-index: 1;
    }

    .coupons-list__event-part--main {
        flex: 1;
        padding: 1rem 0;
    }

    .coupons-list__event-date {
        margin-bottom: .5rem;
        font-size: 1.2rem;
        font-weight: 500;
    }

    .coupons-list__event-background {
        @include position(absolute, top 0 left 0);
        @include flex-config($justify-content: center, $align-items: center);
        opacity: .35;
        height: 8rem;
        width: 8rem;
        border-radius: 50%;
    }

    .coupons-list__event-background-icon {
        font-size: 8.1rem !important;
        line-height: 1;
        width: 8.1rem;
    }

    .coupons-list__event--even {
        .coupons-list__event-background {
            left: auto;
            right: -3rem;
        }
    }

    .only-single-error {
        font-weight: 500;
    }

    .only-single-error__icon-container,
    .coupons-list__event-remove-btn {
        @include flex-config($justify-content: center, $align-items: center, $flex-direction: column);
        width: 3rem;
        flex: 1;
        border: none;
        cursor: pointer;
        font-size: 1.2rem;
        transition: color ease .2s;
    }

    .only-single-error__icon-container {
        cursor: default;
    }

    .coupons-list__event-remove-btn {
        .only-single-error__icon {
            margin-top: 1rem;
        }
    }

    .only-single-error__icon {
        font-size: 1.4rem;
    }

    .coupons-list__event-info {
        @include flex-config($justify-content: space-between, $align-items: center);
        max-width: 100%;
        width: 100%;
        line-height: 1.2;

        .team-name-holder {
            max-width: 100%;
        }
    }

    .coupons__event-value,
    .event-info__match-name,
    .event-info__selected,
    .coupons-list__event-type {
        font-weight: 500;
    }

    .event-info__selected,
    .coupons-list__event-type {
        margin-bottom: .1rem;
    }

    .event-info__match-name .team-name-holder {
        white-space: normal;
    }

    .event-info__match-name,
    .event-info__selected,
    .coupons-list__event-type {
        line-height: 1.2;
        min-height: 2rem;
        max-width: 100%;
        width: 100%;
    }

    .event-info__selected {
        @include flex-config($justify-content: space-between, $align-items: flex-start);
    }

    .event-info__selected-name {
        flex: 1;
    }

    .coupons__event-value {
        margin-left: .5rem;
        font-weight: bold;
    }

    .coupons-list__event--custom {
        .event-info__selected {
            font-weight: bold;
        }

        .event-info__details-title {
            font-weight: bold;
        }

        .event-info__custom-bets {
            max-width: 100%;
            width: 100%;
        }

        .event-info__custom-bet-selection {
            padding: .5rem .5rem .5rem 0;
            line-height: 1.2;
        }

        .event-info__custom-bet {
            display: flex;
            align-items: center;
            @include flex-config($justify-content: space-between, $align-items: center);
        }

        .event-info__custom-bet--with-dots {
            position: relative;
            padding-left: 2rem;
            overflow: hidden;
            height: fit-content;

            &:before {
                content: '';
                width: 5px;
                height: 5px;
                border-radius: 5px;
                border: 1px solid;
                @include flex-config($align-items: center);
                position: absolute;
                left: .2rem;
                z-index: 2;
            }

            &:after {
                z-index: 1;
                content: '';
                width: 1px;
                left: .5rem;
                display: block;
                position: absolute;
                height: 100%;
            }
        }

        .event-info__custom-bet--with-dots:first-child {
            &:after {
                transform: translateY(50%);
            }
        }

        .event-info__custom-bet--with-dots:last-child {
            &:after {
                transform: translateY(-50%);
            }
        }

        .event-info__custom-bet-selection-delete {
            cursor: pointer;
            @include flex-config($justify-content: center, $align-items: center);
        }

        .event-info__custom-bet-selection-delete-icon {
            font-size: 1.6rem;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.1. COUPONS MOBILE/TABLET
|--------------------------------------------------------------------------
*/

%G-coupons--mobile--tablet {
    width: 100%;
    @include position(fixed, left 0, $z-index: 10);

    &.coupons--open {
        z-index: 110;
    }

    .coupons__container-top--whide {
        width: 100%;
        padding: 0 1rem;
        @include flex-config($justify-content: space-between);
    }

    .coupons__col {
        @include flex-config($align-items: center);
        font-size: 1.2rem;

        &:nth-last-of-type(1) {
            margin-right: 0;
        }
    }

    .coupons__bet {
        @include flex-config($align-items: center);
        flex-grow: 1;
        margin-right: 1rem;
    }

    .coupons__bet-value {
        margin-right: 1rem;
        padding: 0.1rem 0.7rem 0.2rem;
        border-radius: 50%;
        font-weight: 600;
    }

    .coupons__bet-counters {
        @include flex-config($justify-content: space-between, $align-items: center);
        height: 4rem;
    }

    .coupons__bet-counters-text {
        font-size: 1.4rem;
        font-weight: 500;
    }

    .coupons__bet-icon--open,
    .coupons__bet-icon--close {
        @include flex-config($justify-content: center, $align-items: center);
        align-self: stretch;

        .coupons__close-icon {
            padding: .4rem;
            border-radius: 50%;
            vertical-align: middle;
            transition: 0.3s ease-in-out;

            &::before {
                font-size: 1.3rem;
            }
        }
    }

    .coupons__bet-icon--close {
        .coupons__close-icon {
            transform: rotate(90deg);
        }
    }

    .coupons__bet-icon--open {
        .coupons__close-icon {
            transform: rotate(-90deg);
        }
    }

    .coupons__loading {
        @include flex-config($align-items: center, $justify-content: center);
        padding: 1rem 0 3rem;
        width: 100%;
    }

    .coupons__scrollbox {
        max-height: calc(100vh - 5rem - 9rem - 6rem);
        @supports (-webkit-touch-callout: none) {
            max-height: calc(100vh - 5rem - 9rem - 6rem - 7rem);
            max-height: calc(100svh - 5rem - 9rem - 6rem);
        }

        &.coupons__scrollbox--closed {
            max-height: 0;
        }

        &.coupons__scrollbox--open {
            overflow: auto;
        }
    }

    .coupons__view-tabs {
        height: 4rem;
        border: none;
    }

    .coupons__view-tab {
        @include flex-config($justify-content: center, $align-items: center);
        font-size: 1.4rem;
        height: 100%;

        &:not(:last-of-type) {
            &:after {
                content: '';
                @include position(absolute, right 0 top 50%);
                @include size(.1rem, 4rem);
                transform: translateY(-50%);
            }
        }
    }

    .coupons-list__event {
        margin-bottom: .1rem;
    }

    .only-single-error__icon-container,
    .coupons-list__event-remove-btn {
        width: 4rem;
    }

    .event-info__custom-bet-selection-delete-icon {
        padding-right: 1rem;
    }

    .coupons__custom-warning {
        padding: 1.2rem;
        text-align: center;
    }

    .ll-btn--accent,
    .coupons__amount-preset-btn {
        height: 4rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.2. COUPONS DESKTOP
|--------------------------------------------------------------------------
*/

%G-coupons--desktop {
    position: relative;

    .coupons__loader {
        @include flex-config($align-items: center, $justify-content: center);
        @include size(100%);
        @include position(absolute, top 0 left 0, $z-index: 2);
        text-align: center;
        transition: 0.2s height;
    }

    .prematch__coupons--empty {
        height: 3.2rem;
        @include flex-config($justify-content: center, $align-items: center);
        margin-bottom: .3rem;
        text-align: center;
    }

    .coupons__custom-warning {
        padding: 1.2rem;
        text-align: center;
    }

    .coupons-list {
        margin-top: .3rem;
    }

    .coupons-list__event {
        margin-bottom: .3rem;
        border-radius: 3px;
    }

    .coupons__settings {
        margin-bottom: .3rem;
        border-radius: 3px;

        .coupons__amount-preset-editor {
            .amount-preset-editor__item {
                height: 3.2rem;
            }

            .amount-preset-editor__item-input {
                height: 3.2rem;
            }
        }

        .coupons__bet-system-picker-button {
            height: 3.2rem;
        }
    }

    .coupons__amount {
        .coupons__amount-wrapper {
            height: 3.2rem;
        }
    }

    .ll-btn--accent,
    .coupons__amount-preset-btn {
        height: 3.2rem;
    }

    .coupons__amount-preset {
        height: 3.2rem;
    }

    .coupons__detete-btn,
    .coupons__amount-preset-edit {
        height: 3.2rem !important;
        max-width: 3.2rem !important;
    }

    .coupons__submit-container {
        min-height: 3.2rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 3. SPORT PAGE RIGHT PANEL DESKTOP
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 3.1 DESKTOP RIGHT PANEL TABS
|--------------------------------------------------------------------------
*/

%G-coupons-tabs {
    height: 100%;

    .coupon-tabs__header {
        @include flex-config();
        border-radius: 3px 3px 0 0;
        overflow: hidden;
    }

    .coupon-tabs__header-tab {
        @include flex-config($align-items: center, $justify-content: center);
        flex: 1;
        padding: .7rem 1rem;
        text-transform: uppercase;

        transition: all .2s ease-in;

        &.coupon-tabs__header-tab--btn {
            cursor: pointer;
        }
    }

    .coupon-tabs__header-icon {
        margin-right: 0.5rem;
        position: relative;
    }

    .coupon-tabs__header-icon-count {
        @include flex-config($align-items: center, $justify-content: center);
        @include position(absolute, top .7rem left -.5rem);
        @include size(1.2rem);
        border-radius: 50px;
        font-size: 1rem;
        transition: all .5s ease-out;

        &.animated {
            transform: scale(1.5);
        }
    }

    .coupon-tabs__content {
        height: calc(100% - 3.4rem);

        &.coupon-tabs__content--bets {
            margin-top: .1rem;
            height: calc(100% - 3.4rem);
        }
    }

    .coupon-tabs__content-item {
        height: 100%;
        @include flex-config($flex-direction: column);
    }
}

%G-coupons-tabs--live {
    height: calc(100% - 3.4rem);

    .coupon-tabs__content {
        height: calc(100% - 3.4rem);

        &.coupon-tabs__content--bets {
            margin-top: .1rem;
            height: calc(100% - 3.4rem - 2.7rem);
        }
    }
}