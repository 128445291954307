/*
|--------------------------------------------------------------------------
| DATEBOX
|--------------------------------------------------------------------------
*/

.dx-datebox-wrapper-rollers {
    .dx-overlay-content {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }

    .dx-popup-content {
        padding: 20px 0;
    }
}

input:focus,
input:-webkit-autofill:focus,
input:-webkit-autofill {
    //caret-color: $color-webkit-input-app-caret-text;
    //color: $color-standard-input !important;
    transition: background-color 50000000s ease-in-out 0s;
    //-webkit-text-fill-color: $color-webkit-input-app-text;
    //-webkit-box-shadow: 0 0 0 30px $color-webkit-input-app-box-shadow inset;
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
    white-space: normal;
    padding: 0;
}

.dx-button.dx-state-disabled {
    opacity: .3;
}

.dx-dropdowneditor-overlay {
    z-index: 1601 !important;
}

.dx-texteditor.dx-editor-filled.dx-state-hover,
.dx-texteditor.dx-editor-outlined.dx-state-hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: none;
}

.dx-texteditor.dx-editor-filled.dx-state-hover::after,
.dx-texteditor.dx-editor-filled::after,
.dx-texteditor.dx-state-active::before,
.dx-texteditor.dx-state-focused::before {
    border-bottom: none!important;
}

.dx-dropdowneditor-input-wrapper {
    width: 100%;
}