/*
|--------------------------------------------------------------------------
| SCSS mixins for basic needs
|--------------------------------------------------------------------------
*/

/// == @example mq
///   .some-element {
///     @include mq('tablet') {
///     width: 90%;
///  }
/// or
///   .some-element {
///     @include mq('(min-width: 1367px)') {
///     width: 90%;
///  }

@mixin mq($size) {
    $desktop: '(min-width: 1366px)';//todo: изменить потом
    // $tablet: '(min-width: 768px) and (max-width: 1023px)';
    $tablet: '(max-width: 1365px)';//todo: изменить потом
    $mobile: '(max-width: 767px)';
    @if $size == desktop {
        @media only screen and #{$desktop} {
            @content;
        }
    } @else if $size == tablet {
        @media only screen and #{$tablet} {
            @content;
        }
    } @else if $size == mobile {
        @media only screen and #{$mobile} {
            @content;
        }
    } @else {
        @media only screen and #{$size} {
            @content;
        }
    }
}

///  == @example position
///   .some-element {
///    @include position(absolute, top 100% left 0, $z-index: 2)
///   }

@mixin position($position, $args, $z-index: false) {
    @each $o in top right bottom left {
        $i: index($args, $o);
        @if $i and
            $i +
            1 <=
            length($args) and
            type-of(nth($args, $i + 1)) ==
            number
        {
            #{$o}: nth($args, $i + 1);
        }
    }
    position: $position;

    @if $z-index != false {
        z-index: $z-index;
    }
}

///  == @example size
///   .some-element {
///     @include size(auto, 10em);
///   }
///  Will give 100% height and width
///   .some-element {
///     @include size(100%);
///   }
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

///  == @example flex-config
///  .some-element {
///    @include flex-config($flex-direction: row, $align-items: center, $justify-content: center);
///  }

@mixin flex-config(
    $display: flex,
    $justify-content: false,
    $flex: false,
    $flex-direction: false,
    $align-items: false,
    $align-content: false,
    $flex-wrap: false,
    $gap: false,
) {
    display: $display;

    @if $justify-content != false {
        justify-content: $justify-content;
    }

    @if $flex != false {
        flex: $flex;
    }

    @if $flex-direction != false {
        flex-direction: $flex-direction;
    }

    @if $align-items != false {
        align-items: $align-items;
    }

    @if $align-content != false {
        align-content: $align-content;
    }

    @if $flex-wrap != false {
        flex-wrap: $flex-wrap;
    }

    @if $gap != false {
        gap: $gap;
    }
}
