/*
3.PAGES
└── 3.10. HELP PAGE
*/

%G-help {
    @include flex-config($align-items: center, $flex-direction: column);
    max-width: 1300px;
    margin: auto;
    padding: 1.6rem;

    .help__header {
        margin: 3.6rem 0 1rem;
        font-size: 3.5rem;
        font-weight: 400;
    }

    .help__description {
        font-size: 1.8rem;
        margin: 2rem 0;
        text-align: center;
    }

    .help__tiles-container {
        @include flex-config($align-items: center, $justify-content: center);
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    .help__tile {
        @include size(301px, 447px);
        @include flex-config(
            $align-items: center,
            $flex-direction: column,
            $justify-content: center
        );
        margin: 1.6rem;
        @media (max-width: 768px) {
            @include size(320px, 400px);
        }
    }

    .help__tile-text-container {
        @include flex-config($flex-direction: column);
        margin-top: 7.5rem;
        width: 65%;
        padding: 1.6rem 0.8rem;
        text-align: center;
        font-size: 1.6rem;
        
        .help__tile-text {
            margin-bottom: 3.2rem;
        }
        .help__tile-text__title{
            margin-bottom: 2.8rem;
            font-size: 2rem;
            font-weight: bold;
        }
    }
}

%G-help-mobile {
    width: 90%;
    padding: 0;

    .help__header {
        font-size: 1.8rem;
    }

    .help__tile {
        margin: 1rem 0;
        padding: 0;
        width: 95%;
    }

    .help__tile-text-container {
        font-size: 1.4rem;
        text-align: center;
    }
}