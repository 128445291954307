.pre-match-details__market-group__custom-bets {
    background: #031E33;
    color: #fff;
}

.ll-custom-bets {
    .ll-custom-bets__info {
        background-color: #02799230;
        color: #fff;
    }

    .ll-custom-bets__about-image {
        background-image: url("../../../../static/themes/theme/images/custom-bets/custom-bets.png");
    }

    .ll-custom-bets__title {
        color: #fff;
        background: #027992;
        border-radius: 0;
    }

    .ll-custom-bets__about-terms-link {
        color: #F0871A;
    }

    .ll-custom-bets__close-info {
        background-color: #F0871A;
        color: white;

        &:after {
            border-top: 5px solid #F0871A;
        }
    }

    .ll-custom-bets__open-close-info {
        color: white;
    }

    .custom-market-group > .match-details__market-header {
        background-color: #031E33;
        color: #fff;
    }

    .match-details__market-header {
        background-color: #85B0BA;
        color: #000;
    }

    .match-details__market {
        background: #042E4B;
    }

    .match-details__bet--custom-unavailable {
        opacity: .5;
        cursor: not-allowed;
    }
}

.ll-scrollbox--tablet .ll-custom-bets,
.ll-scrollbox--desktop .ll-custom-bets {
    .match-details__bet {
        color: #fff;
        background-color: #35678E;

        &.match-details__bet--active {
            background: #F6A83C;
            color: #000;
        }

        &.bet--column2:nth-child(2n+1):not(:only-child) {
            box-shadow: inset -1px 0 0 0 #07476b;
        }

        &.bet--column3 {
            box-shadow: inset -1px 0 0 0 #07476b;
        }
    }
}

.ll-scrollbox--mobile .ll-custom-bets {
    @extend %G-custom-bets--mobile;

    .custom-market-group > .match-details__market-header {
        background-color: #031E33;
    }

    .match-details__market-header {
        background-color: #042E4B;
        color: #FFFFFF;
    }

    .match-details__bets {
        background: #215278;
    }

    .match-details__bet {
        background: #35678E;
        line-height: normal;
        border: none;

        &.match-details__bet--active {
            border-color: #E6F0F6;
        }
    }
}

.body--with-cursor {
    .bet-builder-modal__skip-btn.ll-btn--outlined:hover {
        color: #000;
    }

    .ll-custom-bets {
        .ll-custom-bets__about-terms-link:hover {
            color: #fff;
        }

        .ll-custom-bets__close-info:hover {
            background-color: #F0871A;
            color: #000;

            &:after {
                border-top: 5px solid #F0871A;
            }
        }

        .ll-custom-bets__open-close-info:hover {
            border-color: #F0871A;
            color: #F0871A;
        }

        .ll-custom-bets-pre__market-group:hover {
            background: transparent !important;
            color: #F0871A;
        }

        .ll-custom-bets__bet-button:hover {
            color: #F0871A;
        }

        .match-details__bet:not(.match-details__bet--disabled):hover {
            background: #F6A83C;
            border-color: #F6A83C;
            box-shadow: none;
            color: #000;
        }
    }

    .ll-custom-bets__about-info-button:hover {
        color: #F0871A;
    }
}

.bet-builder-modal__info-header {
    color: #F6A83C;
}

.ll-custom-bets__about-info-container {

    .ll-custom-bets__about-info-button {
        color: #FFFFFF;
    }

    .ll-custom-bets__about-info-content-container {
        color: #ffffff;
        background-color: #07476B;
        border-color: #06486C;
    }

    .ll-custom-bets__about-info-close {
        color: #E0E9EF;
    }
}
