/*
3.PAGES
└── 3.11. ERROR PAGE
*/

%G-error {
    @include flex-config($align-items: center, $justify-content: space-evenly);
    @include size(100%, 70vh);
    min-height: 50vh;
    max-width: 1300px;
    margin: auto;
    flex-flow: column nowrap;

    .error__title,
    .error__text {
        margin: 0.5rem;
    }

    .error__text {
        max-width: 500px;
    }

    .ll-btn {
        line-height: unset;
        height: unset;
    }

    .ll-btn__link {
        margin: 2.5rem;
        border-radius: 5rem;

        .dx-button-content {
            padding: 0;

            .dx-button-text {
                padding: 1rem 2.5rem;
            }
        }

        &:hover {
            opacity: 0.8;
            transition: 0.3s;
        }
    }

    img {
        height: 50%;
    }
}

%G-error--tablet {
    img {
        @include size(80%, auto);
    }
}

%G-error--mobile {
    img {
        @include size(90%, auto);
    }
}

%G-error-visualizer {
    display: table;
    width: 300px;
    margin: auto;
    max-width: 70%;
    min-height: 10rem;
    background: #fff;
    border: 1px solid #cacaca;
    border-radius: 5px;
    text-align: center;
    box-shadow: 2px 2px 2px rgba(255, 0, 0, 0.37);

    .ll-error-visualizer__header {
        @extend %error-bg-gradient;
        width: 100%;
        @include flex-config($align-items: center, $justify-content: space-evenly);
        height: 2.2em;
        font-size: 1.6rem;
        color: #fff;
    }

    .ll-error-visualizer__container {
        padding: 2rem;
        white-space: pre-wrap;
    }

    .ll-error-visualizer__btn {
        margin-top: 3rem;
        padding: .5rem;
        @include flex-config($align-items: center, $justify-content: space-evenly);
    }
}

%G-global-error {
    @include flex-config($align-items: center, $justify-content: space-evenly);
    width: 100vw;
    height: 100vh;
}

%G-page--error {
    @include flex-config($align-items: center);
    justify-content: space-evenly !important;
}