/*
3.PAGES
└── 3.6. MY ACCOUNT PAGE
    ├── 3.6.1 MY INFORMATION
    ├── 3.6.2 PAYMENTS
    ├── 3.6.4 ACCOUNT HISTORY
    ├── 3.6.5 BET HISTORY
    ├── 3.6.6 CASINO REPORT
    ├── 3.6.7 MY LIMITS
    ├── 3.6.8 GOOGLE AUTHORIZATION
    └── 3.6.9 MESSAGES
*/

/*
|--------------------------------------------------------------------------
| == 3.6 MY ACCOUNT PAGE
|--------------------------------------------------------------------------
*/

%G-my-account {
    .my-account__title {
        font-weight: 300;
        text-transform: capitalize;
    }

    .my-account__content-container {
        flex: 4;
        @include flex-config($flex-direction: column);
    }

    .my-account__content {
        flex: 1;
    }

    .my-account__content-first-part {
        width: 100%;
        @include flex-config($flex-direction: column);
    }

    .my-account__loader-box {
        width: 100%;
        @include flex-config($justify-content: center, $align-items: center);
    }

    .my-account__table-box {
        position: relative;
        margin: 10px 0;

        .coupons__live-indicator {
            margin: 0 1rem 0 0;
        }
    }
}

%G-my-account--tablet {
    .my-account__content-first-part {
        @include flex-config($flex-direction: row, $justify-content: space-between, $align-items: flex-start);
    }

    .my-account__content-container {
        padding: 0;
    }

    .my-limits__casino,
    .my-limits__sports {
        flex: 1;
    }

    .my-limits__sports {
        margin-right: 1rem;

        .my-account-section {
            margin-bottom: 1rem !important;
        }
    }
}

%G-my-account--mobile {
    padding: 2rem 1rem 0;
    margin: 0;
    width: 100%;
    flex: 1;

    .my-account__date-picker-wrapper {
        margin-bottom: 1rem;
    }

    .my-account__loader-box {
        min-height: 200px;
    }

    .my-account-section,
    .my-account-section--secondary {
        overflow: hidden;
        border-radius: 3px;
        margin-bottom: 2rem;
    }

    .my-account-section__title {
        @include flex-config($justify-content: center, $align-items: center);
        width: 100%;
        height: 3rem;
        font-size: 1.4rem;
    }
}

%G-my-account__title--mobile {
    position: relative;
    height: 4.6rem;
    width: 100%;
    font-size: 1.4rem;
    @include flex-config($justify-content: center, $align-items: center);

    .my-account__title-back-icon {
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(180deg);
        padding: 1.5rem;
    }
}

%G-my-account--tablet-desktop {
    @include flex-config($justify-content: space-between);

    .my-account__date-picker-wrapper {
        @include flex-config($justify-content: flex-end);
    }
}

%G-my-account--tablet {
    flex: 1;
    position: relative;
    padding: 1rem;

    .my-account__title {
        @extend %G-my-account__row;

        justify-content: center;
        text-transform: uppercase;
    }

    .my-account__loader-box {
        width: 100%;
        min-height: calc(100vh - 350px);
        max-height: calc(100vh - 350px);
    }
}

%G-my-account--desktop {
    width: 100%;
    padding: 1.5rem 2rem;

    .my-account__title {
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 0 1.5rem;
        margin-bottom: 1.5rem;
        border-radius: 3px;
        font-size: 2rem;
        user-select: none;
    }

    .my-account__title-text {
        flex: 1;
        text-align: center;
    }

    .my-account__loader-box {
        height: 100%;
    }
}

%G-my-account__row {
    @include flex-config($justify-content: space-between, $align-items: center);
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 3px;
    font-size: 14px;
    text-transform: capitalize;
    transition: all 0.25s;
}

%G-my-account-drawer {
    @include flex-config($flex-direction: column, $align-items: center);

    .my-account-drawer__blocked-user {
        font-size: 1.5rem;
        text-align: center;
        padding: 1rem;
    }

    .my-account-drawer__menu-list {
        width: 100%;
        @include flex-config($align-items: center);
    }

    .my-account-drawer__menu-link {
        display: flex !important;
        align-items: center;
        padding: .8rem 1.2rem;
        font-size: 1.4rem;
        cursor: pointer;
        user-select: none;
    }

    .my-account-drawer__menu-icon {
        margin-right: .5rem;
    }

    .my-account-drawer__close-btn {
        font-size: 1rem;
        cursor: pointer;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.1 MY ACCOUNT PAGE -> MY INFORMATION
|--------------------------------------------------------------------------
*/
%G-my-info--mobile {
    .my-account__title {
        margin: 0 0 2rem;
    }
}

%G-my-info__wrapper--mobile {
    @include flex-config($flex-direction: column);

    .my-account-info-list {
        margin: 0;
        border: none;
    }

    .my-account-section {
        margin-bottom: 2.5rem;
    }

    .my-info__change-pass {
        margin-left: 2rem;
    }
}

%G-my-info__wrapper--tablet {
    @include flex-config($flex-direction: row-reverse, $justify-content: space-between, $align-items: start);
    padding: 0;

    .my-account-section {
        flex: 1;
    }

    .my-account-section:last-of-type {
        margin-right: 1rem;
    }
}

%G-my-info__wrapper--desktop {
    @include flex-config($align-items: flex-start, $flex-direction: row-reverse);

    .my-info__sub-title {
        height: 2.8rem;
        @include flex-config($justify-content: center, $align-items: center);
        font-size: 1.6rem;
        font-weight: 300;
    }

    .my-info__coll {
        flex: 1;

        &:not(:last-of-type) {
            margin-left: 4rem;
        }
    }

    .my-info__row {
        @include flex-config($justify-content: space-between, $align-items: center);
        height: 3.4rem;
        padding: 0 1rem;
        font-size: 1.4rem;
    }

    .my-info__divider {
        flex: 1;
        text-align: center;
    }

    .my-info__cell--title,
    .my-info__cell--value {
        flex: 5;
        line-height: 1;
    }

    .my-info__cell--value {
        text-align: right;
    }

    .my-info__cell-link {
        margin-left: 1rem;
        border-color: transparent;
        cursor: pointer;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.2 PAYMENTS
|--------------------------------------------------------------------------
*/
%G-payments-btn-link {
    .payment__btn-link {
        @include size(100%);
        @include flex-config($flex-direction: column, $justify-content: center);
        font-size: 1.2rem;

        .payment__btn-link-text {
            margin-bottom: .5rem;
        }
    }
}

%G-payments {
    flex: 1;

    .payments__header--mobile {
        height: 4.4rem;
        padding: 0 1rem;
        @include flex-config($justify-content: space-between, $align-items: center);
    }

    .payments__content--mobile,
    .payments__content--desktop {
        @include flex-config($flex-wrap: wrap, $justify-content: center);
        row-gap: 3rem;

        .payment {
            @include flex-config(
                    $flex-direction: column,
                    $justify-content: space-between
            );
            margin: 0 4rem;
            text-transform: capitalize;
            overflow: hidden;
        }

        .payment__logo {
            @include size(100%, 100%);
            @include flex-config($align-items: center, $justify-content: center);
        }

        .payment__btns {
            @include flex-config();
            margin-bottom: -1px;
        }

        .payment__btn {
            @include size(50%, 100%);
            flex: 2;

            &.payment__btn--info {
                flex: 1;
            }
        }
    }

    .payments__content--mobile {
        @include mq('(min-width: 450px)') {
            @include flex-config($align-items: center, $justify-content: center);
            gap: 3rem 1.5rem;
        }

        &.my-account__content {
            padding-top: 3rem;
        }

        .payment {
            min-width: 14rem;
            max-width: 18rem;
            height: 10rem;
            flex: 1;
            margin: 0 .5rem;

            @include mq('(min-width: 768px)') {
                min-width: 20rem;
                max-width: 20rem;
            }
        }

        .payment__logo-img {
            width: 65%;
        }

        .payment__btns {
            @include size(100%, 3.5rem);

            .ll-btn--square-outlined--mobile {
                border-right: none;
            }

            .payment__btn {
                padding: 0;

                .ll-btn__content {
                    @include size(100%);
                    padding: 0;
                }

                div {
                    @include flex-config($justify-content: center, $align-items: center);
                }
            }
        }

        .payment__btn {
            font-size: 1.2rem;
            font-weight: 600;
        }

        .payment__btn-link-text {
            margin: .5rem 0 0;
        }

        .payment__btn-link-name {
            margin: 0 0 .5rem;
        }
    }

    .payments__icon {
        font-size: 2.2rem;
    }

    .payments__content--desktop {
        padding: 0 2.8rem 0 1.2rem;
        row-gap: 3rem;
        font-size: 1.6rem;

        .payments__no-data {
            min-width: 70rem;
            padding: .3rem 0;
            border-radius: 3px;
            text-align: center;
        }

        .payment {
            @include size(25rem, 15.5rem);
            padding: 0 2.4rem;
        }

        .payment__logo {
            flex: 1;
        }

        .payment__logo-img {
            width: 85%;
        }

        .payment__btns {
            @include size(100%, 5rem);
        }

        .payment__btn {
            padding: 0;

            .ll-btn__content {
                @include size(100%);
                padding: 0;
                font-size: 1.6rem;
            }

            div {
                @include flex-config($justify-content: center, $align-items: center);
            }
        }
    }
}

%G-payment-modal {
    padding: 2rem 3rem;
    font-size: 1.4rem;
    line-height: 2.1rem;

    .payment-modal__description {
        margin-bottom: 2rem;

        // This <li> can come from back, don't remove this style
        li {
            margin-left: 4rem;
        }
    }

    .payment-modal__btn-wrapper {
        @include flex-config($justify-content: center);
    }

    &.payment-modal--mobile {
        width: 85vw;
    }

    &.payment-modal--tablet {
        width: 85vw;
    }

    &.payment-modal--desktop {
        .payment-modal__btn-wrapper {
            justify-content: flex-end;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.4 ACCOUNT HISTORY
|--------------------------------------------------------------------------
*/

%G-account-report--mobile {
    @include flex-config($justify-content: center);
    border-radius: 3px;
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;

    &.opened {
        margin-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .account-report-item,
    .account-report-item--active {
        @include flex-config($justify-content: space-between, $align-items: center);
        height: 4.6rem;
        flex: 1;
        padding: 0 .5rem 0 1rem;
        font-size: 1.4rem;

        .ll-burger-icon,
        .ll-burger-icon--opened {
            width: 2rem;
        }
    }

    .account-report__progress-bar {
        bottom: 0;
        height: 2px;
    }
}

%G-account-report--tablet,
%G-account-report--desktop {
    .account-report-item {
        position: relative;
    }

    .my-account-info-item__content--overflow {
        position: absolute;
        z-index: 2;
        top: 3rem;
        max-height: 16.2rem;
        @include flex-config($flex-direction: column);
        width: 100%;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        transition: max-height .4s ease-out;

        &.expanded {
            max-height: 60vh;
        }
    }

    .my-account-info-item__expand-panel {
        max-height: 3rem;
        min-height: 3rem;
        @include flex-config($justify-content: center, $align-items: center);
        cursor: pointer;
    }

    .my-account-info-item__expand-panel--icon {
        transform: rotate(90deg);
        transition: transform .3s ease-out;

        &.expanded {
            transform: rotate(270deg);
        }
    }
}

%G-account-report--tablet {
    @include flex-config(
            $align-items: flex-start,
            $flex-wrap: wrap,
            $justify-content: center
    );

    .account-report-item {
        @include size(calc(50% - 3rem), 21.8rem);
        @include flex-config($flex-direction: column);
        margin: 1rem;
        padding: 2rem 2rem 5rem;
        border-radius: 15px;
    }

    .my-account-info-item__content--overflow {
        width: calc(100% - 4rem);
        top: 7rem;
        padding: 0 1rem;
    }

    .my-account-info-item__title {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        font-size: 1.8rem;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
    }

    .my-account-info-item__loader-wrapper {
        flex: 1;
        @include flex-config($justify-content: center, $align-items: center);
    }

    .my-account-info-list__item {
        @include flex-config($justify-content: space-between);
    }

    .my-account-info-list__item-label,
    .my-account-info-list__item-value {
        flex: 1;
    }

    .my-account-info-list__item-label,
    .my-account-info-list__item-value,
    .my-account-info-item__line-divider {
        font-size: 1.4rem;
        line-height: 3.2rem;
    }

    .my-account-info-list__item-label--capitalized {
        text-transform: capitalize;
    }

    .my-account-info-list__item-value {
        text-align: right;
    }

    .my-account-info-item__line-divider {
        width: 1rem;
    }
}

%G-account-report--desktop {
    @include flex-config();
    margin: 2rem 0 4rem;

    .account-report-item {
        @include flex-config($flex-direction: column);
        @include size(auto, 16.2rem);
        flex: 1;
        padding-bottom: 3rem;
        border-radius: 3px;

        &:not(:last-of-type) {
            margin-right: 2rem;
        }
    }

    .my-account-info-item__loader-wrapper {
        flex: 1;
        @include flex-config($justify-content: center, $align-items: center);
    }

    .my-account-info-item__title {
        @include flex-config($align-items: center, $justify-content: center);
        height: 3rem;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        font-size: 1.6rem;
        font-weight: 500;
    }

    .my-account-info-list__item {
        max-height: 3.4rem;
        min-height: 3.4rem;
        margin: 0 1rem;
        @include flex-config($justify-content: space-between, $align-items: center);

        .my-account-info-list__item-label,
        .my-account-info-list__item-value {
            flex: 1;
        }

        .my-account-info-list__item-value {
            text-align: right;
        }
    }
}

%G-my-account-info-list {
    width: 100%;
    margin-bottom: 2rem;

    .my-account-info-list__item {
        height: 4.6rem;
        @include flex-config($justify-content: space-between, $align-items: center);
        font-size: 1.4rem;

        .my-account-info-list__item-label,
        .my-account-info-list__item-value {
            padding: 1.5rem 1rem;
            font-weight: 500;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.5. BET HISTORY
|--------------------------------------------------------------------------
*/
%G-bet-history--mobile-tablet {
    height: 100%;

    .ll-drawer__header--mobile {
        font-size: 1.2rem;

        @media (min-width: 375px) {
            font-size: 1.4rem;
        }
    }

    .bets-list {
        height: calc(100% - 1rem);
        padding: 1rem;

        &.bets-list--history {
            height: calc(100% - 8rem);
        }
    }

    .bets-list__table {
        height: 100%;
        display: block;
    }

    .bet-history__status-filter--disabled,
    .bet-history__status-filter {
        height: 100%;
        position: relative;
        @include flex-config($align-items: center);

        .ll-dropdown-menu-item {
            height: 100%;
            @include flex-config($align-items: center);
            margin: 0;
            padding: 1rem 0;
            font-size: 1.2rem;
            text-transform: capitalize;

            @media (min-width: 375px) {
                margin-left: .5rem;
                font-size: 1.4rem;
            }
        }

        .ll-dropdown-menu__icon,
        .ll-dropdown-menu__icon--opened {
            font-size: 2rem;
            transition: all .2s ease;
        }

        .ll-dropdown-menu__icon--opened {
            transform: rotate(-90deg);
        }

        &.ll-dropdown-wrapper--opened {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        .bet-history__status-filter-dropdown {
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
            width: 10rem;

            .ll-dropdown-menu-item--active,
            .ll-dropdown-menu-item--disabled,
            .ll-dropdown-menu-item {
                width: 100%;
                padding: 1rem 1.4rem;
                font-weight: 500;
            }
        }
    }

    .bet-history__date-picker-wrapper {
        margin: 1rem 1rem 0;
    }

    .data-table__pagination {
        width: calc(100% - 2rem);
        //position: absolute;
        bottom: 1.5rem;
    }
}

%G-bet-history--tablet {
    .bet-history__date-picker-wrapper {
        @include flex-config($justify-content: flex-end);
    }
}

%G-bet-history--desktop {
    td {
        position: relative;
    }

    .bet-history__action-cell {
        padding: 0 !important;
    }

    .bet-history__cashout-cell-btn {
        height: auto;
        margin: 0 auto;
        border-radius: 0.3rem;
        cursor: pointer;

        .ll-btn__content {
            padding: .4rem 1.2rem;
        }
    }

    .bet-history__delete-cell {
        @include flex-config($align-items: center, $justify-content: center);
        @include size(50%);
        margin: auto;
    }

    .bet-delete-icon {
        color: red;
    }

    /* ---------------------- Bet details in table ---------------------- */

    #bet-history-detail-table:not(.bet-history-detail-table--with-summary) {
        .data-table__summary {
            margin-top: 0;
        }
    }

    .bet-history-detail__info-row {
        padding: 0.5rem 1rem;
        line-height: 20px;
        font-size: 14px;
        font-weight: 500;
    }

    .bet-history-detail__info-td {
        position: relative;
        padding: 0 1rem;

        &:first-child {
            padding-left: 0;
        }

        &:not(:last-child)::after {
            display: inline-block;
            content: '';
            @include size(1px, 1rem);
            @include position(absolute, top .3rem right 0);
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.7. MY LIMITS
|--------------------------------------------------------------------------
*/

%G-my-limits {
    .my-limits__content-box-title {
        font-weight: 500;
    }

    .my-limits__content-box {
        flex: 1;
    }

    .my-limits__double-content {
        @include flex-config($justify-content: space-between);
        padding: 0 1rem;

        & > * {
            display: block;
            @include size(50%, 100%);
            @include flex-config($align-items: center);

            &:last-child {
                justify-content: right;
            }
        }
    }

    .my-limits__content-box-title {
        padding: 0 1rem;
        @include flex-config($align-items: center);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .my-limits__content-box-title-icon {
        margin-right: 1rem;
    }

    .my-limits__content-row {
        font-size: 1.4rem;
    }

    .my-limits__provider-name {
        line-height: 1.2;
    }

    .my-limits__provider-limit-info-icon {
        display: inline-block;
        margin-left: 1rem;
    }

    .my-limits__content-box-info {
        font-size: 1.4rem;

        li {
            list-style: '- ';
            line-height: 1.2;
        }
    }
}

%G-my-limits--mobile-table {
    .my-limits__content-box {
        margin-bottom: 2rem;
    }

    .my-limits__content-box-title {
        height: 3rem;
        font-size: 1.5rem;
    }

    .my-limits__content-box--info .my-limits__content-box-title {
        padding: 0;
    }

    .my-limits__content-row {
        height: 4.6rem;
    }

    .my-limits__content-box-info {
        padding: 1rem 1rem 1rem 0;

        li {
            margin: 0 0 .8rem 1rem;
        }
    }
}

%G-my-limits--desktop {
    .my-limits__content-wrp {
        @include flex-config($justify-content: space-between, $align-items: flex-start);
        gap: 2rem;
    }

    .my-limits__content-box-title {
        height: 2.8rem;
        font-size: 1.6rem;
    }

    .my-limits__content-box-wrp {
        max-height: calc(100vh - 14.3rem);
        overflow-y: auto;
    }

    .my-limits__content-row {
        height: 3.5rem;
    }

    .my-limits__content-box-info {
        padding: 1rem 1rem 1rem 2rem;

        li {
            margin: 0 0 .4rem 1rem;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.8 GOOGLE AUTHORIZATION
|--------------------------------------------------------------------------
*/

%G-google-auth {
    @include flex-config($flex-direction: column, $align-items: center);
    @include size(100%, 100%);
    position: relative;
    flex-flow: column nowrap;
    box-sizing: border-box;

    .google-auth__title,
    .google-auth__title--mobile {
        @include position(absolute, top 10px left 40px);
        font-size: 21px;
        font-weight: 300;
        line-height: 1.1;
    }

    .google-auth__title--mobile {
        position: static;
        top: unset;
        left: unset;
    }

    .my-account__title {
        width: 100%;
    }

    .google-auth__content {
        width: 50%;
        margin-top: 1rem;
        padding: 2rem;
        border-radius: 5px;
    }

    .google-auth__install-app {
        margin-bottom: 4rem;
        padding-bottom: 1rem;
    }

    .google-auth__install-text {
        width: 100%;
        display: inline-block;
        margin-bottom: 2rem;
        font-size: 1.6rem;
        font-style: italic;
        text-align: center;
    }

    .google-auth__install-links-box {
        @include flex-config($justify-content: space-evenly);
        margin: 2rem 0;
    }

    .google-auth__install-link {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @include size(12rem, 4rem);
        @include flex-config($justify-content: center);
    }

    .google-auth__install-link--apple {
        background-image: url('../../../static/themes/theme/images/icons/apple-store.png');
    }

    .google-auth__install-link--google {
        background-image: url('../../../static/themes/theme/images/icons/google-store.png');
    }


    .google-auth__form {
        @include flex-config($flex-direction: column, $align-items: center);
    }

    .google-auth__label {
        font-size: 1.4rem;
        font-weight: 500;
        text-align: center;
    }

    .google-auth__input {
        margin: 1rem 0 3rem 0;
    }

    .google-auth__submit-btn {
        font-size: 1.5rem;
    }

    .google-auth__qr-img-container {
        @include flex-config($flex-direction: column, $align-items: center);
        margin-bottom: 2rem;
    }

    .google-auth__img-qr {
        margin: 2rem;
    }
}

%G-google-auth--mobile {
    .google-auth__content {
        width: 100%;
    }
}

%G-google-auth__form-password {
    @include flex-config($align-items: center, $justify-content: flex-start);
    margin-top: 1rem;
    flex-flow: column nowrap;

    .form-password__input-wrapper {
        @include flex-config($align-items: center, $justify-content: space-between);
        flex-flow: row nowrap;
        font-weight: 300;
        width: 100%;
        padding: 4.5rem 5rem;
        border-radius: .5rem;
        font-size: 1.8rem;

        .form-password__title--small {
            margin-right: 1rem;
        }

        .form-password__input {
            border-radius: 4px;
            font-size: 1.4rem;
            padding: 0;

            .dx-placeholder:before {
                padding: 1rem 1.2rem;
            }
        }
    }

    .dx-state-disabled.dx-widget {
        opacity: unset;
    }

    .ll-btn__google-auth {
        margin: 5rem 0 3rem 0;
        border-radius: 50px;
        outline: none;
        font-weight: 500;
        user-select: none;
        font-size: 2.1rem;
        height: 4rem;

        .ll-btn__text-wrapper--loaded {
            padding: 0 4rem;
        }

        .ll-btn__text {
            line-height: 4rem;
            text-transform: capitalize;
            font-weight: 300;
            padding: 0 1.5rem;
        }

        &:hover {
            opacity: 1;
        }
    }
}

%G-google-auth__form-password--mobile {
    margin-top: 5rem;
    @media (max-width: 53.5rem) {
        margin-top: 0;
    }

    .form-password__input-wrapper {
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: center;
        padding: 4.5rem 2.5rem;
        @media (max-width: 33rem) {
            padding: 2.5rem 1.5rem;
        }
    }

    .ll-btn__google-auth {
        margin: 4rem 0 2rem 0;
        @media (max-width: 53.5rem) {
            margin: 3rem 0 2rem 0;
        }
    }
}

%G-google-auth__form-qr-code {
    @include flex-config($align-items: center, $justify-content: space-evenly);
    position: relative;
    margin-top: 8rem;
    box-sizing: border-box;
    padding: 1rem 5rem;
    width: 60rem;
    border-radius: .5rem;
    flex-flow: column nowrap;

    .dx-state-disabled.dx-widget {
        opacity: unset;
    }

    .form-qr-code__enter-key {
        margin: 1rem 0;
        font-weight: 300;
        font-size: 1.8rem;
        text-align: center;
    }

    .form-qr-code__install {
        margin: 3.5rem 0;
        padding: 1rem;
        font-weight: 300;
        font-size: 1.8rem;
        text-align: center;
        border-radius: 3.5rem;
    }

    .form-qr-code__img-qr {
        width: 20rem;
    }

    .form-qr-code__line {
        width: 100%;
    }

    .form-qr-code__title-input {
        margin: 1rem 0 .5rem 0;
        font-weight: 300;
        font-size: 1.8rem;
    }

    .form-qr-code__input {
        padding: 0;
        border-radius: 4px;
        font-size: 1.4rem;
    }

    .ll-btn__google-auth {
        margin: 2.5rem 0 1rem;
        height: 4rem;
        border-radius: 5rem;
        outline: none;
        font-weight: 500;
        user-select: none;
        font-size: 2.1rem;

        .ll-btn__text-wrapper--loaded {
            padding: 0 4rem;
        }

        .ll-btn__text {
            padding: 0 1.5rem;
            line-height: 4.0rem;
            text-transform: capitalize;
            font-weight: 300;
        }

        &:hover {
            opacity: 1;
        }
    }
}

%G-google-auth__form-qr-code--mobile {
    width: unset;
    margin-top: 2rem;
    padding: .5rem;

    @media (max-width: 39rem) {
        .form-qr-code__enter-key,
        .form-qr-code__install,
        .form-qr-code__title-input,
        .dx-button-text {
            font-size: 1.4rem;
        }
    }
}

%G-user-bets__popper-cashout {
    width: 300px;
    height: 136px;
    border-radius: 2px;
    z-index: 2000;
    bottom: 20px !important;
    right: -11px !important;

    &:before {
        content: "";
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        position: absolute;
        z-index: 998;
        right: 35px;
        bottom: -8px;
    }

    &.bet-history__popper-cashout:before {
        right: 60px;
    }

    &[data-popper-placement="bottom-end"] {
        margin-top: 2rem !important;

        &:before {
            content: "";
            transform: rotate(135deg);
            top: -8px!important;
        }
    }

    .user-bets__popper-cashout-message {
        font-size: 16px;
        width: 100%;
        height: 100%;
        border-radius: 2px;
        position: relative;
        z-index: 998;
        padding: 20px;
    }
}

%G-user-bets__popper-delete {
    width: 300px;
    height: 136px;
    border-radius: 2px;
    z-index: 2000;
    bottom: 20px !important;
    right: -20px !important;

    &:before {
        content: "";
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        position: absolute;
        z-index: 998;
        right: 22px;
        bottom: -8px;
    }

    &.bet-history__popper-delete {
        right: 2px!important;

        &:before {
            right: 45px;
        }
    }

    &[data-popper-placement="bottom-end"] {
        margin-top: 2rem !important;

        &:before {
            content: "";
            transform: rotate(135deg);
            top: -8px!important;
        }
    }

    .user-bets__popper-delete-message {
        font-size: 16px;
        width: 100%;
        height: 100%;
        border-radius: 2px;
        position: relative;
        z-index: 998;
        padding: 20px;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.9 MESSAGES PAGE
|--------------------------------------------------------------------------
*/

%G-messages {
    .message-list {
        box-sizing: border-box;
    }

    .message-item {
        border-radius: 3px;
        padding: 1rem;
        line-height: 1.4;
        margin-bottom: .2rem;
    }
}

%G-messages--mobile {
    .message-list {
        width: 100%;
        margin-bottom: 2rem;
    }

    .message-item {
        min-height: 4rem;
    }

    .message-text {
        margin-bottom: .8rem;
        font-size: 1.2rem;
    }

    .message-date {
        font-size: 1rem;
    }
}

%G-messages--desktop {
    .message-list {
        width: 50%;
        margin: 0 auto;
    }

    .message-item {
        min-height: 6rem;
    }

    .message-text {
        margin-bottom: 1rem;
        font-size: 1.6rem;
    }

    .message-date {
        font-size: 1.2rem;
    }
}