/*
3.PAGES
├── 3.1. PREMATCH COMMON ELEMENTS
│   └── 3.1.2. PREMATCH BREADCRUMBS DROPDOWN
│   ├── 3.1.3. PREMATCH RANGED EVENTS PICKER
│   ├── 3.1.4. PREMATCH LL-WIDGETS
│   ├── 3.1.5. SPORT MARKET
│   └── 3.1.6. COMMON MATCH LIST ITEM
├── 3.2. PREMATCH PAGE(desktop)
│   ├── 3.2.1 PREMATCH LEFT MENU
│   ├── 3.1.2 PREMATCH CENTER
│   │   ├── 3.1.2.1 PREMATCH - MATCHLIST
│   │   └── 3.1.2.2 PREMATCH CENTER
│   └── 3.1.3 PREMATCH RIGHT CONTENT (COUPONS)
└── 3.1.m PREMATCH PAGE(mobile)
    ├── 3.1.m.1 PREMATCH MOBILE MENU
    ├── 3.1.m.2 PREMATCH TOURNAMENT MOBILE MENU
    ├── 3.1.m.3 MATCH DETAILS
    └── 3.1.m.4 PREMATCH COUPONS
*/

/*
|--------------------------------------------------------------------------
| == 3.PAGES
|--------------------------------------------------------------------------
| == 3.1. PREMATCH COMMON ELEMENTS
|--------------------------------------------------------------------------
 */

.ll-page.prematch--desktop {
    min-height: $size-max-height--page-container_desktop;
    max-height: $size-max-height--page-container_desktop;
    padding-top: 1rem;
}

.ll-page.prematch--tablet {
    padding-top: 1rem;
    padding: .5rem 0;
    min-height: $size-min-height--page-container_tablet;
    max-height: $size-max-height--page-container_tablet;

    //ios crutch
    @supports (-webkit-touch-callout: none) {
        min-height: calc(#{$size-min-height--page-container_tablet} - 7rem);
        max-height: calc(#{$size-max-height--page-container_tablet} - 7rem);
    }

    &.sports-live {
        max-height: fit-content;
    }

    .live-page__landscape-scrollbox {
        max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
        - #{$size-height--header-nav-menu_tablet} - 4.5rem);

        @supports (-webkit-touch-callout: none) {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - 11.5rem);
        }
    }
}

.ll-page.prematch--tablet.prematch--tablet__with-ticket {
    min-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
    - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one});
    max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
    - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one});

    @supports (-webkit-touch-callout: none) {
        min-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
        - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - 7rem);
        max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
        - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - 7rem);
    }

    .live-detail__section,
    .live-menu__section {
        padding-bottom: $size-height__prematch-coupons--one;
    }

    &.sports-live {
        max-height: fit-content;
    }

    @media screen and (orientation: landscape) {
        max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
        - #{$size-height--header-nav-menu_tablet});

        @supports (-webkit-touch-callout: none) {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - 7rem);
        }

        @media screen and (max-width: 1023px) {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one});

            @supports (-webkit-touch-callout: none) {
                max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
                - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - 7rem);
            }
        }

        &.sports-live {
            max-height: fit-content;
        }

        .live-page__landscape-scrollbox {
            min-height: auto;
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - 4.5rem);

            @supports (-webkit-touch-callout: none) {
                max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
                - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - 11.5rem);
            }
        }

        .betting-dashboard {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - .5rem);

            @supports (-webkit-touch-callout: none) {
                max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
                - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - 7.5rem);
            }
        }

        .betting-dashboard .ll-scrollbox--tablet {
            min-height: auto;
            max-height: fit-content;
        }
    }
}

.ll-page.prematch--tablet.prematch--with-tickets {
    padding-bottom: $size-height__prematch-coupons--few;

    @media screen and (orientation: landscape) {
        max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
        - #{$size-height--header-nav-menu_tablet});
        padding-bottom: 0;

        @supports (-webkit-touch-callout: none) {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - 7.5rem);
        }

        @media screen and (max-width: 1023px) {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet});
            padding-bottom: $size-height__prematch-coupons--few;

            @supports (-webkit-touch-callout: none) {
                max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
                - #{$size-height--header-nav-menu_tablet} - 7rem);
            }
        }

        &.sports-live {
            max-height: fit-content;
        }

        .live-menu__section {
            padding-bottom: $size-height__prematch-coupons--few;
        }

        .live-page__landscape-scrollbox {
            min-height: auto;
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--few} - 4.5rem);

            @supports (-webkit-touch-callout: none) {
                max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
                - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--few} - 11.5rem);
            }
        }
        .betting-dashboard {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--few} - .5rem);

            @supports (-webkit-touch-callout: none) {
                max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
                - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--few} - 7.5rem);
            }
        }
    }
}

/* ---------------------- Slider ---------------------- */

.pre-market-groups__slider {
    @extend %G-pre-market-groups-slider;

    background-color: #042E4B;

    .slider__arrow {
        color: #fff;
    }

    .pre-match-details__market-group {
        color: #fff;
    }

    .pre-match-details__market-group--active {
        background-color: #F0871A;
        color: #000;
    }
}

.body--with-cursor {
    .pre-market-groups__slider {
        .pre-match-details__market-group:hover {
            background-color: #F0871A !important;
            color: #000;
        }

        .slider__arrow:hover {
            color: #F0871A;
        }
    }
}

.market-groups__slider--disabled:before {
    background: #00000020;
}

/*
|--------------------------------------------------------------------------
| == 3.1.2. PREMATCH BREADCRUMBS DROPDOWN
|--------------------------------------------------------------------------
*/

.prematch-breadcrumbs-dropdown {
    @extend %G-prematch-breadcrumbs-dropdown;

    .prematch-breadcrumbs-dropdown__content {
        color: #000;
    }
}

.body--with-cursor {
    .prematch-breadcrumbs-dropdown__match-item--match:hover {
        .prematch-breadcrumbs-dropdown__match-name {
            color: #CD5B1D;
        }
    }
}

.prematch-breadcrumbs-dropdown--desktop,
.prematch-breadcrumbs-dropdown--tablet {
    @extend %G-prematch-breadcrumbs-dropdown--tablet--desktop;

    .prematch-breadcrumbs-dropdown__content {
        border: 1px solid #C7C7C7;
        background: #E0E9EF;
    }

    .prematch-breadcrumbs-dropdown__match-item--match {
        background-color: #fff;
    }

    .prematch-breadcrumbs-dropdown__match-item--date {
        background-color: #E0E9EF;
    }

    .prematch-breadcrumbs-dropdown__match-item--breadcrumbs {
        background-color: #abcad4;
    }

    .prematch-breadcrumbs-dropdown__match-item {
        &:not(:last-of-type) {
            border-bottom: 1px solid #C7C7C7;
        }
    }
}

.prematch-breadcrumbs-dropdown--mobile {
    @extend %G-prematch-breadcrumbs-dropdown--mobile;

    .prematch-breadcrumbs-dropdown__content {
        background-color: #35678E;
        color: #fff;
    }

    .prematch-breadcrumbs-dropdown__match-item--match,
    .prematch-breadcrumbs-dropdown__match-item--tournament {
        &:not(:last-of-type) {
            border-bottom: 1px solid #042E4B;
        }
    }

    .prematch-breadcrumbs-dropdown__match-item--date,
    .prematch-breadcrumbs-dropdown__match-item--breadcrumbs {
        background-color: #042E4A;
    }

    .prematch-breadcrumbs-dropdown__match-item--date {
        color: #E0E9EF;
    }

    .prematch-breadcrumbs-dropdown__match-item--breadcrumbs {
        color: #fff;
        border-bottom: 1px solid #35678E;
    }

    .prematch-breadcrumbs-dropdown__match-count {
        background-color: #F6A83C;
        color: #000000;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.3. PREMATCH RANGED EVENTS PICKER
|--------------------------------------------------------------------------
*/

.ranged-events-picker {
    @extend %G-ranged-events-picker;

    color: #FFFFFF;
}

.ranged-events-picker--desktop,
.ranged-events-picker--tablet {
    @extend %G-ranged-events-picker--tablet--desktop;

    background: #066A80;


    .slider__item {
        &:not(:last-child) {
            border-right: 1px solid #07476B;
        }
    }

    .ranged-events-picker__slider-item--active {
        background: #F6A83C;
        color: #000;
    }
}

.ranged-events-picker--mobile {
    @extend %G-ranged-events-picker--mobile;

    background: #031E33;
    border-bottom: 1px solid #042e4b;

    .ranged-events-picker__slider-item {
        background-color: #35678E;
    }

    .ranged-events-picker__slider-item--active {
        color: #F6A83C;
    }
}

.body--with-cursor .ranged-events-picker {
    .ranged-events-picker__slider-item {
        cursor: pointer;

        &:hover {
            background: #F6A83C;
            color: #000;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.4. PREMATCH LL-WIDGETS
|--------------------------------------------------------------------------
*/

.ll-sport-widget {
    @extend %G-ll-sport-widget;

    .ll-sport-widget__slider-arrow {
        color: #fff;
    }
}

.page-wrapper--desktop .ll-sport-widget {
    @extend %G-ll-sport-widget--desktop;
    background-color: #06486C;
}

/*
|--------------------------------------------------------------------------
| == 3.1.6. COMMON MATCH LIST ITEM
|--------------------------------------------------------------------------
*/

.common-match-item {
    @extend %G-common-match-item;
}

.common-match-item--mobile {
    border-bottom: 1px solid #031e33;
    background: #042e4b;
    color: #fff;

    .matches__date {
        color: #c7c7c7;
    }

    .matches__bets-btn {
        background: #35678e;
        color: #f6a83c;
    }

    .matches__odds-count-btn {
        color: #e0e9ef;
    }

    .matches__bets-btn--active {
        background: #f6a83c;
        color: #000;

        .matches__bets-btn-value-original-odd {
            color: #00000080;

            &::after {
                color: #000;
            }
        }
    }
}

.common-match-item--mobile,
.common-match-item--tablet {
    @extend %G-common-match-item--mobile--tablet;
}

.common-match-item--tablet,
.common-match-item--desktop {
    border-bottom: 1px solid #0C5982;
    background: #042E4B;

    .matches__date {
        color: #E0E9EF;
    }

    .matches__bets-btn {
        background: #35678E;
        color: #F6A83C;

        .matches__bets-btn-value-original-odd {
            color: #F6A83C80;

            &::after {
                color: #F6A83C;
            }
        }
    }

    .matches__odds-count-btn {
        color: #FFFFFF;
    }

    .matches__bets-btn--active {
        background: #F6A83C;
        color: #042E4B;

        .matches__bets-btn-value-original-odd {
            color: #042E4B80;

            &::after {
                color: #042E4B;
            }
        }
    }
}

.common-match-item--desktop {
    @extend %G-common-match-item--desktop;
}

.body--with-cursor {
    .matches__bets-btn:hover {
        background: #F6A83C;
        color: #042E4B;

        .matches__bets-btn-value-original-odd {
            color: #042E4B80;

            &::after {
                color: #042E4B;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2. PREMATCH PAGE(desktop)
|--------------------------------------------------------------------------
*/
.prematch--tablet,
.prematch--desktop {
    @extend %G-prematch--tablet--desktop;

    .prematch__loading {
        background: #06486C;
    }

    .prematch__loading--initial {
        background: #06486C;
    }

    .prematch__breadcrumbs {
        background: #027992;
        color: #fff;
    }
    .popular-tournaments {
        .popular-tournaments__list-item,
        .popular-tournaments__header {
            background: #ABCAD4;
            color: #000;
        }

        .popular-tournaments__list-item {
            background: #E6F0F6;
            color: #000;
        }

        .popular-tournaments__match-count {
            background: #F6A83C;
            color: #000;
        }

        .active {
            background-color: #f0871a;
            color: #000;

            .popular-tournaments__match-count {
                background: #000;
                color: #F6A83C;
            }
        }
    }
}

.body--with-cursor {
    .popular-tournaments__list-item:hover {
        background-color: #f0871a;
        color: #000;

        .popular-tournaments__match-count {
            background: #000;
            color: #F6A83C;
        }
    }

    .prematch__breadcrumbs-text-link:hover {
        color: #F6A83C;
    }
}

.prematch--tablet {
    @extend %G-prematch--tablet;
}

/*
 |--------------------------------------------------------------------------
 | == 3.2.1 PREMATCH LEFT MENU
 |--------------------------------------------------------------------------
*/

.prematch--tablet {
    .prematch__menu-section {
        min-width: $size-width__prematch-menu--tablet-768;
        max-width: $size-width__prematch-menu--tablet-768;

        @include mq('(min-width: 1024px)') {
            min-width: $size-width__prematch-menu--tablet-1024;
            max-width: $size-width__prematch-menu--tablet-1024;
        }
    }
}

.prematch--desktop {
    .prematch__menu-section {
        min-width: $size-width__prematch-menu--desktop-1366;
        max-width: $size-width__prematch-menu--desktop-1366;
    }
}

/* ---------------------- Left Menu Component ---------------------- */

.prematch__menu {
    @extend %G-prematch__menu;

    &.prematch__menu-disabled:before {
        background: #06486C99;
    }

    .prematch__menu-filters-btn {
        background-color: #027992;
        color: #fff;
    }

    .menu__loading {
        // TODO: Calc these props properly for tablet/desktop
        min-height: calc(100vh - 350px);
        max-height: calc(100vh - 350px);

        background: rgba(0, 0, 0, 0.2);
    }

    .menu-item {
        background: #fff;
        color: #000;
    }

    .menu-item--category {
        background: #DCE6EB;
        margin: .1rem;
    }

    .menu-item--tournament {
        background: #DCE6EB;
        margin: .1rem;
    }

    .menu-item--active {
        background: #F6A83C;
        color: #000;
    }

    .menu-item__progress-bar .ll-progress-bar__value {
        background-color: #F0871A;
    }

    .prematch__menu-extra-btn {
        background: #027992;
        color: #fff;
    }
}

.prematch--tablet {
    .prematch__menu {
        @extend %G-prematch__menu--tablet;
    }
}

.prematch--desktop {
    .prematch__menu {
        @extend %G-prematch__menu--desktop;
    }
}

.body--with-cursor {
    .prematch__menu {
        @extend %G-prematch__menu--with-cursor;

        .prematch__menu-filters-btn:hover {
            background-color: #005B6D;
        }

        .menu-item:hover {
            background: #F0871A;
            color: #000;
        }

        .prematch__menu-extra-btn:hover {
            background: #F0871A !important;
            color: #fff;

            &::before {
                background-color: #fff;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.2 PREMATCH CENTER
|--------------------------------------------------------------------------
*/

.prematch--tablet,
.prematch--desktop {
    .betting-dashboard {
        @extend %G-betting-dashboard--tablet--desktop;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.1 PREMATCH - MATCHLIST
|--------------------------------------------------------------------------
*/

.prematch--tablet,
.prematch--desktop {
    .match-list {
        @extend %G-match-list--tablet--desktop;

        .match-list__date {
            background-color: #07476b;
            color: #fff;
        }

        .match {
            border-left: 1px solid #07476b;
            background-color: transparent;
            color: #000;
        }

        .match--last-in-list {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .match__time,
        .match__time--full,
        .match__bet,
        .match__odds-count,
        .match__additional-info-box,
        .ll-outright-event .match__bets {
            border-right: 1px solid #07476b;
            border-bottom: 1px solid #07476b;
            background-color: #fff;
            color: #000;
        }

        .match__bet {
            &.match__bet--active {
                background: #F6A83C;

                .match__bet-value {
                    color: #000;
                }

                .match__bet-value-original-odd {
                    color: #000;
                }
            }
        }

        .match__icon--can-cashout,
        .match__icon--cant-cashout {
            color: #000;
        }

        .match__icon--cant-cashout {
            opacity: .2;
        }

        .match__bet-value {
            color: #CD5B1D;
        }

        .match__bet-value-original-odd {
            color: #777777;
        }

        .match__tv {
            color: #F6A83C;
        }

        /* ---------------------- Outrights ---------------------- */
        .ll-outright-tournament {
            .ll-outright-tournament__accordion,
            .ll-outright-tournament__accordion--active {
                border: 1px solid #373737;
                border-top: none;
                background: #07476b;
                color: #fff;
            }

            &:not(:first-of-type) {
                .ll-outright-tournament__accordion,
                .ll-outright-tournament__accordion--active {
                    border-top: none;
                }
            }
        }

        .match.ll-outright-event {
            .match__odds-count {
                width: $size-width__prematch-list-odds-count--tablet;
                background: none;
            }
        }

        /* ---------------------- Tops/Favs ---------------------- */
        .match-list__top-fav-title {
            color: #fff;

            &.match-list__top-fav-title--top {
                background: #005b6d;
            }

            &.match-list__top-fav-title--favorite {
                background: #005b6d;
            }
        }

        /* ---------------------- Search ---------------------- */
        .match-list__search-title {
            background: #06486C;
            color: #fff;
        }

        .match-list__search-no-data-text {
            border: 1px solid #027992;
            background-color: #027992;
            color: #fff;
        }
    }

    .match.ll-outright-event {
        .match__icon--can-cashout,
        .match__icon--cant-cashout {
            color: #000;
        }
        .match__icon--cant-cashout {
            opacity: .2;
        }
    }
}

.prematch--tablet {
    .match-list {
        @extend %G-match-list--tablet;

        .match {
            border-color: #424242;
            background: #5D5D5D;

            & > div {
                &:not(:last-of-type),
                &.match__bets > *:not(:last-of-type) {
                    border-right: 1px solid #424242;
                }
            }
        }

        .match__odds-count {
            color: #F6A83C;
        }
    }
}

.body--with-cursor {
    .prematch--tablet,
    .prematch--desktop {
        .match-list {
            .match__bet:not(.match__bet--disabled):not(.match__bet--without-main-market):hover {
                background: #F6A83C;

                .match__bet-value {
                    color: #000;
                }

                .match__bet-value-original-odd {
                    color: #000;
                }
            }

            .match__tv,
            .match__tv--disabled,
            .match__icon-betbuilder,
            .match__stats {
                &:hover {
                    color: #F0871A;
                }
            }

            .match:not(.ll-outright-event) .match__odds-count:hover {
                background: #F6A83C;
                color: #000;
            }

            .match.ll-outright-event:hover {
                .match__odds-count {
                    color: #000;
                }
                .match__bets {
                    background: #F6A83C;
                    color: #000;
                }
                .match__icon--can-cashout,
                .match__icon--cant-cashout {
                    color: #000;
                }
            }
        }
    }
}

.filtered-matches-list--mobile {
    @extend %G-filtered-matches-list;

    .filtered-matches-list__breadcrumbs-wrp {
        background: #06486C;
        color: #E0E9EF;
    }

    .filtered-matches-list__no-data-text {
        border: 1px solid #06486c;
        background-color: #06486c;
    }
}

.filtered-matches-list--tablet,
.filtered-matches-list--desktop {
    @extend %G-filtered-matches-list;

    .filtered-matches-list__breadcrumbs-wrp {
        background: #06486C;
        color: #E0E9EF;
    }

    .filtered-matches-list__no-data-text {
        color: #E0E9EF;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.1 PREMATCH - DETAILS
|--------------------------------------------------------------------------
*/

.match-details--desktop,
.match-details--tablet,
.match-details--mobile,
.live-match-details {
    .fancy-bets-slider-name {
        color: #E0E9EF;
    }

    .fancy-bets-slider {
        color: #F6A83C;
    }

    .fancy-bets-slider__rail {
        color: #7B99B1;
    }

    .fancy-bets-slider__label {
        color: #fff;
    }
}

.match-details--desktop,
.match-details--tablet,
.live-match-details {
    .fancy-bets-slider-wrp {
        border-top: 1px solid #07476b;
        background-color: #dce6eb;
    }

    .fancy-bets-slider-name {
        color: #606060;
    }

    .fancy-bets-slider:nth-of-type(2n):before {
        background-color: #07476b;
    }

    .fancy-bets-slider__label {
        color: #000;
    }
}

.match-details--tablet,
.match-details--desktop {
    @extend %G-match-details--tablet--desktop;
    color: #fff;

    .match-details__error {
        background: #4A4A4A;
    }

    .match-details__empty-markets {
        background-color: #4A4A4A;
    }

    .match-details__market-header {
        background-color: #07476b;
        color: #fff;
    }

    .market__icon--can-cashout,
    .market__icon--cant-cashout {
        color: #fff;
    }

    .status-filter-switcher .status-filter-switcher-track {
        background-color: #031E33 !important;
    }

    .market__icon--cant-cashout {
        opacity: .2;
    }

    .match-details__market {
        border: 1px solid #07476b;
        background-color: transparent;
    }

    .match-details__bets {
        &:not(:last-of-type) {
            border-bottom: 1px solid #07476b;
        }
    }

    .match-details__bet {
        color: #000;
        background-color: #fff;

        &.match-details__bet--active {
            background: #F6A83C;

            .match-details__bet-value {
                color: #000;
            }
        }

        &.bet--column2:nth-child(2n+1):not(:only-child) {
            box-shadow: inset -1px 0 0 0 #07476b;
        }

        &.bet--column3 {
            box-shadow: inset -1px 0 0 0 #07476b;
        }
    }

    .match-details__bet-value {
        color: #CD5B1D;
    }
}

.match-details--tablet {
    @extend %G-match-details--tablet;
}

.match-details--desktop {
    @extend %G-match-details--desktop;
}


.prematch--tablet,
.prematch--desktop {
    /* ---------------------- score board ---------------------- */

    .match-details__header-info {
        @extend %G-prematch-scoreboard--tablet--desktop;

        color: #fff;

        .match-details__header-info-competitor:after {
            background-color: #000000;
        }

        .match-details__description {
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.51);
        }
    }

    /* ---------------------- OutRights details ---------------------- */

    .outright-details {
        @extend %G-prematch-outright-details;

        .match-details__bets {
            border-left: 1px solid #07476b;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .outright-details__bet {
            border-right: 1px solid #07476b;
            border-bottom: 1px solid #07476b;
            background-color: #fff;
            color: #000;

            &.outright-details__bet--active {
                background: #F6A83C;

                .outright-details__bet-value {
                    color: #000;
                }
            }
        }

        .outright-details__bet-value {
            color: #CD5B1D;
        }
    }
}


.prematch--desktop {
    @extend %G-prematch-scoreboard--desktop;

}

.prematch--tablet {
    @extend %G-prematch-scoreboard--tablet;
}

.body--with-cursor {
    .match-details__bet {
        &:not(.match-details__bet--disabled) {
            &:hover {
                background: #F6A83C;
                box-shadow: none;

                .match-details__bet-value {
                    color: #000;
                }
            }
        }
    }

    /* ---------------------- OutRights details ---------------------- */

    .outright-details .outright-details__bet:hover {
        background: #F6A83C;

        .outright-details__bet-value {
            color: #000;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.3 PREMATCH RIGHT CONTENT (COUPONS)
|--------------------------------------------------------------------------
*/

.coupons__section {
    @extend %G-coupons__section--desktop;
}

/*
|--------------------------------------------------------------------------
| == 3.1.m PREMATCH PAGE(mobile)
|--------------------------------------------------------------------------
*/
.prematch--mobile {
    @extend %G-prematch--mobile;
    color: #fff;

    .prematch__content--with--panel-opened {
        height: $size-height__page-content-100vh_mobile;
        overflow: hidden;
    }

    .prematch__loading--mobile,
    .ll-preloader__prematch-detail {
        min-height: calc(100vh - #{$size-height--footer_mobile} - #{$size-height--header_mobile} - #{$size-height--header-nav-menu_mobile} - 7rem);
        background: #06486C99;
    }

    /* ----------------- Mobile Filters Panel ----------------- */

    .prematch__filters--mobile {
        background-color: #031E33;

        .prematch__filters-search-btn {
            border-color: #35678E;
            background-color: #031E33;
            color: #FFFFFF;
        }

        .ranged-events-picker--mobile {
            .slider__item {
                background-color: #35678E;
            }
        }
    }

    /* ----------------- Popular Tournaments ----------------- */

    .popular-tournaments {
        background-color: #184161;

        .popular-tournaments__list-item {
            background: #042E4B;
            color: #fff;
        }

        .popular-tournaments__match-count {
            background: #F6A83C;
            color: #000;
        }
    }

    .menu__board {
        box-shadow: 3px 2px 5px #00000026;
    }

    .menu__board-back {
        color: #F6A83C;
    }

    .menu__breadcrumbs-title {
        color: #F6A83C;
    }

    /* ---------------------- Tournament Details ---------------------- */
    .ll-tournament-detail__accordion {
        color: #fff;

        .ll-accordion__header {
            border-bottom: 0.1rem solid rgba(255, 255, 255, 0.12);
            background: #35678e;
        }

        .ll-accordion__icon,
        .ll-accordion__icon--right {
            color: #F6A83C;
        }

        .match__icon--cant-cashout {
            opacity: .2;
        }
    }

    .ll-tournament__accordion-head {
        border-bottom: 1px solid #06486C;
        background-color: #06486C;
        color: #C7C7C7;
    }

    .ll-tournament-detail__name {
        background: #35678E;
        color: #fff;
    }

    .ll-tournament-detail__matches-count {
        background: #F6A83C;
        color: #000;
    }

    /* ---------------------- Outrights ---------------------- */

    .ll-outright-tournament__accordion,
    .ll-outright-tournament__accordion--active {
        border-bottom: 1px solid #031E33;
        background: #35678E;
        color: #fff;

        .ll-outright-tournament__accordion-arrow {
            color: #F6A83C;
        }
    }

    .ll-outright-tournament .match__icon--cant-cashout {
        opacity: .2;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.m.1 PREMATCH MOBILE MENU
|--------------------------------------------------------------------------
*/

.prematch--mobile {
    .menu-item__after {
        color: #000;
        font-weight: 500;
    }

    .menu-item__after-pre-count {
        background: #F6A83C;
    }

    .menu-item__after-out-count {
        background: #F0871A;

        &::before {
            border-bottom: 2.3rem solid #F0871A;
            border-left: 1rem solid transparent;
        }
    }

    .prematch__menu {
        @extend %G-prematch__menu--mobile;

        .menu-item {
            background: #35678E;
            color: #fff;

            &.menu-item--sport {
                border-bottom: .5px solid #031E33;
            }
        }

        .menu-item__progress-bar {
            .ll-progress-bar__value {
                background-color: #F6A83C;
            }
        }

        .menu-item--tournament-count {
            background: #F6A83C;
            color: #000;
        }

        .menu-item__expand-icon {
            color: #F6A83C;
        }
    }
}

/* ---------------------- Ranged Events ---------------------- */
.ranged-events--mobile {
    @extend %G-ranged-events--mobile;

    .ranged-events__back-btn {
        color: #f6a83c;
    }

    .ranged-events__tops-title {
        background: #2C628D;
        color: #fff;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.m.3 MATCH DETAILS
|--------------------------------------------------------------------------
*/
.match-details--mobile,
.prematch--mobile .outright-details {
    @extend %G-sport-outright-match-details--mobile;

    .status-filter-switcher {
        .status-filter-switcher-track {
            background-color: #042E4B !important;
        }

        .status-filter-switcher-switch-base {
            color: #fff !important;
        }
    }
}

.match-details__title {
    @extend %G-match-details__title;

    background-color: #35678E;
    color: #E0E9EF;

    & > *:not(:last-child)::after {
        background-color:  #E0E9EF;
    }
}

.match-details--mobile,
.ll-scrollbox--mobile {
    @extend %G-match-details--mobile;

    color: #fff;

    .match-details__error {
        background: #042E4B;
    }

    .match-details__market {
        border-bottom: 2px solid #031E33;
    }

    /* ---------------------- Scoreboard ---------------------- */

    .match-details__competitor-name,
    .match-details__competitor-without-img-icon {
        color: #fff;
    }

    .match-details__description {
        color: #fff;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.51);
    }

    /* ---------------------- Markets ---------------------- */

    .match-details__market-header {
        background: #35678E;
        color: #fff;
    }

    .market__icon--can-cashout,
    .market__icon--cant-cashout {
        color: #fff;
    }

    .market__icon--cant-cashout {
        opacity: .2;
    }

    .match-details__bets {
        background: #215278;
        border-bottom: 2px solid #215278;

        &:last-of-type {
            border-bottom: none;
        }
    }

    .match-details__bet {
        background: #042E4B;
        color: #E0E9EF;

        &.bet--column2:nth-child(2n+1):not(:only-child) {
            box-shadow: inset -2px 0 0 0 #215278;
        }

        &.bet--column3 {
            box-shadow: inset -2px 0 0 0 #215278;
        }
    }

    .match-details__bet-value {
        color: #F6A83C;
    }

    .match-details__bet--active {
        background: #F6A83C;
        color: #000;

        .match-details__bet-value {
            color: #000;
        }
    }
}

.prematch--mobile .outright-details {
    @extend %G-outright-details--mobile;

    .ll-outright__title {
        color: #fff;
    }

    .outright-details__item {
        border-bottom: 1px solid #031E33;
        background: #042E4B;
    }

    .outright-details__bet {
        background: #35678E;
        color: #F6A83C;
    }

    .outright-details__bet--active {
        background: #F6A83C !important;
        color: #000;
    }
}

.prematch__empty-data,
.prematch-details__empty-data {
    background: #07476b;
    color: #F6A83C;
}

.prematch-mobile__empty-data {
    background: #031E33;
}
.prematch-mobile__empty-data-msg {
    background: #07476b;
    color: #F6A83C;
}
