/*
1. SLOTS BATTLES
├── 1.1. SLOTS BATTLES COMMON ELEMENTS
│   ├── 1.1.1  BTNS
│   ├── 1.1.2  BATTLE CARD + BATTLE INFO CARD
│   ├── 1.1.3  BATTLE LOBBY
│   ├── 1.1.4  BACK BTN
│   ├── 1.1.5  BATTLES TYPES MENU
│   ├── 1.1.6  SLOT BATTLES DROPDOWN
│   ├── 1.1.7  FILTERS + SEARCH
│   ├── 1.1.8  SLOTS BATTLES TOP MENU
│   ├── 1.1.9  GAME ITEM
│   ├── 1.1.10 CHECKBOX
│   ├── 1.1.11 PRIVATE PASS
│   ├── 1.1.12 BRANDLOGO + LOCK
│   ├── 1.1.13 SHARE BTN TOOLTIP
│   ├── 1.1.14 BANNER
│   └── 1.1.15 TUTORIAL
└── 1.2. PAGES
    ├── 1.2.1 MAIN
    └── 1.2.2 CREATE/BATTLE-INFO
*/

/*
|--------------------------------------------------------------------------
| == 1.1. SLOTS BATTLES COMMON ELEMENTS
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 1.1.1 BTNS
|--------------------------------------------------------------------------
*/

%G-slots-battles__btn--bounced {
    justify-content: center;

    &::before {
        @include size(100%);
        @include position(absolute, left 0);
        content: '';
        border-radius: 0.3rem;
        transition: all .3s;
    }

    &[disabled] {
        cursor: not-allowed;
    }
}

%G-slots-battles__btn--bounced-effect {
    &:not([disabled]):hover::before {
        transform: scaleX(1.05);
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.2 BATTLE CARD + BATTLE INFO CARD
|--------------------------------------------------------------------------
*/

%G-battle-card {
    border-radius: 0.3rem;
}

%G-battle-card--desktop {
    @include size(57.5rem, 27.2rem);
    padding: 2rem;
}

%G-battle-card--tablet {
    width: 100%;
}

%G-battle-card--mobile {
    width: 100%;
}

%G-battle-info-card {
    position: relative;
    border-radius: 0.3rem;

    .battle-info-card__image {
        border-radius: 0.3rem;
        overflow: hidden;

        .game-img__logo {
            height: 100%;
            object-fit: cover;
        }
    }

    .battle-info-card__not-real-timer {
        opacity: .8;
    }

    .battle-info-card__wrp {
        @include flex-config($align-items: stretch);
    }

    .battle-info-card__content {
        @include flex-config($flex-direction: column, $justify-content: flex-start);
        position: relative;
        flex: 1;
    }

    .battle-info-card__content-item-name {
        text-transform: uppercase;
    }

    .battle-info-card__content-items {
        @include flex-config($flex-direction: column, $justify-content: center);
    }

    .battle-info-card__content-item {
        @include flex-config($justify-content: space-between, $align-items: center);
    }

    .join-battle-btn {
        width: 100%;
        position: relative;
        bottom: 0;
        height: 3.6rem;
        margin: 2rem 0 0;
        text-transform: uppercase;
        font-size: 1.6rem;

        &[disabled] {
            opacity: .5;
        }
    }

    .slots-battles__private-pass-form {
        @include position(absolute, top 0 left 0);
        @include size(100%);
        padding: 3rem;
        @include flex-config($justify-content: center, $align-items: center);
        z-index: 2;
    }

    .slots-battles__private-pass-form-inner {
        @include flex-config($justify-content: center, $align-items: center, $flex-direction: column);
        @include size(100%);
        padding: 2rem;
        border-radius: .3rem;
        flex: 1;
    }

    .slots-battles__private-pass-form-buttons {
        @include flex-config($justify-content: space-around, $align-items: center);
        width: 100%;

        .ll-btn2 {
            width: 100%;
        }
    }

    .battle-info-card__image {
        .game__provider-with-img .game-img__logo {
            @include size(100%, auto);
        }

        .game__provider-bg-without-img {
            @include size(100%);
        }
    }

    .battle-info-card__content-btn {
        position: relative;
        bottom: 0;
    }
}

%G-battle-info-card--desktop {
    padding: 2rem;
    font-size: 1.6rem;

    .battle-info-card__image {
        @include size(17.6rem, 23.2rem);
        position: relative;
    }

    .battle-info-card__wrp {
        gap: 2rem;
    }

    .battle-info-card__del-btn {
        top: 0;
        right: -1.5rem;
    }

    .battle-info-card__content {
        gap: 1rem;
        height: 23.2rem;

        .join-battle-btn {
            position: absolute;
            bottom: 0;
            margin: 0;
        }
    }

    .battle-info-card__content-btn {
        @include size(100%, 3.6rem);
        position: absolute;
        bottom: 0;
    }

    .battle-info-card__content-items {
        gap: 1rem;
    }

    .battle-info-card__content-message {
        min-height: 4.5rem;
    }

    .slots-battles__private-pass-form-buttons {
        gap: 2rem;
    }

}

%G-battle-info-card--tablet {
    padding: 2rem;
    font-size: 1.4rem;

    .battle-info-card__image {
        @include size(13rem, 17.1rem);
        position: relative;
    }

    .battle-info-card__wrp {
        gap: 2rem;
    }

    .battle-info-card__del-btn {
        top: 0;
        right: -1.5rem;
    }

    .battle-info-card__content {
        height: 17.1rem;
        gap: 1rem;
    }

    .battle-info-card__content-items {
        gap: 1rem;
    }

    .battle-info-card__content-header {
        font-size: 1.6rem;
    }

    .battle-info-card__content-message {
        min-height: 4rem;
    }

    .join-battle-btn {
        position: relative;
        width: 100%;
        margin: 2rem 0 0;
        text-transform: uppercase;

        &[disabled] {
            opacity: .5;
        }
    }

    .slots-battles__private-pass-form-buttons {
        gap: 1rem;
    }
}

%G-battle-info-card--mobile {
    padding: 2rem;
    font-size: 1.4rem;

    .battle-info-card__image {
        @include size(13rem, 17.1rem);
        position: relative;
    }

    .battle-info-card__wrp {
        gap: 1rem;
    }

    .battle-info-card__del-btn {
        top: 0;
        right: -1rem;
        width: 3rem;
    }

    .battle-info-card__content {
        height: 17.1rem;
        gap: 1rem;
    }

    .battle-info-card__content-items {
        gap: 1rem;
    }

    .battle-info-card__content-header {
        font-size: 1.6rem;
    }

    .battle-info-card__content-message {
        min-height: 4rem;
    }

    .join-battle-btn {
        position: relative;
        width: 100%;
        margin: 2rem 0 0;
        text-transform: uppercase;

        &[disabled] {
            opacity: .5;
        }
    }

    .slots-battles__private-pass-form-buttons {
        gap: 1rem;
    }

    @media screen and (max-width: 374px) {
        font-size: 1.2rem;
        .battle-info-card__content-header {
            font-size: 1.4rem;
        }
        .slots-battles__private-pass-form-buttons {
            flex-direction: column;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.3 BATTLE LOBBY
|--------------------------------------------------------------------------
*/

%G-battle-lobby {
    @include flex-config($flex-direction: column, $justify-content: flex-start, $align-items: center);
    padding: 2rem 1rem 2rem 2rem;
    font-size: 1.6rem;

    .battle-lobby__header {
        @include flex-config($align-items: center, $justify-content: space-between);
        width: 100%;
        padding-right: 1rem;
    }

    &.battle-lobby--mobile .battle-lobby__header {
        font-size: 1.4rem;
    }

    .battle-lobby__battle-share-btn {
        @include flex-config($align-items: center, $justify-content: center);
        height: 3.2rem;
        padding: 0.5rem 1.6rem;
        border-radius: 3px;
        text-transform: capitalize;
        font-size: 1.4rem;
        cursor: pointer;
        user-select: none;
    }

    .battle-lobby__battle-share-icon {
        margin-right: .5rem;
    }

    .battle-lobby__detail {
        margin-top: 2rem;
        font-size: 1.4rem;
        width: 100%;
    }

    .battle-lobby__detail-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        height: 2rem;
        position: relative;
    }

    .battle-lobby__detail-row-wrp {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        position: absolute;
        top: 0;
    }

    .battle-lobby__detail-row-status {
        text-align: center;
        @include flex-config($justify-content: center, $align-items: center);
    }

    .battle-lobby__detail-row-result {
        padding-right: 0.3rem;
        @include flex-config($justify-content: flex-end, $align-items: center);
    }

    .battle-lobby__detail-row--header {
        padding-right: 1rem;
    }

    .battle-lobby__detail-content {
        max-height: 16rem;
        width: 100%;
        overflow: auto;
        padding-right: 1rem;
        position: relative;
    }

    .battle-lobby__detail-row {
        margin-top: 1rem;
    }

    .battle-lobby__total-result {
        @include flex-config($flex-direction: column, $justify-content: space-between, $align-items: center);
        width: 90%;
        z-index: 2;
    }

    .battle-lobby__total-result-text {
        padding: 2rem;
    }
}

%G-battle-lobby--tablet {
    width: 40%;
}

/*
|--------------------------------------------------------------------------
| == 1.1.4 BACK BTN
|--------------------------------------------------------------------------
*/

%G-slots-battles-back-btn {
    @include flex-config($align-items: center);
    font-size: 1.5rem;
    cursor: pointer;

    .slots-battles-back-btn__icon {
        margin-right: 1rem;
        transform: rotate(180deg);
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.5 BATTLES TYPES MENU
|--------------------------------------------------------------------------
*/

%G-slots-battles-types-menu {
    @include size(100%);
    @include flex-config($align-items: center, $justify-content: center);

    @media screen and (max-width: 374px) {
        .slots-battles-types-menu__item .slots-battles-types-menu__game-type {
            font-size: 1.3rem;
        }
    }

    .slots-battles-types-menu__game-type {
        font-size: 1.6rem;
    }

    .slots-battles-types-menu__questions {
        position: relative;
        @include flex-config($align-items: center, $justify-content: center);
    }

    .slots-battles-types-menu__questions-icon {
        font-size: 2rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.6 SLOT BATTLES DROPDOWN
|--------------------------------------------------------------------------
*/

%slots-battles-dropdown {
    .slots-battles-dropdown__wrapper {
        @include flex-config($justify-content: space-between, $align-items: center);
        height: 3.6rem;
        padding: 0.9rem 1rem;
        border: none;
        border-radius: 0.3rem;
        font-size: 1.4rem;
        user-select: none;

        &.ll-dropdown-wrapper--disabled {
            cursor: not-allowed;
        }
    }

    .ll-dropdown-wrapper--opened {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .ll-dropdown-menu__icon,
    .ll-dropdown-menu__icon--opened {
        font-size: 2rem;
        margin-right: -0.5rem;
        transition: transform .2s ease-in-out;
    }

    .ll-dropdown-menu__icon--opened {
        transform: rotate(-180deg);
    }

    .slots-battles-dropdown__content {
        width: 100%;
    }

    .slots-battles-dropdown__scrollbox {
        max-height: 20rem;
        width: 100%;
        border-bottom-left-radius: .3rem;
        border-bottom-right-radius: .3rem;
    }

    .slots-battles__dropdown-item {
        height: 100%;
        padding: 1rem;
        text-transform: capitalize;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.7 FILTERS + SEARCH
|--------------------------------------------------------------------------
*/

// Filter

%G-slots-battles-filter {
    .slots-battles-dropdown__wrapper {
        width: 15rem;
    }
}

// Search

%G-slots-battles-search {
    @include flex-config($align-items: center, $justify-content: center);
    height: 3.6rem;
    border-radius: .3rem;
    text-transform: capitalize;

    .dx-texteditor-input {
        height: 3.6rem;
        padding: 0 !important;
    }

    .dx-placeholder {
        &::before {
            padding: 0 !important;
        }
    }
}

%G-slots-battles-search--desktop {
    width: 19rem;
    margin-left: .8rem;
    padding: 0 .8rem;
    font-size: 1.6rem !important;

    .dx-texteditor-input,
    .dx-placeholder::before {
        font-size: 1.6rem !important;
    }

    .slots-battles__search-icon::before {
        font-size: 1.3rem;
    }
}

%G-slots-battles-search-dummy {
    @include size(3.6rem);
}

%G-slots-battles-search--mobile {
    @include size(3.6rem);
    transition: all .4s ease-in-out;
    position: absolute;
    right: 0;
    z-index: 101;

    .slots-battles__search-icon {
        display: inline-block;
        margin: 0 1.2rem;
    }

    &.open {
        width: 108%;

        .slots-battles-search__input {
            display: flex;
        }
    }

    .slots-battles-search__input-wrp {
        width: 100%;
        @include flex-config($justify-content: flex-end, $align-items: center);
    }

    .slots-battles-search__input {
        display: none;

        .slot-battle__search {
            padding: 0;
        }

        .dx-placeholder {
            display: flex;
            align-items: center;
        }

        .ll-input__textbox {
            input,
            .dx-placeholder::before {
                padding: 0 !important;
            }
        }

        input {
            height: 3.8rem;
        }
    }

    .slots-battles__search-icon::before,
    .dx-texteditor-input,
    .dx-placeholder::before {
        font-size: 1.4rem !important;
    }

    .dx-texteditor-input,
    .dx-placeholder::before {
        margin-left: 1.2rem;
    }
}

// Filter + Search Wrapper

%G-slots-battles__filters-search {
    @include size(100%);
    @include flex-config($align-items: center, $justify-content: space-between);
    position: relative;

    .filter-btn-search__item {
        @include flex-config();
    }

    .slots-battles__filters-wrp {
        @include flex-config($align-items: center, $justify-content: space-around);
    }

    .slots-battles-filter {
        margin: 0 1.5rem;
    }
}

%G-slots-battles__filters-search--desktop {
    .slots-battles__filters-wrp {
        text-transform: capitalize;
    }
}

%G-slots-battles__filters-search--tablet--mobile {
    .slots-battles__filters-wrp.slots-battles__filters-wrp--details {
        width: auto;
        position: relative;
        left: 0;

        .slots-battles-filter {
            margin: 0 .3rem;
        }
    }
}

%G-slots-battles__filters-search--tablet {
    .slots-battles__filters-wrp {
        justify-content: space-between;
    }

    .slots-battles-filter {
        margin: 0 .5rem;

        &:first-of-type {
            margin-left: 0;
        }
    }

    .slots-battles-dropdown__wrapper {
        width: 13rem;
        padding: .9rem 1rem;
    }

    .slots-battles-search--desktop .dx-texteditor-input,
    .slots-battles-search--desktop .dx-placeholder::before {
        padding: 1.1rem 0 !important;
        font-size: 1.4rem !important;
    }

}

%G-slots-battles__filters-search--mobile {
    .slots-battles__filters-wrp {
        height: 100%;
        width: calc(100vw - 6.5rem);
        @include flex-config($align-items: center, $justify-content: space-around);
        @include position(absolute, top 0 left -80rem, $z-index: 97);
        transition: left 1s ease-in-out;
    }

    @include mq('(max-width: 480px)') {
        .slots-battles__filters-wrp {
            transition-duration: .7s;
        }
    }

    .slots-battles__filters-wrp--details {
        z-index: 100;

        .slots-battles-dropdown__wrapper {
            width: calc((100vw - 12rem) / 2);
        }
    }

    .filter-btn-search__item.open {
        .slots-battles__filters-wrp {
            left: 4.5rem;
        }
    }

    .slots-battles-filter:only-child .slots-battles-dropdown__wrapper {
        width: 18rem;
        position: relative;
        left: calc(50% - 2rem);
        transform: translateX(-50%);
    }

    .slots-battles-dropdown__wrapper {
        width: calc(50vw - 4rem);
        padding: 0.9rem 1rem;
    }

    .slots-battles-dropdown__content {
        transform: translate3d(0, 36px, 0) !important;
    }

    .slots-battles-filter {
        margin: 0 .5rem;

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.body--with-cursor {
    .slots-battles-dropdown__wrapper {
        cursor: pointer;
    }

    .slots-battles__search-icon {
        cursor: pointer;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.8 SLOTS BATTLES TOP MENU
|--------------------------------------------------------------------------
*/

%G-slots-battles-top-menu {
    .slots-battles__create-btn {
        font-size: 1.5rem;
        text-transform: uppercase;

        &[disabled] {
            opacity: .5;
        }
    }

    .slots-battles__text {
        font-size: 1.6rem;
        font-weight: 500;
    }
}

%G-slots-battles-top-menu--desktop {
    @include flex-config($justify-content: center);
    height: 5.4rem;
    text-transform: uppercase;

    .slots-battles-top-menu__content {
        @include flex-config($justify-content: space-between, $align-items: center);
    }

    .slots-battles-top-menu__center {
        @include flex-config($justify-content: space-between);
    }

    .slots-battles-top-menu__content > *:not(.slots-battles-top-menu__center) {
        flex: 1;
    }

    .slots-battles-top-menu__left {
        position: relative;
    }

    .slots-battles-top-menu__right {
        @include flex-config($align-items: center, $justify-content: flex-end);
    }

    .slots-battles-types-menu {
        justify-content: flex-start;
    }

    .slots-battles-types-menu__item {
        padding: 0 2rem;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            border: none;
        }
    }

    .slots-battles__filters-search {
        justify-content: flex-end;
    }

    .slots-battles-types-menu__questions {
        padding: 0 2rem;

        .battle-tutorial-popup {
            width: 50rem;
            left: calc(75% - 25rem);
        }

        .battle-tutorial-popup__parent-indicator--center {
            left: 24rem;
        }

        &:last-child {
            border: none;
        }
    }
}

%G-slots-battles-top-menu--tablet-mobile {
    font-size: 1.5rem;
    text-transform: capitalize;

    .filter-btn-search__item,
    .filter-btn-search__item-search,
    .slots-battles-types-menu__questions,
    .slots-battles__provider-dropdown {
        border-radius: 0.3rem;
    }

    .slots-battles__create-btn {
        height: 3.6rem;
    }

    .slots-battles-top-menu__filter-btn-search {
        @include flex-config($flex-direction: row, $justify-content: space-between, $align-items: center);
        position: relative;
        height: 5rem;

        .slots-battles__provider-dropdown {
            @include mq('(max-width: 374px)') {
                .slots-battles__dropdown-wrapper {
                    padding: .9rem .6rem;
                }
            }

            &:first-of-type {
                margin: 0 auto;
            }
        }

        .filter-btn-search__item-filter {
            @include flex-config($justify-content: center, $align-items: center);
            @include size(3.2rem);
        }

        .filter-btn-search__item-button {
            @include size(16rem, 3.2rem);

            &[disabled] {
                opacity: .5;
            }
        }

        .filter-btn-search__item-search {
            @include flex-config($justify-content: center, $align-items: center);
            @include size(3.6rem);
            padding: 0 1rem;
            transition: all 0.3s ease-out;

            .filter-btn-search__item-search-btn {
                @include flex-config($justify-content: center, $align-items: center);
            }
        }
    }

    .slots-battles-top-menu__slot-category {
        @include flex-config($flex-direction: row, $justify-content: space-between, $align-items: center);
        height: 5rem;
        position: relative;

        .slots-battles-top-menu__category {
            @include flex-config($justify-content: center, $align-items: center);
            @include size(100%);
            text-transform: capitalize;
        }

        .slots-battles-top-menu__category--active {
            font-weight: 500;
        }

        .slots-battles-top-menu_border {
            width: 0.1rem;
            height: 1.5rem;
        }
    }

    .slots-battles-back-btn {
        padding: 1.5rem 0;
        font-size: 1.4rem;
    }

    .slots-battles-types-menu {
        position: relative;
    }

    .slots-battles-types-menu__item {
        padding: 0 2rem;

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            border: none;
        }
    }

    .slots-battles-types-menu__questions {
        @include size(3.6rem, 3.6rem);
        transition: all 0.4s ease-in-out;
        @include position(absolute, top .6rem right 0);
        @include flex-config($justify-content: center, $align-items: center);
    }

    .slots-battles-types-menu__questions-icon {
        font-size: 1.4rem;
        margin: 0 1.2rem;
    }
}

%G-slots-battles-top-menu--tablet {
    .slots-battles__provider-dropdown {
        @include size(12rem, 3.2rem);
        margin: 0 1rem;

        &:first-of-type {
            margin: 0 auto;
        }

        .slots-battles__dropdown-wrapper {
            @include size(12rem, 3.2rem);
        }
    }

    .filter-btn-search__item-search-input {
        display: flex;
    }

    .filter-btn-search__item-filter {
        @include flex-config($justify-content: center, $align-items: center);
        @include size(3.2rem);

    }

    .filter-btn-search__item-button {
        @include size(16rem, 3.2rem);

        &[disabled] {
            opacity: .5;
        }
    }

    .filter-btn-search__item-search {
        @include flex-config($justify-content: center, $align-items: center);
        @include size(3.2rem);
        padding: 1rem;
    }

    .filter-btn-search__item {
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 1rem 0;

        &:nth-child(1) {
            justify-content: flex-start;
            width: 30rem;
        }

        &:nth-child(2) {
            justify-content: center;
            width: 16rem;
        }

        &:nth-child(3) {
            justify-content: flex-end;
            width: 30rem;
        }

        .filter-btn-search__item-search {
            width: 100%;
        }

        .filter-btn-search__item-search-input {
            display: flex;
        }
    }

    .slots-battles-search {
        padding: 0 1rem;
    }

    .slots-battles__filters-search {
        & > div {
            width: auto;
            flex: 1;
            text-align: center;
        }

        .slots-battles__create-btn {
            margin: 0 3rem;
        }
    }

    .slots-battles-filter:only-child .slots-battles-dropdown__wrapper {
        width: 25rem;
    }

    .battle-tutorial-popup {
        width: 31rem;
    }

    .slots-battles-types-menu__questions .battle-tutorial-popup {
        width: 52rem;
    }

    .battle-tutorial-popup.battle-tutorial-popup--left {
        left: calc(50% - 15.5rem);
    }

    .battle-tutorial-popup .battle-tutorial-popup__parent-indicator--right {
        right: 1rem;
    }
}

%G-slots-battles-top-menu--mobile {
    .filter-btn-search__icon-wrp {
        position: relative;
        z-index: 99;

        &::before {
            content: '';
            position: absolute;
            left: -5rem;
            @include size(5rem, 3.6rem);
        }
    }

    .filter-icon {
        border-radius: .3rem;
    }

    .slots-battles-search--mobile {
        z-index: 95;

        &.open {
            width: 100%;
            z-index: 104;
        }
    }

    .slots-battles-top-menu__slot-category {
        justify-content: center;
    }

    .slots-battles-types-menu {
        justify-content: flex-start;
    }

    .battle-tutorial-popup {
        transform: translateY(calc(100% + .5rem));
        @include size(31rem, 40vh);
        padding: 2rem;
    }

    @media (max-width: 374px) {
        .slots-battles-types-menu__item {
            padding: 0 1.5rem;
        }

        .battle-tutorial-popup {
            width: 100%;
            min-width: 20rem;
        }
    }
}

.body--with-cursor {
    .slots-battles-types-menu__item,
    .slots-battles-types-menu__questions {
        cursor: pointer;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.9 GAME ITEM
|--------------------------------------------------------------------------
*/

%G-slots-battles-game-item {
    position: relative;
    border-radius: 0.3rem;
    cursor: pointer;

    .slots-battles-game-item__wrp {
        @include size(100%);
        @include flex-config($align-items: center);
        position: relative;
    }

    .slots-battles-game-item__img-wrp {
        @include size(100%);
        border-radius: 0.3rem;
        overflow: hidden;
    }

    .slots-battles-game-item__info-section {
        @include flex-config($flex-direction: column, $justify-content: flex-start);
        flex: 1;
        position: relative;
    }

    .slots-battles-game-item__info {
        @include flex-config($flex-direction: column, $justify-content: center);
    }

    .slots-battles-game-item__info-item {
        @include flex-config($justify-content: space-between, $align-items: center);
    }

    .slots-battles-game-item__info-item--accent {
        .ll-popover-wrapper {
            -webkit-line-clamp: 2;
        }
    }

    .slots-battles-game-item__btns {
        @include flex-config($justify-content: space-between);
        @include position(absolute, bottom 0 left 0);
        width: 100%;

        .slots-battles-game-item__join-btn {
            width: 100%;
        }
    }

    .slots-battles-game-item__leave-btn,
    .slots-battles-game-item__join-btn {
        text-transform: uppercase;
        margin: 0.8rem 0 0;
        width: 100%;

        &[disabled] {
            opacity: .5;
        }
    }

    .slots-battles__private-pass-form {
        @include position(absolute, top 0 left 0);
        @include size(100%);
        @include flex-config($justify-content: center, $align-items: center);
        z-index: 2;
    }

    .slots-battles__private-pass-form-inner {
        @include flex-config($justify-content: center, $align-items: center, $flex-direction: column);
        border-radius: .3rem;

        .ll-input__label {
            left: 1rem;
        }
    }

    &.slots-battles-game-item--completed {
        .slots-battles-game-item__info-section {
            justify-content: center;
        }
    }

    &.slots-battles-game-item--started {

        .slots-battles-game-item__info-item--played {
            min-height: 2.3rem;

            .ll-popover-wrapper {
                -webkit-line-clamp: 3;
            }
        }
    }

}

%G-slots-battles-game-item--desktop {
    min-width: 39.5rem;
    padding: 2rem;

    .slots-battles-game-item__wrp {
        gap: 2rem;
    }

    .slots-battles-game-item__img-section {
        min-width: 14rem;
        max-width: 14rem;
        height: 18.5rem;
    }

    .slots-battles-game-item__info-section {
        height: 18.5rem;
        flex: 1 1;
        font-size: 1.6rem;
    }

    .slots-battles-game-item__info {
        gap: 1rem;
    }

    .slots-battles-game-item__info-item--accent {
        min-height: 4.5rem;
    }

    .slots-battles__private-pass-form {
        width: 21.5rem;
        height: 14rem;
        padding: 0;
        left: -1rem;
    }

    .slots-battles__private-pass-form-inner {
        @include size(100%);
        padding: 0;

        .ll-input {
            padding: 0 1rem;
            margin-bottom: 3rem;
        }
    }

    .slots-battles__private-pass-form-buttons {
        @include flex-config($justify-content: center, $align-items: center);
        width: 100%;
        gap: 2rem;
        padding: 0 1rem;

        .ll-btn2 {
            width: 100%;
            flex: 1 1;
            margin: 0;
        }
    }

    .slots-battles-lock {
        left: 12rem
    }

}

%G-slots-battles-game-item--tablet--mobile {
    min-width: 35.5rem;
    padding: 1rem;

    .slots-battles-game-item__wrp {
        gap: 1rem;
    }

    .slots-battles-game-item__img-section {
        min-width: 11rem;
        max-width: 11rem;
        height: 14.5rem;
    }

    .slots-battles-game-item__info-section {
        height: 14.5rem;
        flex: 1 1;
        font-size: 1.4rem;
    }

    .slots-battles-game-item__info {
        gap: 0.5rem;
    }

    .slots-battles-game-item__info-item--accent {
        min-height: 4rem;
    }


    .slots-battles__private-pass-form {
        padding: 0;
    }

    .slots-battles__private-pass-form-inner {
        @include size(100%);
        padding: 0;

        .ll-input {
            padding: 0 1rem;
            margin-bottom: 3rem;
        }
    }

    .slots-battles__private-pass-form-buttons {
        @include flex-config($justify-content: center, $align-items: center);
        width: 100%;
        gap: 1rem;
        padding: 0 1rem;

        .ll-btn2 {
            width: 100%;
            flex: 1 1;
            margin: 0;
        }
    }

    .slots-battles-lock {
        left: 9rem
    }

    @media (max-width: 354px) {
        min-width: 20rem;

        .slots-battles-game-item__info-section {
            font-size: 1.2rem;
        }

        .slots-battles__private-pass-form-buttons {
            flex-direction: column;
        }
    }

}

/*
|--------------------------------------------------------------------------
| == 1.1.10 CHECKBOX
|--------------------------------------------------------------------------
*/

%G-slots-battles-checkbox {
    height: 3.6rem;
    margin-top: -2rem;
    margin-bottom: 1rem;
    cursor: pointer;

    &.slots-battles-checkbox__start-partial {
        margin-bottom: 2rem;
    }

    &.slots-battles-checkbox--tablet,
    &.slots-battles-checkbox--mobile {
        margin-top: -1rem;
        margin-bottom: 2rem;
    }

    .dx-checkbox-container {
        margin-bottom: 2rem;
        @include flex-config($align-items: center);
    }

    .dx-checkbox-icon {
        margin: 0 .5rem 0 .3rem;
        border-radius: .3rem;
        @include size(1.5rem);
    }

    .dx-checkbox-text {
        @include flex-config($align-items: center);
    }

    &.slots-battles-checkbox--desktop .dx-checkbox-text {
        font-size: 1.6rem;
    }

    &.slots-battles-checkbox--tablet .dx-checkbox-text {
        font-size: 1.4rem;
    }

    &.slots-battles-checkbox--mobile .dx-checkbox-text {
        font-size: 1.3rem;
    }

    .dx-invalid-message-content {
        min-width: 30rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.11 PRIVATE PASS
|--------------------------------------------------------------------------
*/

%G-slots-battles__private-pass {
    @include size(100%, 3.6rem);
    margin-bottom: 3rem;
    border-radius: 0.3rem;
}

/*
|--------------------------------------------------------------------------
| == 1.1.12 BRANDLOGO + LOCK
|--------------------------------------------------------------------------
*/

%G-slots-battles-lock {
    @include position(absolute, top 1rem right 1rem);
    text-shadow: 0 0 3px rgba(0, 0, 0, 1);

    &.slots-battles-lock--desktop {
        font-size: 1.8rem;

    }

    &.slots-battles-lock--tablet,
    &.slots-battles-lock--mobile {
        font-size: 1.9rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.13 SHARE BTN TOOLTIP
|--------------------------------------------------------------------------
*/

%G-slot-battles-copy-tooltip {
    backdrop-filter: blur(2px);
    border-radius: 3px;

    .slot-battles-copy-tooltip__text {
        padding: 1rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.14 BANNER
|--------------------------------------------------------------------------
*/

%G-slots-battles-banner {
    height: 14rem;
    width: 100%;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;

    .slots-battles-banner__loader-container {
        @include size(100%);
        height: 14rem;
        @include flex-config($justify-content: center, $align-items: center);
    }

    .slots-battles-banner__item {
        @include size(100%);
        display: block;
        background-repeat: no-repeat;
    }

    .slots-battles-banner__item--custom {
        background-size: cover;
        background-position: center;
    }

    .slots-battles-banner__item--default {

        @include flex-config($justify-content: center, $align-items: center);
        background-position: center;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.15 TUTORIAL
|--------------------------------------------------------------------------
*/

%G-ll-modal--battles-tutorial {
    border-radius: 3px;
    overflow: hidden;
    width: 90vw;

    &.ll-modal--battles-rules {
        height: 90vh;

        .ll-modal__custom-content {
            height: 100%;
        }

        .battle-tutorial-modal {
            display: flex;

            &.battle-tutorial-modal--desktop {
                height: 100%;
            }

            &.battle-tutorial-modal--tablet,
            &.battle-tutorial-modal--mobile {
                height: calc(100% - 8rem);
            }

            &.battle-tutorial-modal--tablet {
                .ll-btn__content {
                    white-space: nowrap;
                }
            }
        }

        .battle-tutorial-modal__content {
            flex: 1;
            height: auto;
        }

        .ll-modal__scrollbox--tablet {
            max-height: unset;
        }
    }

    &.ll-modal--desktop {
        width: 60vw;
        max-width: 91rem;
    }

    &.ll-modal--battles-tutorial--first-step {
        width: 60rem;

        .battle-tutorial-modal {
            height: auto;
        }
    }

    .ll-scrollbox--mobile {
        max-height: 90vh;
    }

    .battle-questions--mobile {
        width: 100%;

        .battle-questions__buttons {
            @include flex-config($justify-content: space-around, $align-items: center);
        }

        @media (max-width: 389px) {
            .battle-questions__buttons {
                @include flex-config($justify-content: space-around, $align-items: center);
                width: 100%;
                margin: auto;
            }
        }

        @media (max-width: 374px) {
            .battle-questions__buttons {
                @include flex-config($justify-content: space-around, $align-items: center, $flex-direction: column);
                width: 100%;
                margin: auto;

                .slots-battles__btn--accent {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

%G-battle-tutorial-modal {
    @include flex-config($justify-content: center, $align-items: flex-start, $flex-direction: column);
    width: 70rem;
    height: 40rem;

    &.battle-rules-modal {
        display: block;

        .battle-tutorial-modal__content {
            height: 60vh;
        }

        @media (max-width: 389px) {
            .battle-tutorial-modal__content {
                height: calc(100% - 14rem) !important;
            }

            .battle-tutorial-modal__buttons {
                @include flex-config($justify-content: space-around, $align-items: center, $flex-direction: column);
                width: 100%;
                margin: auto;

                .slots-battles__btn--outlined {
                    margin: 3rem 0 0 0;
                }
            }
        }
    }

    .battle-tutorial-modal__content {
        width: 100%;
        overflow: auto;
        margin-bottom: 3rem;
        white-space: pre-wrap;

        p {
            margin-bottom: 1rem;
            font-size: 1.4rem;
        }

        .battle-tutorial-modal__content-video {
            max-width: 63rem;
        }

        &.battle-tutorial-modal__video {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        body *::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: transparent;
        }

        body *::-webkit-scrollbar-thumb {
            background-color: #F5782Ccc;
        }
    }

    .battle-tutorial-modal__buttons {
        @include flex-config($justify-content: center, $align-items: center);
        width: 50%;
        margin: 0 auto;
    }

    .slots-battles__btn {
        margin-left: 3rem;
    }

    .battle-tutorial-modal__instruction-container {
        @include flex-config($justify-content: center, $align-items: center);
        height: 100%;
    }

    .battle-tutorial-modal__part {
        @include flex-config($justify-content: space-between, $flex-direction: column);
        flex: 1;
        height: 100%;

        &:first-child {
            padding-right: 1rem;
        }
    }

    .battle-tutorial-game-instruction-img {
        height: 20rem;
        width: 100%;
    }

    .battle-tutorial-modal__freespin-instruction,
    .battle-tutorial-modal__freespin-instruction-tip,
    .battle-tutorial-modal__freespin-instruction-btn {
        @include flex-config($justify-content: center, $align-items: center, $flex-direction: column);
    }

    .battle-tutorial-modal__freespin-instruction-tip,
    .battle-tutorial-modal__freespin-instruction-btn {
        border-radius: 3px;
        padding: 1rem;
    }

    .battle-tutorial-modal__freespin-instruction-btn {
        width: 13.7rem;
        text-align: center;
    }

    .battle-tutorial-modal__freespin-instruction-tip {
        position: relative;
        margin-bottom: 2rem;
        font-size: 1.4rem;
        line-height: 1.4rem;
        font-weight: 500;

        span {
            font-weight: bold;
        }
    }

    .battle-tutorial-modal__freespin-instruction-tip--bottom {
        width: 22rem;
        margin-bottom: 0;
    }

    .battle-tutorial-modal__parent-indicator {
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        transform: rotate(45deg);

        &.battle-tutorial-modal__parent-indicator--bottom {
            bottom: -.7rem;
            left: calc(50% - .35rem);
        }

        &.battle-tutorial-modal__parent-indicator--center {
            left: 19rem;
        }

        &.battle-tutorial-modal__parent-indicator--right {
            right: -.7rem;
            transform: rotate(-45deg);
        }

        &.battle-tutorial-modal__parent-indicator--left {
            left: -.7rem;
            transform: rotate(135deg);
        }

        &.battle-tutorial-modal__parent-indicator--top {
            left: 19rem;
            top: -.7rem;
            transform: rotate(225deg);
        }
    }

    .battle-tutorial-modal__freespin-instruction-btn {
        text-transform: uppercase;
        font-weight: bold;
        padding: 1.5rem 0;

        p:first-of-type {
            font-size: 1.4rem;
        }

        p:last-of-type {
            font-size: 3rem;
        }
    }

    .battle-tutorial-modal__text-instruction {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 2rem;
    }

    .battle-tutorial-modal__bet-instruction {
        height: 7.4rem;
        align-self: flex-end;
        @include flex-config($flex-direction: row-reverse);
    }

    .battle-tutorial-modal__bets-count {
        @include flex-config($align-items: center);
        margin-left: 2rem;
    }

    .battle-tutorial-modal__bets-count-icon {
        @include flex-config($justify-content: center, $align-items: center);
        border-radius: 50%;
    }

    .battle-tutorial-modal__bets-count-icon--small {
        @include size(4rem);
        font-size: 1.1rem;
    }

    .battle-tutorial-modal__bets-count-icon--big {
        @include size(6.4rem);
        margin: 0 1rem;
        font-size: 3rem;
    }
}

%G-battle-tutorial-modal--desktop {
    .battle-tutorial-modal__part:last-of-type {
        flex: 4;
    }
}

%G-battle-tutorial-modal--mobile {
    height: 60vh;

    &.battle-tutorial-second-modal {
        min-height: 54rem;
    }

    &.battle-rules-modal.battle-tutorial-modal {
        height: 50vh;
    }

    .battle-tutorial-modal__freespin-instruction {
        flex-direction: row-reverse;
        margin: auto;
    }

    .battle-tutorial-modal__freespin-instruction-btn {
        margin-right: 2rem;

        p:first-of-type {
            font-size: 1.2rem;
        }

        p:last-of-type {
            font-size: 2.4rem;
        }
    }

    .battle-tutorial-modal__freespin-instruction-tip,
    .battle-tutorial-modal__freespin-instruction-btn {
        padding: .5rem 1rem;
        width: 13rem;
    }

    .battle-tutorial-game-instruction-img {
        height: 13rem;
    }

    .battle-tutorial-modal__freespin-instruction-tip {
        height: 100%;
        margin-bottom: 0;
        line-height: normal;
    }

    .battle-tutorial-modal__bet-instruction {
        margin: 0 auto;
        flex-direction: column;
        height: auto;

        .battle-tutorial-modal__bets-count {
            margin: 0 auto 2rem;
        }

        .battle-tutorial-modal__bets-count-icon--small {
            font-size: 1.6rem;
        }

        .battle-tutorial-modal__bets-count-icon--big {
            font-size: 3.4rem;
        }

        .battle-tutorial-modal__freespin-instruction-tip--bottom {
            width: 20rem;
        }

        .battle-tutorial-modal__parent-indicator--top {
            left: 9.5rem;
        }
    }

    .slots-battles__btn--accent {
        font-size: 1.6rem;
    }

    &.battle-rules-modal .battle-tutorial-modal__content {
        height: 80%;
    }

    .battle-tutorial-modal__buttons {
        margin: 0 auto;
    }

    .battle-tutorial-modal__freespin-instruction-tip--bottom {
        margin-bottom: 2rem;
    }

    &.battle-tutorial-second-modal {
        align-items: center;
    }

    .battle-tutorial-modal__buttons {
        width: 100%;
    }

    @media (max-width: 374px) {
        &.battle-tutorial-second-modal {
            min-height: 51rem;
        }

        .battle-tutorial-modal__text-instruction {
            font-size: 1.3rem;
            margin-bottom: 1rem;
        }

        .battle-tutorial-modal__freespin-instruction-tip,
        .battle-tutorial-modal__freespin-instruction-btn {
            padding: .5rem 1rem;
            width: 11rem;
            font-size: 1.2rem;
        }

        .battle-tutorial-modal__freespin-instruction-btn {
            margin-right: 1rem;

            p:first-of-type {
                font-size: 1rem;
            }

            p:last-of-type {
                font-size: 2rem;
            }
        }

        .battle-tutorial-game-instruction-img {
            height: 11rem;
        }

        .battle-tutorial-modal__buttons {
            justify-content: space-around;
        }
    }
}

%G-battle-tutorial-modal--tablet {
    @include size(70rem, 60rem);

    &.battle-rules-modal.battle-tutorial-modal {
        height: 50vh;
    }

    .battle-tutorial-modal__text-instruction {
        text-align: center;
    }

    .battle-tutorial-modal__freespin-instruction {
        flex-direction: row-reverse;
        margin: auto;

        .battle-tutorial-modal__freespin-instruction-btn {
            margin-right: 4rem;
            padding: .5rem 0;

            p:last-of-type {
                font-size: 2.8rem;
            }
        }
    }

    .battle-tutorial-modal__freespin-instruction-tip {
        height: 100%;
        width: 14rem;
        margin-bottom: 0;
        line-height: normal;
    }

    .battle-tutorial-modal__bet-instruction {
        margin: 0 auto;
        flex-direction: column;
        height: auto;

        .battle-tutorial-modal__bets-count {
            margin: 0 auto 2rem;
        }

        .battle-tutorial-modal__bets-count-icon--small {
            font-size: 1.6rem;
        }

        .battle-tutorial-modal__bets-count-icon--big {
            font-size: 3.4rem;
        }

        .battle-tutorial-modal__freespin-instruction-tip--bottom {
            width: 20rem;
        }

        .battle-tutorial-modal__parent-indicator--top {
            left: 9.5rem;
        }
    }

    .slots-battles__btn--accent {
        font-size: 1.6rem;
    }

    &.battle-rules-modal .battle-tutorial-modal__content {
        height: 80%;
    }

    .battle-tutorial-modal__buttons {
        margin: 0 auto;
    }

    .battle-tutorial-modal__freespin-instruction-tip--bottom {
        margin-bottom: 2rem;
    }

    &.battle-tutorial-second-modal {
        align-items: center;
    }
}

%G-battle-tutorial-popup {
    position: absolute;
    z-index: 1001;
    transform: translateY(calc(100% + 2.5rem));
    width: 40rem;
    padding: 2rem 2rem 3rem;
    border-radius: 3px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.5);

    .battle-tutorial--mobile {
        .battle-tutorial__content {
            height: calc(40vh - 11rem);
            display: block;
        }
    }

    &.battle-tutorial-popup--left {
        left: 0;
        bottom: 0;
    }

    &.battle-tutorial-popup--center {
        left: calc(75% - 20rem);
        bottom: 0;
    }

    &.battle-tutorial-popup--right {
        right: 0;
        bottom: 0;
    }

    &.battle-tutorial-popup--top {
        top: 0;
        left: 28.8rem;
        transform: none;
    }

    .battle-tutorial-popup__parent-indicator {
        position: absolute;
        z-index: 1000;
        width: 1rem;
        height: 1rem;
        transform: rotate(45deg);
    }

    .battle-tutorial-popup__parent-indicator--left {
        left: 2rem;
        top: -.5rem;
    }

    .battle-tutorial-popup__parent-indicator--center {
        left: 19rem;
        top: -.5rem;
    }

    .battle-tutorial-popup__parent-indicator--right {
        right: 2rem;
        top: -.5rem;
    }

    .battle-tutorial-popup__parent-indicator--bottom-left {
        left: 2rem;
        bottom: -.5rem;
    }

    .battle-tutorial-popup__parent-indicator--bottom-center {
        left: 19rem;
        bottom: -.5rem;
    }

    .battle-tutorial-popup__parent-indicator--left-top {
        left: -.5rem;
        top: 5rem;
    }

    .battle-tutorial__content {
        @include flex-config($justify-content: center, $align-items: center);
        height: 80%;
        width: 100%;
        overflow: auto;
        margin-bottom: 3rem;
        font-size: 1.4rem;
        text-transform: none;
    }

    .battle-questions__buttons,
    .battle-tutorial__buttons {
        @include flex-config($justify-content: center, $align-items: center);
        width: 50%;
        margin: auto;
    }

    .battle-questions__buttons {
        justify-content: space-around;
        width: 80%;
    }
}

%G-battle-tutorial-popup--desktop {
    .battle-questions__content {
        max-height: 36rem;
    }
}

%G-battle-tutorial-popup--tablet {
    bottom: 1.5rem;

    &.battle-tutorial-popup--top {
        transform: translateY(0);
    }
}

%G-battle-tutorial-popup--mobile {
    &.battle-tutorial-popup--top {
        top: 0;
        left: 0;
        transform: none;
    }
}

%G-battle-questions {
    .battle-questions__header {
        @include flex-config($justify-content: center, $align-items: center);
        @include position(relative, top -2rem left -2rem);
        width: calc(100% + 4rem);
        height: 5rem;

        .battle-questions__header-title {
            text-align: center;
            font-size: 1.6rem;
        }

        .battle-questions__header-close {
            @include position(absolute, top 1.8rem right 1.8rem);
            font-size: 1.4rem;
        }
    }

    .battle-questions__content {
        white-space: pre-wrap;
        text-transform: none;
        font-size: 1.4rem;
        margin-bottom: 3rem;
        overflow: auto;
    }
}

%G-battle-questions--tablet {
    .battle-questions__header {
        .battle-questions__header-title {
            font-size: 1.6rem;
        }

        .battle-questions__header-close {
            font-size: 1.4rem;
        }
    }

    .battle-questions__content {
        max-height: 40rem;
    }
}

%G-battle-questions--mobile {
    .battle-questions__header {
        .battle-questions__header-title {
            font-size: 1.6rem;
        }

        .battle-questions__header-close {
            font-size: 1.4rem;
        }
    }

    .battle-questions__content {
        max-height: calc(90vh - 20rem);
        margin-bottom: 2rem;
    }
}

html[lang="aa"] .battle-tutorial-popup p,
html[lang="aa"] .battle-tutorial-modal p {
    direction: rtl;
}

/*
|--------------------------------------------------------------------------
| == 1.2. PAGES
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 1.2.1 MAIN
|--------------------------------------------------------------------------
*/

%G-slots-battles {
    @include flex-config($flex-direction: column);

    .slots-battles__loading {
        @include size(100%);
        flex: 1;
        @include flex-config($justify-content: center, $align-items: center);
    }

    .slots-battles__content:not(.slots-battles__content--new) {
        &,
        .slots-battles-wrp,
        .slots-battles__list-wrapper {
            flex: 1;
            @include flex-config($flex-direction: column);
        }
    }

    .games-no-data {
        max-width: 100%;
        @include flex-config($flex-direction: column, $justify-content: space-around, $align-items: center);
    }

    .games-no-data__text {
        max-width: 100%;
        text-align: center;

        &.games-no-data__title {
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    .games-no-data--nobattle {
        justify-content: flex-start;

        .slots-battles__create-btn {
            text-transform: uppercase;
        }
    }

    .slots-battles__game-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, 39.5rem);
        justify-content: space-evenly;
        justify-items: center;
    }

    .games-no-data__steps {
        @include flex-config($justify-content: center, $align-items: flex-start);

        .games-no-data__step {
            position: relative;
            @include flex-config($justify-content: space-between, $align-items: center, $flex-direction: column);
            flex: 1;
            text-align: center;
            gap: 0.8rem;
        }

        .step-icon {
            position: relative;
            border-radius: 0.3rem;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .step-arrow {
            @include position(absolute, top 2.4rem);
            transform: translateY(-50%);
        }

        .step-text > .ll-popover-wrapper {
            -webkit-line-clamp: 2;
            word-break: break-word;
        }
    }
}

%G-slots-battles--desktop {
    flex: 1;

    .slots-battles__content {
        @include size(100%);
    }

    .slots-battles__content--new {
        flex: 1;
        width: 100%;
        background-position: center right;
        background-size: auto 100%;
        background-repeat: no-repeat;
    }

    .games-no-data {
        font-size: 1.6rem;
        margin: 0 auto;
    }


    .games-no-data__img {
        height: 30vh;
    }

    .slots-battles__game-list {
        grid-gap: 3rem;
    }

    .slots-battles__list-wrapper {
        margin: 2rem 0;
    }

    .games-no-data--nobattle {
        gap: 3rem;
    }

    .games-no-data__steps {
        gap: 3rem;

        .games-no-data__step {
            min-width: 9.6rem;
            max-width: 10.6rem;
            font-size: 1.6rem;
        }

        .step-icon {
            @include size(9rem, 6rem);
            font-size: 2.8rem;

        }

        .step-arrow {
            right: -2.5rem;
        }
    }
}

%G-slots-battles--tablet-mobile {
    .battle-info {
        gap: 2rem;
    }

    .games-no-data {
        margin: 0 auto;
    }

    .create-battle-wrapper__left-part {
        border-radius: 3px;
    }

    .slots-battles__list-wrapper {
        margin: 1rem 0;
    }

    .slots-battles__game-list {
        grid-template-columns: repeat(auto-fill, 35rem);

        .slots-battles-game-item {
            margin: 0;
        }
    }

    @media (max-width: 500px) {
        &.slots-battles--mobile {
            .slots-battles__game-list {
                grid-template-columns: repeat(auto-fill, 100%);

                .slots-battles-game-item {
                    width: 100%;
                }
            }
        }
    }

    .games-no-data--nobattle {
        gap: 2rem;
    }

    .games-no-data__steps {
        flex-wrap: wrap;
        gap: 2rem;

        .games-no-data__step {
            width: 10rem;
            font-size: 1.4rem;
        }

        .step-icon {
            @include size(6rem, 4rem);
            font-size: 2.5rem;
        }

        .step-arrow {
            right: -1.7rem;
        }
    }

    @media (max-width: 374px) {
        .slots-battles__game-list {
            grid-template-columns: repeat(auto-fill, 100%);
            grid-gap: 2rem;
            justify-items: center;

            .slots-battles-game-item {
                width: 100%;
            }
        }
    }
}

%G-slots-battles--tablet {
    flex: 1;

    .games-no-data__text {
        font-size: 1.6rem;
    }

    .games-no-data__img {
        width: 50%;
        margin: 2rem 1rem 7rem 1rem;
    }

    .slots-battles__game-list {
        grid-gap: 1rem;
    }

    .battle-info {
        grid-gap: 2rem;
    }

    @media screen and (max-width: 900px) {
        .battle-info {
            flex-direction: column;
            align-items: center;
        }
        .battle-lobby--tablet,
        .battle-info-card--tablet {
            max-width: 50rem;
            min-width: 50rem;
        }
    }
}

%G-slots-battles--mobile {
    flex: 1;

    .games-no-data__text {
        font-size: 1.6rem;
    }

    .games-no-data__img {
        width: 80%;
        margin: 2rem;
    }

    .slots-battles__game-list {
        justify-content: space-around;
        grid-gap: 1rem;
    }

    .battle-info {
        flex-direction: column;
    }

    .battle-info__first-part {
        width: 100%;
        align-self: center;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.2.2 CREATE/BATTLE-INFO
|--------------------------------------------------------------------------
*/

%G-battle-details {
    @include flex-config();
    width: 100%;
    gap: 3rem;
    padding: 2rem 0;
}

%G-create-battle {
    margin: 0 auto;

    .create-battle-wrapper__middle-right-part {
        flex: 1;
    }

    .battle-info__first-part {
        flex: 1;
        @include flex-config();
        gap: 3rem;
        position: relative;
    }

    .create-battle-wrapper__left-part,
    .create-battle-wrapper__middle-part,
    .create-battle-wrapper__middle-right-part {
        @include flex-config($justify-content: flex-start, $align-items: flex-start);
    }

    .create-battle-wrapper__left-part {
        justify-content: flex-end;
    }

    .create-battle-menu {
        @include flex-config($flex-direction: column);
        padding: 2rem;
        border-radius: 3px;

        .slots-battles-dropdown {
            margin-bottom: 3rem;
        }

        .slots-battles-dropdown__wrapper {
            width: 100%;
        }

        .create-battle-menu__form-item {
            height: 3.6rem;
            margin-bottom: 3rem;
            border: none;
            border-radius: 0.3rem;
            font-size: 1.6rem;

            .ll-btn__content {
                width: 100%;
                @include flex-config($justify-content: space-between, $align-items: center);
                padding: .5rem 1rem;
            }

            .ll-popover-wrapper {
                margin-right: .5rem;
            }
        }

        .create-battle-menu__form-item--active {
            .create-battle-menu__form-item-icon {
                transform: rotate(180deg);
            }
        }

        .create-battle-menu__form-item-icon {
            transform: rotate(0deg);
            font-size: 0.8rem;
        }

        .create-battle-menu__form-handle-btn,
        .create-battle-menu__form-handle-btn--disabled {
            @include size(100%, 3.6rem);
            border: none;
            border-radius: 0.3rem;
            font-size: 1.6rem;
            text-transform: uppercase;
        }

        .create-battle-menu__form-handle-btn--disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        .dx-invalid-message {
            z-index: 100 !important;
        }

        .create-battle-menu--popup-container {
            position: relative;
        }
    }

    .create-battle-games-list__init-loading {
        @include size(100%);
        @include flex-config($justify-content: center, $align-items: center);
    }

    .create-battle-games-list {
        width: 100%;
        padding: 2rem;
        border-radius: .3rem;

        .create-battle-games-list__header {
            @include flex-config($align-items: center, $justify-content: space-between);

            .create-battle-games-list__header-left {
                @include flex-config($align-items: center);
            }

            .create-battle-games-list__header-right {
                @include flex-config($align-items: center);
            }

            .create-battle-games-list__dropdown {
                margin: 0 15px;
            }
        }

        .create-battle-games-list__body {
            margin: 1rem 0;
            overflow: auto;
            height: calc(100% - 5rem);

            .create-battle-games-list__games-item {
                border-radius: 0.3rem;
                overflow: hidden;
                cursor: pointer;
                margin: 1rem 0;

                .game__provider-with-img .game-img__logo {
                    @include size(100%, auto);
                }

                .game__provider-bg-without-img {
                    @include size(100%);
                }
            }
        }

        .create-battle-games-list__close {
            @include flex-config();
        }

        .create-battle-games-list__close-btn {
            @include size(1.6rem);
            cursor: pointer;
        }

        .slots-battles__dropdown-wrapper {
            margin: 0;

            .ll-scrollbox--desktop,
            .ll-scrollbox--tablet,
            .ll-scrollbox--mobile {
                width: 14rem;

                .ll-dropdown-menu-item,
                .ll-dropdown-menu-item--active {
                    .slots-battles__dropdown-item {
                        height: 100%;
                        padding: 1rem;
                    }
                }
            }
        }
    }

    .filter-btn-search__item--tablet,
    .filter-btn-search__item--mobile {
        flex: 1;

        .slots-battles__filters-wrp--details {
            flex: 1;
        }
    }

    .slots-battles__search-input {
        @include flex-config($align-items: center, $justify-content: center);
        margin-left: .8rem;
        padding: 0 0.8rem;

        .slot-battle__search {
            margin-left: .8rem;
            padding: 0;
            cursor: pointer;
        }

        .dx-placeholder {
            display: flex;
            align-items: center;
        }

        .ll-input__textbox {
            border: none;
            box-shadow: none;

            input,
            .dx-placeholder::before {
                padding: 0 !important;
                font-size: 1.2rem;
            }
        }
    }
}

%G-create-battle--desktop {
    .create-battle-menu {
        width: 28rem;

        .create-battle-menu__form-item,
        .slots-battles-dropdown__wrapper {
            font-size: 1.6rem;
        }

        .create-battle-menu__form-handle-btn,
        .create-battle-menu__form-handle-btn--disabled {
            width: 100%;
            align-self: center;
        }

        .slots-battles__btn--accent:not([disabled]):hover::before {
            transform: scaleX(1.08);
        }
    }

    .slots-battles__battle-detail-block {
        width: 57.5rem;
        min-width: 51.5rem;
        height: 30rem;
    }

    .slots-battles__search {
        width: 19rem;
    }

    .create-battle-games-list__inner {
        margin-right: 1rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, 14.4rem);
        grid-column-gap: 1rem;
        justify-content: space-between;
    }

    .create-battle-games-list__games-item {
        height: 19rem;
    }
}

%G-create-battle--tablet {
    .create-battle-menu {
        width: 28rem;
        flex-shrink: 0;

        .create-battle-menu__form-item,
        .slots-battles-dropdown__wrapper {
            font-size: 1.4rem;
        }
    }

    .battle-info__first-part {
        gap: 2rem;
    }

    .create-battle-wrapper__middle-part {
        flex: 1;
    }

    .slots-battles__battle-detail-block {
        flex: 1;
        height: 30rem;
    }

    .create-battle-games-list .create-battle-games-list__header .slots-battles__search {
        width: auto;
    }

    .slots-battles__search {
        width: 19rem;
    }

    .create-battle-games-list__inner {
        @include flex-config($flex-wrap: wrap, $align-items: center, $justify-content: center);
        grid-column-gap: 2rem;
    }

    .create-battle-games-list__games-item {
        height: 19rem;
    }

    .battle-tutorial-popup--tablet {
        width: 28rem;
    }

    .battle-tutorial-popup__parent-indicator.battle-tutorial-popup__parent-indicator--bottom-center {
        left: 14rem;
    }
}

%G-create-battle--mobile {
    padding: 2rem 0;
    gap: 0;
    flex-direction: column;
    align-items: center;

    .battle-info__first-part {
        flex-direction: column;
        gap: 2rem;
    }

    .create-battle-menu {
        width: 100%;

        .create-battle-menu__form-item,
        .slots-battles-dropdown__wrapper {
            font-size: 1.4rem;
        }
    }

    .slots-battles__battle-detail-block {
        width: 100%;
    }

    .create-battle-games-list__header-left {
        margin-right: .5rem;
    }

    .create-battle-games-list__header-right {
        flex: 1;
    }

    .slots-battles__search-input {
        margin-left: 0;

        .dx-texteditor-input {
            margin-left: 0;
        }
    }

    .create-battle-games-list {
        height: 50vh;
        padding: 1rem;
    }

    .create-battle-games-list__inner {
        @include flex-config($flex-wrap: wrap, $align-items: center, $justify-content: center);
        grid-gap: 1rem;
    }

    .slots-battles__dropdown-wrapper {
        min-width: 9rem;
    }

    .slots-battles-filter {
        margin: 0 .5rem;
    }

    .slots-battles__search {
        width: 3.6rem;

        &:focus {
            width: 19rem;
        }
    }

    .create-battle-menu--popup-container,
    .battle-tutorial-popup {
        width: calc(100vw - 2.5rem);
    }

    .battle-tutorial-popup__parent-indicator--bottom-center {
        left: calc(50% - .25rem);
    }
}

.slots-battles__content--disabled {
    filter: grayscale(1);
}

