/*
1.GLOBAL
├── 1.1. INPUT
├── 1.2. BTN
├── 1.3. DROPDOWN MENU COMPONENT
├── 1.4. SCROLL UP
├── 1.5. PROGRESS BAR
├── 1.6. BREADCRUMBS
├── 1.7. MENU
├── 1.8. ACCORDION
├── 1.9. DATE PICKER - DATE RANGE PICKER
├── 1.10. PASSWORD STRENGTH INDICATOR
├── 1.11. NEW ITEM INDICATOR
├── 1.12. MENU ITEM (SLIDER MENU ITEM)
├── 1.13. WIDGETS
├── 1.14. DATA-TABLE
├── 1.16. COUPON PANEL
├── 1.17. SCROLLBOX
├── 1.18. SLIDER
├── 1.19. SCROLL SLIDER
├── 1.20. BETS LIST
├── 1.21. BURGER
├── 1.22. SWITCHER
├── 1.23. DOTS LOADING
├── 1.24. GAME ITEM
└── 1.25. WIDGET SPORT ITEM
*/

/*
|--------------------------------------------------------------------------
| == 1.GLOBAL
|--------------------------------------------------------------------------
*/

%G-body {
    line-height: 1.42857143;
    padding: 0;
    margin: 0;
    position: relative;

    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    a {
        color: inherit;
    }

    ul li {
        list-style: none;
    }

    .slick-list,
    .slick-track {
        touch-action: pan-y;
    }

    input::placeholder {
        color: transparent !important;
    }
}

%G-ll-preloader {
    width: 100%;
    height: 100%;
    opacity: 1;
    position: absolute;
    z-index: 99;
    transition: opacity 800ms ease-in-out;
    @include flex-config($align-items: center, $justify-content: center);

    &.ll-preloader__prematch-detail {
        transition: opacity 400ms ease-in-out;
    }

    &.ll-preloader-exit-active {
        opacity: 0;
    }
}

%G-content-container-wrapper {
    width: 99%;
    margin: 0 auto;
}

// scrollbar
.body--with-cursor {
    *::-webkit-scrollbar {
        @include size(0.5rem, 0.5rem);
    }

    *:not(.ll-content-container-scroll)::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    *::-webkit-scrollbar-thumb {
        outline: none;

    }

    *:not(.ll-content-container-scroll)::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }

    .scroll-slider__prev:hover,
    .scroll-slider__next:hover {
        opacity: .7;
    }
}

.ll-slider__container {
    position: relative;
    width: 100%;
    height: 100%;
}

.ll-slider {
    width: inherit;

    &.ll-slider--absolute {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

// layout
.ll-content-container--mobile,
.ll-content-container--tablet,
.ll-content-container--desktop {
    @include flex-config($flex-direction: column);
    position: relative;
}

.ll-content-container--desktop {
    height: 100vh;
    overflow: hidden;
}

.ll-content-container--mobile {
    overflow: auto;
}

.ll-content-container--mobile--small-desktop,
.ll-content-container--tablet--small-desktop {
    height: 100vh;
}

%G-page-blank--desktop {
    @include flex-config($align-items: center, $justify-content: center);

    .page-blank__box {
        margin: 10rem;
        text-align: center;
        @include flex-config($align-items: center, $justify-content: center, $flex-direction: column);
    }

    .page-blank__text {
        margin: 3rem 0;
        font-size: 3rem;
    }

    .page-blank__img {
        max-width: 50rem;
    }
}

%G-page-blank--mobile {
    @include flex-config($align-items: center, $justify-content: center, $flex-direction: column-reverse);

    .page-blank__box {
        margin: 0 5rem;
        text-align: center;
        @include flex-config($align-items: center, $justify-content: center, $flex-direction: column);
    }

    .page-blank__text {
        margin: 2rem 0;
        font-size: 1.6rem;
    }

    .page-blank__img {
        max-width: 20rem;
    }
}

%G-page-blank--tablet {
    .page-blank__img {
        max-width: 40rem;
    }

    .page-blank__text {
        font-size: 2rem;
    }
}

.page-wrapper--desktop {
    .ll-scrollbox {
        margin-right: 0.3rem;
    }
}

.coupons--swipe--empty {
    height: .5rem;
}

%G-page-wrapper--tablet-mobile {
    @include flex-config($flex-direction: column);
    position: relative;
    width: 100%;

    &.page-wrapper--with-search-filter-panel {
        margin-bottom: 0;
    }
}

%G-page-wrapper--desktop {
    @include flex-config($flex-direction: column);
    min-height: 100%;
    height: fit-content;
}

%G-ll-modal__scrollbox {
    max-height: 70vh;
    background-color: #fff;
}

/*
|--------------------------------------------------------------------------
| == 1.1. INPUT
|--------------------------------------------------------------------------
*/

%G-ll-input {
    margin: 0;
    border: 0;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 100%;

    label.ll-input__label--inside {
        font-size: 1.6rem;
    }

    label.ll-input__label--top {
        width: 100%;
        display: inline-block;
        padding: 1.5rem 0 .5rem 0;
        font-size: 1.6rem;
        text-align: center;
    }

    .dx-texteditor-input {
        padding: 10px 12px !important;
    }

    .dx-texteditor.dx-editor-filled {
        background-color: transparent;
    }

    .ll-input__textbox.ll-input__textbox--left {
        margin-bottom: 10px;
    }

    label.ll-input__label--inside {
        z-index: 1;
        transform: translate(14px, 8px) scale(1);
        pointer-events: none;
        transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        top: 0;
        left: 0;
        position: absolute;
    }

    &.ll-input--focused label.ll-input__label--inside,
    &.ll-input--labeled-up label.ll-input__label--inside {
        transform: translate(14px, -12px);
        padding: 0 3px;
        zoom: 0.75;
    }

    label.ll-input__label--left {
        width: 35%;
        display: inline-block;
        text-align: left;
    }

    .ll-input__text-box-container--top,
    .ll-input__text-box-container--inside {
        width: 100%;
    }

    .ll-input__text-box-container--left {
        width: 65%;
    }

    .ll-input__textbox--left,
    .ll-input__textbox--top {
        width: 100%;
        display: inline-block;
    }

    .ll-input__text-box-container {
        position: relative;
        display: inline-block;
    }

    .dx-invalid-message.dx-overlay-wrapper {
        visibility: visible !important;
        transform: none !important;
        transition: all 2s ease-in-out !important;
    }

    .dx-invalid-message .dx-overlay-content {
        margin: 0 0 -8px 0 !important;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.2. BTN
|--------------------------------------------------------------------------
*/

%G-ll-btn2 {
    @include flex-config($align-items: center, $justify-content: center);
    height: 3.2rem;
    position: relative;
    outline: none;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    text-transform: capitalize;
    font-family: inherit;

    .preloader-box {
        @include size(100%);
        @include flex-config($justify-content: center, $align-items: center);
        position: absolute;
        top: 0;
        z-index: -1;

        &.active {
            z-index: 1;
        }
    }

    .ll-btn__content {
        padding: 0.5rem 1.6rem;
        position: relative;
        opacity: 0.2;

        &.active {
            opacity: 1;
        }
    }

    &.ll-btn--full-rounded {
        height: 28px;
        padding: 0 22px;
        border-radius: 30px;
        text-transform: uppercase;
    }

    &.ll-btn--outlined {
        background: transparent;
        box-shadow: none;

        &:hover {
            opacity: 1;
        }
    }

    &.ll-btn--inline {
        background: transparent;
        box-shadow: none;
        border-radius: 0;

        &:hover {
            opacity: 1;
        }
    }

    &.ll-btn--square--mobile,
    &.ll-btn--square--tablet,
    &.ll-btn--square--desktop,
    &.ll-btn--square-outlined--mobile,
    &.ll-btn--square-outlined--tablet,
    &.ll-btn--square-outlined--desktop {
        @include flex-config(
                $display: inline-flex,
                $justify-content: center,
                $align-items: center
        );
        border-radius: 0;
        box-shadow: none;
        font-weight: 500;
    }

    &.ll-btn--square--mobile,
    &.ll-btn--square-outlined--mobile {
        padding: 10px 22px;
    }

    &.ll-btn--square--tablet,
    &.ll-btn--square--desktop,
    &.ll-btn--square-outlined--tablet,
    &.ll-btn--square-outlined--desktop {
        padding: 16px 20px;
    }

    &.ll-btn--square-outlined--mobile,
    &.ll-btn--square-outlined--desktop {
        background: transparent;
    }

    &.ll-btn--disabled {
        opacity: .5;
    }
}

.with-ripple-effect {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    &::after {
        content: "";
        display: block;
        position: absolute;
        min-width: 100px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scale(10, 10);
        opacity: 0;
        transition: transform .5s, opacity 1s;
    }

    &:active::after {
        transform: scale(0, 0);
        opacity: .2;
        transition: 0s;
    }
}

%G-ll-btn {
    padding: 0;
    border-radius: 3px;
    height: 32px;
    line-height: 32px;
    text-transform: capitalize;
    transition: all 0.3s;

    .dx-button-content {
        @include flex-config($align-items: center, $justify-content: center);
    }

    .dx-button-link {
        text-decoration: none;
        padding: 6px 16px;
    }

    &.ll-btn--accent:hover {
        opacity: 0.5;
    }

    &.ll-header-btn {
        border-radius: 30px;
        height: 28px;
        box-sizing: border-box;
    }

    &.ll-btn--full-rounded {
        height: 28px;
        padding: 0 22px;
        border-radius: 30px;
        text-transform: uppercase;
    }

    &.ll-btn--outlined {
        background: transparent;
        box-shadow: none;

        &:hover {
            opacity: 1;
        }
    }

    &.ll-btn--square--mobile,
    &.ll-btn--square--tablet,
    &.ll-btn--square--desktop,
    &.ll-btn--square-outlined--mobile,
    &.ll-btn--square-outlined--tablet,
    &.ll-btn--square-outlined--desktop {
        @include flex-config(
                $display: inline-flex,
                $justify-content: center,
                $align-items: center
        );
        border-radius: 0;
        box-shadow: none;
        font-weight: 500;
    }

    &.ll-btn--square--mobile,
    &.ll-btn--square-outlined--mobile {
        padding: 10px 22px;
    }

    &.ll-btn--square--tablet,
    &.ll-btn--square--desktop,
    &.ll-btn--square-outlined--tablet,
    &.ll-btn--square-outlined--desktop {
        padding: 16px 20px;
    }

    &.ll-btn--square-outlined--mobile,
    &.ll-btn--square-outlined--desktop {
        background: transparent;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.3. DROPDOWN MENU COMPONENT
|--------------------------------------------------------------------------
*/

%G-ll-dropdown-wrapper {
    position: relative;

    .ll-dropdown-scrollbox {
        width: 100%;
        @include flex-config($flex-direction: column);
    }

    &.ll-dropdown-wrapper--with-accordion {
        flex-direction: column;

        .ll-dropdown-menu {
            width: 100%;
        }

        .ll-dropdown-menu-item,
        .ll-dropdown-menu-item--active {
            width: 100%;
        }
    }

    .ll-dropdown-menu__title-wrapper {
        @include flex-config($justify-content: space-between, $align-items: center);
        width: 100%;
    }

    .ll-dropdown-menu {
        position: relative;
        z-index: 101;

        ul {
            padding: 0;
            margin: 0;
        }

        li {
            list-style: none;
        }

        .ll-submenu__list {
            display: none;
            position: absolute;
            left: 100%;
        }

        &.full-width-filter--mobile {
            text-transform: capitalize;

            .ll-dropdown__picker,
            .ll-dropdown__picker--active {
                padding: 0.7rem;
                font-size: 1.4rem;
            }

            .ll-dropdown-menu__icon {
                position: absolute;
                top: 50%;
                right: 1rem;
                transform: translateY(-50%);
                transition: 0.3s ease-in-out;
            }

            .ll-dropdown__picker--active {
                .ll-dropdown-menu__icon {
                    transform: translateY(-50%) rotate(-180deg);
                }
            }

            & ~ .ll-submenu {
                position: absolute;
                top: 110%;
                left: 0;
                z-index: 10;
                width: 100%;
                list-style: none;
                border-radius: 0.3rem;

                .ll-submenu__item,
                .ll-submenu__item--active {
                    padding: 0.7rem 2rem;
                    font-size: 1.4rem;
                    text-transform: capitalize;
                }
            }
        }
    }

    .ll-dropdown-menu--unactive {
        display: none;
    }

    .ll-dropdown-menu__content {
        @include flex-config($justify-content: center, $flex-direction: column);
    }
}

/*
|--------------------------------------------------------------------------
| == 1.4. SCROLL UP
|--------------------------------------------------------------------------
*/

%G-scroll-to-top {
    @include position(fixed, bottom 6rem right 1rem); //todo: убрать фиксед
    z-index: 2;
    cursor: pointer;
    @include size(4em, 4em);
    border-radius: 50%;
    @include flex-config($align-items: center, $justify-content: center);

    .scroll-up__icon {
        transform: rotate(270deg);
        margin-bottom: 0.2rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.5. PROGRESS BAR
|--------------------------------------------------------------------------
*/

%G-ll-progress-bar {
    position: absolute;
    overflow: hidden;
    @include size(100%, 5px);
}

%G-ll-progress-bar--determinate {
    .ll-progress-bar__value {
        @include size(0%, 100%);
        position: absolute;
        display: none;
        border: 0 none;
        transition: width 1s ease-in-out;
    }
}

%G-ll-progress-bar--indeterminate {
    @-webkit-keyframes ll-progress-bar-indeterminate-anim {
        0% {
            left: -35%;
            right: 100%;
        }
        60% {
            left: 100%;
            right: -90%;
        }
        100% {
            left: 100%;
            right: -90%;
        }
    }

    @keyframes ll-progress-bar-indeterminate-anim {
        0% {
            left: -35%;
            right: 100%;
        }
        60% {
            left: 100%;
            right: -90%;
        }
        100% {
            left: 100%;
            right: -90%;
        }
    }

    @-webkit-keyframes ll-progress-bar-indeterminate-anim-short {
        0% {
            left: -200%;
            right: 100%;
        }
        60% {
            left: 107%;
            right: -8%;
        }
        100% {
            left: 107%;
            right: -8%;
        }
    }

    @keyframes ll-progress-bar-indeterminate-anim-short {
        0% {
            left: -200%;
            right: 100%;
        }
        60% {
            left: 107%;
            right: -8%;
        }
        100% {
            left: 107%;
            right: -8%;
        }
    }

    .ll-progress-bar__value::before {
        @include position(absolute, top 0 left 0 bottom 0);
        content: '';
        will-change: left, right;
        -webkit-animation: ll-progress-bar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        animation: ll-progress-bar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }

    .ll-progress-bar__value::after {
        @include position(absolute, top 0 left 0 bottom 0);
        content: '';
        will-change: left, right;
        -webkit-animation: ll-progress-bar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation: ll-progress-bar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        -webkit-animation-delay: 1.15s;
        animation-delay: 1.15s;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.6. BREADCRUMBS
|--------------------------------------------------------------------------
*/

%G-ll-breadcrumbs {
    @include flex-config($display: inline-flex);

    .ll-breadcrumbs__content {
        @include flex-config($display: inline-flex);
    }

    .ll-breadcrumbs__menu-item {
        @include flex-config($align-items: center);
    }

    .ll-breadcrumbs__menu-icon {
        margin-right: 5px;
    }
}

%G-ll-breadcrumbs__title {
    text-transform: uppercase;

    .ll-breadcrumbs__menu-item:first-of-type,
    .ll-breadcrumbs__menu-item--link {
        font-weight: 600;
    }

    .ll-breadcrumbs__menu-item--link {
        cursor: pointer;
    }
}

%G-ll-breadcrumbs__title--desktop {
    padding-bottom: 20px;
    line-height: 17px;
}

%G-ll-breadcrumbs__title--mobile {
    line-height: 14px;
}

%G-ll-breadcrumbs__sport {
    .ll-breadcrumbs__separator,
    .ll-breadcrumbs__separator--icon {
        padding: 0 5px;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.7. MENU
|--------------------------------------------------------------------------
*/

%G-menu {
    .menu-item-link {
        display: block;
        cursor: pointer;
        text-decoration: none;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.8. ACCORDION
|--------------------------------------------------------------------------
*/

%G-ll-toggleable__content {
    display: none;
    margin: 0.5rem 0;

    &.ll-toggleable__content-exit-done {
        display: none;
        margin: 0.5rem 0;
    }

    &.ll-toggleable__content-enter {
        max-height: 0;
        display: block;
    }

    &.ll-toggleable__content-enter-active {
        max-height: 1000px;
        overflow: hidden;
        transition: cubic-bezier(0.86, 0, 0.2, 1) 400ms;
    }

    &.ll-toggleable__content-enter-done {
        display: block;
    }

    &.ll-toggleable__content-exit {
        max-height: 2000px;
        display: block;
    }

    &.ll-toggleable__content-exit-active {
        max-height: 0;
        display: block;
        overflow: hidden;
        transition: max-height cubic-bezier(0.86, 0, 0.2, 1) 250ms;
    }
}

%G-ll-accordion {
    &.ll-accordion--disabled {
        opacity: .7;
        filter: grayscale(.6);
    }

    &.ll-accordion--active.ll-accordion--disabled {
        opacity: 1;
        filter: grayscale(0);
    }

    .ll-accordion__progress-bar {
        bottom: 0px;
    }

    &.ll-accordion--active {
        .ll-accordion__header .ll-accordion__icon {
            transform: rotate(-90deg);
        }
    }

    .ll-accordion__header {
        @include flex-config($align-items: center);
        position: relative;
        //text-transform: uppercase;
        height: 32px;
    }

    .ll-accordion__icon,
    .ll-accordion__icon--right {
        @include position(absolute, right 20px);
        padding: 4px;
        padding-left: 3px;
        transform: rotate(0deg);
        transition: transform 0.3s ease-in-out;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.9. DATE PICKER - DATE RANGE PICKER
|--------------------------------------------------------------------------
*/

%G-ll-date-picker {
    position: relative;

    .ll-date-picker__icon {
        height: 100%;
        @include position(absolute, top 0 right 1.5rem);
        @include flex-config($align-items: center);
        font-size: 1.9rem;
    }

    .ll-date-picker__input {
        width: 100%;
        position: relative;
        z-index: 5;
    }

    .react-datepicker-popper {
        z-index: 12;
    }

    /* Reset styles because of different rem sizes */
    .react-datepicker {
        font-size: 1.2rem;
    }

    .react-datepicker__month {
        margin: 0.5rem 0.4rem 0.4rem;
    }

    .react-datepicker__day-names {
        margin-top: 0.4rem;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 2.5rem;
        line-height: 2.5rem;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-size: 1.5rem;
    }

    .react-datepicker__month-container {
        width: 100%;
    }

    .react-datepicker__header {
        width: 100%;
        padding-top: 0.6rem;
    }

    .react-datepicker__navigation {
        line-height: 2.5rem;
        border-width: 0.8rem;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
        top: 0.5rem;
        margin-left: 27px;
        border-width: 0.8rem;
    }

    .react-datepicker__header__dropdown,
    .react-datepicker__header__dropdown--scroll {
        margin-top: 5px;
    }
}

%G-ll-date-picker--mobile {
    .react-datepicker {
        font-size: 1.5rem;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 3rem;
        line-height: 3rem;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-size: 1.8rem;
    }

    .react-datepicker__header {
        padding-top: 1rem;
    }

    .react-datepicker__navigation {
        border-width: 1rem;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
        border-width: 1.5rem;
    }

    .react-datepicker__header__dropdown,
    .react-datepicker__header__dropdown--scroll {
        margin-top: 1rem;
    }

    .react-datepicker__header__dropdown--select select {
        font-size: 1.6rem;
        padding: 0.5rem;
    }
}

%G-ll-date-range-picker--mobile {
    @include flex-config();
    border-radius: 0.3rem;
    overflow: hidden;

    .ll-date-range-picker__btn-box {
        @include flex-config();
    }

    .ll-date-range-picker__range-btn-wrapper {
        position: relative;
        flex: 1;
        border-bottom: 2px solid #000;

        &.active::before {
            content: '';
            @include size(0);
            @include position(absolute, left 50% bottom -7px);
            margin-left: -3px;
            border-style: solid;
            border-width: 7px 7px 0;
            transform: translateX(-50%);
        }
    }

    .ll-date-range-picker__range-btn {
        width: 100%;

        .ll-btn__content {
            line-height: normal;
        }
    }

    .ll-date-range-picker__pickers {
        @include flex-config();
        height: 100%;
        flex: 3;
    }

    .ll-date-range-picker__picker-box {
        flex: 1;
    }

    .ll-date-picker--mobile {
        @include flex-config($flex-direction: column-reverse, $justify-content: center, $align-items: center);

        .ll-date-picker__icon {
            position: inherit !important;
            right: 0;
            font-size: 1.4rem;
            margin-top: .7rem;
        }

        .react-datepicker__portal {
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .ll-date-range-picker__picker-input {
        padding: .2rem .5rem .7rem;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: center;
        background: transparent;
    }

    .ll-date-range-picker__request-btn {
        height: auto;
        flex: 1;
        line-height: 1.6;
        padding: 0;
        text-transform: capitalize;
        border-radius: 0;
    }
}

%G-ll-date-range-picker--tablet-desktop {
    @include size(22rem, 3.6rem);
    position: relative;

    .ll-date-range-picker__values-box {
        @include size(100%);
        @include flex-config($align-items: center);
        padding: 0 1.2rem;
    }

    .ll-date-range-picker__values-down-icon {
        font-size: 1rem;
    }

    .ll-date-range-picker__input-wrap {
        flex: 1;
        padding: 0 0.6rem;
    }

    .ll-date-range-picker__input {
        @include size(100%);
        outline: 0;
        border: 0;
    }

    .ll-date-range-picker__values-divider {
        width: fit-content;
        display: inline-block;
        font-size: 2rem;
        line-height: 2rem;
    }

    .ll-date-range-picker__btn-box {
        @include flex-config($flex-direction: column);
        width: 100%;
    }

    .ll-date-range-picker__dropdown-box {
        &::before {
            content: '';
            @include position(absolute, top -0.7rem right 1.5rem);
            display: inline-block;
        }

        width: fit-content;
        @include position(absolute, right 0, $z-index: 10);
        top: calc(100% + 7px);
        @include flex-config($flex-direction: row-reverse);
        padding: 0.8rem;
    }

    .ll-date-range-picker__scrollbox {
        width: min-content;
    }

    .ll-date-range-picker__range-btn {
        width: 18rem;
        transition: all .2s ease-in-out;

        &:not(:last-of-type) {
            margin-bottom: 0.8rem;
        }
    }

    .ll-date-range-picker__action-btns {
        margin-top: 0.8rem;
        @include flex-config();
    }

    .ll-date-range-picker__action-btn {
        flex: 1;
        transition: all .2s ease-in-out;

        &:not(:last-of-type) {
            margin-right: 1rem;
        }
    }

    .ll-date-range-picker__pickers-box {
        @include flex-config($justify-content: flex-end);
        min-width: 427px;
    }

    .ll-date-range-picker__picker-box {
        height: 100%;
        width: calc(50% - 1rem);
        margin-right: 0.5rem;
        @include flex-config($flex-direction: column);
    }

    .ll-date-picker--desktop > div:not(.ll-date-range-picker__picker-input) {
        display: flex;
    }

    .ll-date-range-picker__picker-input {
        @include size(100%, 3.5rem);
        line-height: 3.5rem;
        display: block;
        margin-bottom: 0.8rem;
        padding: 0 1rem;
    }

    .ll-date-range-picker__picker {
        width: 100%;

        &::before {
            content: '';
            @include position(absolute, top -7px right 50%);
            @include size(1rem);
            display: inline-block;
            transform: translateX(50%) rotate(45deg);
        }

        .react-datepicker__month {
            height: 17rem;
        }
    }
}

%G-ll-date-range-picker--tablet {
    .ll-date-range-picker__input {
        font-size: 1.2rem;
        font-weight: 700;
    }
}

%G-ll-date-range-picker--desktop {
    @include size(22rem, 3.6rem);
    position: relative;

    .ll-date-range-picker__values-box {
        cursor: pointer;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.10. PASSWORD STRENGTH INDICATOR
|--------------------------------------------------------------------------
*/

%G-password-strength {
    @include flex-config($align-items: center);

    .password-strength__str {
        @include size(100%, 38px);
        overflow: hidden;
        display: inline-flex;
        position: relative;
        border-radius: 4px;

        @include mq('mobile') {
            width: 100%;
        }
    }

    .password-strength__pwd-bar {
        width: 100%;
    }

    .password-strength__texts {
        @include flex-config($justify-content: space-around);
        width: 100%;
        z-index: 10;
        position: absolute;

        span {
            flex: 1;
            text-align: center;
        }
    }

    .password-strength__container {
        position: relative;
        width: 100%;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.11. NEW ITEM INDICATOR
|--------------------------------------------------------------------------
*/

%G-new-item-indicator {
    border-radius: 100%;
    line-height: 20px;
    padding: 2px 5px;
    @include position(absolute, top 8px right 40px);
    transform: rotate(30deg);
}

/*
|--------------------------------------------------------------------------
| == 1.12. MENU ITEM (SLIDER MENU ITEM)
|--------------------------------------------------------------------------
*/

%G-menu-item {
    @include size(100%);
    @include flex-config($justify-content: center, $align-items: center);
    position: relative;
    padding: 0 8px;

    .menu-item__submenu {
        z-index: 1;

        @include flex-config($flex-direction: column, $align-items: center, $justify-content: center);

        @include mq('mobile') {display: block;}
    }

    .submenu-icon {
        margin-right: .5rem;
        transform: rotate(90deg);
        transition: transform .3s ease-out;

        &.submenu-icon--opened {
            transform: rotate(270deg);
        }
    }
}

%G-menu-item-accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height .4s ease-out;

    &.menu-item-accordion-content--opened {
        max-height: 200rem;
    }
}

%G-menu-item--desktop {
    @extend %G-menu-item;
    text-transform: uppercase;
    line-height: 44px;
    padding: 0 1rem;
    cursor: pointer;

    .menu-item__img {
        max-height: 2.5rem;
        width: 2.5rem;
        padding: 0.2rem;
        margin: 0.5rem;
        text-align: center;
    }

    .menu-item__icon {
        margin-right: 5px;
        font-size: 2.1rem;

        &.doruk-favorites,
        &.doruk-recently-played {
            font-size: 2rem;
        }
    }

    .menu-item__label {
        white-space: nowrap;
        text-align: center;
        position: relative;
    }
}

%G-menu-item--mobile {
    @extend %G-menu-item;
    flex-direction: column;
    text-transform: capitalize;
    line-height: normal;

    .menu-item__img {
        width: 2rem;
        padding: 0;
        margin: 0;
    }

    .menu-item__icon {
        margin: 0;
        height: 2rem;
    }

    .menu-item__label {
        width: 100%;
        white-space: normal;
        text-align: center;
        text-transform: capitalize;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: normal;
    }
}

%G-menu-item--tablet {
    @extend %G-menu-item;
    flex-direction: column;
    text-transform: capitalize;
    line-height: normal;

    .menu-item__img {
        width: 2rem;
        padding: 0;
        margin: 0;
    }

    .menu-item__icon {
        margin: 0;
        height: 2rem;
    }

    .menu-item__label {
        width: 100%;
        white-space: normal;
        text-align: center;
        text-transform: capitalize;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: normal;
    }
}

%G-ll-submenu-item {
    .ll-submenu-item__icon {
        width: 30px;
        text-align: center;
    }
}

%G-menu-sub-item--mobile {
    width: 100%;
    z-index: 5;

    &.slots-casino-menu__sub-items,
    &.header-upper-menu__sub-items {
        overflow: hidden;

        .ll-scrollbox--mobile {
            max-height: 50vh;
        }

        .sub-item:hover,
        .sub-item--active {
            text-align: center;
        }
    }

    &.header-upper-menu__sub-items {
        .sub-item,
        .sub-item--active {
            height: 50px;
        }
    }

    &.slots-casino-menu__sub-items > div {
        .sub-item,
        .sub-item--active {
            .slots-casino-menu__item-icon {
                max-height: 2.5rem;
                width: 2.5rem;
                padding: 0.2rem;
                margin: 0.5rem;
                text-align: center;
                border-radius: 50%;
            }
        }
    }
}

%G-menu-sub-item--tablet {
    width: 100%;
    z-index: 5;

    &.slots-casino-menu__sub-items,
    &.header-upper-menu__sub-items {
        overflow: hidden;

        .ll-scrollbox--tablet {
            max-height: 50vh;
        }

        .sub-item,
        .sub-item--active {
            text-align: center;

            .slots-casino-menu__item-icon,
            .menu-item__icon,
            .slots-casino-menu__provider-without-img {
                max-height: 2.5rem;
                font-size: 2rem;
                width: 2.5rem;
                padding: 0.2rem;
                margin: 5px;
                text-align: center;
                border-radius: 50%;
            }
        }
    }
}

%G-header-upper-menu__sub-items {
    .slots-casino-menu__provider-without-img,
    .menu-item__icon,
    .menu-item__img {
        width: 2.5rem;
        padding: 0.2rem;
        margin: 5px;
        text-align: center;
        border-radius: 50%;
    }
}

%G-slots-casino__header-menu-sub-items--desktop {
    @include flex-config(
            $flex-wrap: wrap,
            $align-items: center,
            $justify-content: center
    );
    user-select: none;
    max-width: 70vw;
    max-height: calc(100vh - 34.5rem - 14rem);
    border-radius: 3px;
    z-index: 5;
}

%G-slots-casino__header-menu-sub-items--tablet {
    @include flex-config(
            $flex-wrap: wrap,
            $align-items: center,
            $justify-content: flex-start
    );
    user-select: none;
    max-width: 100vw;
    width: max-content;
    z-index: 5;
}

%G-slots-casino__header-menu-sub-items--mobile {
    @include flex-config(
            $flex-wrap: wrap,
            $align-items: center,
            $justify-content: flex-start
    );
    padding: 0;
    z-index: 5;
}

%G-slots-casino__header-menu-sub-item--desktop {
    @include flex-config(
            $flex-direction: column,
            $align-items: center,
            $justify-content: center
    );
    margin: 0.8rem;
    cursor: pointer;
    min-height: 45px;
    width: 120px;

    .slots-casino-menu__item-icon {
        max-height: 2.5rem;
        width: 2.5rem;
        padding: 0.2rem;
        margin: 0.5rem;
        text-align: center;
        border-radius: 50%;
    }
}

%G-slots-casino__header-menu-sub-item--tablet {
    @include flex-config(
            $flex-direction: column,
            $align-items: center,
            $justify-content: center
    );
    height: 7rem;
    width: 20%;
    min-width: 100px;
    margin: 0;
    padding: 1%;
}

%G-slots-casino__header-menu-sub-item--mobile {
    @include flex-config(
            $flex-direction: column,
            $align-items: center,
            $justify-content: center
    );
    height: 60px;
    width: 33.3%;
    margin: 0;
    padding: 1%;
}

/*
|--------------------------------------------------------------------------
| == 1.13. BETTING BUTTON
|--------------------------------------------------------------------------
*/
%G-bet {
    position: relative;
    flex: 1;
    cursor: pointer;

    .bet__loader-wrp {
        @include size(100%);
        @include position(absolute, top 0 left 0, $z-index: 10);
        @include flex-config($justify-content: center, $align-items: center);
        background: rgba(252, 252, 252, 0.2);
    }
}

.bet--column1 {
    min-width: 100%;
}

%G-bet--column2 {
    min-width: 46%;
}

%G-bet--column3 {
    min-width: 30%;
}

%G-bet--draw {
    min-width: 25%;
    flex: 0;

    @include mq('(max-width: 1023px)') {
        min-width: 28%;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.14. DATA-TABLE
|--------------------------------------------------------------------------
*/

%G-data-table {
    @include flex-config($flex-direction: column, $align-items: flex-end);

    &#live-calendar-table {
        .dx-datagrid {
            position: static;
        }

        .dx-datagrid-header-panel {
            @include position(absolute, top 0 left 0 right 0, $z-index: 10);
            height: 4.2rem;

            .dx-toolbar {
                height: 4.2rem;
            }

            .dx-toolbar-after {
                height: fit-content;
                margin-top: .5rem;
            }
        }

        .dx-datagrid-headers {
            @include position(absolute, top 4.2rem left 0, $z-index: 10);
        }

        .dx-gridbase-container > .dx-datagrid-rowsview {
            margin-top: 7rem;
        }
    }

    .data-table__summary {
        @include flex-config();
        width: 50rem;
        margin-top: 2rem;
        font-size: 1.4rem;
        font-weight: 600;
    }

    .data-table__summary-item {
        @include flex-config($justify-content: center, $align-items: center, $flex-wrap: wrap);
        flex: 1;
        padding: .3rem 3rem;

        &:last-child {
            border-left: none;
        }

        &.data-table__summary-item--odds {
            @include flex-config($justify-content: space-between);
            padding: .3rem 5%;
        }
    }

    .data-table__pagination {
        @include flex-config($align-items: center);
        margin-top: 3rem;
    }

    .data-table__pagination-root {
        @include flex-config($justify-content: center);
        flex: 1;
    }

    .data-table__page-sizer {
        width: fit-content;
        flex-shrink: 0;
        margin-right: 2rem;
        border-radius: 3px;
        cursor: pointer;

        .ll-dropdown-menu__icon--opened {
            display: inline-block;
            transition: transform .2s ease-in;
            transform: rotate(180deg) translateY(1px);
        }
    }

    .data-table__page-size {
        font-size: 1.5rem;
    }

    .data-table__page-size-dropdown {
        @include size(5rem, 100%);
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: .7rem;
        border-radius: 3px;
    }

    .data-table__page-size-dropdown-menu {
        width: 100%;

        .ll-dropdown-menu-item,
        .ll-dropdown-menu-item--active {
            width: 100%;
            padding: .7rem .5rem;
            font-size: 1.5rem;
            text-align: center;
        }
    }

    .data-table__pagination-numbers {
        button {
            font-size: 1.4rem;
        }
    }

    .dx-datagrid-headers {
        .dx-datagrid-text-content {
            text-transform: capitalize;
        }
    }

    .dx-datagrid-table {
        .dx-freespace-row {
            display: none;
        }
    }

    .dx-datagrid-nowrap,
    .dx-datagrid-nowrap .dx-header-row > td > .dx-datagrid-text-content {
        white-space: unset;
    }
}

%G-data-table--empty {
    @include flex-config($justify-content: center);
    width: 100%;
    padding: 2.5rem;
    border-radius: 3px;
    font-size: 1.4rem;
    text-align: center;
}

%G-data-table--mobile {
    &#live-calendar-table {
        border: none;

        .dx-datagrid,
        .dx-toolbar {
            background-color: transparent;
        }

        .dx-datagrid-header-panel {
            padding: 0 1.5rem;
            border-bottom: none;
            height: 4rem;
            margin: 1rem;
            border-radius: 3px;

            .dx-toolbar {
                height: 4rem;
            }

            .dx-toolbar-after {
                width: 100%;
                padding: 0;
            }

            .dx-datagrid-search-panel {
                margin: 0;
            }

            .dx-toolbar-item,
            .dx-item-content,
            .dx-texteditor {
                width: 100%;
            }

            .dx-toolbar-after {
                margin-top: 0;
            }

            .dx-texteditor-input {
                padding: 1rem 0 1rem 2.2rem;
            }

            .dx-icon-search {
                right: 0;
                left: auto;
            }

            .dx-placeholder::before {
                padding-left: 0;
            }
        }

        .dx-gridbase-container > .dx-datagrid-rowsview {
            margin-top: 0;
        }

        .dx-datagrid-rowsview {
            .dx-row.dx-data-row {
                width: calc(100vw - 2rem);
                margin: 1rem;
                padding: 1rem;
                @include flex-config($flex-direction: column);
                border-radius: 3px;
                overflow: hidden;

                td {
                    background: none;

                    &:not(:last-of-type) {
                        margin-bottom: .5rem;
                    }
                }

                .live-calendar__item-text {
                    text-align: left;
                    font-weight: 500;
                }
            }
        }
    }

    .data-table__summary {
        width: 100%;
        margin: .5rem 0;
        border-radius: 3px;
        overflow: hidden;
    }

    .data-table__summary-item {
        padding: .3rem .5rem;
    }

    .data-table__pagination {
        width: 100%;
        margin: 1rem 0;
        justify-content: space-between;
    }

    .data-table__pagination-numbers {
        flex-wrap: nowrap;

        svg {
            font-size: 2.15rem;
        }
    }

    .data-table__page-sizer {
        margin-right: 0;
    }

    .data-table__pagination-dummy-box {
        min-width: 5rem;
        max-width: 5rem;
        height: 5rem;
    }

    .dx-datagrid-headers {
        height: 3rem;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;

        .dx-datagrid-content {
            height: 100%;
            @include flex-config($align-items: center);
        }

        .dx-datagrid-table {
            .dx-row {
                height: 100%;

                & > td {
                    padding: 11px 14px;
                    font-size: 1.4rem;
                    font-weight: 700;
                }
            }
        }
    }

    .dx-datagrid-table {
        .dx-row > td {
            padding: .2rem !important;
            border: none;
            font-size: 1.4rem;
            line-height: initial;
        }

        td.dx-command-adaptive {
            max-width: 3rem;
            min-width: 3rem;
        }

        .dx-datagrid-adaptive-more {
            @include size(100%);
            display: inline-block;

            &::before {
                content: '\f055';
                font-family: 'llg' !important;
                font-size: 1.8rem;
            }
        }

        .dx-row > td:last-of-type.dx-command-adaptive {
            padding: 0;
        }

        .dx-row.dx-adaptive-detail-row {
            .dx-master-detail-cell {
                padding-bottom: 0;
            }

            .dx-field-item {
                padding: .3rem .5rem !important;
            }

            .dx-field-item-label-text {
                font-size: 1.4rem;
                text-transform: capitalize;
            }

            .dx-field-item-content {
                font-size: 1.4rem;
            }
        }

        .dx-field-item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > * {
                flex: 1;
                min-height: 100%;
                line-height: unset;
            }

            .dx-field-item-label-text {
                font-size: 16px;
                font-weight: 500;
            }

            .dx-adaptive-item-text,
            .dx-field-item-content {
                font-size: 16px;
            }
        }
    }

    .dx-datagrid-rowsview {
        border: none;
    }
}

%G-data-table--tablet--desktop {
    .bet-history-detail__info-combination.won {
        color: #20a100;
    }
    .bet-history-detail__info-combination.lost {
        color: #d90000;
    }
    .bet-history-detail__info-combination.void {
        color: #979797;
        text-decoration: line-through;
    }
    .dx-row.dx-data-row {
        &.table-row__bet--won,
        &.table-row__bet-detail--won,
        &.table-row__account-history--positive,
        &.table-row__withdraw-history--completed {
            color: #20a100;
        }

        &.table-row__bet--lost,
        &.table-row__bet-detail--lost,
        &.table-row__account-history--negative,
        &.table-row__withdraw-history--cancelled {
            color: #d90000;
        }

        &.table-row__bet--cashouted {
            color: #9d874c;
        }

        &.table-row__bet--deleted,
        &.table-row__bet-detail--returned {
            color: #979797;
            text-decoration: line-through;

            .ll-btn2 span {
                display: inline-block;
            }
        }
    }
}

%G-data-table--tablet {

    &#live-calendar-table {
        .dx-gridbase-container > .dx-datagrid-rowsview {
            margin-top: 8rem;
        }
    }

    .data-table__cell-content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
        font-size: 1.3rem;
    }

    .dx-datagrid div:not(.dx-datagrid-headers) .dx-datagrid-content .dx-datagrid-table .dx-row > td {
        padding: 0;

        & > .ll-popover-wrapper {
            padding: 1rem 0.5rem;
        }
    }

    .dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-row {
        & > td {
            padding: 1rem 0.5rem;
            font-size: 1.3rem;
            font-weight: 600;

            .dx-column-indicators.dx-visibility-hidden {
                display: none;
            }
        }

        .dx-header-filter-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right {
            max-width: calc(100% - 2rem);
        }
    }
}

%G-data-table--desktop {
    &#live-calendar-table {
        .dx-datagrid[aria-rowcount$="0"]:not([aria-rowcount="10"]) .dx-datagrid-headers {
            right: .5rem;
        }
    }

    .dx-datagrid-headers {
        .dx-datagrid-table {
            .dx-row > td,
            .dx-row > td:hover {
                padding: .5rem;
                font-size: 14px;
                font-weight: 700;

                .dx-column-indicators.dx-visibility-hidden {
                    display: none;
                }
            }
        }
    }

    .dx-row.dx-data-row {
        &.table-row--clickable {
            cursor: pointer;
        }
    }

    .dx-datagrid tr.dx-row > td {
        padding: .5rem;

        .dx-datagrid-header-panel {
            display: none !important;
        }

        &.dx-master-detail-cell {
            padding: 1rem !important;
        }

        .data-table__master-detail-title {
            @include flex-config();

            p {
                display: flex;
                padding: .5rem;
                position: relative;

                &:first-of-type {
                    padding-left: 0;
                }

                &:not(:last-of-type)::after {
                    content: '|';
                    position: absolute;
                    right: -3px;
                }
            }
        }

        .data-table__cell--bet-result-link {
            cursor: pointer;
            vertical-align: middle;
        }
    }

    .dx-master-detail-row {
        & > * {
            padding: 0 !important;
        }

        .dx-row.dx-data-row {
            td:nth-child(2) {
                padding-left: 0;
            }
        }

        .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td {
            padding: .5rem 1rem;

            &.dx-command-expand.dx-datagrid-group-space {
                padding: 0;
            }

            &:nth-child(2) {
                padding-left: 0;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.17. SCROLLBOX
|--------------------------------------------------------------------------
*/

%G-ll-scrollbox {
    overflow: auto;
    height: 100%;

    &.live-page__menu-scrollbox {
        height: calc(100% - 3.5rem);
    }
}

%G-scroll-to-top--shifted {
    bottom: 12rem;
}

%G-scroll-to-top--semi-shifted {
    bottom: 10rem;
}

/*
|--------------------------------------------------------------------------
| == 1.18. SLIDER
|--------------------------------------------------------------------------
*/

%G-slider {
    width: 100%;
    overflow: hidden;
    position: relative;
    @include flex-config($justify-content: center);

    &.slider--oversized > div {
        overflow: hidden;
    }

    &.slider.slider--mobile.slider--oversized.slider--with-absolute-arrows > div,
    &.slider.slider--tablet.slider--oversized.slider--with-absolute-arrows > div,
    &.slider.slider--desktop.slider--oversized.slider--with-absolute-arrows > div {
        width: 100%;
    }

    .slider__arrow {
        position: absolute;
        z-index: 2;
        height: 100%;
        @include flex-config($justify-content: center, $align-items: center);
        color: inherit;
        cursor: pointer;

        &.slider__arrow--disable {
            filter: blur(1px) grayscale(90%) opacity(30%);
            cursor: initial;
        }
    }

    .slider__arrow--left {
        left: 0;
    }

    .slider__arrow--right {
        right: 0;
    }

    .slider__arrow-icon--left {
        transform: rotate(180deg);
    }

    .slider__content {
        @include flex-config($align-items: center);
        transition: all 1s ease-in-out;
    }

    .slider__item {
        @include size(fit-content);
        color: inherit;

        & * {
            white-space: nowrap;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.19. SCROLL SLIDER
|--------------------------------------------------------------------------
*/

%G-scroll-slider {
    position: relative;
    max-width: 100%;
    width: 100%;

    .scroll-slider__wrp {
        @include flex-config();
        column-gap: .2rem;
        overflow-x: auto;
        padding-bottom: .3rem;
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .scroll-slider__item {
        flex-shrink: 0;
        scroll-snap-align: start;
    }

    .scroll-slider__prev,
    .scroll-slider__next {
        @include position(absolute, top 50%, $z-index: 5);
        @include size(3rem);
        @include flex-config($justify-content: center, $align-items: center);
        transform: translateY(-50%);
        cursor: pointer;
        border-radius: 50%;

        &.disabled {
            opacity: .2;
            cursor: not-allowed;
        }
    }

    .scroll-slider__prev {
        left: .5rem;

        & > i {
            transform: rotate(180deg);
            margin-right: 2px;
        }
    }

    .scroll-slider__next {
        right: .5rem;

        & > i {
            margin-left: 2px;
        }
    }

    .scroll-slider__dots {
        width: 100%;
        height: 3rem;
        @include flex-config($justify-content: center, $align-items: center);

        .scroll-slider__dot {
            @include size(1rem);
            border-radius: 50%;

            &:not(:last-of-type) {
                margin-right: 2rem;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.20. BETS LIST
|--------------------------------------------------------------------------
*/

%G-bets-list {
    max-height: 100%;

    .bets-list__table {
        max-height: 100%;
    }

    table {
        table-layout: auto !important;
    }

    .dx-widget {
        width: 100%;
    }

    .data-table--mobile {
        border: none;
    }

    .bets-list__loader-box {
        width: 100%;
        min-height: 20rem;
        @include flex-config($justify-content: center, $align-items: center);

        .dx-loadindicator {
            width: 3.2rem;
        }
    }

    .bet-list__no-data-text {
        min-height: 20rem;
        @include flex-config($justify-content: center, $align-items: center);
        border-radius: .3rem;
        font-size: 1.4rem;
        text-align: center;
    }

    .bets-list__scrollbox {
        width: 100%;
        max-height: calc(100% - 7rem);
    }

    .bets-list__bet {
        @include flex-config($flex-direction: column);
        border-radius: 3px;
        font-size: 1.4rem;

        &:not(:last-of-type) {
            margin-bottom: .3rem;
        }
    }

    .bets-list__bet-progress-bar {
        height: .2rem;
        left: 0;
        bottom: 0;
    }

    .data-table__pagination {
        margin-top: 0;
        padding: 1rem 0;
        align-self: stretch;
        @include flex-config($justify-content: center);
        border-radius: 3px;
    }

    .data-table__pagination-dummy-box {
        min-width: 0;
        max-width: 0;
    }

    .bets-list__bet-collapse {
        cursor: pointer;
    }

    .bets-list__bet-collapse-head {
        @include flex-config($justify-content: space-between, $align-items: center);
        height: 3rem;
        padding: .5rem 1rem;
        position: relative;
    }

    .bets-list__bet-collapse-arrow {
        width: 1.5rem;
        @include flex-config($align-items: center);
        font-size: 1rem;
    }

    .bets-list__bet-collapse-content {
        @include flex-config($flex-direction: column);
        width: 100%;
        transition: all .3s ease-in-out;
        max-height: 0;
        overflow: hidden;
    }

    .bets-list__bet.opened .bets-list__bet-collapse-content {
        height: max-content;
        max-height: 3000vh;
    }

    .bets-list__match-row {
        margin: .5rem 0;
        @include flex-config($justify-content: space-between, $align-items: center);
    }

    .bets-list__match-row--bet-builder {
        padding: .5rem;
    }

    .bets-list__match {
        padding: .5rem 1rem;
        font-size: 1.4rem;
    }

    .bets-list__match.bets-list__match--no-data {
        padding: 1rem;
        text-align: center;
    }

    .bets-list__match-type {
        font-weight: 500;
    }

    .bets-list__match-type--bet-builder {
        position: relative;
        padding-left: 2rem;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 2.2rem;

        &::before {
            content: "";
            display: block;
            @include size(.5rem);
            @include position(absolute, top 50% left .2rem, $z-index: 2);
            transform: translateY(-50%);
            border-radius: 5px;
        }

        &::after {
            content: "";
            display: block;
            height: 100%;
            @include position(absolute, top 0 left .4rem, $z-index: 1);
        }

        &:first-of-type::after {
            transform: translateY(50%);
        }

        &:last-of-type::after {
            transform: translateY(-50%);
        }

        &.bets-list__match-type--bet-builder-single {
            &::after {
                border: none;
            }
        }
    }

    .bets-list__match-score {
        margin-top: .5rem;
        font-weight: 600;
    }

    .bets-list__match-time {
        margin-bottom: .2rem;
        font-size: 1.2rem;
    }

    .bets-list__match-selection {
        margin-left: .5rem;
    }

    .bets-list__match-odds {
        font-weight: bold;
    }

    .bets-list__match-odds--combined {
        align-self: flex-start;
        margin-left: .5rem;
    }

    .bets-list__bet-money-item-value {
        font-weight: 500;
    }

    .bets-list__bet-money-item-inner {
        display: flex;
    }

    .data-table--mobile,
    .data-table--tablet {
        .bets-list__bet {
            margin: 0 0 .5rem 0;
        }
    }

    .data-table--desktop {
        .bets-list__bet {
            margin-bottom: .5rem;
        }
    }

    .bets-list__bet {
        position: relative;
    }

    .bets-list__bet-title {
        @include flex-config();

        @media (max-width: 350px) {
            font-size: 1.2rem;
        }
    }

    .bets-list__bet-time {
        @include flex-config($align-items: center);

        &::before {
            content: '|';
            margin: 0 .5rem .3rem;
            font-size: 1.1rem;
        }
    }

    .bets-list__cashout-box {
        align-self: flex-end;
        @include flex-config($justify-content: space-between, $align-items: center);
        padding-top: .5rem;
    }

    .bets-list__bet-btn {
        border-radius: .3rem;
        cursor: pointer;

        .dx-loadindicator-segment-inner {
            border-width: 0.2rem;
        }
    }

    .bets-list__bet-lower-part {
        @include flex-config($justify-content: space-between, $align-items: flex-start);
    }

    .bets-list__bet-left-lower-part {
        @include flex-config($flex-direction: column);
    }

    .bets-list__bet-money {
        position: relative;
        padding: 0 1rem 1rem;
    }

    .bets-list__bet-money-item {
        display: flex;
        margin: .3rem 0;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .bets-list__bet-money-item--with-status {
        @include flex-config($justify-content: space-between);
        font-weight: 500;
    }

    .bets-list__bet-money-item-title {
        margin-right: .5rem;
        font-size: 1.4rem;
        font-weight: 500;
    }

    .bets-list__bet-status {
        align-self: flex-end;
        padding: 0 .3rem;
        border-radius: 3px;
    }

    //    Bet Details
    .bets-list__group {
        display: block;
        font-weight: 500;
    }

    .bets-list__group-title {
        padding: .5rem 1rem;
        @include flex-config($justify-content: space-between);
    }

    .bets-list__group-info {
        @include flex-config($flex-direction: column);
    }

    .bets-list__group-item {
        @include flex-config($flex-direction: column);
        padding: 0 1rem .5rem;

        & > p {
            margin-bottom: .5rem;
        }
    }

    .bets-list__group-combination {
        font-weight: 700;
    }

    .bets-list__group-item-info {
        margin-top: .5rem;
        @include flex-config($justify-content: space-between);
        font-weight: 500;
    }

    .bets-list__group-info-item {
        & > span {
            font-weight: 400;
        }
    }

    .bets-list__group-item-status {
        display: inline-block;
        margin-left: 1rem;
    }

    .bets-list__status,
    .bets-list__group-item-status {
        text-transform: capitalize;
        padding: 0 .3rem;
        border-radius: 3px;
    }

    .bets-list__info {
        &.bet-details__info--combined {
            .bet-details__odd {
                display: inline-block;
                margin-left: 2rem;
            }
        }
    }

    .bets-list__info-odd-type {
        @include flex-config($justify-content: space-between);
        font-weight: 600;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.21. BURGER
|--------------------------------------------------------------------------
*/

%G-ll-burger-icon {
    @include flex-config($justify-content: center, $align-items: center, $flex-direction: column);
    width: 4rem;
    cursor: pointer;
    z-index: 2;

    div {
        position: relative;
        height: .4rem;
        width: .4rem;
        margin: .1rem auto;
        transition: all 0.3s ease;
        border-radius: 2px;
    }

    &.filter-icon {
        @include size(3.6rem);

        div {
            height: .3rem;
            margin: .2rem auto;
        }
    }
}

%G-live-widgets__nav-burger {
    padding-left: 1rem;
}

%G-ll-burger-icon--closed {
    div {
        &:first-child {
            transform-origin: 0;
        }

        &:last-child {
            margin-bottom: 0;
            transform-origin: 0;
        }
    }

    &.filter-icon {
        div {
            &:first-child {
                width: 2.1rem;
            }

            &:nth-child(2) {
                width: 1.4rem;
            }
        }
    }
}

%G-ll-burger-icon--opened {
    div {
        &:nth-child(2) {
            width: 0;
        }

        &:first-child {
            top: 5px;
            width: 1.8rem;
            height: .2rem;
            transform: rotateZ(45deg);
        }

        &:last-child {
            top: -5px;
            width: 1.8rem;
            height: .2rem;
            transform: rotateZ(-45deg);
        }
    }

    &.filter-icon {
        div {
            &:first-child {
                top: 6.5px;
            }

            &:last-child {
                top: -6.5px;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.22. SWITCHER
|--------------------------------------------------------------------------
*/

%G-status-filter-switcher {
    width: 5rem;

    .status-filter-switcher-thumb {
        height: 1.3rem;
        width: 1.3rem;
    }

    .status-filter-switcher-switch-base {
        top: .3rem;
        margin: 9px;
        padding: 0 !important;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.23. DOTS LOADING
|--------------------------------------------------------------------------
*/

%G-ll-dots-loading {
    @include size(.2rem);
    position: relative;
    margin: 0 .7rem;
    animation-delay: .4s;

    &::before,
    &::after {
        content: '';
        display: inline-block;
        @include size(.2rem);
        position: absolute;
        top: 0;
    }

    &::before {
        left: -.5rem;
        animation-delay: 0s;
    }

    &::after {
        left: .5rem;
        animation-delay: .8s;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.24. GAME ITEM
|--------------------------------------------------------------------------
*/

%G-game-img {
    @include size(100%);

    .game-img__wrp {
        @include size(100%);
        @include flex-config($justify-content: center, $align-items: center);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .game__provider-without-img {
        height: 4rem;
        @include flex-config($align-items: center);
        padding: 0.2rem 0.5rem;
        border-radius: 3px;
        opacity: .85;
    }

    .game__provider-with-img {
        @include flex-config($justify-content: center, $align-items: center);
        @include size(100%);
        position: relative;
    }

    .game__provider-bg-without-img {
        @include flex-config($justify-content: center, $align-items: center);
        @include size(75%);
        background-image: url('../../../static/themes/theme/images/slots-and-casino/slot-casino-without-img-big.png');
        background-size: 200%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
    }

    .game-img__logo {
        width: 100%;
    }

    .game-img__wrp--without-img {
        .game__provider-without-img {
            .game__provider-game-name {
                bottom: 0;
                left: 0;
                position: absolute;
                width: 100%;
                margin: auto;
                padding: 0 0.8rem;
                line-height: 2.4rem;
                height: 2.4rem;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
                font-size: 1rem;
                font-weight: 600;
                text-align: center;
                text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.51);
                text-transform: uppercase;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }
        }

        .game__provider-with-img {
            .ll-popover-wrapper {
                bottom: 0;
                left: 0;
                position: absolute;
                width: 100%;
                margin: auto;
                padding: 0 0.8rem;
                line-height: 2.4rem;
                height: 2.4rem;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
                font-size: 1rem;
                font-weight: 600;
                text-align: center;
                text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.51);
                text-transform: uppercase;
            }
        }
    }

}

/*
|--------------------------------------------------------------------------
| == 1.25. WIDGET SPORT ITEM
|--------------------------------------------------------------------------
*/

%G-ll-widget-item1 {
    background: transparent;
}

%G-ll-widget-item {
    @include flex-config($flex-direction: column);
    position: relative;
    height: 18rem;

    @media (max-width: 375px) {
        .ll-popover-wrapper {
            word-break: break-word;
        }
    }

    .ll-widget-item__widget-bg {
        position: absolute;
        top: -1rem;
        margin: 0 auto;
        font-size: 17rem;
        opacity: 0.03;
        width: 100%;
        height: 100%;
        z-index: 0;
        text-align: center;
        display: inline-block;

        &.doruk-rocket1 {
            top: 2rem;
        }

        &::before {
            position: relative;
            text-align: center;
            display: contents;
            z-index: 0;
        }
    }

    &.ll-widget-item--superodds {
        .ll-widget-item__top-box .ll-widget-item__event-name {
            margin-top: 2rem;
            min-height: 8rem;
        }
    }

    .ll-widget-item__top-box {
        @include flex-config($flex-direction: column);
        flex: 0 1;
        padding: 1rem 1rem .5rem;
        z-index: 1;

        .ll-widget-item__event-name {
            margin-top: 1rem;
            column-gap: 1rem;

            .ll-widget-item__event-name-box,
            .ll-widget-item__event-name-box--right {
                flex: 1;
                gap: 0.5rem;

                .ll-popover-wrapper {
                    -webkit-line-clamp: 2;
                }
            }
        }

        .ll-widget-item__event-name {
            @include flex-config($justify-content: space-around, $align-items: center);

            .ll-widget-item__event-name-box,
            .ll-widget-item__event-name-box--right {
                @include flex-config($justify-content: flex-start, $align-items: center, $flex-direction: row);
                flex: 1 1;
                text-align: center;
            }

            .ll-widget-item__event-name-box--right {
                justify-content: flex-end;
            }

            .ll-widget-item__competitor-logo,
            .ll-widget-item__competitor-logo--no-logo {
                max-width: 2.4rem;
            }

            .ll-widget-item__competitor-logo--no-logo {
                @include size(2.4rem);
                position: relative;

                &::before {
                    font-size: 2rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

        }

        .ll-widget-item__event-name--winner {
            @include flex-config($justify-content: space-around, $align-items: center);
            margin: 1rem 1.5rem 0;
            column-gap: 2rem;

            .ll-widget-item__event-name-box,
            .ll-widget-item__event-name-box--right {
                @include flex-config($justify-content: center, $align-items: center, $flex-direction: column);
                flex: 1;
                gap: 1rem;

                .ll-popover-wrapper {
                    line-height: 1.5rem;
                    -webkit-line-clamp: 1;
                }
            }

            .ll-widget-item__competitor-logo,
            .ll-widget-item__competitor-logo--no-logo {
                width: 4.8rem;
                max-width: 4.8rem;
            }

            .ll-widget-item__competitor-logo--no-logo {
                @include size(4.8rem);
                position: relative;

                &::before {
                    font-size: 3.6rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .ll-widget-item__game-info,
    .ll-widget-item__info {
        @include flex-config($justify-content: space-between);
        position: relative;

        .ll-widget-item__game-info-item {
            min-width: 7rem;
        }

        .ll-widget-item__info-item {
            min-width: 9rem;
        }

        .ll-widget-item__game-info-item,
        .ll-widget-item__info-item {
            @include flex-config($align-items: center);
            font-size: 1.1rem;
            flex: 1 1;


            &.ll-widget-item__info-item--sport,
            &.ll-widget-item__game-info-item--type {
                text-align: right;
                justify-content: flex-end;
            }

            .ll-widget-item__date {
                &::after {
                    content: '';
                    display: inline-block;
                    @include size(0.1rem, 0.8rem);
                    margin: 0 .5rem;
                }
            }
        }

        .ll-widget-item__icon {
            margin-right: .5rem;
            font-size: 1rem;

            &::before {
                width: auto;
            }
        }

        .ll-widget-title {
            display: block;
            flex: 1 2 100%;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-word;
            margin: 0 0.6rem;
            line-height: 1.2rem;
        }
    }

    &.ll-widget-item--game {
        overflow: hidden;
        //padding: 0;

        .ll-widget-item__content {
            z-index: 1;
            @include size(100%, 100%);
            padding: 1rem 1rem 0;
        }

        .ll-widget-item__bg--image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            z-index: 0;
            filter: blur(5px);
        }

        .ll-widget-item__bg {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.2;
            height: 100%;
            width: 100%;
            z-index: 0;
            overflow: hidden;

            .ll-widget-item__bg--image-slot {
                object-fit: fill;
            }

            .ll-widget-item__bg--image-casino {
                top: 0;
                transform: translate(-50%, -10%);
                width: 120%;
            }
        }

        .ll-widget-game {
            @include flex-config($justify-content: space-between, $align-items: center);
            width: 100%;
            height: calc(100% - 1.1rem);
            position: relative;

            .ll-widget-game__left-part,
            .ll-widget-game__right-part {
                width: 50%;
                text-align: center;
            }

            &.ll-widget-game--multiple {
                justify-content: center;
            }

            .ll-widget-game__game-items {
                @include flex-config($justify-content: space-evenly);
                text-align: center;
                column-gap: 1rem;
                width: 100%;
                margin: 1rem 1.5rem 2rem;

                .ll-widget-game__game-item-new,
                .ll-widget-game__game-item {
                    @include flex-config($flex-direction: column, $justify-content: flex-end, $align-items: center);
                    flex: 1 1;
                    height: 12.7rem;
                    width: 100%;
                    min-width: 7.2rem;
                    max-width: 10rem;
                    overflow: hidden;
                    row-gap: 0.5rem;


                    &.ll-widget-game__game-item--promo {
                        justify-content: center;

                        .ll-popover-wrapper {
                            -webkit-line-clamp: 3;
                        }
                    }
                }

                .ll-widget-game-image {
                    max-width: 9rem;
                    max-height: 12rem;
                    border-radius: 0.3rem;
                    transform-origin: center;
                    transform: scale(1);
                    transition: all .1s ease-in-out;
                    overflow: hidden;

                    &:hover {
                        transform: scale(1.05);
                    }

                }

                .ll-widget-game-button {
                    @include flex-config($justify-content: center, $align-items: center);
                    min-height: 3.2rem;
                    height: 3.2rem;
                    width: 100%;
                    min-width: 7.2rem;
                    max-width: 10rem;
                    border-radius: 0.3rem;
                    font-size: 1.4rem;
                }
            }

            .ll-widget-game__left-part {
                @include flex-config($flex-direction: column);
                @include size(100%, 1rem);

                .ll-widget-game__left-part--text {
                    position: absolute;
                    top: 3rem;
                    width: 50%;
                }

                .ll-widget-game__left-part--promo-title,
                .ll-widget-game__left-part--promo-text {
                    margin: 0 auto;
                    line-height: 1.3rem;
                    height: 3.2rem;
                    padding: 0.5rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    text-align: center;
                    -webkit-box-orient: vertical;
                    word-break: break-word;
                }

                .ll-widget-game__left-part--button {
                    position: absolute;
                    bottom: 2.1rem;
                    width: 50%;

                    .ll-widget-game-button {
                        margin: 0 auto;
                        @include flex-config($justify-content: center, $align-items: center);
                        height: 3.2rem;
                        min-height: 3.2rem;
                        min-width: 7.2rem;
                        max-width: 10rem;
                        width: 100%;
                        border-radius: .3rem;
                        z-index: 2;
                        cursor: pointer;
                        font-size: 1.4rem;
                    }
                }
            }

            .ll-widget-game__right-part {
                @include flex-config($justify-content: center, $align-items: center);
                @include size(100%, 100%);

                .ll-widget-game__right-part--image {
                    max-width: 9rem;
                    max-height: 12rem;
                    transform-origin: center;
                    border-radius: 0.3rem;

                    transform: scale(1);
                    transition: all .1s ease-in-out;
                    overflow: hidden;

                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
        }

        .ll-widget-game-left-part--title,
        .ll-widget-game-left-part--text {
            margin: 0 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            text-align: center;
            -webkit-box-orient: vertical;
            word-break: break-word;
        }

        .ll-widget-game-right-part {
            position: relative;
            padding-right: 2rem;

            .ll-widget-game-right-part--img {
                @include flex-config($justify-content: center, $align-items: center);
                margin: 0;

                img {
                    @include position(absolute, left 50% top 50%);
                    transition: all .1s ease-out;
                    transform: translate(-50%, -50%);
                    z-index: 2;
                    cursor: pointer;
                    height: 11rem;
                    width: 12rem;
                }
            }

            .ll-widget-game-right-part--img-casino img {
                height: 13.4rem;
                width: 10rem;
            }
        }

        .ll-widget-game-left-part--title {
            font-size: 1.2rem;
            text-align: center;
        }

        .ll-widget-game-left-part--text {
            font-size: 1rem;
            text-align: center;
        }

    }

    .ll-widget-item__bets-content {
        @include flex-config($flex-direction: column, $justify-content: space-between);
        @include position(absolute, bottom 0);
        flex: 0 1;
        gap: 0.5rem 1rem;
        width: 100%;

    }

    .ll-widget-item__market-name {
        @include flex-config($justify-content: center, $align-items: center);
        padding: 0 3rem;
        max-width: 100%;

        &.ll-widget-item__market-name--top {
            margin-bottom: 1rem;
        }

        .ll-popover-wrapper {
            text-align: center;
        }
    }

    .ll-widget-item__bets,
    .ll-widget-item__bets-names {
        @include flex-config($justify-content: space-evenly, $align-items: center);
        column-gap: 1rem;
        margin: 0 0.5rem;

        & > * {
            flex: 1;
            text-align: center;
            column-gap: 1rem;
        }

        .ll-popover-wrapper {
            -webkit-line-clamp: 2;
        }

        .ll-widget-item__bet--with-dropdown-value {
            height: 3rem;
            line-height: 3rem;
            border-radius: 0.3rem;
        }

        .ll-widget-item__bet,
        .ll-widget-item__bet-name,
        .ll-widget-item__bet--with-dropdown {
            min-width: 7.2rem;
            max-width: 10rem;
            width: 100%;
            line-height: 1.5rem;

            .ll-widget-item__bet-value {
                height: 3.2rem;
                line-height: 3rem;
                border-radius: 0.3rem;
            }
        }
    }

    &.ll-widget-item--select,
    &.ll-widget-item--bet-builder {
        .ll-widget-item__bets-content {
            @include position(absolute, bottom 0);
            flex: 0 1;
            gap: 0.5rem 1rem;
            width: 100%;
        }

        .ll-widget-item__bets,
        .ll-widget-item__bets--with-dropdown {
            @include flex-config($justify-content: space-evenly, $align-items: center, $flex-direction: column);
        }
    }

    .ll-widget-item__bets-content--bet-builder {
        display: block;
        width: 100%;
        height: 5.7rem;
        padding: 0 2rem;
        flex: none;
        text-align: left;

        .ll-popover-wrapper {
            display: block;
            @include size(100%, calc(100% - 0.8rem));
        }

        .ll-popover-wrapper--desktop {
            display: block;
            @include size(100%, calc(100% - 1rem));
        }


        .ll-widget-item__bets-item-cross--opened {
            @include position(absolute, top 0.5rem right 1rem);
            display: block;
        }

        .ll-widget-item__bets-item-cross--closed {
            display: none;
        }

        &.ll-widget-item__bets-content--bet-builder--overflowed {
            position: relative;
            cursor: pointer;

            .ll-widget-item__bets-item--bet-builder-dots {
                padding-top: 0;
                padding-left: 2rem;

                &::after {
                    transform: translateY(-50%);
                }
            }

            .doruk-dots {
                display: block;
                position: relative;
                width: 25px;
                height: 12px;
                border-radius: 3px;
                bottom: 0;
                left: 0;

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    padding: 0 0.5rem;
                    border-radius: 3px;
                }
            }
        }
    }

    &.ll-widget-item__bets--bet-builder,
    &.ll-widget-item__bets--with-dropdown {

        .ll-widget-item__bet--with-dropdown-value {
            @include flex-config($justify-content: center, $align-items: center);
            min-width: 7.2rem;
            max-width: 10rem;
            width: 100%;
            height: 3rem;
            border-radius: 0.3rem;
            text-align: center;
        }
    }

    .ll-widget-item__bets-box--with-dropdown {
        flex: 1;
        width: 83%;
        margin: 0 1rem 0.8rem;

        .ll-popover-wrapper {
            line-height: 3rem;
        }
    }

    .ll-widget-item__dropdown {
        flex: 1;
        height: 3.2rem;
        @include flex-config($justify-content: space-between, $align-items: center);
        border-radius: 3px;
        cursor: pointer;
        padding: 0 1rem;

        &.ll-dropdown-wrapper--opened {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .ll-widget-item-dropdown-content__bet {
        @include flex-config($justify-content: space-between);
        padding: 1rem;
    }

    .ll-widget-item-dropdown-content__bet-value {
        margin-left: 1rem;
        @include flex-config($align-items: center);
        font-weight: 500;
    }

    &.ll-widget-item--superodds {
        .ll-widget-item__event-name {
            min-height: 11rem;
            justify-content: center;
            text-align: center;

            .ll-popover-wrapper {
                -webkit-line-clamp: 3;
                white-space: break-spaces;
                word-break: break-word;
                word-wrap: break-word;
            }
        }

        .ll-widget-item__bet-value {
            .ll-widget-item__bet-value-original-odd {
                opacity: 0.5;
                text-decoration: line-through;
                margin-right: 0.5rem;
                font-size: 1.2rem;

            }

            .ll-widget-item__bet-value-odd {
                &::before {
                    content: "»";
                    font-size: 1.4rem;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}

%G-ll-widget-item--desktop {
    font-size: 1.1rem;

    .ll-widget-item__game-info,
    .ll-widget-item__info {
        line-height: 1.2rem;
    }

    .ll-widget-item__top-box {
        font-size: 1.2rem;
    }

    .ll-widget-item__bets-content {
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    .ll-widget-item__bet-value {
        font-size: 1.4rem;
    }

    .ll-widget-item__bet--with-dropdown-value-odd {
        font-size: 1.4rem;
    }

    .ll-widget-item__bet-value-odd {
        font-size: 1.4rem;
    }
}

%G-ll-widget-item--tablet-mobile {
    font-size: 1rem;

    .ll-widget-item__game-info,
    .ll-widget-item__info {
        line-height: 1rem;
    }

    .ll-widget-item__top-box {
        font-size: 1.2rem;
    }

    .ll-widget-item__bets-content {
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    .ll-widget-item__bet-value {
        font-size: 1.2rem;
    }

    .ll-widget-item__bet {
        &.ll-widget-item__bet--combined {
            min-width: auto;
            max-width: none;
            margin: 0 1rem;
            width: 95%;
        }
    }

    .ll-widget-item__bet--with-dropdown-value-odd {
        font-size: 1.2rem;
    }

    .ll-widget-item__bet-value-odd {
        font-size: 1.2rem;
    }

    &.ll-widget-item--superodds {
        .ll-widget-item__bet-value-odd {
            font-size: 1.4rem;
        }
    }
}

%G-ll-widget-item-overflowed-content {
    padding: 0.9rem 1rem 1.2rem;
    margin: 1rem !important;
    cursor: pointer;
    min-height: 10rem;
    line-height: 1.1rem;
    border-radius: 0.3rem;

    &.ll-popover--mobile {
        min-height: 10.5rem;
        z-index: 0;
    }

    .ll-widget-item__bets-item--bet-builder:not(:first-child) {
        padding-top: .5rem;
    }

    .ll-widget-item__bets-item-cross--opened {
        @include position(absolute, top 1rem right 1rem);
        font-size: 1rem;
        display: block;
    }

    .ll-widget-item__bets-item-cross--closed {
        display: none;
    }
}

.ll-widget-item__market-dropdown {
    @include flex-config($justify-content: center, $align-items: center);
    max-width: 100%;
    cursor: pointer;
}

.ll-widget-item__bets-item--bet-builder {
    position: relative;
    padding-left: 2rem;
    @include flex-config($justify-content: space-between, $align-items: center);
    font-size: 1.1rem;

    &::before {
        content: "";
        display: block;
        @include size(.5rem);
        @include position(absolute, left .2rem, $z-index: 2);
        border-radius: 5px;
    }

    &::after {
        content: "";
        display: block;
        @include size(.1rem, 100%);
        @include position(absolute, left .4rem, $z-index: 1);
    }

    &:first-child::after {
        transform: translateY(50%);
    }

    &:last-child::after {
        transform: translateY(-50%);
    }
}

%G-ll-widget-item-dropdown-content {
    max-height: 50vh;
    @include flex-config($flex-direction: column);

    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .ll-dropdown-menu-item,
    .ll-dropdown-menu-item--active {
        font-size: 1.1rem;
        cursor: pointer;
    }

    .ll-widget-item-dropdown-content__bet {
        @include flex-config($justify-content: space-between);
        padding: 1rem;
    }

    .ll-widget-item-dropdown-content__bet-value {
        margin-left: 1rem;
        @include flex-config($align-items: center);
        font-weight: 500;
    }
}

