$min-height-desktop: calc(100vh - 18.5rem);
$min-height-mobile: calc(100vh - 16rem);

.lobby-page {
    @include flex-config($flex-direction: column, $align-items: flex-start);
    width: 100%;
    height: 100%;
    min-height: $min-height-mobile;

    .lobby-page--loader-container {
        @include flex-config($align-items: center, $justify-content: center);
        width: 100%;
    }

    .lobby-page__body {
        margin: 1.6rem auto;
    }

    .lobby-page__iframe {
        width: 100%;
        height: 100%;
        border: none;
        min-height: $min-height-mobile;
    }

    .lobby-item {
        width: 100%;
    }

    .lobby-page__body--empty,
    .lobby-item__title {
        margin-bottom: 2rem;
        font-size: 2rem;
        line-height: 2.3rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    .lobby-page__body--empty {
        @include size(100%);
        text-align: center;
    }

    .lobby-item__wrp {
        width: 100%;
        @include flex-config();
    }

    .lobby-item__games {
        flex-shrink: 0;
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
    }

    .lobby-item__game {
        height: 100%;
        flex-shrink: 0;
        position: relative;
        @include flex-config($justify-content: center, $align-items: center);
        border-radius: 3px;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .lobby-item__body {
        flex: 1 1;
        @include flex-config($align-items: center, $justify-content: center);
        position: relative;
        background-position: left top;
        background-repeat: no-repeat;
        border-radius: 0.3rem;
    }

    .lobby-item__body-content {
        @include flex-config($flex-direction: column, $align-items: center, $justify-content: center);
        padding: 0 15%;
    }

    .lobby-item__label-text {
        z-index: 1;
        margin-bottom: 2rem;
        font-size: 4rem;
        font-weight: 400;
        line-height: 4.8rem;
        text-transform: uppercase;
    }

    .lobby-item__btn {
        z-index: 1;
        height: 5rem;
        padding: 0 2.2rem;
        text-transform: uppercase;
        border-radius: 1.2rem;
        line-height: 5rem;
        font-size: 2rem;

        &:hover {
            filter: brightness(1.5);
        }
    }
}

%G-lobby-item-bg {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;

    .lobby-item__body-content {
        background: linear-gradient(to right, var(--lobby-bg-color) 20%, var(--lobby-bg-color) 80%, rgba(0, 0, 0, 0));
        background: linear-gradient(to right,
                rgba(0, 0, 0, 0),
                color-mix(in srgb, var(--lobby-bg-color), transparent 28%) 16%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 26%) 18%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 24%) 20%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 22%) 22%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 20%) 24%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 20%) 76%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 22%) 78%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 24%) 80%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 26%) 82%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 28%) 84%,
                rgba(0, 0, 0, 0) 100%
        );
    }
}

.lobby-page--desktop {
    @extend %G-lobby-item-bg;
    min-height: $min-height-desktop;

    .lobby-page__body {
        width: 85%;
        max-width: 169.6rem;
    }

    .lobby-page__iframe {
        min-height: $min-height-desktop;
    }

    .lobby-item {
        margin-bottom: 4rem;
    }

    .lobby-item__wrp {
        gap: 1.3rem;
    }

    .lobby-item__games {
        max-width: 65%;
        gap: 1.3rem;
    }

    .lobby-item__game {
        width: 176px;
    }

    .lobby-item__body-content {
        height: 23.2rem;
    }
}

.lobby-page--tablet,
.lobby-page--mobile {
    .lobby-item {
        margin-bottom: 3rem;
    }

    .lobby-item__label-text {
        text-transform: uppercase;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
    }
}

.lobby-page--tablet {
    @extend %G-lobby-item-bg;

    .lobby-page__body {
        width: 90%;
    }

    .lobby-item__wrp {
        gap: 1.3rem;
    }

    .lobby-item__games {
        max-width: 36.5rem;
        gap: 1.3rem;
    }

    .lobby-item__game {
        width: 17.6rem;
    }

    .lobby-item__body-content {
        height: 23.2rem;
    }

    .lobby-item__label-text {
        text-align: center;
    }
}

.lobby-page--mobile {
    .lobby-page__body {
        width: 95%;
        max-width: calc(100% - 2rem);
    }

    .lobby-item__wrp {
        gap: 1.1rem;
        max-width: calc(100vw - 2rem);
        overflow: auto;
    }

    .lobby-item__games {
        max-width: 228px;
        gap: 4px;
    }

    .lobby-item__game {
        width: 11.2rem;
    }

    .lobby-item__body {
        justify-content: flex-end;
        padding-right: .1rem;
    }

    .lobby-item__body-content {
        max-width: 85%;
        background: linear-gradient(-90deg, var(--lobby-bg-color) 75%, rgba(0, 0, 0, 0));
        background: linear-gradient(-90deg,
                color-mix(in srgb, var(--lobby-bg-color), transparent 20%) 0,
                color-mix(in srgb, var(--lobby-bg-color), transparent 20%) 66%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 22%) 68%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 24%) 70%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 26%) 72%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 28%) 74%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 30%) 76%,
                color-mix(in srgb, var(--lobby-bg-color), transparent 32%) 78%,
                rgba(0, 0, 0, 0) 100%
        );
        height: 14.8rem;
        align-items: flex-end;
        padding: 0 2rem;
    }

    .lobby-item__body--compact {
        .lobby-item__body-content {
            width: 11.2rem;
            max-width: 100%;
            padding: 0 1rem;
            align-items: center;
        }
    }

    .lobby-item__label-text {
        margin-bottom: 1rem;
        font-size: 2.4rem;
        line-height: 2.9rem;
        text-align: right;
    }

    .lobby-item__title {
        font-size: 1.6rem;
        line-height: 1.8rem;
    }

    .lobby-item__btn {
        height: 3.2rem;
        line-height: 3.2rem;
        font-size: 1.4rem;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
    }
}
