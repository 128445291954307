/*
3.PAGES
├── 3.1. PREMATCH COMMON ELEMENTS
│   ├── 3.1.2. PREMATCH BREADCRUMBS DROPDOWN
│   ├── 3.1.3. PREMATCH RANGED EVENTS PICKER
│   ├── 3.1.4. PREMATCH LL-WIDGETS
│   ├── 3.1.5. SPORT MARKET
│   └── 3.1.6. COMMON MATCH LIST ITEM
├── 3.2. PREMATCH PAGE(desktop)
│   ├── 3.2.1 PREMATCH LEFT MENU
│   ├── 3.1.2 PREMATCH CENTER
│   │   ├── 3.1.2.1 PREMATCH - MATCHLIST
│   │   └── 3.1.2.2 PREMATCH - DETAILS
│   └── 3.1.3 PREMATCH RIGHT CONTENT (COUPONS)
└── 3.1.m PREMATCH PAGE(mobile)
    ├── 3.1.m.1 PREMATCH MOBILE MENU
    ├── 3.1.m.2 PREMATCH TOURNAMENT MOBILE MENU
    ├── 3.1.m.3 MATCH DETAILS
    └── 3.1.m.4 PREMATCH COUPONS
*/

/*
|--------------------------------------------------------------------------
| == 3.1. PREMATCH COMMON ELEMENTS
|--------------------------------------------------------------------------
*/
.ll-page.prematch--tablet,
.ll-page.prematch--desktop {
    @include flex-config($justify-content: space-between);
    @include size(90%, 100%);
    max-width: 170rem;
    margin: 0 auto;

    @include mq('(max-width: 1500px)') {
        width: calc(100% - 1rem);
    }

    .bet--column2.bet--draw {
        flex: 1 !important;
    }
}

%G-pre-market-groups-slider {
    .pre-match-details__market-group,
    .pre-match-details__market-group--active {
        width: auto;
        min-height: 4rem;
        @include flex-config($justify-content: center, $align-items: center);
        display: flex !important;
        position: relative;
        padding: 0 1rem;
        border: none;
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
        cursor: pointer;

        .pre-match-details__progress-bar {
            @include position(absolute, bottom 0);
        }
    }
}

.prematch--mobile {
    position: relative;
    display: flex;
    flex-direction: column;

    .sports-menu__back-drop {
        @include position(absolute, top 0);
        height: 100%;
        width: 100%;
    }
}

.match-tv-modal {
    width: 100%;
    max-width: 30rem;
}

.match-tv-modal__item {
    display: flex;
    align-items: flex-start;
    margin: 1rem 0;
}

.match-tv-modal__item i {
    margin-top: 0.3rem;
    margin-right: 0.5rem;
}

.market-groups__slider--disabled:before {
    content: '';
    @include size(100%);
    @include position(absolute, top 0, $z-index: 2);
}

/*
|--------------------------------------------------------------------------
| == 3.1.2. PREMATCH BREADCRUMBS DROPDOWN
|--------------------------------------------------------------------------
*/

%G-prematch-breadcrumbs-dropdown {
    height: 100%;
    @include flex-config($align-items: center);

    .prematch-breadcrumbs-dropdown__icon {
        display: inline-block;
        margin: 0 .5rem;
        font-size: .8rem;
        transform: rotate(90deg);
        transition: transform .2s ease-in-out;

        &.prematch-breadcrumbs-dropdown__icon--opened {
            transform: rotate(270deg);
        }
    }

    .prematch-breadcrumbs-dropdown__loading {
        align-self: flex-end;
        margin-bottom: .5rem;
    }

    .prematch-breadcrumbs-dropdown__content {
        min-width: 30rem;

        max-height: 60vh;
        @include flex-config($flex-direction: column);
    }

    .prematch-breadcrumbs-dropdown__match-item {
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: .5rem 1rem;
        line-height: 1;
    }

    .prematch-breadcrumbs-dropdown__match-item--match {
        cursor: pointer;
    }

    .prematch-breadcrumbs-dropdown__match-item--date {
        text-transform: capitalize;
    }

    .prematch-breadcrumbs-dropdown__match-date {
        min-width: fit-content;
        font-size: 1.2rem;
    }

    .prematch-breadcrumbs-dropdown__match-name {
        font-size: 1.4rem;
    }
}

.body--with-cursor {
    .prematch-breadcrumbs-dropdown {
        cursor: pointer;

        &:hover {
            background-color: #00000011;
        }
    }
}

%G-prematch-breadcrumbs-dropdown--tablet--desktop {
    width: 100%;
    height: calc(100% - 3.5rem);
    @include position(absolute, bottom 0, $z-index: 11);

    .prematch-breadcrumbs-dropdown__content {
        width: 35rem;
        @include position(absolute, top 9.7rem right 50%, $z-index: 2);
        transform: translateX(50%);
        border-radius: 3px;
        overflow: hidden;
    }

    .prematch-breadcrumbs-dropdown__match-item--match {
        height: 3.2rem;
        line-height: 1;
    }

    .prematch-breadcrumbs-dropdown__match-item--date,
    .prematch-breadcrumbs-dropdown__match-item--breadcrumbs {
        height: 2.5rem;
        font-size: 1.2rem;
    }

    .prematch-breadcrumbs-dropdown__match-item--breadcrumbs {
        font-weight: 500;
    }

    .prematch-breadcrumbs-dropdown__match-name {
        font-weight: 500;
    }
}

%G-prematch-breadcrumbs-dropdown--mobile {
    @include size(100%, 10.5rem);
    @include position(absolute, top 0, $z-index: 11);

    .prematch-breadcrumbs-dropdown__icon {
        margin: 0 .8rem;
        font-size: 1rem;
    }

    .prematch-breadcrumbs-dropdown__content {
        width: calc(100vw - 2rem);
        max-height: calc(80vh - 5rem - 6rem - 5rem - 7rem);
        @include position(absolute, top 3.5rem left 1rem, $z-index: 5);
        border-radius: 3px;
    }

    .prematch-breadcrumbs-dropdown__match-item {
        position: relative;
        min-height: 4rem;
    }

    .prematch-breadcrumbs-dropdown__match-item--tournament {
        @include flex-config($align-items: center);

        .prematch-breadcrumbs-dropdown__match-name {
            width: calc(100%);
            @include flex-config($justify-content: space-between, $align-items: center);
        }
    }

    .prematch-breadcrumbs-dropdown__match-item--date,
    .prematch-breadcrumbs-dropdown__match-item--breadcrumbs {
        min-height: 2rem;
        height: 2rem;
        font-size: 1.2rem;
    }

    .prematch-breadcrumbs-dropdown__match-item--match {
        font-size: 1.4rem;
    }

    .prematch-breadcrumbs-dropdown__match-item--breadcrumbs {
        font-weight: 500;
    }

    .prematch-breadcrumbs-dropdown__progress-bar {
        @include position(absolute, bottom 0px left 0px);
        @include size(100%, 4px);
        overflow: hidden;
    }

    .prematch-breadcrumbs-dropdown__tournament-name {
        @include flex-config($align-items: center);
    }

    .prematch-breadcrumbs-dropdown__tournament-icon {
        margin-right: 2rem;
    }

    .prematch-breadcrumbs-dropdown__match-count {
        padding: .1rem .5rem;
        border-radius: 3px;
        font-size: 1.2rem;
    }

    .prematch-breadcrumbs-dropdown__match-date {
        margin-left: .5rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.3. PREMATCH RANGED EVENTS PICKER
|--------------------------------------------------------------------------
*/

%G-ranged-events-picker {
    .ranged-events-picker__slider {
        height: 100%;
    }

    .slider__content {
        flex: 1;
        align-items: stretch;
    }

    .slider__item {
        height: 100%;
        flex: 1;
    }

    .ranged-events-picker__slider-item {
        @include size(100%);
        @include flex-config($justify-content: center, $align-items: center);
        padding: 0 2rem;
    }
}

%G-ranged-events-picker--tablet--desktop {
    height: 3.2rem;
    overflow: hidden;

    .slider__content {
        height: 3.2rem;
    }
}

%G-ranged-events-picker--mobile {
    overflow: hidden;
    padding: 0 0 1rem 1rem;

    .slider.slider--oversized > div {
        width: 100%;
    }

    .slider__content {
        height: 4rem;
        font-size: 1.4rem;
        gap: 0.2rem;
    }

    .ranged-events-picker__slider-item {
        border-radius: 0.3rem;
    }
}

%G-ranged-events--mobile {
    .ranged-events__back-btn {
        height: 4.8rem;
        padding: 0 1rem;
        @include flex-config($align-items: center);
        font-weight: 500;
    }

    .ranged-events__back-btn-icon {
        transform: rotate(180deg);
    }

    .ranged-events__back-btn-text {
        margin-left: 1rem;
        font-size: 1.6rem;
    }

    .ranged-events__tops-title {
        height: 4rem;
        font-size: 1.6rem;
        @include flex-config($justify-content: center, $align-items: center);
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.4. PREMATCH LL-WIDGETS
|--------------------------------------------------------------------------
*/

%G-ll-sport-widget {
    min-width: 100%;
    max-width: 100%;

    .ll-sport-widget__slider-arrow {
        //height: 3rem !important;
        max-width: 3rem !important;
        min-width: 3rem !important;
    }

    &:not(.ll-sport-widget--empty) {
        & ~ .ll-scrollbox--desktop.match-list {
            height: calc(100% - 21.8rem);
        }
    }

    .slider .slider__item * {
        white-space: initial;
    }

    .slider__arrow {
        height: 4rem;
        top: calc(50% - 2.3rem);
    }
}

%G-ll-sport-widget--desktop {
    border-radius: 3px;
    overflow: hidden;
}

/*
|--------------------------------------------------------------------------
| == 3.1.5. SPORT MARKET
|--------------------------------------------------------------------------
*/

%G-match-details__market {
    .match-details__market-header {
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 0 1rem;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
    }

    .match-details__view-switcher-box {
        width: 6rem;
        min-width: 6rem;
        margin: 0 1rem 0 -1rem;
    }

    .match-details__view-switcher {
        padding: 1rem;

        &::before,
        &::after {
            @include position(absolute, top 1.4rem);
            @include size(.8rem);
            transform: translateY(-50%);
        }

        &::before {
            content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 8 8' style='enable-background:new 0 0 8 8;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bopacity:0.5;%7D .st1%7Bfill:%237C7C7C;%7D %3C/style%3E%3Cg id='Group_2510' transform='translate(-3899 1448)' class='st0'%3E%3Crect id='Rectangle_891' x='3899' y='-1448' class='st1' width='3' height='3'/%3E%3Crect id='Rectangle_900' x='3899' y='-1443' class='st1' width='3' height='3'/%3E%3Crect id='Rectangle_892' x='3904' y='-1448' class='st1' width='3' height='3'/%3E%3Crect id='Rectangle_899' x='3904' y='-1443' class='st1' width='3' height='3'/%3E%3C/g%3E%3C/svg%3E ");
            left: 1.8rem;
        }

        &::after {
            content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bopacity:0.5;%7D .st1%7Bfill:%237C7C7C;%7D %3C/style%3E%3Cg id='Group_2470' transform='translate(-3939 1450)' class='st0'%3E%3Crect id='Rectangle_893' x='3939' y='-1442' class='st1' width='10' height='2'/%3E%3Crect id='Rectangle_893-2' x='3939' y='-1446' class='st1' width='10' height='2'/%3E%3Crect id='Rectangle_893-3' x='3939' y='-1450' class='st1' width='10' height='2'/%3E%3C/g%3E%3C/svg%3E%0A");
            right: 1.8rem;
        }
    }

    span.match-details__view-switcher-switch-base {
        top: .1rem;
        left: .2rem;
    }

    span.match-details__view-switcher-track {
        border-radius: 9px;
    }

    span.match-details__view-switcher-thumb {
        @include size(1.8rem);

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            color: #000;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' height='14' width='14' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'/%3E%3C/svg%3E ");
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .match-details__market-header-name {
        @include flex-config($align-items: center);
        margin-left: -2rem;
        margin-right: .5rem;
    }

    .market__icon--can-cashout,
    .market__icon--cant-cashout {
        width: 1.6rem;
        display: inline-block;
        margin: 0 1.2rem 0 0;
    }

    .match-details__market-expand {
        @include size(4rem, 100%);
        @include flex-config($justify-content: flex-end, $align-items: center);
    }

    .match-details__market-expand-icon {
        transform: rotate(90deg);
        transition: transform .2s ease-in-out;
    }

    .match-details__market-expand-icon--expanded {
        transform: rotate(-90deg);
    }

    .match-details__content {
        height: auto;
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s ease-out;
    }

    .match-details__content--opened {
        max-height: 3000px;
    }

    .fancy-bets-slider-wrp {
        width: 100%;
    }

    .fancy-bets-slider {
        margin-bottom: 0;
        padding: 2.8rem 0;
    }

    .fancy-bets-slider__label {
        top: 40px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.43;
        letter-spacing: 0.01071em;
        position: absolute;
        white-space: nowrap;
        transform: translateX(-50%);
    }
}

%G-match-details__breadcrumbs-common-elems {
    .match-details__header-arrow-btn,
    .match-details__header-arrow-btn--opened {
        .llg-chevron-left {
            display: inline-block;
            transform: rotate(-90deg);
            transition: transform .2s ease-in-out;
        }
    }

    .match-details__header-arrow-btn--opened {
        .llg-chevron-left {
            transform: rotate(90deg);
        }
    }

    .match-details__scoreboard-wrp {
        position: relative;
    }
}

%G-match-details__fancy-slider--desktop {
    .match-details__market {
        .fancy-bets-slider-wrp {
            @include flex-config();
            position: relative;
        }

        .fancy-bets-slider-name {
            @include position(absolute, top .1rem);

            &:first-of-type {
                left: 2rem;
            }

            &:last-of-type {
                left: calc(50% + 2rem);
            }
        }

        .fancy-bets-slider {
            flex: 1;
            padding: 3.2rem 0 2.2rem;
            margin: 0 2rem;

            &:nth-of-type(2n) {
                position: relative;

                &:before {
                    content: "";
                    @include size(.1rem, 100%);
                    @include position(absolute, top 0 left -2rem);
                }
            }
        }

        .fancy-bets-slider__label {
            font-size: 1.2rem;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.6. COMMON MATCH LIST ITEM
|--------------------------------------------------------------------------
*/

%G-common-match-item {
    display: block;
    position: relative;
    padding: .8rem 1rem 1rem 1rem;
    font-size: 1.25rem;
    font-weight: 400;
    transition: 0.3s ease-in-out;

    .matches__item-info-container {
        @include flex-config($justify-content: space-between, $align-items: center);
    }

    &.matches__item--list {
        .matches__title,
        .matches__date {
            font-size: 1.6rem;
            font-weight: 300;
        }

        .matches__date {
            font-size: 1.3rem;
            margin-left: .5rem;
        }

        .matches__title span {
            display: inline-block;
        }
    }

    &.matches__item--filtered {
        .matches__date {
            font-size: 1.2rem;
        }

        .matches__title {
            margin-top: .3rem;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.2;
        }
    }

    .match__icon--can-cashout,
    .match__icon--cant-cashout {
        font-size: 1.2rem;
        margin-right: .5rem;
    }

    .matches__bets {
        list-style: none;
        @include flex-config($align-items: center, $justify-content: space-between);
        margin-top: .5rem;
    }

    .matches__bets-item {
        width: 100%;
        margin: 0 .1rem;

        .matches__bets-btn-value-odd {
            font-weight: 500;
        }

        .matches__bets-btn-value-original-odd {
            text-decoration: line-through;
            font-size: 1.2rem;

            &::after {
                content: "»";
                display: inline-block;
                margin: 0 0.5rem;
                font-size: 1.4rem;
                text-decoration: none;
            }
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    .matches__bets-btn,
    .matches__bets-btn--active {
        width: 100%;
        display: inline-block;
        border-radius: 0.3rem;
        border: 0.1rem solid transparent;
        font-weight: 400;
        font-size: 1.4rem;
        text-align: center;

        .bet__loader {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }

    .matches__odds-count-btn {
        position: relative;
        display: inline-block;

        .match__odds-count-icon {
            margin-left: .5rem;
            font-size: .9rem;
        }

        .bet__loader {
            @include position(absolute, top 50% left 50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }
}

%G-common-match-item--mobile--tablet {
    .matches__bets-btn,
    .matches__bets-btn--active {
        padding: 0.8rem 0.2rem;
    }
}

%G-common-match-item--desktop {
    .matches__bets-btn,
    .matches__bets-btn--active {
        padding: 0.5rem 0.2rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2. PREMATCH PAGE(desktop)
|--------------------------------------------------------------------------
*/
%G-prematch--tablet--desktop {
    position: relative;

    .prematch__loading,
    .prematch__loading--initial {
        @include flex-config($align-items: center, $justify-content: center);
        @include size(100%);
        position: absolute;
        background: rgba(255, 255, 255, 0.4);
        z-index: 10;
    }

    .popular-tournaments {
        width: 100%;

        .popular-tournaments__list-item {
            font-weight: 500;
        }

        .popular-tournaments__list-item-name-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .popular-tournaments__list-item-name {
            margin-right: .5rem;
        }

        .popular-tournaments__sport-icon {
            font-size: 1.4rem;
            margin-right: .3rem;
        }

        .popular-tournaments__match-count {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 2.1rem;
            height: 1.4rem;
            border-radius: 3px;
            font-size: 12px;
        }

        .popular-tournaments__list-item,
        .popular-tournaments__header {
            display: flex;
            position: relative;
            padding: 0 1rem;
            border-radius: 3px;
            font-size: 1.4rem;
            margin-bottom: 0.3rem;
            line-height: 3.2rem;
        }

        .popular-tournaments__list-item {
            justify-content: space-between;
            align-items: center;
        }

        @media only screen and (max-width: 1024px) {
            .popular-tournaments__list-item,
            .popular-tournaments__header {
                font-size: 1.1rem;
            }
        }

        .popular-tournaments__header-icon {
            line-height: inherit;
            margin-right: 0.5rem;
        }

        .popular-tournaments__list {
            @include flex-config($flex-direction: column);
        }
    }

    .prematch__breadcrumbs {
        @include flex-config($align-items: center);
        padding: 0 1rem;
        height: 3.2rem;

        &:not(:first-of-type) {
            margin-top: .3rem;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }

        .prematch__breadcrumbs-sport-icon {
            margin: .8rem;
        }

        .prematch__breadcrumbs-separator {
            margin: 0 .5rem;
        }

        .prematch__breadcrumbs-content {
            // TODO: do smth with trancate on other places than match-detials
            //overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-word;
        }

        .prematch__breadcrumbs-loading {
            margin-top: .7rem;
        }
    }
}

%G-prematch--tablet {
    .prematch__breadcrumbs {
        .ll-breadcrumbs__content {
            margin-top: 0;
            font-size: 1.3rem;
        }
    }

    .match__bet__odds-name,
    .match-details__bet__odds-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }

    .match__bet-value,
    .match__odds-count {
        font-size: 1.3rem;
        font-weight: 600;
    }

    @media screen and (max-width: 1023px) {
        .prematch__breadcrumbs {
            font-size: 1.1rem;
        }

        .match__bet-value,
        .match__odds-count {
            font-size: 1rem;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.1 PREMATCH LEFT MENU
|--------------------------------------------------------------------------
*/

// Menu Component here
%G-prematch__menu {
    @include size(100%, auto);
    position: relative;

    &.prematch__menu--with--panel-opened {
        height: 100%;
        overflow: hidden;
    }

    &.prematch__menu-disabled:before {
        content: '';
        @include size(100%);
        @include position(absolute, top 0, $z-index: 2);
    }

    .prematch__menu-filters-btn {
        height: 3.2rem;
        @include flex-config($justify-content: space-between, $align-items: center);
        margin-bottom: .3rem;
        padding: 0 1rem;
        border-radius: 3px;
        font-size: 1.4rem;
        font-weight: 500;
        cursor: pointer;
    }

    .prematch__menu-filters-btn-icon {
        font-size: 1.6rem;
    }

    .menu__loading {
        @include flex-config($align-items: center, $justify-content: center);
    }

    .menu-item {
        @include flex-config($flex-direction: column);
        position: relative;
        padding: 0 1rem;
        border-radius: 3px;
        line-height: 2.4rem;
        font-size: 1.4rem;
    }

    .menu-item__icon {
        margin-right: .5rem;
    }

    .menu-item__content {
        @include flex-config($align-items: center);

        .ll-popover-wrapper {
            flex-grow: 2;
        }
    }

    .menu-item__text {
        @include mq('(max-width: 1024px)') {
            display: inline;
            line-height: 1.2;
            font-size: 1.1rem;
        }
    }

    .menu-item__after {
        margin-left: 0.5rem;
        white-space: nowrap;

        @include mq('(max-width: 1024px)') {
            font-size: 1.2rem;
        }
    }

    .menu-item__dropdown-icon,
    .menu-item__dropdown-icon--active {
        transition: transform 0.3s;
    }

    .menu-item__dropdown-icon--active {
        transform: rotate(-90deg);
    }

    .menu-item__progress-bar {
        @include position(absolute, bottom 0px left 0px);
        @include size(100%, 4px);
        overflow: hidden;
    }

    .menu-item--sport,
    .menu-item--sub-sport {
        margin-bottom: .3rem;
        line-height: 3.2rem;
    }

    .menu-item--tournament .menu-item__content::before {
        content: '-';
        margin-right: .5rem;
    }

    .ll-toggleable__content--collapsed {
        margin: 0;
    }

    .prematch__menu-extra-btn {
        width: 100%;
        margin-bottom: .3rem;
        border-radius: 3px;
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 0.5rem 1.6rem;
        position: relative;
        font-size: 13px;
    }
}

%G-prematch__menu--with-cursor {
    .menu-item {
        cursor: pointer;
    }

    .prematch__menu-extra-btn:hover {
        opacity: 1;

        &::before {
            content: '';
            top: 50%;
            left: -.3rem;
            position: absolute;
            width: .7rem;
            height: .7rem;
            display: inline-block;
            transform: translateY(-50%) rotate(45deg);
        }
    }
}

%G-prematch__menu--tablet {
    .menu__loading {
        min-height: calc(100vh - 315px);
        max-height: calc(100vh - 315px);
    }

    .prematch__menu-filters-btn {
        font-size: 1.3rem;
    }
}

%G-prematch__menu--desktop {
    min-height: calc(100vh - 210px);

    .menu-item,
    .menu-item--active {
        &.menu-item--category,
        &.menu-item--sub-category,
        &.menu-item--tournament {
            padding-left: 3.1rem;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.2 PREMATCH CENTER
|--------------------------------------------------------------------------
*/
%G-betting-dashboard--tablet--desktop {
    position: relative;
    min-width: 0;
    flex: 1;
    margin-left: 0.5rem;
    font-size: 1.4rem;
    @include flex-config($flex-direction: column);
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.1 PREMATCH - MATCHLIST
|--------------------------------------------------------------------------
*/

%G-match-list--tablet--desktop {
    flex: 1;

    &.ll-scrollbox--tablet {
        height: 100%;
    }

    .match-list__date {
        padding: .3rem .8rem;
        font-size: 1.2rem;
    }

    .prematch__breadcrumbs--list {
        padding: 0 1rem 0 0;
    }

    .match {
        @include flex-config();
        position: relative;
        height: 3.2rem;
        font-size: 1.3rem;
    }

    .match--last-in-list {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        overflow: hidden;
    }

    .match__time {
        @include flex-config($justify-content: center, $align-items: center);
        width: 5.5rem;
        padding-left: .5rem;
        cursor: default;
    }

    .match__time--full {
        @include flex-config($justify-content: center, $align-items: center);
        width: 11%;
        min-width: 8.5rem;
        padding-left: .5rem;
    }

    .match__bets {
        display: flex;
        flex: 1;
    }

    .match__bet {
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 0 0.5rem;

        &.match__bet--disabled {
            cursor: not-allowed;
        }

        &.match__bet--draw {
            flex: 1;
        }
    }

    .match__bet-name {
        padding: 0.6rem 0;
        line-height: 1.4;
        min-width: 0;
    }

    .match__bet-value {
        padding-left: .5rem;
    }

    .match__additional-info-box {
        @include flex-config($align-items: center);
    }

    .match__icon-betbuilder,
    .match__icon-betbuilder--disabled,
    .match__icon--can-cashout,
    .market__icon--cant-cashout,
    .match__tv,
    .match__tv--disabled,
    .match__stats {
        min-width: 2.5rem;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;
    }

    .match__icon-betbuilder {
        font-size: 1.6rem;
        line-height: 1.2;
    }

    .match__icon--can-cashout,
    .market__icon--cant-cashout {
        cursor: default;
    }

    .match__tv--disabled,
    .match__icon-betbuilder--disabled {
        opacity: 0.3;
        cursor: default;
    }

    .match__icon--can-cashout,
    .market__icon--cant-cashout {
        font-size: 1.3rem;
    }

    .match__odds-count {
        @include flex-config($align-items: center, $justify-content: center);
        width: 5.3rem;
        padding: 0 .5rem;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;
    }

    .match__odds-count-icon {
        margin-left: .5rem;
        font-size: 1rem;
    }

    .match__tv--disabled {
        pointer-events: none;
        cursor: default;

        i {
            opacity: 0.3;
        }
    }

    .live-widgets__item {
        margin-top: .3rem;
    }

    /* ---------------------- Outrights ---------------------- */
    .ll-outright-tournament {
        &:last-of-type {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            overflow: hidden;
        }

        .ll-outright-tournament__accordion,
        .ll-outright-tournament__accordion--active {
            @include flex-config($justify-content: space-between, $align-items: center);
            cursor: pointer;
            padding: .5rem .8rem;

            .ll-outright-tournament__accordion-arrow {
                font-size: 1rem;
            }
        }

        .ll-outright-tournament__accordion--active .ll-outright-tournament__accordion-arrow {
            transform: rotate(-90deg);
        }

        .match__time--full {
            min-width: 9rem;
            padding: 0 .5rem;
        }
    }

    .match.ll-outright-event {
        .match__bets {
            @include flex-config($justify-content: space-between, $align-items: center);
            min-width: 0;
            padding: 0 1rem;
            cursor: pointer;
        }

        .match__bet__odds-name {
            flex: 1;
        }

        .match__odds-count {
            padding-left: 1rem;
            border: none;
        }
    }

    /* ---------------------- Tops/Favs ---------------------- */
    .match-list__top-fav-title {
        @include flex-config($align-items: center, $justify-content: center);
        margin-bottom: .3rem;
        padding: 0 1rem;
        line-height: 3.2rem;
        border-radius: 3px;

        font-weight: bold;
    }

    /* ---------------------- Search ---------------------- */
    .match-list__search-title {
        @include flex-config($justify-content: space-between);
        padding: 0 1rem;
        line-height: 3.2rem;
        margin-bottom: 0.3rem;
        font-weight: 400;
    }

    .match-list__search-string {
        padding-left: 5px;
        font-weight: bold;
    }

    .match-list__search-no-data-text {
        @include flex-config($align-items: center, $justify-content: center);
        height: 15rem;
        line-height: 32px;
        border-radius: 3px;
        font-size: 1.3rem;
    }
}

%G-match-list--tablet {
    .match-list__date {
        font-size: 1rem;
    }

    .match {
        height: 3.2rem;
        border-radius: 3px;
        font-size: 1.2rem;

        & > div {
            @include flex-config($justify-content: center, $align-items: center);
        }
    }

    .match__time {
        min-width: 5rem;
        padding-left: 0;
    }

    .match__bet {
        height: 100%;
        @include flex-config($align-items: center);
    }

    .match__bet-name {
        @include flex-config($align-items: center);
        padding: 0;
        line-height: 1.2;
    }

    @media screen and (max-width: 1023px) {
        .match {
            font-size: 1.1rem;
        }

        .match__time {
            min-width: 4rem;
        }

        .match__odds-count-icon {
            margin-left: .3rem;
            font-size: .9rem;
        }
    }
}

%G-filtered-matches-list {
    .filtered-matches-list__breadcrumbs-wrp {
        width: 100%;
        min-height: 2.5rem;
        @include flex-config($align-items: center);
        padding: 0 1rem;
    }

    .filtered-matches-list__no-data-text {
        @include flex-config($align-items: center, $justify-content: center);
        height: 15rem;
        line-height: 3.2rem;
        font-size: 1.3rem;
    }

    .filtered-matches-list__breadcrumbs {
        flex: 1;
        font-size: 1.2rem;
        line-height: 1.2;

        .ll-breadcrumbs__content {
            width: 100%;
            @include flex-config($align-items: center, $flex-wrap: wrap);

            & > * {
                white-space: pre-wrap;
                @include flex-config($align-items: center, $flex-wrap: wrap);
            }
        }
    }

    .filtered-matches-list__breadcrumbs-sport-icon {
        font-size: 1.2rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.1 PREMATCH - DETAILS
|--------------------------------------------------------------------------
*/
%G-match-details--tablet--desktop {
    @extend %G-match-details__breadcrumbs-common-elems;

    .prematch__breadcrumbs {
        .prematch__breadcrumbs-content {
            height: 3.2rem;
            @include flex-config($align-items: center);
        }
    }

    .match-details__error {
        width: 100%;
        padding: 1rem;
        text-align: center;
    }

    .match-details__empty-markets {
        text-align: center;
    }

    .match-details__market {
        @extend %G-match-details__market;
        border-radius: 3px;
        overflow: hidden;
    }

    .match-details__market-header {
        height: 3.2rem;
        text-transform: uppercase;
    }

    .match-details__market .match-details__view-switcher {
        &::before,
        &::after {
            top: 1.2rem;
        }
    }

    .match-details__market--empty {
        display: none;
    }

    .match-details__market-group {
        overflow-x: hidden;
    }

    @extend %G-match-details__fancy-slider--desktop;

    .match-details__bets {
        margin-right: -.2rem;
        @include flex-config($flex-wrap: wrap);

        .bet.column3:nth-child(3n),
        .bet.column2:nth-child(2n),
        .bet:last-child {
            box-shadow: none;
        }
    }

    .match-details__bet {
        @include flex-config($justify-content: space-between);
        line-height: 2.8rem;
        transition: all 0.3s;

        &.match-details__bet--disabled {
            cursor: not-allowed;
        }
    }

    .match-details__bet-name {
        padding: 0 .8rem;
        flex: 1;
        min-width: 0;
    }

    .match-details__bet-value {
        padding: 0 1rem;
    }

    .match-details__market {
        position: relative;
        margin-bottom: .3rem;
    }

    .market__icon--can-cashout,
    .market__icon--cant-cashout {
        font-size: 1.3rem;
    }
}

%G-match-details--tablet {
    font-size: 1.1rem;

    .match-details__market-header {
        font-size: 1.2rem;
    }

    .match-details__bet {
        @include flex-config($align-items: center);
        box-shadow: none;
    }

    .match-details__bet-name {
        @include flex-config($align-items: center);
    }

    .match-details .match-details__bet-name {
        white-space: initial;
    }

    .match-details__bet-value {
        font-size: 1.3rem;
        font-weight: 600;
    }

    @media screen and (max-width: 1023px) {
        .match-details__bet-value {
            font-size: 1.2rem;
        }
    }
}

%G-match-details--desktop {
    width: calc(100% - 2px);

    .prematch__breadcrumbs {
        .prematch__breadcrumbs-text {
            min-width: 3rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-word;
            line-height: 1.1;

            & * {
                word-break: break-word;
            }
        }
    }
}

/* ---------------------- score board ---------------------- */
%G-prematch-scoreboard--desktop {
    .match-details__header-info-competitor {
        min-width: 9rem;
        max-width: 9rem;
        height: 9rem;

        &:after {
            @include size(8rem);
        }
    }

    .match-details__header-info-img {
        height: 8rem;
    }
}

%G-prematch-scoreboard--tablet {
    .match-details__header-info-competitor {
        min-width: 8rem;
        max-width: 8rem;
        height: 8rem;

        &:after {
            @include size(7rem);
        }
    }

    .match-details__header-info-img {
        height: 7rem;
    }
}

%G-prematch-scoreboard--tablet--desktop {
    @include flex-config($align-items: center, $justify-content: space-evenly);
    height: 12.3rem;
    margin-bottom: .1rem;
    background-size: cover;

    .match-details__header-info-competitor {
        flex: 1;
        position: relative;
        @include flex-config(
                $align-items: center,
                $justify-content: space-between,
                $flex-direction: column
        );
        text-align: center;

        &:after {
            content: '';
            @include position(absolute, top .5rem left .5rem, $z-index: 0);
            border-radius: 4rem;
            opacity: .3
        }
    }

    .match-details__header-info-img {
        margin: .5rem;
        object-fit: contain;
        position: relative;
        z-index: 1;
    }

    .match-details__competitor-without-img {
        @include size(100%);
        @include flex-config($align-items: center, $justify-content: center);
    }

    .match-details__competitor-without-img-icon {
        z-index: 1;

        &::before {
            margin-top: .2rem;
            width: 5rem;
            font-size: 5rem;
        }
    }

    .match-details__description {
        max-width: 70%;
        text-align: center;
    }

    .match-details__competitors {
        @include flex-config($align-items: center);
        min-width: 0;
        margin: 1rem 0;
        font-size: 2rem;
        text-transform: uppercase;

        .ll-popover-wrapper {
            min-width: 15rem;
            padding: 0 1rem;
            -webkit-line-clamp: 2;
        }
    }

    .match-details__header-arrow-btn,
    .match-details__header-arrow-btn--opened {
        margin-left: 1rem;
        font-size: 1.5rem;
    }
}

/* ---------------------- OutRights details ---------------------- */
%G-prematch-outright-details {
    .prematch__breadcrumbs {
        .prematch__breadcrumbs-content {
            height: 3.2rem;
            @include flex-config($align-items: center);
        }
    }

    @extend %G-match-details__breadcrumbs-common-elems;

    .match-details__header-info {
        height: 4rem;
    }

    .match-details__header-arrow-btn,
    .match-details__header-arrow-btn--opened {
        margin-left: 1rem;
    }

    .prematch-breadcrumbs-dropdown__content {
        top: 3.5rem;
    }

    .match-details__bets {
        @include flex-config($justify-content: space-between, $flex-wrap: wrap);
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        overflow: hidden;
    }

    .outright-details__bet:nth-child(2n),
    .outright-details__bet:last-child {
        margin-right: 0;
    }

    .outright-details__bet {
        @include flex-config($justify-content: space-between);
        line-height: 3.4rem;
        padding: 0 .5rem;
        cursor: pointer;
        transition: all 0.3s;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.3 PREMATCH RIGHT CONTENT (COUPONS)
|--------------------------------------------------------------------------
*/
%G-coupons__section--desktop {
    min-width: 30rem;
    max-width: 30rem;
    margin-left: 0.5rem;
    position: relative;

    .coupons__scrollbox {
        max-height: 100%;
        height: auto;
    }

    .coupons {
        width: 100%;
    }

    .prematch-widgets {
        width: 100%;
        text-align: center;

        a,
        img,
        a > img {
            display: block;
            width: 100%;
            user-select: none;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.m PREMATCH PAGE(mobile)
|--------------------------------------------------------------------------
*/
%G-prematch--mobile {
    flex: 1;
    padding: 0;

    &.prematch--mobile__with-tickets {
        padding-bottom: 4rem;
    }

    &.prematch--mobile__with-ticket {
        padding-bottom: 6rem;
    }

    .prematch__loading--mobile,
    .ll-preloader__prematch-detail {
        @include flex-config($align-items: center, $justify-content: center);
        @include size(100%);
        z-index: 4;
        top: 0;
    }

    .menu__loading {
        @include flex-config($align-items: center, $justify-content: center);
        @include size(100%, 60vh);
    }

    /* ----------------- Popular Tournaments ----------------- */

    .popular-tournaments {
        width: 100%;
        padding-bottom: 1rem;

        .popular-tournaments__list-item,
        .popular-tournaments__header {
            display: flex;
            position: relative;
            padding: 0 1rem;
            font-size: 1.4rem;
            line-height: 4rem;
        }

        .popular-tournaments__list-item {
            justify-content: center;
            align-items: center;
            margin: 0 .1rem;
            border-radius: .4rem;
        }

        .popular-tournaments__list-item-name-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .popular-tournaments__overflowed-name {
                -webkit-line-clamp: 2;
                word-break: break-word;
                line-height: 1.6rem;
                min-width: 167px;

                .popular-tournaments__list-item-name {
                    white-space: normal;
                }
            }
        }

        .popular-tournaments__sport-icon {
            font-size: 1.4rem;
            margin-right: .3rem;
        }

        .popular-tournaments__match-count {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 2.1rem;
            height: 1.4rem;
            border-radius: 3px;
            margin-left: .5rem;
            font-size: 12px;
        }

        .popular-tournaments__list {

        }

        .popular-tournaments__list-item {
            max-width: 23.2rem;
            font-weight: 500;
        }

        .popular-tournaments__header {
            font-size: 1.6rem;
            line-height: 4rem;
        }

        .popular-tournaments__header-icon {
            line-height: inherit;
            margin-right: 0.5rem;
        }
    }

    .menu__board {
        @include size(100%, auto);
        min-height: 4rem;
        @include flex-config($align-items: center);
        padding: .3rem 1rem;
        font-size: 1.5rem;
    }

    .menu__board-back {
        margin-right: 2rem;
    }

    .menu__board-sport {
        font-size: 1.8rem;
        font-weight: 400;
    }

    .menu-item__after {
        display: flex;
        font-size: 1.4rem;
    }

    .menu-item__after-pre-count,
    .menu-item__after-out-count {
        @include flex-config($justify-content: center, $align-items: center);
        min-width: 5.5rem;
        height: 2.3rem;
    }

    .menu-item__after-pre-count {
        border-radius: 1.3rem 0 0 1.3rem;
    }

    .menu-item__after-out-count {
        position: relative;
        min-width: 4.5rem;
        border-radius: 0 1.3rem 1.3rem 0;

        &::before {
            content: '';
            @include position(absolute, top 0 left 0);
            transform: translateX(-100%);
        }
    }

    .menu__breadcrumbs {
        flex: 1;
        @include flex-config($justify-content: flex-start, $flex-direction: column);
    }

    .menu__breadcrumbs-title {
        font-size: 1.6rem;
        text-transform: capitalize;
    }

    .menu__breadcrumbs-text {
        font-size: 1.4rem;
    }

    .ll-tournament-widgets {
        transition: all 0.3s ease-in-out;
    }

    .ll-tournament-widgets--active {
        height: 100%;
    }

    /* ---------------------- Tournament Details ---------------------- */
    .ll-tournament-detail__accordion {
        font-size: 1.3rem;
        cursor: pointer;

        .ll-accordion__header {
            height: max-content;
            padding: .3rem .5rem;
            justify-content: space-between;
        }

        .ll-accordion__header-text {
            width: calc(100% - 2.2rem);
            line-height: 1.4rem;
        }

        .ll-accordion__icon {
            right: 1rem;
        }
    }

    .ll-tournament__accordion-head {
        @include flex-config($align-items: center, $justify-content: space-between);
        flex-grow: 2;
        min-height: 3rem;
        padding: 0 1rem;
        border-radius: 0;
        font-size: 1.4rem;
        font-weight: 300;
    }

    .ll-tournament-detail__name {
        flex: 1;
        @include flex-config($align-items: center, $justify-content: space-between);
        padding: 0.7rem 1rem;
        font-size: 1.4rem;
    }

    .ll-tournament-detail__text {
        width: 85%;
    }

    .ll-tournament-detail__matches-count {
        @include flex-config($justify-content: center, $align-items: center);
        @include size(4.4rem, 1.8rem);
        border-radius: 1.5rem;
        font-weight: 400;
        text-align: center;
    }

    .ll-tournament__accordion-date-group {
        font-size: 1.3rem
    }

    /* ---------------------- Outrights ---------------------- */

    .ll-outright-tournament__accordion,
    .ll-outright-tournament__accordion--active {
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 1rem;
        border-radius: .3rem;
        font-size: 1.4rem;
        cursor: pointer;

        .matches__item .matches__title {
            @include flex-config($justify-content: flex-start, $align-items: center);
        }
    }

    .ll-outright-tournament__accordion--active .ll-outright-tournament__accordion-arrow {
        transform: rotate(-90deg);
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.m.1 PREMATCH MOBILE MENU
|--------------------------------------------------------------------------
*/
%G-prematch__menu--mobile {
    @include size(100%, auto);
    flex: 1;
    position: relative;
    //min-height: calc(100vh - 20rem); посчитать норомально, тогда можно с боди белый снимать

    .menu-item {
        @include flex-config($flex-direction: column, $justify-content: center);
        min-height: 4rem;
        line-height: 2.4rem;
        position: relative;
        margin-bottom: 0;
        padding: 0 1rem;
        font-size: 1.4rem;
        cursor: pointer;

        .menu-item--active {
            &.menu-item--category,
            &.menu-item--sub-category,
            &.menu-item--tournament {
                margin: 0;
            }
        }
    }

    .menu-item__progress-bar {
        @include position(absolute, bottom 0 left 0);
        @include size(100%, 0.4rem);
        overflow: hidden;
    }

    .menu-item__content {
        @include flex-config($align-items: center);
    }

    .menu-item__name {
        flex-grow: 2;
        margin-right: .2rem;
    }

    .menu-item__icon {
        margin-right: 1.5rem;
    }

    .menu-item--tournament-count {
        min-width: 4.4rem;
        height: 1.8rem;
        @include flex-config($justify-content: center, $align-items: center);
        margin-right: 1rem;
        border-radius: 9px;
    }

    /* ---------------------- Search ---------------------- */

    .prematch-menu__search-title {
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 1rem;
        font-size: 1.4rem;
    }

    .prematch-menu__search-time {
        font-size: 1.2rem;
    }

    .prematch-menu__search-string {
        margin-left: 0.5rem;
        font-weight: 700;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.m.2 PREMATCH TOURNAMENT MOBILE MENU
|--------------------------------------------------------------------------
*/

// TODO: wtf here?
.match-details__all-markets-loading {
    .match-details__all-markets-progress-bar {
        position: relative;
        height: 3px;
        width: 100%;
    }

    width: 100%;
}

/*
|--------------------------------------------------------------------------
| == 3.1.m.3 MATCH DETAILS
|--------------------------------------------------------------------------
*/

%G-sport-outright-match-details--mobile {
    .match-detail__wrapper {
        position: relative;

        .match-detail__back-drop {
            width: 100%;
            height: 100%;
            background-color: #2D2D2D99;
            @include position(absolute, top 0);
            z-index: 4;
        }
    }

    .match-details__header-match-name {
        min-height: 2.8rem;
        max-width: calc(100% - 4.5rem);
        @include flex-config($justify-content: center, $align-items: center);
        position: relative;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.1;
        text-align: center;
        margin: auto;
    }

    @extend %G-match-details__breadcrumbs-common-elems;

    .match-details__header-arrow-btn,
    .match-details__header-arrow-btn--opened {
        height: 2.2rem;
        margin: 0 .5rem;
        font-size: 1.2rem;
    }
}

%G-match-details__title {
    min-height: 4rem;
    padding: 0 1.5rem 0 1rem;
    @include flex-config();
    font-size: 1.2rem;
    line-height: 1.2rem;

    & > * {
        @include flex-config($align-items: center);

        &:not(:last-child) {
            position: relative;
            padding-right: 1rem;

            &::after {
                content: "";
                display: inline-block;
                width: 1px;
                height: 1.2rem;
                @include position(absolute, right 0);
            }
        }

        &:last-child {
            margin-left: 1rem;
        }
    }
}

%G-match-details--mobile {
    .match-details__error {
        width: 100%;
        text-align: center;
        padding: 1rem;
    }

    .match-details__empty-markets {
        text-align: center;
    }

    /* ---------------------- Scoreboard ---------------------- */

    .match-details__header-info {
        @include size(100%, 10.5rem);
        padding: 0.5rem 1rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .match-details__header-competitors {
        @include flex-config($align-items: center, $justify-content: space-between);
    }

    .match-details__competitor-without-img {
        @include flex-config($align-items: center, $justify-content: center);
        @include size(4rem);
        margin: 1rem;
        z-index: 1;
    }

    .match-details__competitor-without-img-icon {
        @include position(absolute, top 1rem left 1.75rem);
        font-size: 3.5rem;
    }

    .match-details__header-info-competitor {
        @include flex-config(
                $align-items: center,
                $justify-content: space-between,
                $flex-direction: column
        );
        min-width: 7rem;
        max-width: 7rem;
        text-align: center;
        position: relative;
        flex: 1;

        &::after {
            content: '';
            @include position(absolute, top 1rem left 1rem, $z-index: 0);
            @include size(5rem);
            border-radius: 3rem;
            background-color: #000000;
            opacity: .3
        }
    }

    .match-details__header-info-img {
        height: 5rem;
        margin: 1rem 0 .5rem 0;
        object-fit: contain;
        z-index: 1;
    }

    .match-details__competitor-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        flex: 1;
    }

    .match-details__description {
        max-width: 50%;
        margin: 0 1rem;
        text-align: center;
    }

    .match-details__date-time {
        @include flex-config($flex-direction: column);
        margin-top: 1rem;
    }

    .match-details__time-range,
    .match-details__date {
        display: block;
    }

    .match-details__time-range {
        margin-top: .5rem;
        font-size: 1.4rem;
    }

    /* ---------------------- Markets ---------------------- */

    .match-details__market {
        @extend %G-match-details__market;

        position: relative;
    }

    .match-details__market-header {
        min-height: 3.6rem;
        font-size: 1.4rem;
        line-height: 1.1;
    }

    .market__icon--can-cashout,
    .market__icon--cant-cashout {
        font-size: 1.2rem;
    }

    .fancy-bets-slider-wrp {
        padding: 0 2rem;
    }

    .fancy-bets-slider-name {
        margin: 1rem 0 -1rem;
    }

    .match-details__bets {
        @include flex-config($flex-wrap: wrap);
    }

    /* ---------------------- Bet ---------------------- */

    .match-details__bet {
        height: 4rem;
        @include flex-config($justify-content: space-between, $align-items: center);
        padding: 0 1rem;

        &.bet--column2 {
            min-width: calc(50% - 1rem);

            .match-details__bet-name {
                margin-right: .5rem;
            }
        }

        &.bet--column3 {
            min-height: 5.8rem;
            height: auto;
            @include flex-config($flex-direction: column, $justify-content: space-evenly);
            text-align: center;

            &.bet--draw {
                min-width: 25%;
                flex: 0;

                @include mq('(max-width: 1023px)') {
                    min-width: 28%;
                }
            }

            &:nth-child(3n),
            &:only-child,
            &:nth-child(3n - 1):last-child {
                box-shadow: none;
            }

            .match-details__bet-value {
                line-height: 1;
            }
        }
    }

    .match-details__bet-name {
        font-size: 1.2rem;
        line-height: 1.1;
    }

    .match-details__bet__odds-name {
        word-break: break-word;
    }

    .match-details__bet-value {
        font-size: 1.4rem;
    }
}

%G-custom-bets--mobile {
    .match-details__bet.bet--column3 {
        min-height: 4rem;
        height: auto;
    }

    .match-details__bet-name {
        @include flex-config($justify-content: center, $align-items: center);
        margin: auto;
        padding: .8rem;
        border-radius: 0.3rem;
        height: 100%;
    }

    .match-details__bet--custom-unavailable {
        opacity: 1;

        span {
            opacity: .4;
        }
    }
}

%G-outright-details--mobile {
    width: 100%;
    overflow-x: hidden;

    .prematch-breadcrumbs-dropdown--mobile {
        height: 4rem;
    }

    .match-details__header-match-name {
        min-height: 4rem;
    }

    .match-detail__wrapper {
        position: relative;
        min-height: calc(100vh - 5rem - 6rem - 5rem - 7rem);

        .match-detail__back-drop {
            width: 100%;
            height: 100%;
            background-color: #2D2D2Daa;
            @include position(absolute, top 0);
            z-index: 4;
        }
    }

    .ll-outright__title {
        margin-bottom: 0.8rem;
        padding: .3rem 1.5rem;
        border-bottom: 1px solid transparent;
        font-size: 1.6rem;
        font-weight: 300;
    }

    .outright-details__item {
        padding: 0.25rem;
    }

    .outright-details__bet {
        padding: 0.8rem;
        margin: 0.5rem;
        border-radius: 0.3rem;
        text-align: center;
        border: 0.1rem solid transparent;
        font-size: 1.4rem;
        font-weight: 400;
    }
}

.match__bet-value-original-odd {
    text-decoration: line-through;
    margin-right: 1rem;
}

.match__bet-value-odd,
.match-details__bet-value-odd,
.outright-details__bet-value-odd {
    font-weight: 500;
}

.team-name-holder {
    display: inline-block !important;
    white-space: nowrap;
}

.prematch__empty-data,
.prematch-details__empty-data,
.prematch-mobile__empty-data {
    height: 100%;
    @include flex-config($align-items: center, $justify-content: center);
}

.prematch__empty-data {
    margin-top: 1rem;
    height: calc(100% - 1rem);
    @include flex-config($align-items: center, $justify-content: center);
}

.prematch__empty-data-img,
.prematch-details__empty-data-img {
    height: calc(80% - 1rem);
}

.prematch-menu__empty-data--desktop,
.prematch-menu__empty-data--tablet {
    height: calc(100vh - 90px - 50px - 45px - 9.7rem);
    padding: 4rem;
    font-size: 1.6rem;
    align-items: flex-start;
}

.prematch-menu__empty-data--tablet {
    height: calc(100vh - 60px - 50px - 50px - 9.7rem);
}

.prematch-mobile__empty-data {
    height: 50vh;
    padding: 2rem;
    flex-direction: column;
    justify-content: space-between;
}

.prematch-mobile__empty-data-msg {
    padding: 2rem;
    font-size: 1.6rem;
    text-align: center;
    border-radius: 3px;
}

.prematch-mobile__empty-data-img {
    width: 90%;
}
