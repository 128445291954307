/*
3.PAGES
└── 3.5. PROMO PAGE
    ├── 3.5.1 PROMO PAGE CARDS LIST
    └── 3.5.2 PROMO PAGE MODAL
*/

/*
|--------------------------------------------------------------------------
| == 3.5.1 PROMO PAGE CARDS LIST
|--------------------------------------------------------------------------
*/

.page-wrapper--mobile,
.page-wrapper--tablet {
    .promo-content {
        flex: 1;
    }
}

%G-promo-content {
    height: 100%;
    margin: 0 1.5rem;
    padding: 1.5rem 0;

    @include mq('desktop') {
        width: 100%;
        max-width: 130rem;
        margin: 0 auto;
        padding: 5.5rem 0;

        .promo-content__cards--odd-cards {
            @include flex-config($flex-wrap: wrap, $justify-content: center);
        }
    }

    .promo-content__loader {
        @include flex-config($align-items: center, $justify-content: center);
        min-height: 100%;
    }

    .promo-content__title {
        margin-bottom: 1.5rem;
        font-weight: 500;
        font-size: 2.8rem;
        text-align: center;
        text-transform: uppercase;

        @include mq('desktop') {
            margin-bottom: 3rem;
            font-size: 2.6rem;
        }
    }

    .promo-content__cards,
    .promo-content__cards--width,
    .promo-content__cards--big {
        @include flex-config($justify-content: center);
        flex-wrap: wrap;
    }

    .promo-content__cards--width {
        .promo-content__item {
            width: 22rem;
        }
    }

    .promo-content__cards--big {
        .promo-content__inner-icon-wrp {
            @include size(6rem);
        }

        .promo-content__inner-title,
        .promo-content__inner-title--empty {
            margin-top: 3rem;
            font-size: 1.6rem;
            font-weight: 500;
        }

        .promo-content__inner {
            padding: 0 3rem 2rem;
        }

        .promo-content__inner--empty {
            padding-top: 6rem;
        }

        .promo-content__inner-title--empty {
            margin-top: 5rem;
        }

        .promo-content__item {
            &:nth-of-type(even) {
                margin-left: 0;
            }

            @include mq('tablet') {
                &:nth-of-type(even) {
                    margin-left: 4%;
                }
            }

            @include mq('mobile') {
                &:nth-of-type(even) {
                    margin-left: 4%;
                }
            }
        }
    }

    .promo-content__item {
        @include flex-config($flex-direction: column, $justify-content: center);
        z-index: 1;
        margin-bottom: 2rem;
        text-align: center;
        transition: 0.3s ease-in-out;
        width: 32rem;
        height: 28rem;

        &:nth-of-type(even) {
            margin-left: 4%;
        }

        @include mq('tablet') {
            &:nth-of-type(even) {
                margin-left: 2%;
            }

            margin: 0 2% 2rem;
        }

        @include mq('desktop') {
            margin: 0 1rem 5rem 1rem!important;
        }
    }

    .promo-content__inner,
    .promo-content__inner--empty {
        position: relative;
        flex-grow: 2;
        padding: 1.4rem;
        padding-top: 0;

        //&::before {
        //    content: '';
        //    @include position(absolute, top 0 left 0);
        //    @include size(100%);
        //    z-index: -1;
        //}
    }

    .promo-content__inner--empty {
        padding-top: 6rem;
    }

    .promo-content__inner-title,
    .promo-content__inner-title--empty {
        margin: 0.8rem 0 1rem;
        font-size: 1.6rem;
        font-weight: 500;
    }

    .promo-content__inner-title--empty {
        margin: 3.5rem 0;
    }

    .promo-content__inner-img {
        @include size(100%);
        @include position(absolute, top 0 left 0);
        z-index: -2;
        object-fit: cover;
    }

    .promo-content__inner-icon-wrp {
        @include flex-config($justify-content: center, $align-items: center);
        @include size(4rem);
        margin: 0 auto;
    }

    .promo-content__btn-wrp {
        @include flex-config($justify-content: space-between);
    }

    .promo-content__btn {
        height: 5.6rem;
        padding: 0;
        flex: 1;
        border-radius: 0;
        line-height: 1.4;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        border: none!important;

        &.ll-btn {
            padding: 0;
        }

        .dx-button-content {
            width: 100%;
        }

        .dx-button-content {
            line-height: inherit;
        }
    }
}

.promo-content__inner-title,
.promo-content__inner-title--empty {
    text-shadow: 2px 1px 5px rgba(0,0,0), 2px -1px 5px rgba(0,0,0);
}

/*
|--------------------------------------------------------------------------
| == 3.5.2 PROMO PAGE MODAL
|--------------------------------------------------------------------------
*/

%G-promo-modal {
    padding: 2rem 2rem 4rem 2rem;

    @include mq('(min-width: 766px)') {
        padding: 3rem;
    }

    .promo-modal__logo {
        display: block;
        width: 13.5rem;
        margin: 2.5rem auto;
    }

    .promo-modal__descr {
        margin-bottom: 4rem;
        text-align: center;
    }

    .promo-modal__btn-wrp {
        @include flex-config($justify-content: center, $align-items: center);
    }

    .promo-modal__btn--primary,
    .promo-modal__btn--secondary {
        @include flex-config($justify-content: center, $align-items: center);
        margin: 0 0.5rem;
        padding: 1rem 1.8rem;
        cursor: pointer;
        border-radius: 0.3rem;
        border: none;

        &:focus,
        &:hover {
            opacity: 1;
        }
    }
}